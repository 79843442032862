import React from 'react';

function OfferAttention(props) {
	return (
		<>
			<div className="detail-body__attention">
				{props.language === 'ja_JP' ? (
					<div>
						<p>※ライブ（一部公演は無観客で事前収録した公演のストリーミング）配信のみとなります。会場で開催する公演と同時刻に、ご自身のデバイス（PC・スマホ・タブレット）にてご鑑賞いただけます。一部のストリーミング配信でも配信予定日時より配信が始まり、その時間のみご鑑賞いただけます。いずれも公演終了後のアーカイブ配信はございません。</p>
						<p>※公演途中（もしくは配信途中）から視聴した場合は、その時点からのライブ（一部は事前収録映像）配信となり、巻き戻しての再生はできません。</p>
						<p>※複数の端末・ブラウザ・タブから同一公演を同時に視聴することはできません。</p>
						<p>※ログインID/PWは、お客様の責任において厳重に管理し、第三者には絶対に教えないでください。お客様の個人情報を他人に知られるだけでなく、クレジットカードの不正利用、悪質サイトへの誘導、ウイルス感染等の被害に繋がる恐れがあります。お客様が故意にアカウント情報を第三者に開示したことによる損害に関して、株式会社インターネットイニシアティブは責任を負いかねます。</p>
						<p>※本配信の一切の権利は株式会社インターネットイニシアティブが有します。動画サイトなどへの無断転載・共有を行った場合、法的責任に問われる場合がございます。</p>
						<p>※インターネット回線の突発的なトラブルによる配信の一時中断の可能性があることをご了承ください。視聴前に必ず動画視聴に適した通信環境をご準備ください。お客様のインターネット環境に伴う閲覧の不具合に関して、株式会社インターネットイニシアティブは責任を負いかねます。</p>
						<p>※ご登録完了後、お客様の都合による変更やキャンセルは承りません。</p>
						<p>※やむを得ぬ事情により内容に変更が生じる可能性がございます。</p>
						<p>※営利目的の転売はいかなる場合でも固くお断りします。</p>
					</div>
				) : (
					<div>
						<p>- Live streaming (some performances will be pre-recorded and streamed without an audience) only. You can watch the show on your own device (PC, smartphone, tablet) at the same time as the performance at the venue. Some streaming transmissions will also start from the scheduled transmission date and time, and you will be able to view them only during that time. There will be no archived streaming after the performance.</p>
						<p>- If you start watching from the middle of the performance (or the middle of the streaming), you will only be able to watch the live performance (or some of the pre-recorded videos) from that point, and you will not be able to rewind.</p>
						<p>- The same performance cannot be viewed from multiple devices, browsers, or tabs at the same time.</p>
						<p>- Please keep your login ID/PW strictly at your own risk and never share it with any third party. It may lead to unauthorized use of your credit card, inducement to malicious sites, virus infection, etc., as well as the disclosure of your personal information to others. Internet Initiative Japan Inc. will not be held responsible for any damages resulting from the intentional disclosure of your account information to a third party.</p>
						<p>- Internet Initiative Japan Inc. retains all rights to the contents of this site. Unauthorized reproduction or sharing of the contents on video sites, etc. may result in legal liability.</p>
						<p>- Please note that there is a possibility of temporary interruption of transmission due to sudden problems with the Internet connection. Please be sure to prepare a suitable communication environment for viewing the video before viewing. Internet Initiative Japan Inc. cannot be held responsible for any problems with viewing caused by your Internet environment.</p>
						<p>- Please note that no changes or cancellations will be accepted after registration.</p>
						<p>- Please note that there is a possibility that the content may change due to unavoidable circumstances.</p>
						<p>- Resale is strictly prohibited under any circumstances.</p>
					</div>
				)}
			</div>
		</>
	);
}

export default OfferAttention;
