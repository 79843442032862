import React from "react"
import {connect} from "react-redux"
import {purchaseTicket} from "../../actions/piano"
import ProgramStateConst from "../../common/ProgramStateConst"

function OfferPurchaseButton(props) {
    if (props.isLocationNG){
        return getButton(props, "購入不可地域","Non-purchasable area",true)
    }
    // return getButton(props, "デバッグモード","Purchased",false)
    switch (props.programState) {
        case ProgramStateConst.purchased:
            return getButton(props, "購入済み","Purchased",true)
        case ProgramStateConst.streaming:
            return "" // 何も表示しない
        case ProgramStateConst.done:
            return ""  // 何も表示しない
        case ProgramStateConst.waitSale:
            return "" // 何も表示しない
        case ProgramStateConst.unavailable:
            return "" // 何も表示しない
        case ProgramStateConst.onSale:
            return getButton(props, "購入する", "Purchase Ticket", false)
        case ProgramStateConst.cancelled:
            return ""
        default:
            return ""
    }
}

function getButton(props, body, body_en, isInvalidation) {
    // 購入不可
    if (isInvalidation) {
        if (props.isSP) {
            return (
                <div
                    className="detail-side__buy-button sp-only before-live-only"
                    title={body}
                    style={{cursor: "default"}}
                >
                    {props.language === "ja_JP"
                        ? body
                        : body_en}
                </div>
            )
        } else {
            return (
                <div
                    className="detail-side__buy-button before-live-only"
                    title={body}
                    style={{cursor: "default"}}
                >
                    {props.language === "ja_JP"
                        ? body
                        : body_en}
                </div>
            )
        }
    } else {
        // 購入可
        if (props.isSP) {
            return (
                <a
                    className="detail-side__buy-button sp-only before-live-only"
                    title={body}
                    onClick={() => {
                        props.purchaseTicket(props.offerId)
                    }}
                >
                    {props.language === "ja_JP"
                        ? body
                        : body_en}
                </a>
            )
        } else {
            return (
                <a
                    className="detail-side__buy-button before-live-only"
                    title={body}
                    onClick={() => {
                        props.purchaseTicket(props.offerId)
                    }}
                >
                    {props.language === "ja_JP"
                        ? body
                        : body_en}
                </a>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    purchaseTicket: (offerId) => dispatch(purchaseTicket(offerId)),
})

export default connect(null, mapDispatchToProps)(OfferPurchaseButton)
