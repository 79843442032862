import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { login, logout, langSwap } from "../../actions/piano"


function MobileMenu(props) {
  const [active, setActive] = useState("")
  const [officialUrl, setOfficialUrl] = useState('');
  const [additionalUrl1, setAdditionalUrl1] = useState('');
	const [additionalTitle1, setAdditionalTitle1] = useState('');
	const [additionalTitle1En, setAdditionalTitle1En] = useState('');
	const [additionalUrl2, setAdditionalUrl2] = useState('');
	const [additionalTitle2, setAdditionalTitle2] = useState('');
	const [additionalTitle2En, setAdditionalTitle2En] = useState('');
	const [additionalTitle3, setAdditionalTitle3] = useState('');
	const [additionalUrl3, setAdditionalUrl3] = useState('');
	const [additionalTitle3En, setAdditionalTitle3En] = useState('');
	const [additionalTitle4, setAdditionalTitle4] = useState('');
	const [additionalUrl4, setAdditionalUrl4] = useState('');
	const [additionalTitle4En, setAdditionalTitle4En] = useState('');

  useEffect(() => {
    if (props.customize) {
      // 公式サイト
      setOfficialUrl(props.customize.official_url);
      // 追加リンク
      setAdditionalUrl1(props.customize.additional_url1);
      setAdditionalTitle1(props.customize.additional_title1);
      setAdditionalTitle1En(props.customize.additional_title1_en);
      setAdditionalUrl2(props.customize.additional_url2);
      setAdditionalTitle2(props.customize.additional_title2);
      setAdditionalTitle2En(props.customize.additional_title2_en);
      setAdditionalUrl3(props.customize.additional_url3);
      setAdditionalTitle3(props.customize.additional_title3);
      setAdditionalTitle3En(props.customize.additional_title3_en);
      setAdditionalUrl4(props.customize.additional_url4);
      setAdditionalTitle4(props.customize.additional_title4);
      setAdditionalTitle4En(props.customize.additional_title4_en);
    }
  },[props.customize])

  const handleActive = () => {
    if (active === "") {
      setActive("active")
    } else if (active === "active") {
      setActive("")
    }
  }
  const handlePurchaseActive = () => {
    if (active === "") {
      setActive("active")
    } else if (active === "active") {
      setActive("")
      window.location.href = "/"
    }
  }

  return (
    <>
      <nav className={`mobile-menu ${active}`}>
        <div className="mobile-menu__line1"/>
        <ul className="mobile-menu-links">
          <li className="mobile-menu-item">
            <Link to="/" title="プログラム" onClick={() => handlePurchaseActive()}>
              {props.language === "ja_JP" ? "プログラム" : "Programs"}
            </Link>
          </li>
          <li className="mobile-menu-item">
            <Link
              to={{
                pathname: "/",
                state: { sortType: "purchased" }
              }}
              title="購入済み"
              onClick={() => handlePurchaseActive()}
            >
              {props.language === "ja_JP" ? "購入済み" : "Purchased"}
            </Link>
          </li>
        </ul>
        <div className="mobile-menu__line2"/>
        <ul className="mobile-menu-links tp-cloak">
          {props.loggedIn ? (
            <>
              <li className="mobile-menu-item show-if-logged-in">
                <Link
                  to="/mypage"
                  title="マイページ"
                  onClick={() => handleActive()}
                >
                  {props.language === "ja_JP" ? "マイページ" : "MyPage"}
                </Link>
              </li>
              <li className="mobile-menu-item show-if-logged-in">
                <a
                  href="#"
                  className="tp-logout"
                  title="ログアウト"
                  onClick={() => {
                    handleActive()
                    props.logout()
                  }}
                >
                  {props.language === "ja_JP" ? "ログアウト" : "Logout"}
                </a>
              </li>
            </>
          ) : (
            <li className="mobile-menu-item show-if-logged-out">
              <a
                href="#"
                className="tp-login"
                title="ログイン"
                onClick={() => {
                  handleActive()
                  props.login()
                }}
              >
                {props.language === "ja_JP" ? "ログイン" : "Login"}
              </a>
            </li>
          )}
        </ul>

        <ul className="menu-small-links">
          <li className="menu-small-links__item">
            <Link
                to="/guide"
                title="ご利用ガイド"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "ご利用ガイド"
                  : "How to use"}
            </Link>
          </li>

          <li className="menu-small-links__item">
            <Link
                to="/test"
                title="動画再生確認"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "動画再生確認"
                  : "Video playback environment confirmation page"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/faq"
              title="よくあるご質問"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP" ? "よくあるご質問" : "FAQ"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/privacy_policy"
              title="プライバシーポリシー"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                ? "プライバシーポリシー"
                : "Privacy Policy"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
                to="/personal_information"
                title="個人情報の取扱いについて"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "個人情報の取扱いについて"
                  : "Acquisition and use of personal information"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/specified_commercial_transaction_law"
              title="特定商取引法に基づく表記"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                ? "特定商取引法に基づく表記"
                : "Specified Commercial Transaction Act"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/term_of_service"
              title="利用規約"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP" ? "利用規約" : "Term of Service"}
            </Link>
          </li>
        </ul>
        {props.language === "ja_JP" ?
          <ul className="mobile-menu-links">
            {officialUrl ? 
              <li className="mobile-menu-item"><a href={officialUrl} target="_blank" title="公式サイト">公式サイト</a></li>
            : '' }
            {additionalUrl1 && additionalTitle1 ?
              <li className="menu-small-links__item"><a href={additionalUrl1} target="_blank" rel="noreferrer" title={additionalTitle1}>{additionalTitle1}</a></li>
              : ''
            }
            {additionalUrl2 && additionalTitle2 ?
              <li className="menu-small-links__item"><a href={additionalUrl2} target="_blank" title={additionalTitle2}>{additionalTitle2}</a></li>
              : ''
            }
            {additionalUrl3 && additionalTitle3 ?
              <li className="menu-small-links__item"><a href={additionalUrl3} target="_blank" title={additionalTitle3}>{additionalTitle3}</a></li>
              : ''
            }
            {additionalUrl4 && additionalTitle4 ?
              <li className="menu-small-links__item"><a href={additionalUrl4} target="_blank" title={additionalTitle4}>{additionalTitle4}</a></li>
              : ''
            }
          </ul>
        :
          <ul className="mobile-menu-links">
            {officialUrl ? 
              <li className="mobile-menu-item"><a href={officialUrl} target="_blank" title="Official Site">Official Site</a></li>
              : '' }
            {additionalUrl1 && additionalTitle1En ?
              <li className="menu-small-links__item"><a href={additionalUrl1} target="_blank" title={additionalTitle1En}>{additionalTitle1En}</a></li>
              : ''
            }
            {additionalUrl2 && additionalTitle2En ?
              <li className="menu-small-links__item"><a href={additionalUrl2} target="_blank" title={additionalTitle2En}>{additionalTitle2En}</a></li>
              : ''
            }
            {additionalUrl3 && additionalTitle3En ?
              <li className="menu-small-links__item"><a href={additionalUrl3} target="_blank" title={additionalTitle3En}>{additionalTitle3En}</a></li>
              : ''
            }
            {additionalUrl4 && additionalTitle4En ?
              <li className="menu-small-links__item"><a href={additionalUrl4} target="_blank" title={additionalTitle4En}>{additionalTitle4En}</a></li>
              : ''
            }
          </ul>
        }

        <ul
          className="language language--ja language--menu"
          onClick={() => props.langswap()}
        >
           <li className={props.language === "ja_JP" ? "language__ja" : "language__en"}>
            <a title="日本語">日本語</a>
          </li>
          <li className="language__slash">/</li>
           <li className={props.language === "en_US" ? "language__ja" : "language__en"}>
            <a title="English">English</a>
          </li>
        </ul>
      </nav>
      <div
        className={`mobile-menu-button ${active}`}
        onClick={() => handleActive()}
      >
        <a href="#">
          <span className="mobile-menu-button__line"></span>
          <span className="mobile-menu-button__line"></span>
          <span className="mobile-menu-button__line"></span>
        </a>
      </div>
    </>
  )
}

const ActiveStyle = {
  display: "inline-block",
  textDecoration: "none",
  color: "#EDEDED",
  cursor: "pointer"
}

const InActiveStyle = {
  display: "inline-block",
  color: "#D56096",
  textDecoration: "none",
  pointerEvents: "none"
}

const mapStateToProps = (state) => {
  return {
    language: state.piano.language,
    loggedIn: state.piano.loggedIn,
    customize: state.customize.customize
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
  logout: () => dispatch(logout()),
  langswap: () => dispatch(langSwap())
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
