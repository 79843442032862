import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { donePageLoading, getSingleOffer, setIsStreaming } from '../actions/offer.js';
import OfferPlayer from '../components/OfferComponentsM/OfferPlayer';
import OfferTitle from '../components/OfferComponentsM/OfferTitle';
import OfferVenue from '../components/OfferComponentsM/OfferVenue';
import OfferPerformer from '../components/OfferComponentsM/OfferPerformer';
import OfferProgram from '../components/OfferComponentsM/OfferProgram';
import OfferOfficialSite from '../components/OfferComponentsM/OfferOfficialSite';
import OfferSurvey from '../components/OfferComponentsM/OfferSurvey';
import OfferSponsors from '../components/OfferComponentsM/OfferSponsors';
import OfferDetailInfo from '../components/OfferComponentsM/OfferDetailInfo';
import PurchaseButton from '../components/Card/PurchaseButton';
import OfferPurchaseButton from '../components/OfferComponentsM/OfferPurchaseButton.js';
import OfferSpOnlyPurchaseButtonAndInfo from '../components/OfferComponentsM/OfferSpOnlyPurchaseButtonAndInfo';
import OfferFreeText from '../components/OfferComponentsM/OfferFreeText';
import OfferRecommendBrowserSp from '../components/OfferComponentsM/OfferRecommendBrowserSp';
import OfferAttention from '../components/OfferComponentsM/OfferAttention';
import OfferChangePlayer from '../components/OfferComponentsM/OfferChangePlayer';
import Cookies from 'js-cookie';

function OfferPage(props) {
	const isFirstRender = useRef(false);
	const isFirstLanguage = useRef(true);
	const [isLoading, setLoading] = useState(true);
	const { offer_id } = props.match.params;

	const [performanceTitle, setPerformanceTitle] = useState();
	const [performanceSubTitle, setPerformanceSubTitle] = useState();
	const [performanceSeries, setPerformanceSeries] = useState();
	const [performanceThumbnail, setPerformanceThumbnail] = useState();

	const [performanceDate, setPerformanceDate] = useState();
	const [performanceOpenTime, setPerformanceOpenTime] = useState();
	const [performanceStartTime, setPerformanceStartTime] = useState();
	const [performanceVenue, setPerformanceVenue] = useState();

	const [performer, setPerformer] = useState();
	const [program, setProgram] = useState();

	// 価格
	const [pianoPrice, setPianoPrice] = useState();

	const [pianoOfferId, setPianoOfferId] = useState();

	const [programState, setProgramState] = useState();
	const [purchase_end_time, setPurchaseEndTime] = useState();
	const [purchase_start_time, setPurchaseStartTime] = useState();

	// 公式サイトURL
	const [official_site_url, setOfficialSiteUrl] = useState();

	// スポンサー
	const [organizer, setOrganizer] = useState();
	const [co_organizer, setCoOrganizer] = useState();
	const [support, setSupport] = useState();
	const [special_co_operation, setSpecialCoOperation] = useState();
	const [organizer_en, setOrganizerEn] = useState();
	const [co_organizer_en, setCoOrganizerEn] = useState();
	const [support_en, setSupportEn] = useState();
	const [special_co_operation_en, setSpecialCoOperationEn] = useState();
	const [support_url, setSupportURL] = useState();
	const [special_co_operation_url, setSpecialCoOperationURL] = useState();
	const [support_logo_url, setSupportLogoURL] = useState();
	const [special_co_operation_logo_url, setSpecialCoOperationLogoURL] = useState();

	// マルチアングル
	const [is_multiple_angles, setIsMultipleAngles] = useState('');
	const [frame_string, setFrameString] = useState('');

	// 日本か判定
	const [is_location_japan, setIsLocationJapan] = useState(true);

	// 公演中止
	const [is_ensemble_cancelled, setIsEnsembleCancelled] = useState('');

	// フリーテキスト
	const [free_text, setFreeText] = useState();

	// アンケート
	const [survey_link_text, setSurveyText] = useState();
	const [survey_link_url, setSurveyUrl] = useState();
	const [survey_applicable, setSurveyApplicable] = useState();

	// プレイヤーハッシュ 通常配信・4K・マルチアングル・字幕
	const [current_script_url, setCurrentScriptUrl] = useState();
	const [current_script_string, setCurrentScriptString] = useState('通常配信');
	const [is_4_K, setIs4K] = useState(false);
	const [is_multi, setMulti] = useState(false);
	const [is_caption, setCaption] = useState(false);

	// 期間
	const [eventPeriod, setEventPeriod] = useState();

	// エラーハンドリング
	useEffect(() => {
		if (props.error !== null && props.error !== undefined) {
			// ネットワークエラー
			if (props.error.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.error.response !== undefined) {
				if (props.error.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.error.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
		// props.err
		if (props.err !== null && props.err !== undefined) {
			// ネットワークエラー
			if (props.err.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.err.response !== undefined) {
				if (props.err.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.err.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
	}, [props.error, props.err]);

	// コピーライト
	const [thum_copyright, setThumCopyright] = useState();

	// アクセス制限
	const [aclType, setAclType] = useState('');
	const [aclTarget, setAclTarget] = useState('');

	useEffect(() => {
		if (isFirstRender.current) {
			props.getSingleOffer(props.loggedIn, offer_id);
		}
	}, [props.loggedIn]);

	useEffect(() => {
		if (isFirstLanguage.current !== true) {
			props.getSingleOffer(props.loggedIn, offer_id);
			isFirstLanguage.curren = false;
		}
	}, [props.language]);

	useEffect(() => {
		if (programState === 'Streaming') {
			props.setIsStreaming(true);
		} else {
			props.setIsStreaming(false);
		}
	});

	// 国セット
	useEffect(() => {
		if (getCookie('_pc_country') === 'JP') {
			setIsLocationJapan(true);
		} else {
			setIsLocationJapan(false);
		}
	});

	useEffect(() => {
		isFirstRender.current = true;
	}, []);

	useEffect(() => {
		setCurrentScriptUrl(props.script);
		if (props.script4K !== undefined && props.script4K !== '') {
			setIs4K(true);
		}
		if (props.scriptMulti !== undefined && props.scriptMulti !== '') {
			setMulti(true);
		}
		if (props.scriptCaption !== undefined && props.scriptCaption !== '') {
			setCaption(true);
		}
	}, [props.script, props.script4K, props.scriptMulti, props.scriptCaption]);

	useEffect(() => {
		switch (current_script_string) {
			case '通常配信':
				setCurrentScriptUrl(props.script);
				break;
			case '4K':
				setCurrentScriptUrl(props.script4K);
				break;
			case 'マルチアングル':
				setCurrentScriptUrl(props.scriptMulti);
				break;
			case '字幕':
				setCurrentScriptUrl(props.scriptCaption);
				break;
			default:
				setCurrentScriptUrl(props.script);
		}
	}, [current_script_string]);

	useEffect(() => {
		let localPurchaseEndTime = ''; // userEffectの中で変数を定義しておくとなんか知らんけどちゃんと表示される
		let localPurchaseStartTime = ''; // userEffectの中で変数を定義しておくとなんか知らんけどちゃんと表示される
		let local_is_ensemble_cancelled; //新しく追加したメタは二回目の1回目の呼び出し時に存在しない・・・
		if (props.offer !== {} && props.offer !== null) {
			if (props.language === 'ja_JP') {
				setPerformanceTitle(props.offer.performance_title);
				setPerformanceSubTitle(props.offer.performance_sub_title);
				setPerformanceSeries(props.offer.series);
				setPerformanceThumbnail(props.offer.performance_thumbnail);
				setPianoPrice(props.offer.piano_price);
				setPianoOfferId(props.offer.piano_offer_id);
				props.offer.meta_struct.forEach(meta => {
					if (meta.meta_field_name === 'performance_date') setPerformanceDate(meta.value);
					if (meta.meta_field_name === 'open_time') setPerformanceOpenTime(meta.value);
					if (meta.meta_field_name === 'performance_start_time') setPerformanceStartTime(meta.value);
					if (meta.meta_field_name === 'venue') setPerformanceVenue(meta.value);
					if (meta.meta_field_name === 'performer') setPerformer(meta.value);
					if (meta.meta_field_name === 'program') setProgram(meta.value);
					if (meta.meta_field_name === 'purchase_end_time') setPurchaseEndTime(meta.value);
					if (meta.meta_field_name === 'purchase_start_time') setPurchaseStartTime(meta.value);
					// 詳細URL
					if (meta.meta_field_name === 'official_site_url') setOfficialSiteUrl(meta.value);

					// スポンサー
					if (meta.meta_field_name === 'organizer') setOrganizer(meta.value);
					if (meta.meta_field_name === 'co_organizer') setCoOrganizer(meta.value);
					if (meta.meta_field_name === 'support') setSupport(meta.value);
					if (meta.meta_field_name === 'special_co_operation') setSpecialCoOperation(meta.value);
					if (meta.meta_field_name === 'support_url') setSupportURL(meta.value);
					if (meta.meta_field_name === 'special_co_operation_url') setSpecialCoOperationURL(meta.value);
					if (meta.meta_field_name === 'support_logo_url') setSupportLogoURL(meta.value);
					if (meta.meta_field_name === 'special_co_operation_logo_url') setSpecialCoOperationLogoURL(meta.value);
					if (meta.meta_field_name === 'is_ensemble_cancelled') setIsEnsembleCancelled(meta.value);
					// アクセス制限
					if (meta.meta_field_name === 'acl_type') setAclType(meta.value);
					if (meta.meta_field_name === 'acl_target') setAclTarget(meta.value);
					if (meta.meta_field_name === 'is_multiple_angles') setIsMultipleAngles(meta.value);
					if (meta.meta_field_name === 'frame_string') setFrameString(meta.value);
					// 購入ボタン用
					if (meta.meta_field_name === 'purchase_end_time') localPurchaseEndTime = meta.value;
					if (meta.meta_field_name === 'purchase_start_time') localPurchaseStartTime = meta.value;
					if (meta.meta_field_name === 'is_ensemble_cancelled') local_is_ensemble_cancelled = meta.value;
					// フリーテキスト
					if (meta.meta_field_name === 'free_text') setFreeText(meta.value);
					// コピーライト
					if (meta.meta_field_name === 'thum_copyright') setThumCopyright(meta.value);
					// アンケート
					if (meta.meta_field_name === 'survey_link_text') setSurveyText(meta.value);
					if (meta.meta_field_name === 'survey_link_url') setSurveyUrl(meta.value);
					// 期間
					if (meta.meta_field_name === 'eventPeriod') setEventPeriod(meta.value);
				});
				setLoading(false);
				setSurveyApplicable(false);
				props.donePageLoading(true);
			} else if (props.language === 'en_US') {
				setPianoOfferId(props.offer.piano_offer_id);
				setPerformanceThumbnail(props.offer.performance_thumbnail);
				setPianoPrice(props.offer.piano_price);
				props.offer.meta_struct.forEach(meta => {
					if (meta.meta_field_name === 'title_en') setPerformanceTitle(meta.value);
					if (meta.meta_field_name === 'episode_en') setPerformanceSubTitle(meta.value);
					if (meta.meta_field_name === 'series_title_en') setPerformanceSeries(meta.value);
					if (meta.meta_field_name === 'performance_date') setPerformanceDate(meta.value);
					if (meta.meta_field_name === 'open_time') setPerformanceOpenTime(meta.value);
					if (meta.meta_field_name === 'performance_start_time') setPerformanceStartTime(meta.value);
					if (meta.meta_field_name === 'venue_en') setPerformanceVenue(meta.value);
					if (meta.meta_field_name === 'performer_en') setPerformer(meta.value);
					if (meta.meta_field_name === 'program_en') setProgram(meta.value);
					if (meta.meta_field_name === 'purchase_end_time') setPurchaseEndTime(meta.value);
					if (meta.meta_field_name === 'purchase_start_time') setPurchaseStartTime(meta.value);
					// 詳細URL
					if (meta.meta_field_name === 'official_site_url') setOfficialSiteUrl(meta.value);
					// スポンサー
					if (meta.meta_field_name === 'organizer_en') setOrganizerEn(meta.value);
					if (meta.meta_field_name === 'co_organizer_en') setCoOrganizerEn(meta.value);
					if (meta.meta_field_name === 'support_en') setSupportEn(meta.value);
					if (meta.meta_field_name === 'special_co_operation_en') setSpecialCoOperationEn(meta.value);
					if (meta.meta_field_name === 'support_url') setSupportURL(meta.value);
					if (meta.meta_field_name === 'special_co_operation_url') setSpecialCoOperationURL(meta.value);
					if (meta.meta_field_name === 'support_logo_url') setSupportLogoURL(meta.value);
					if (meta.meta_field_name === 'special_co_operation_logo_url') setSpecialCoOperationLogoURL(meta.value);
					if (meta.meta_field_name === 'is_ensemble_cancelled') setIsEnsembleCancelled(meta.value);
					// アクセス制限
					if (meta.meta_field_name === 'acl_type') setAclType(meta.value);
					if (meta.meta_field_name === 'acl_target') setAclTarget(meta.value);
					if (meta.meta_field_name === 'is_multiple_angles') setIsMultipleAngles(meta.value);
					if (meta.meta_field_name === 'frame_string_en') setFrameString(meta.value);
					// 購入ボタン用
					if (meta.meta_field_name === 'purchase_end_time') localPurchaseEndTime = meta.value;
					if (meta.meta_field_name === 'purchase_start_time') localPurchaseStartTime = meta.value;
					if (meta.meta_field_name === 'is_ensemble_cancelled') local_is_ensemble_cancelled = meta.value;
					// フリーテキスト
					if (meta.meta_field_name === 'free_text_en') setFreeText(meta.value);
					// コピーライト
					if (meta.meta_field_name === 'thum_copyright') setThumCopyright(meta.value);
					// 期間
					if (meta.meta_field_name === 'eventPeriod_en') setEventPeriod(meta.value);
				});
				setLoading(false);
				setSurveyApplicable(false);
				props.donePageLoading(true);
			}
			let currentDate = new Date();
			let startDate = new Date(props.offer.live_start_date);
			let endDate = new Date(props.offer.live_end_date);
			let purchaseEndTime = new Date(localPurchaseEndTime);
			let purchaseStartTime = new Date(localPurchaseStartTime);

			if (props.offer.isPurchased) {
				//購入後
				if (startDate > currentDate) {
					setProgramState('Purchased'); // 購入済み
				} else if (endDate > currentDate) {
					setProgramState('Streaming'); // 配信中
					setSurveyApplicable(true);
				} else {
					setProgramState('Done'); //公演終了
					setSurveyApplicable(true);
				}
			} else {
				//購入前
				if (purchaseStartTime > currentDate) {
					setProgramState('WaitSale'); //販売前
				} else if (purchaseEndTime > currentDate) {
					setProgramState('OnSale'); //販売中
				} else if (endDate > currentDate) {
					setProgramState('Unavailable'); //販売終了
				} else {
					setProgramState('Done'); // 公演終了
				}
			}
			//中止処理
			if (local_is_ensemble_cancelled) {
				setProgramState('Cancel'); // 公演中止
			}
			isFirstRender.current = false;
		} else {
		}
	}, [props.offer, props.language]);
	if (isLoading) {
		return (
			<div>
				<style jsx="true">{'\
        .global-footer{\
          display:none;\
        }\
      '}</style>
			</div>
		);
	}
	let ifrm = new Date();
	return (
		//dev

		<section className="detail">
			<OfferPlayer is_multiple_angles={is_multiple_angles} programState={programState} script={current_script_url} format={current_script_string} performanceThumbnail={performanceThumbnail} ifrm={ifrm} />
			<div className="player-copyright">{thum_copyright}</div>
			<OfferChangePlayer setCurrentScriptString={setCurrentScriptString} current_script_string={current_script_string} is_4_K={is_4_K} is_multi={is_multi} is_caption={is_caption} />

			{survey_applicable ? <OfferSurvey survey_link_text={survey_link_text} survey_link_url={survey_link_url} programState={programState} language={props.language} /> : ''}
			<div className="detial-content">
				<div className="detail-body">
					<OfferTitle date={performanceDate} startTime={performanceStartTime} language={props.language} title={performanceTitle} subTitle={performanceSubTitle} series={performanceSeries} is_multiple_angles={is_multiple_angles} frame_string={frame_string} />
					<OfferSpOnlyPurchaseButtonAndInfo offerId={pianoOfferId} language={props.language} isSP={true} programState={programState} purchase_end_time={purchase_end_time} pianoPrice={pianoPrice} isLocationNG={isLocationNG(aclType, aclTarget)} isJapan={is_location_japan} />
					<dl className="detail-info">
						<dt className="detail-info__title">{props.language === 'ja_JP' ? '日時・会場' : 'Date・Venue'}</dt>
						<OfferVenue period={eventPeriod} venue={performanceVenue} language={props.language} />
						<dt className="detail-info__title">{props.language === 'ja_JP' ? 'スケジュール' : 'Schedule'}</dt>
						<OfferProgram program={program} />
						<dt className="detail-info__title">{props.language === 'ja_JP' ? '出演' : 'Performer'}</dt>
						<OfferPerformer performer={performer} />
						<OfferRecommendBrowserSp language={props.language} />
					</dl>
					<OfferFreeText language={props.language} freeText={free_text} />
					<OfferOfficialSite official_site_url={official_site_url} language={props.language} />

					<OfferSponsors organizer={organizer} co_organizer={co_organizer} support={support} special_co_operation={special_co_operation} support_url={support_url} special_co_operation_url={special_co_operation_url} support_logo_url={support_logo_url} special_co_operation_logo_url={special_co_operation_logo_url} language={props.language} organizer_en={organizer_en} co_organizer_en={co_organizer_en} support_en={support_en} special_co_operation_en={special_co_operation_en} />
					<OfferAttention language={props.language} />
				</div>
				<aside className="detail-side">
					{props.offer ? <OfferPurchaseButton offerId={pianoOfferId} language={props.language} isSP={false} programState={programState} isLocationNG={isLocationNG(aclType, aclTarget)} /> : ''}
					<OfferDetailInfo language={props.language} purchase_end_time={purchase_end_time} pianoPrice={pianoPrice} isJapan={is_location_japan} />
				</aside>
			</div>
		</section>
	);
}

function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function isLocationNG(aclType, aclTarget) {
	if (aclType === 'whitelist') {
		if (aclTarget === 'JP') {
			let cookieLocation = Cookies.get('_pc_country');
			if (cookieLocation !== 'JP') {
				return true;
			}
		}
	} else if (aclType === 'blacklist') {
		if (aclTarget === 'DE') {
			let cookieLocation = Cookies.get('_pc_country');
			if (cookieLocation === 'DE') {
				return true;
			}
		}
	}
	return false;
}

const mapStateToProps = state => {
	return {
		offer: state.offer.singleOffer,
		language: state.piano.language,
		loggedIn: state.piano.loggedIn,
		hash: state.offer.hash,
		key: state.offer.key,
		error: state.offer.error,
		err: state.offer.err,
		script: state.offer.script,
		script4K: state.offer.script4K,
		scriptMulti: state.offer.scriptMulti,
		scriptCaption: state.offer.scriptCaption,
	};
};

const mapDispatchToProps = dispatch => ({
	getSingleOffer: (isLoggedIn, offer_id) => dispatch(getSingleOffer(isLoggedIn, offer_id)),
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
