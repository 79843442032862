import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PurchaseButton from './PurchaseButton';
import MetaFieldConst from '../../common/MetaFieldConst';
import ProgramStateConst from '../../common/ProgramStateConst';
import OfferDisplayStatusConst from '../../common/OfferDisplayStatusConst';
import BackendResponseProgramStatusConst from '../../common/BackendResponsePerformanceStatusConst';
import Const from '../../common/Const'

function Card(props) {
	const [series, setSeries] = useState('');
	const [performance_title, setPerformance_title] = useState('');
	const [venue, setVenue] = useState('');
	const [thumbnail, setThumbnail] = useState('');
	const [offer_id, setOffer_id] = useState('');
	const [programState, setProgramState] = useState('');
	const [purchase_start_time, setPurchase_start_time] = useState('');
	const [purchase_end_time, setPurchase_end_time] = useState('');
	const [open_time, setOpen_time] = useState('');
	const [performance_date, setPerformance_date] = useState('');
	const [performance_start_time, setPerformance_start_time] = useState('');
	const [is_multiple_angles, setIsMultipleAngles] = useState('');
	const [frame_string, setFrameString] = useState('');
	const [is_ensemble_cancelled, setIsEnsembleCancelled] = useState('');
	const [is_special_performance, setIsSpecialPerformance] = useState('');
	const [archive_purchase_end_time, setArchivePurchaseEndTime] = useState();
	const [displayStatus, setDisplayStatus] = useState(OfferDisplayStatusConst.normal);
	const [offerPageUrl, setOfferPageUrl] = useState('');
	useEffect(() => {
		// クエリパラメーター取得
		if (props.selectedDistributorId) {
			//公演詳細のクエリパラメータありのリンクを作成
			setOfferPageUrl(`/offer/${offer_id}` + Const.newDistributorQueryNonSlash + props.selectedDistributorId)
		} else {
			//公演詳細のリンクを作成
			setOfferPageUrl(`/offer/${offer_id}`)
		}
	}, [offer_id]);
	useEffect(() => {
		let metaJson = props.offerJson.meta_struct;
		let date = '';
		let startTime = '';
		let openTime = '';
		let open_time = '';
		let localPurchaseEndTime = '';
		let localPurchaseStartTime = '';
		let local_is_ensemble_cancelled;
		if (props.language === 'ja_JP') {
			setSeries(props.offerJson.series);
			setPerformance_title(props.offerJson.performance_title);
			setThumbnail(props.offerJson.performance_thumbnail);

			setFrameString('');
			metaJson.forEach(meta => {
				if (meta.meta_field_name === MetaFieldConst.venue) setVenue(meta.value);
				if (meta.meta_field_name === MetaFieldConst.pianoOfferId) setOffer_id(meta.value);
				if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) setPurchase_start_time(meta.value);
				if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) setPurchase_end_time(meta.value);
				if (meta.meta_field_name === MetaFieldConst.performanceDate) date = meta.value;
				if (meta.meta_field_name === MetaFieldConst.performanceStartTime) startTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.isMultipleAngles) setIsMultipleAngles(meta.value);
				if (meta.meta_field_name === MetaFieldConst.frameString) setFrameString(meta.value);
				if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
				if (meta.meta_field_name === MetaFieldConst.archivePurchaseEndTime) setArchivePurchaseEndTime(meta.value);
				// 公演ステータス判定用
				if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) localPurchaseEndTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) localPurchaseStartTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) local_is_ensemble_cancelled = meta.value;
				if (meta.meta_field_name === MetaFieldConst.isSpecialPerformance) setIsSpecialPerformance(meta.value);
				if (meta.meta_field_name === MetaFieldConst.openTime) open_time = meta.value;
			});
		} else if (props.language === 'en_US') {
			setThumbnail(props.offerJson.performance_thumbnail);
			setFrameString('');
			metaJson.forEach(meta => {
				if (meta.meta_field_name === MetaFieldConst.seriesTitleEn) setSeries(meta.value);
				if (meta.meta_field_name === MetaFieldConst.titleEn) setPerformance_title(meta.value);
				if (meta.meta_field_name === MetaFieldConst.venueEn) setVenue(meta.value);
				if (meta.meta_field_name === MetaFieldConst.pianoOfferId) setOffer_id(meta.value);
				if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) setPurchase_start_time(meta.value);
				if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) setPurchase_end_time(meta.value);
				if (meta.meta_field_name === MetaFieldConst.performanceDate) date = meta.value;
				if (meta.meta_field_name === MetaFieldConst.performanceStartTime) startTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.isMultipleAngles) setIsMultipleAngles(meta.value);
				if (meta.meta_field_name === MetaFieldConst.frameStringEn) setFrameString(meta.value);
				if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
				if (meta.meta_field_name === MetaFieldConst.archivePurchaseEndTime) setArchivePurchaseEndTime(meta.value);
				// 公演ステータス判定用
				if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) localPurchaseEndTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) localPurchaseStartTime = meta.value;
				if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) local_is_ensemble_cancelled = meta.value;
				if (meta.meta_field_name === MetaFieldConst.openTime) open_time = meta.value;
			});
		}
		setPerformance_date(new Date(startTime));
		setPerformance_start_time(new Date(startTime));

		// 公演ステータス判定
		switch (props.offerJson.performance_status) {
			case BackendResponseProgramStatusConst.waitSale:
				setProgramState(ProgramStateConst.waitSale); //販売前
				break;
			case BackendResponseProgramStatusConst.onSale:
				if (props.offerJson.isLivePurchased) {
					if (props.offerJson.is_streaming) {
						setProgramState(ProgramStateConst.streaming); // 配信中
					} else {
						setProgramState(ProgramStateConst.purchased); // 購入済み
					}
				} else {
					setProgramState(ProgramStateConst.onSale); //販売中
				}
				break;
			case BackendResponseProgramStatusConst.unavailable:
				if (props.offerJson.isLivePurchased) {
					if (props.offerJson.is_streaming) {
						setProgramState(ProgramStateConst.streaming); // 配信中
					} else {
						setProgramState(ProgramStateConst.purchased); // 購入済み
					}
				} else {
					setProgramState(ProgramStateConst.unavailable); //販売終了
				}
				break;
			case BackendResponseProgramStatusConst.rerun:
				if (props.offerJson.isLivePurchased) {
					setProgramState(ProgramStateConst.rerun); // 見逃し配信中
				} else {
					setProgramState(ProgramStateConst.done); //公演終了
				}
				break;
			case BackendResponseProgramStatusConst.archiveInPreparation:
				setProgramState(ProgramStateConst.archiveInPreparation); //アーカイブ準備中
				break;
			case BackendResponseProgramStatusConst.archiveOnSale:
				if (props.offerJson.isArchivePurchased) {
					if (props.offerJson.is_streaming) {
						setProgramState(ProgramStateConst.archive); // アーカイブ配信中
					} else {
						setProgramState(ProgramStateConst.purchased); // 購入済み
					}
				} else {
					setProgramState(ProgramStateConst.onSale); //販売中
				}
				break;
			case BackendResponseProgramStatusConst.archiveUnavailable:
				if (props.offerJson.isArchivePurchased) {
					if (props.offerJson.is_streaming) {
						setProgramState(ProgramStateConst.archive); // 配信中
					} else {
						setProgramState(ProgramStateConst.purchased); // 購入済み
					}
				} else {
					setProgramState(ProgramStateConst.unavailable); //販売終了
				}
				break;
			default:
				setProgramState(ProgramStateConst.done); //公演終了
				break;
		}
		//中止処理
		if (local_is_ensemble_cancelled) {
			setProgramState(ProgramStateConst.cancelled); // 公演中止
		}
	}, [props]);

	return (
		<React.Fragment>
			<div className={`card ${programState === ProgramStateConst.streaming ? 'performance--live' : programState === ProgramStateConst.done ? 'performance--close' : programState === ProgramStateConst.unavailable ? 'performance--endofsales' : programState === ProgramStateConst.cancelled ? 'performance--endofsales' : programState === ProgramStateConst.rerun ? 'performence--live' : programState === ProgramStateConst.archive ? 'performence--live' : programState === ProgramStateConst.end ? 'performence--endofsales' : ''}`}>
				<a href={offerPageUrl} className="card__inner">
					<div className="card-header">
						<div className="card-header__date">
							{performance_start_time
								? props.language === 'ja_JP'
									? `${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12][performance_start_time.getMonth()]}/${performance_start_time.getDate()} ${['[日]', '[月]', '[火]', '[水]', '[木]', '[金]', '[土]'][performance_start_time.getDay()]}`
									: `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][performance_start_time.getMonth()]} ${performance_start_time.getDate()}, ${['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][performance_start_time.getDay()]}`
								: ''}
							<br className="sp-only" />
							{performance_start_time
								? props.language === 'ja_JP'
									? ` ${performance_start_time.getHours()}:` + (`0` + `${performance_start_time.getMinutes()}`).slice(-2) + ' '
									: ` ${performance_start_time.getHours()}:` + (`0` + `${performance_start_time.getMinutes()}`).slice(-2) + ' '
								: ''}
							<br className="sp-only" />
							{performance_start_time
								? props.language === 'ja_JP'
									? '開演'
									: 'Start'
								: ''}
						</div>
						<div className="card-header__tag sp-only">
							<div className={`card-tag performance__status ${programState === ProgramStateConst.waitSale ? 'card-tag--wait' : programState === ProgramStateConst.streaming ? 'card-tag--live' : programState === ProgramStateConst.purchased ? 'card-tag--purchased' : programState === ProgramStateConst.onSale ? 'card-tag--sale' : programState === ProgramStateConst.done ? 'card-tag--end' : programState === ProgramStateConst.unavailable ? 'card-tag--end' : programState === ProgramStateConst.cancelled ? 'card-tag--cancelled' : programState === ProgramStateConst.rerun ? 'card-tag--rerun' : programState === ProgramStateConst.archive ? 'card-tag--archive' : programState === ProgramStateConst.archiveInPreparation ? 'card-tag--archive-preparation' : 'card-tag--other'}`}>
								{props.language === 'ja_JP' ? getProgramStateJapanese(programState) : programState}
							</div>
						</div>
					</div>
					<div className="card-thumbnail">
						<div className="card-thumbnail__inner">
							{thumbnail !== '' ? <img src={thumbnail} alt="thumbnail" /> : <img src={process.env.REACT_APP_IMAGE_DIR_URL + 'ensemble/DEFAULT_ENSEMBLE_THUMBNAIL.jpeg'} alt="thumbnail" />}
						</div>
						<div className="card-thumbnail__grad"></div>
						{props.smallLogoUrl ?
							<div className="card-thumbnail__logo"><img src={props.smallLogoUrl} alt="" /></div>
							: ''
						}
					</div>
					<div className="card-body">
						<div className="card-tags pc-only">
							<div className={`card-tag performance__status ${programState === ProgramStateConst.waitSale ? 'card-tag--wait' : programState === ProgramStateConst.streaming ? 'card-tag--live' : programState === ProgramStateConst.purchased ? 'card-tag--purchased' : programState === ProgramStateConst.onSale ? 'card-tag--sale' : programState === ProgramStateConst.done ? 'card-tag--end' : programState === ProgramStateConst.unavailable ? 'card-tag--end' : programState === ProgramStateConst.cancelled ? 'card-tag--cancelled' : programState === ProgramStateConst.rerun ? 'card-tag--rerun' : programState === ProgramStateConst.archive ? 'card-tag--archive' : programState === ProgramStateConst.archiveInPreparation ? 'card-tag--archive-preparation' : ''}`}>
								{props.language === 'ja_JP' ? getProgramStateJapanese(programState) : programState}</div>
							{frame_string !== '' ? <div className="card-tag performance__movie-type">{frame_string}</div> : ''}
						</div>
						{/*}
						<div className="performance__date">
							{performance_date
								? props.language === 'ja_JP'
									? `${performance_start_time.getFullYear()}/${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12][performance_start_time.getMonth()]}/${performance_start_time.getDate()} ${['[日]', '[月]', '[火]', '[水]', '[木]', '[金]', '[土]'][performance_start_time.getDay()]} ${performance_start_time.getHours()}:${performance_start_time.getMinutes() === 0 ? '00' : performance_start_time.getMinutes()} 開演`
									: `Start on ${['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][performance_start_time.getDay()]},
									${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][performance_start_time.getMonth()]} ${performance_start_time.getDate()}  ${performance_start_time.getHours()}:${performance_start_time.getMinutes() === 0 ? '00' : performance_start_time.getMinutes()}`
								: ''}
						</div>
	*/}
						<div className="card-body__category">{series}</div>
						<div className="card-body__title">{performance_title}</div>
						<div className="card-body__place">{venue}</div>
						<div className="card-body__deadline">
							{displayStatus === OfferDisplayStatusConst.archive
								? archive_purchase_end_time ? props.language === 'ja_JP'
									? `購入期限： ${dateTimeJPrint(archive_purchase_end_time)}`
									: `Available until: ${dateTimeEPrint(archive_purchase_end_time)}`
									: ''
								: purchase_end_time ? props.language === 'ja_JP'
									? `購入期限： ${dateTimeJPrint(purchase_end_time)}`
									: `Available until: ${dateTimeEPrint(purchase_end_time)}`
									: ''}
						</div>
						<div className="card-tags sp-only">
							{frame_string !== '' ? <div className="card-tag performance__movie-type">{frame_string}</div> : ''}
						</div>
					</div>
				</a>
			</div>
		</React.Fragment>
	);
}

function getProgramStateJapanese(key) {
	switch (key) {
		case ProgramStateConst.purchased:
			return '購入済み';
		case ProgramStateConst.streaming:
			return '配信中';
		case ProgramStateConst.done:
			return '公演終了';
		case ProgramStateConst.onSale:
			return '販売中';
		case ProgramStateConst.waitSale:
			return '販売前';
		case ProgramStateConst.unavailable:
			return '販売終了';
		case ProgramStateConst.cancelled:
			return '公演中止';
		case ProgramStateConst.rerun:
			return '見逃し配信中';
		case ProgramStateConst.archive:
			return 'アーカイブ配信中';
		case ProgramStateConst.archiveInPreparation:
			return 'アーカイブ準備中';
		default:
			return '準備中';
	}
}
function dateTimeJPrint(time) {
	let _dt = new Date(time);
	return _dt.getFullYear() + '年' + (_dt.getMonth() + 1) + '月' + _dt.getDate() + '日 ' + _dt.getHours() + ':' + ('0' + _dt.getMinutes()).slice(-2);
}
function dateTimeEPrint(time) {
	let _dt = new Date(time);
	return _dt.getFullYear() + '/' + (_dt.getMonth() + 1) + '/' + _dt.getDate() + ' ' + _dt.getHours() + ':' + ('0' + _dt.getMinutes()).slice(-2);
}
export default Card;
