import React, { useState, useEffect } from 'react';
import SafeSrcDocIframe from '../../customHooks/safe-src-doc-iframe';

function OfferPlayer(props) {
	var addedScript = '';
	if (props.format === 'マルチアングル') {
		addedScript = `
			<script type="text/javascript" src="../assets/js/ma3/fvLivePlayer/lib/platform.js" crossorigin="anonymous"></script>
			<script type="text/javascript" src="../assets/js/ma3/fvLivePlayer/embed.min.js" crossorigin="anonymous"></script>
			<script type="text/javascript" src="../assets/js/ma3/fvLivePlayer/player_fm.min.js" crossorigin="anonymous"></script>
			<script type="text/javascript" src="../assets/js/ma3/fvLivePlayer/lib/jquery.ui.touch-punch.min.js" crossorigin="anonymous"></script>
			<div id="fvPlayer" class="fvPlayerWrapper">
				<div class="fvVideoView"></div>
			</div>
			<style>.tb_button {padding:1px;cursor:pointer;border-right: 1px solid #8b8b8b;border-left: 1px solid #FFF;border-bottom: 1px solid #fff;}.tb_button.hover {borer:2px outset #def; background-color: #f8f8f8 !important;}.ws_toolbar {z-index:100000} .ws_toolbar .ws_tb_btn {cursor:pointer;border:1px solid #555;padding:3px}   .tb_highlight{background-color:yellow} .tb_hide {visibility:hidden} .ws_toolbar img {padding:2px;margin:0px}</style>
			<script type="text/javascript">
				function resize() {
					var dh = $("#fvPlayer").height();
					$("#fvPlayer iframe",parent.document).height(dh);
				}
				function init(json) {
					var players = new FvLiveFmPlayer("fvPlayer",json);
				}
			</script>
		`;
	} else if (props.format === '4K') {
		addedScript = `
			<script type="text/javascript" src="../assets/js/jquery-3.5.1.min.js"></script>
			<script src="../assets/js/shaka-player.ui.debug.js"></script>
			<link rel="stylesheet" type="text/css" href="../assets/css/controls.css">
			<link rel="stylesheet" type="text/css" href="../assets/css/style.css">
			<div class="player">
				<div class="player-header">
					<p class="setting"></p>
				</div>
				<div class="container">
					<div data-shaka-player-container data-shaka-player-cast-receiver-id="D2E16040" shaka-controls="true" class="shaka-video-container">
						<video autoplay data-shaka-player id="video" style="width:100%; height:100%" class="shaka-video"/>
					</div>
				</div>
			</div>
		`;
	} else {
		addedScript = '';
	}
	const html =
		addedScript +
		`
	  <div id="msp_player">
	    <script src="${props.script}"></script>
	  </div>
		<script>
		const resizeObserver = new ResizeObserver(entries => {
		  for (const entry of entries) {
		    let h = entry.target.clientHeight;
		      let msp_frame = window.top.document.getElementById("msp_frame");
		      msp_frame.style.height = h + "px";
		  }
		});
		const target = document.querySelector('div');
		resizeObserver.observe(target);
		</script>
		`;
	let thumbnailURL;
	if (props.performanceThumbnail === '') {
		thumbnailURL = process.env.REACT_APP_IMAGE_DIR_URL + 'ensemble/DEFAULT_ENSEMBLE_THUMBNAIL.jpeg';
	} else {
		thumbnailURL = props.performanceThumbnail;
	}

	return (
		<>
			<div className="player" id="fvPlayer">
				{props.programState === 'Streaming' && props.script ? (
					<div id="msp_frame">
						<SafeSrcDocIframe id="myFrame" key={props.ifrm} srcDoc={'<body>' + html + '</body>'} sandbox="allow-same-origin allow-top-navigation allow-scripts allow-modals" width="100%" height="100%" frameBorder="0" marginHeight="0px" marginWidth="0px" />
					</div>
				) : (
					<div className="player__thumbnail">
						<img src={thumbnailURL} alt="thumbnail" />
					</div>
				)}
			</div>
			{/*{props.is_multiple_angles ? (*/}
			{/*  getMenu()*/}
			{/*) : (*/}
			{/*  <div className="player-config">*/}
			{/*    <button className="player-config-button active">*/}
			{/*      <span className="player-config-button__icon" />*/}
			{/*      <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*  </div>*/}
			{/*)}*/}
		</>
	);
}

function getMenu() {
	return (
		<>
			<div className="player-config">
				<button className="player-config-button active">
					<span className="player-config-button__icon" />
					<span className="player-config-button__text">通常配信</span>
				</button>
			</div>
			{/* active */}
			{/*<div className="player-config">*/}
			{/*    <button className="player-config-button active">*/}
			{/*        <span className="player-config-button__icon"/>*/}
			{/*        <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*    <div className="pulldown" onClick={menuActive()}>*/}
			{/*        <div className="pulldown__bg"/>*/}
			{/*        <ul className="pulldown-list active">*/}
			{/*            <li className="pulldown-item active">通常配信</li>*/}
			{/*            <li className="pulldown-item">マルチアングル</li>*/}
			{/*        </ul>*/}
			{/*    </div>*/}
			{/*</div>*/}
			{/*// no active*/}
			{/*<div className="player-config">*/}
			{/*    <button className="player-config-button">*/}
			{/*        <span className="player-config-button__icon"/>*/}
			{/*        <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*    <div className="pulldown">*/}
			{/*        <div className="pulldown__bg"/>*/}
			{/*        <ul className="pulldown-list">*/}
			{/*            <li className="pulldown-item active">通常配信</li>*/}
			{/*            <li className="pulldown-item">マルチアングル</li>*/}
			{/*        </ul>*/}
			{/*    </div>*/}
			{/*</div>*/}
		</>
	);
}

export default OfferPlayer;
