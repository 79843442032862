import React from 'react';

function OfferRecommendBrowserSp(props) {
	return (
		<>
			<br/>
				<div className="mobile-menu__line2 sp-only"/>
				<dd className="detail-info__text sp-only">
						{props.language === 'ja_JP' ? (
							<p>
								<a href="/test">動画再生環境確認ページ</a>でご利用する端末およびブラウザで動画が再生できることを確認してください。
							</p>
							) : (
							<p>
								Please confirm that the video can be played on the device and browser you will be using on the video playback environment <a href="/test">confirmation page</a>.
							</p>
						)}
				</dd>
			<dt className="detail-info__title sp-only"> {props.language === 'ja_JP' ? '推奨環境' : 'System Requirements'}</dt>
			<dt className="detail-info__text sp-only">
				{props.language === 'ja_JP' ? (
					<div>
						<div className="detail-info__text-title">【PC】</div>
						<div className="detail-info__text-title">Windows 10</div>
						<p>・Google Chrome（最新版）</p>
						<p>・Mozilla Firefox（最新版）</p>
						<p>・Microsoft Edge（最新版）</p>
						<div className="detail-info__text-title">Mac OSX Catalina(10.15以降)</div>
						<p>・Google Chrome（最新版）</p>
						<p>・Mozilla Firefox（最新版）</p>
						<p>・Safari（最新版）</p>
						<p>【スマートフォン】</p>
						<div className="detail-info__text-title">Android （Android 9以降）</div>
						<p>・Google Chrome（最新版）</p>
						<div className="detail-info__text-title">Apple（iOS 15以降、iPadOS 15以降）</div>
						<p>・Safari（最新版）</p>
						<p>&nbsp;</p>
					</div>
				) : (
					''
				)}
				{props.language === 'en_US' ? (
					<div>
						<div className="detail-info__text-title">【PC】</div>
						<div className="detail-info__text-title">Windows 10</div>
						<p>・Google Chrome（latest）</p>
						<p>・Mozilla Firefox（latest）</p>
						<p>・Microsoft Edge（latest）</p>
						<div className="detail-info__text-title">Mac OSX Catalina(10.15 or later)</div>
						<p>・Google Chrome（latest）</p>
						<p>・Mozilla Firefox（latest）</p>
						<p>・Safari（latest）</p>
						<p>【Smart Phone】</p>
						<div className="detail-info__text-title">Android （Android 9 or later）</div>
						<p>・Google Chrome（latest）</p>
						<div className="detail-info__text-title">Apple（iOS 15 or later、iPadOS 15 or later）</div>
						<p>・Safari（latest）</p>
						<p>&nbsp;</p>
						<p>
							Please confirm that the video can be played on the device and browser you will be using on the video playback environment <a href="/test">confirmation page</a>.
						</p>
					</div>
				) : (
					''
				)}
			</dt>
		</>
	);
}

export default OfferRecommendBrowserSp;
