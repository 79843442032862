import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FileNameConst from "../../common/FileNameConst";
import { isFileExist } from "../../common/CommonUtil";

function Footer(props) {
	const [jasracImgPass, setJasracImgPass] = useState('');
	//logoImagePath[0]:ja_header_file, logoImagePath[1]:en_header_file 
	const [logoImagePath, setlogoImagePath] = useState([]);

	useEffect(() => {
		//イメージファイルをS3から取得する
		getJasracImg();
		getLogoImg();
	}, []);

	const getJasracImg = () => {
		setJasracImgPass(getFilePath(FileNameConst.JASRAC_IMG))
	}

	const getLogoImg = () => {
		setlogoImagePath( (prevState) => ([ ...prevState, getFilePath(FileNameConst.LOGO_JA_IMG) ]) )
		setlogoImagePath( (prevState) => ([ ...prevState, getFilePath(FileNameConst.LOGO_EN_IMG) ]) )
	}
	function getFilePath(fileImage) {
		const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + fileImage);
		return (FileExistFlag) ? process.env.REACT_APP_S3_PASS_DEFAULT + fileImage : process.env.REACT_APP_DEFAULT_IMG_PASS + fileImage ;
	}

	if (props.donePageLoading !== true) {
		return <p></p>;
	}
	return (
		<>
			<style jsx="true">
				{`
          			.footer-external {
						position: absolute;
						bottom: 60px;
						right: 40px;
						list-style: none;
					}
					.footer-external li {
						width: 60px;
						height: auto;
						float: right;
					}
					.footer-external li img {
						width: 100%;
					}
					.footer-external li p {
						font-size: 0.6rem;
						line-height: 1.2em;
					}
        		`}
			</style>
			{props.language === 'ja_JP' ? (
				<>
					<footer className="global-footer">
						<div className="global-footer__inner">
							<div className="global-footer__logo">
								<img src={logoImagePath[0]} alt="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO" />
							</div>

							<div className="footer-links-wrapper">
								<ul className="footer-links">
									<li className="footer-links__item">
										<a href="/guide" title="ご利用ガイド">
											ご利用ガイド
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/test" title="動画再生確認">
											動画再生確認
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/faq" title="よくあるご質問">
											よくあるご質問
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/privacy_policy" title="プライバシーポリシー">
											プライバシーポリシー
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/personal_information" title="個人情報の取扱いについて">
											個人情報の取扱いについて
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/specified_commercial_transaction_law" title="特定商取引法に基づく表記">
											特定商取引法に基づく表記
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/term_of_service" title="利用規約">
											利用規約
										</a>
									</li>
								</ul>
								<ul className="footer-social">
									<li className="footer-links__item"><a href="https://www.tokyo-harusai.com/" target="_blank" rel="noreferrer" title="東京･春･音楽祭 公式サイト">東京･春･音楽祭 公式サイト</a></li>
									<li className="footer-links__item"><a href="https://harusai.base.shop/" target="_blank" rel="noreferrer" title="公式オンラインショップ">公式オンラインショップ</a></li>
									<li className="footer-links__item"><a href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer" title="東京･春･音楽祭 ご支援のお願い">東京･春･音楽祭 ご支援のお願い</a></li>
									<li className="footer-social__item footer-social__item--facebook">
										<a href="https://www.facebook.com/tokyoharusai/" title="Facebook" target="_blank" rel="noreferrer"><span>Facebook</span></a>
									</li>
									<li className="footer-social__item footer-social__item--twitter">
										<a href="https://twitter.com/tokyo_harusai" title="Twitter" target="_blank" rel="noreferrer"><span>Twitter</span></a>
									</li>
									<li className="footer-social__item footer-social__item--instagram">
										<a href="https://www.instagram.com/springfestivalintokyo/" title="Instagram" target="_blank" rel="noreferrer"><span>Instagram</span></a>
									</li>
									<li className="footer-social__item footer-social__item--youtube">
										<a href="https://www.youtube.com/user/tokyoHARUSAI" title="YouTube" target="_blank" rel="noreferrer"><span>YouTube</span></a>
									</li>
								</ul>
								<ul className="footer-external">
									<li>
										<img src={jasracImgPass} alt="JASRAC" />
										<p></p>
									</li>
								</ul>
							</div>
							<p className="powerdby">IIJ Teatrista</p>
							<p className="copyright" />
						</div>
					</footer>
				</>
			) : (
				<>
					<footer className="global-footer">
						<div className="global-footer__inner">
							<div className="global-footer__logo">
								<img src={logoImagePath[1]} alt="Tokyo Spring Music Festival" />
							</div>

							<div className="footer-links-wrapper">
								<ul className="footer-links">
									<li className="footer-links__item">
										<a href="/guide" title="How to use">
											How to use
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/test" title="Video playback environment confirmation">
											Video playback environment confirmation
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/faq" title="FAQ">
											FAQ
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/privacy_policy" title="Privacy Policy">
											Privacy Policy
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/personal_information" title="Acquisition and use of personal information">
											Acquisition and use of personal information
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/specified_commercial_transaction_law" title="Specified Commercial Transaction Act">
											Specified Commercial Transaction Act
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/term_of_service" title="Term of Service">
											Term of Service
										</a>
									</li>
								</ul>
								<ul className="footer-social">
									<li className="footer-links__item"><a href="https://www.tokyo-harusai.com/" target="_blank" rel="noreferrer"  title="Official Site">Spring Festival in Tokyo Official Site</a></li>
									<li className="footer-links__item"><a href="https://harusai.base.shop/" target="_blank" rel="noreferrer"  title="Official Online Shop">Official Online Shop</a></li>
									<li className="footer-links__item"><a href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer" title="Support Spring Festival in Tokyo">Support Spring Festival in Tokyo</a></li>
									<li className="footer-social__item footer-social__item--facebook">
										<a href="https://www.facebook.com/tokyoharusai/" title="Facebook" target="_blank" rel="noreferrer"><span>Facebook</span></a>
									</li>
									<li className="footer-social__item footer-social__item--twitter">
										<a href="https://twitter.com/tokyo_harusai" title="Twitter" target="_blank" rel="noreferrer"><span>Twitter</span></a>
									</li>
									<li className="footer-social__item footer-social__item--instagram">
										<a href="https://www.instagram.com/springfestivalintokyo/" title="Instagram" target="_blank" rel="noreferrer"><span>Instagram</span></a>
									</li>
									<li className="footer-social__item footer-social__item--youtube">
										<a href="https://www.youtube.com/user/tokyoHARUSAI" title="YouTube" target="_blank" rel="noreferrer"><span>YouTube</span></a>
									</li>
								</ul>
								<ul className="footer-external">
									<li>
										<img src={jasracImgPass} alt="JASRAC" />
										<p></p>
									</li>
								</ul>
							</div>
							<p className="powerdby">IIJ Teatrista</p>
							<p className="copyright" />
						</div>
					</footer>
				</>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

export default connect(mapStateToProps)(Footer);
