import React, { useEffect} from "react"
import { connect } from "react-redux"
import { showPasswordReset } from "../actions/piano"
import { setIsStreaming, donePageLoading } from "../actions/offer"
import queryString from 'query-string';

function PasswordReset(props) {
  useEffect(() => {
    props.donePageLoading(false)
    props.setIsStreaming(false)
    // クエリパラメーター取得
    //const queryString = require('query-string');
    let query = queryString.parse(props.location.search);
    if (query !== undefined){
      props.showPasswordReset(query,gotoTopPage);
    }
  }, [])

  const gotoTopPage = () =>{
    window.location.href = "/"
  }

  window.setTimeout(function () {
    props.donePageLoading(true)
  }, 1000)
  return (
    <div>
      <div id={"my-account"} style={{margin:"15px"}}/>
      <div id="myPage"/>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  showPasswordReset: (query,gotoTopPage) => dispatch(showPasswordReset(query,gotoTopPage)),
  setIsStreaming: (state) => dispatch(setIsStreaming(state)),
  donePageLoading: (state) => dispatch(donePageLoading(state))
})

export default connect(null, mapDispatchToProps)(PasswordReset)
