import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import FileNameConst from '../common/FileNameConst';
import { isFileExist } from "../common/CommonUtil";

function InternalServerError(props) {
  const [status_code, setStatusCode] = useState(500);
  const [logoSqImgPass, setLogoSqImgPass] = useState('');

  useEffect(() => {
    props.setIsStreaming(false);
    props.donePageLoading(true);
    if (props.statusCode !== undefined) {
      setStatusCode(props.statusCode);
    }
    //イメージファイルをS3から取得する
    getLogoSqImg();
  }, []);

  const getLogoSqImg = () => {
    // ファイル存在チェック
    const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG);
    if (FileExistFlag) {
      setLogoSqImgPass(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG);
    } else {
      //デフォルトイメージを取得する。
      setLogoSqImgPass(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.LOGO_SQ_IMG);
    }
  }
  return (
    <section className="unavailable">
      <style jsx="true">
        {`

    .unavailable {
      width: 60%;
      margin: 4em auto;
      text-align: center;
      background-size: 100% 100%;
      background-position: center bottom;
    }
      .unavailable img {
        width: 120px;
        height: auto;
      }
      .unavailable h1 {
        font-size: 38px;
        font-weight: bold;
        line-height: 2em;
      }
      .unavailable h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 2em;
      }
      .unavailable .unavailable-page {
        width: 100%;
        text-align: center;
      }
      .unavailable .unavailable-page p {
        text-align: center;
        line-height: 1.6em;
        margin-bottom: 1.6em;
      }
        .unavailable .unavailable-page p.last {
          margin-bottom: 4em;
        }
        .unavailable .unavailable-page p.list {
          font-weight: bold;
        }
      .unavailable .btn-basic {
        position: relative;
        background: #666;
        text-align: center;
        color: #fff;
        line-height: 2em;
        padding: 1em;
        border-radius: 40px;
        text-decoration: none;
        font-weight: bold;
      }
      @media (max-width: 1149px) {
        .unavailable {
          width: 80%;
        }
        .unavailable h1 {
          font-size: 24px;
        }
        .unavailable h2 {
          font-size: 12px;
        }
      }

            `}
      </style>
      <div className="unavailable-page">
        <img src={logoSqImgPass} alt="東京･春･音楽祭" />
        <h1>サービスを利用できません</h1>
        <h2>Service Temporarily Unavailable</h2>
        <p>
          ご利用いただきありがとうございます。
          <br />
          指定されたページはメンテナンスもしくはアクセスが集中しているため表示できません。
        </p>
        <p>しばらくしてからもう一度お試しください。</p>
        <p className="last">
          The server is temporarily unable to service your request
          <br /> due to maintenance downtime or capacity problems.
          <br />
          Please try again after a while.
        </p>
        <a href="/" className="btn-basic">
          TOP
        </a>
      </div>
    </section>
  );
}

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(null, mapDispatchToProps)(InternalServerError);
