import {
  LOGIN,
  LOGGEDIN,
  LOGOUT,
  LOGGEDOUT,
  INITIALIZE,
  LANGSWAP,
  LANGJP,
  LANGEN,
  PURCHASETICKET,
  SHOWMYACCOUNT, SHOW_PASSWORD_RESET
} from "../actions/piano"

const initialState = {
  offers: null,
  loggedIn: null,
  language: null
}

const piano = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return { ...state }
    case LOGIN:
      return { ...state }
    case LOGGEDIN:
      return { ...state, loggedIn: true }
    case LOGOUT:
      return { ...state, loggedIn: false }
    case LOGGEDOUT:
      return { ...state, loggedIn: false }
    case LANGSWAP:
      return { ...state }
    case LANGJP:
      return { ...state, language: "ja_JP" }
    case LANGEN:
      return { ...state, language: "en_US" }
    case PURCHASETICKET:
      return { ...state }
    case SHOWMYACCOUNT:
      return { ...state }
    case SHOW_PASSWORD_RESET:
      return { ...state }
    default:
      return state
  }
}

export default piano
