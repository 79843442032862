import React, { useState, useEffect } from "react"

function OfferTopData(props) {
  let datest = new Date(props.startTime);
  let datest2 = new Date(props.startTime).toLocaleTimeString('en-US');

  const [dayOfWeek2, setDayOfWeek2] = useState()
  useEffect(() => {
    let date = new Date(props.date)
    if (props.language === "ja_JP") {
      setDayOfWeek2(["日", "月", "火", "水", "木", "金", "土"][date.getDay()])
    }
    if (props.language === "en_US") {
      setDayOfWeek2(
        ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]
      )
    }
  }, [props])
  return (
      <>
        {props.language === "ja_JP"
            ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${dayOfWeek2}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} 開演`
            : ""}
        {props.language === "en_US"
            ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${dayOfWeek2}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} Start`
            : ""}
      </>
  )
}

export default OfferTopData
