import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { purchaseTicket } from '../../actions/piano';
function PurchaseButton(props) {
	let date = new Date(props.purchase_end_time);
	if (props.programState === 'Purchased' || props.programState === 'Streaming') {
		return (
			<a
				className="performance__link"
				// target="_blank"
				href={`/offer/${props.offerId}`}
			>
				{props.language === 'ja_JP' ? '視聴する' : 'Play'}
			</a>
		);
	} else if (props.programState === 'OnSale') {
		return (
			<>
				<a
					className="performance__link"
					// target="_blank"
					// onClick={() => props.purchaseTicket(props.offerId)}
					href={`/muti/${props.offerId}`}
				>
					{props.language === 'ja_JP' ? '詳細を見る・登録する' : 'See details / Purchase now'}
				</a>
				<div className="performance__limit"> {`${props.language === 'ja_JP' ? `登録期限 ${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][date.getMonth()]}/${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}` : `Available until ${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]},${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`}`}</div>
			</>
		);
	} else {
		return '';
	}
}

const mapDispatchToProps = dispatch => ({
	purchaseTicket: offerId => dispatch(purchaseTicket(offerId)),
});

export default connect(null, mapDispatchToProps)(PurchaseButton);
