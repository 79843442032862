import React from 'react';

function OfficialSiteButton(props) {
	if (props.language === 'ja_JP') {
		return (
			<>
				<a className="official-site-link" href="/guide" title="ご利用ガイド">
					ご利用ガイド
				</a>
				<a className="guide-link" href="https://www.tokyo-harusai.com/" title="公式サイト" target="_blank" rel="noreferrer">
					公式サイト
				</a>
				<ul className="header-social">
					<li className="header-social__item header-social__item--facebook">
						<a href="https://www.facebook.com/tokyoharusai/" title="Facebook" target="_blank" rel="noreferrer" />
					</li>
					<li className="header-social__item header-social__item--twitter">
						<a href="https://twitter.com/tokyo_harusai" title="Twitter" target="_blank" rel="noreferrer" />
					</li>
					<li className="header-social__item header-social__item--instagram">
						<a href="https://www.instagram.com/springfestivalintokyo/" title="Instagram" target="_blank" rel="noreferrer"></a>
					</li>
					<li className="header-social__item header-social__item--youtube">
						<a href="https://www.youtube.com/user/tokyoHARUSAI" title="YouTube" target="_blank" rel="noreferrer"></a>
					</li>
				</ul>
			</>
		);
	} else {
		return (
			<>
				<a className="official-site-link" href="/guide" title="How to use">
					How to use
				</a>
				<a className="guide-link" href="https://www.tokyo-harusai.com/" title="Official Site" target="_blank" rel="noreferrer">
					Official Site
				</a>
				<ul className="header-social">
					<li className="header-social__item header-social__item--facebook">
						<a href="https://www.facebook.com/tokyoharusai/" title="Facebook" target="_blank" rel="noreferrer" />
					</li>
					<li className="header-social__item header-social__item--twitter">
						<a href="https://twitter.com/tokyo_harusai" title="Twitter" target="_blank" rel="noreferrer" />
					</li>
					<li className="header-social__item header-social__item--instagram">
						<a href="https://www.instagram.com/springfestivalintokyo/" title="Instagram" target="_blank" rel="noreferrer"></a>
					</li>
					<li className="header-social__item header-social__item--youtube">
						<a href="https://www.youtube.com/user/tokyoHARUSAI" title="YouTube" target="_blank" rel="noreferrer"></a>
					</li>
				</ul>
			</>
		);
	}
}

export default OfficialSiteButton;
