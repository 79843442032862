import React from "react"
import {connect} from "react-redux"
import {login, logout} from "../../actions/piano"
import {useWindowDimensions} from "../../customHooks/useWindowDimensions"

function LoginLogoutForm(props) {
    const {width, height} = useWindowDimensions()
    if (props.isSp){
        return (
            <>
                <ul
                    className="login-logout tp-cloak"
                    style={width > 930 ? {display: "none"} : {}}
                >
                    {props.isLoggedIn ? (
                        <li className="show-if-logged-in pc-only">
                            <a href="#" onClick={() => props.logout()} className="login-button tp-logout">
                                {props.language === "ja_JP" ? "ログアウト" : "Logout"}
                            </a>
                        </li>
                    ) : (
                        <li className="show-if-logged-out">
                            <a href="#" onClick={() => props.login()} className="login-button tp-login">
                                {props.language === "ja_JP" ? "ログイン" : "Login"}
                            </a>
                        </li>
                    )}
                </ul>
            </>
        )
    }
    return (
        <>
            <ul
                className="login-logout tp-cloak"
                style={width < 930 ? {display: "none"} : {}}
            >
                {props.isLoggedIn ? (
                    <li className="show-if-logged-in pc-only">
                        <a href="#" onClick={() => props.logout()} className="login-button tp-logout">
                            {props.language === "ja_JP" ? "ログアウト" : "Logout"}
                        </a>
                    </li>
                ) : (
                    <li className="show-if-logged-out">
                        <a href="#" onClick={() => props.login()} className="login-button tp-login">
                            {props.language === "ja_JP" ? "ログイン" : "Login"}
                        </a>
                    </li>
                )}
            </ul>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    login: () => dispatch(login()),
    logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(LoginLogoutForm)
