import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Banner.css'
import Const from '../../common/Const'
import queryString from 'query-string';

function Banner(props) {
    // レイアウトの調整
    const settings = {
        dots: (props.showDots === 'true'),
        infinite: true,
        slidesToShow: props.slidesToShow,
        slidesToScroll: props.slidesToScroll,
        adaptiveHeight:true,
        autoplay: true,
        autoplaySpeed: props.autoplaySpeed,
        centerMode:true,
        responsive: [
            {
              breakpoint: 927,
              settings: {
                slidesToShow: props.slidesToShowMobile,
              },
            },
        ]
    };
   
    const handleClick = (event) => {
        // props.queryが指定されている場合は、クエリにdistributor_idを追加（小バナー用）
        if (props.query) {        
            //const queryString = require('query-string');
            let parsed = queryString.parse(props.query);
            // 末尾にアンカーがついているかチェックする
            let isAnchor = false;
            let url = window.location.href
            if (window.location.href.endsWith(Const.sharp) || window.location.hash) {
                isAnchor = true;
                // アンカーなしURLを再作成
                url = window.location.origin + window.location.pathname + window.location.search;
            }
            if (parsed === '') {
                url = Const.newDistributorQuery + event.target.id;
            } else {
                url += Const.addDistributorQuery + event.target.id;
            }

            if (isAnchor) {
                // アンカーをつけなおす
                if(window.location.hash) {
                    url += window.location.hash
                } else {
                    url += Const.sharp
                }
            }
            window.location.href = url;
        } else {
            // props.queryが指定されていない場合は、専用URLを生成して別タブで開く(大バナー用)
            var url = Const.httpsProtocol + event.target.id + process.env.REACT_APP_DOMAIN + Const.slash;
            window.open(url, '_blank')
        }
    }
    return (
        <> 
            {props.distributors !== null ?
                <div className={"slick-wrapper"}>
                    <Slider {...settings}>
                        {props.distributors.map((distributor , index) => {
                            return (
                                <img
                                    className={"slick-slide"} 
                                    id={distributor.distributor_id} 
                                    src={distributor.banner_url} 
                                    alt="banner"
                                    onClick={(e) => handleClick(e)}
                                    key={index}
                                />
                            )
                        })}
                    </Slider>
                </div>
                :
                ''
            }
        </>
    )
};

export default Banner;
