import React from 'react';

function AppRelease(props) {
	if (props.language === 'ja_JP') {
		return (
			<>
{/*
				<div className="appnotice">
					<p>※既にアプリをインストールしている場合は、ご利用前にGoogle PlayもしくはApp Storeにて、アプリを最新版に更新していただく必要がございます。</p>
				</div>

*/}
				<div className="releasenotice">
					<div className="releasenotice__inner">
						<dl className="releasenotice__items">
							<input id="info1" type="checkbox" />
							<label htmlFor="info1">
							<dt className="releasenotice__item">
								<h2>
									2月23日 [金] 12:00から3月8日 [金] 23:59までの限定取扱い<br/>
									「東京･春･音楽祭2024公式プログラム」付ネット席
								</h2>
								<p>クリックして詳細を表示</p>
							</dt>
							</label>
								<dd>
									<p>
									2月23日 [金] 12:00から3月8日 [金] 23:59までの期間限定で、公式プログラム付ネット席を販売いたします。<br/>
									配信視聴料にプラス1,070円で、「公式プログラム2024」をお手元にお届けします！<br/>
									ご希望の方は、公演の購入ボタンをクリック後、【公式プログラム付】をお選びください。<br/>
									購入後、マイページにて必ず送付先のご住所をご入力ください。<br/>
									<span>
									※公式プログラムは音楽祭全体を網羅するものとなり、各公演毎にご用意した仕様ではございません。 複数の公演をご予約される場合は、1公演のみで「公式プログラム付」を選択してください。<br/>
									※発送先は日本国内に限らせていただきます。<br/>
									※ご購入いただいた公演が中止になった場合、視聴料はご返金いたしますが、公式プログラムの代金は返金の対象外です。<br/>
									※公式プログラムの発送は、3月15日 [金] 頃を予定しております。</span>
									</p>
								</dd>

						</dl>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
{/*
				<div className="appnotice">
					<p>※If you have already installed the application, you need to update it to the latest version at the Google Play or the App Store before use.</p>
				</div>
*/}
				<div className="releasenotice">
					<div className="releasenotice__inner">
						<dl className="releasenotice__items">
							<input id="info1" type="checkbox" />
							<label htmlFor="info1">
							<dt className="releasenotice__item">
								<h2>
									Limited sale from Februrary 23 [Fri.] 12:00 JST to March 8 [Fri.] 23:59 JST.<br/>
									Live streaming ticket with Official Program Book
								</h2>
								<p>Click to open</p>
							</dt>
							</label>
							<dd>
								<p>
									For a limited period from Feb. 23 23:59(JST) to Mar. 8 12:00(JST), we are selling live streaming tickets with the Official Program Book.<br/>
									For an additional 1,070 yen to the live streaming fee, the Official Program 2024 will be shipped to you!<br/>
									If you want to purchase it, please select [With Official Program Book] after clicking the Purchase button on the concert page.<br/>
									After purchasing, don't forget to enter your mailing address on "My Page".<br/>
									<span>
									※The official program is Spring Festival in Tokyo 2024's book. The official program book is are not prepared for each show. Please be careful when purchasing multiple shows.<br/>
									※The shipping address must be within Japan.<br/>
									※In case the concert you buy were cancelled, the live-streaming fee would be refunded, but the fee for the program would not be refunded.<br/>
									※Shipping is scheduled around March 15 [Fri.].</span>
								</p>
							</dd>
						</dl>
					</div>
				</div>
			</>
		);
	}
}

export default AppRelease;
