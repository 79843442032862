import React, { useEffect } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';

function Guide(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	return (
		<>
{/*
			{props.language === 'ja_JP' ? (
				<section className="guide" id="guide-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">ご利用ガイド</h1>
					<section className="guide-section">
						<h2 className="common-title">アプリの利用方法</h2>
						<section className="guide-section">
							<p className="guide-text guide-text--attention">
								※既にアプリをインストールしている場合は、ご利用前にGoogle PlayもしくはApp Storeにて、アプリを最新版に更新していただく必要がございます。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 アカウント登録</h2>
							<p className="guide-text">
								アプリをご利用いただく前に、本サイトでアカウント登録をしていただく必要があります。
								<br />
								本アプリからは新規の会員登録はできません。<br/>
								お使いのパソコン又はスマートフォンのブラウザから 「東京･春･音楽祭 LIVE Streaming」のサイトにアクセスして会員登録をお願いします。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 アプリのダウンロード</h2>
							<h3>iPhone / iPad をお使いの方</h3>
							<p class="guide-text">iOS版は近日公開予定です。</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP3 お支払い方法の指定</h2>
							<p className="guide-text">本アプリはアプリ内決済で公演チケットを購入するため、お使いのApple アカウントでお支払い方法を登録、選択してください。</p>
							<h3>iPhone / iPad をお使いの方</h3>
							<p className="guide-text">
								アプリ内決済の設定は以下のページをご確認の上、設定してください。<br />
								<a href="https://support.apple.com/ja-jp/HT201266" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
							<h3>iOS 払い戻し</h3>
							<p className="guide-text">
								公演がキャンセルされた場合は以下のページから払い戻しの申請をしてください。<br/>
								申請していただかないと払い戻しはされません。<br />
								<a href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP4 アプリの利用</h2>
							<p className="guide-text">東京春祭というアプリがホーム画面に追加されているので、タップして起動してください。</p>
							<h3>画面説明：ホーム</h3>
							<p className="guide-text">
								春祭公式サイトに掲載されているニュース一覧をご覧いただけます。
								<br />
								ホーム画面でニュース詳細をご覧いただいた後は、右上の家の形をしたボタンをタップしていただくと、お知らせ一覧に戻ります。
							</p>
							<h3>画面説明：配信公演</h3>
							<p className="guide-text">
								配信される公演の一覧が表示されます。<br />
								詳細を見たい公演をタップすると公演の詳細情報をご覧いただけます。<br/>
								一覧に戻る場合は右上の「×」ボタンをタップしてください。
							</p>
							<h3>画面説明：購入済</h3>
							<p className="guide-text">
								購入された公演の一覧をご確認いただけます。<br />
								※表示にはログインする必要があります。
							</p>

							<h3>メニュー</h3>
							<p className="guide-text">左上の三本線のボタンをタップするとメニュー画面が表示されます。メニューを閉じる場合は画面上部にある「×」ボタンをタップしてください。</p>
							<ul className="guide-list">
								<li className="guide-list__item">
									<h4>ログイン</h4>
									<p className="guide-text">タップするとログインする画面が表示されるので、必要な情報を入力してログインしてください。<br/>
									※本アプリではアカウントの新規登録はできません。ウェブサイトにブラウザでアクセスし、アカウントを作成してからご利用ください。</p>
								</li>
								<li className="guide-list__item">
									<h4>動画再生確認</h4>
									<p className="guide-text">お使いの機器で配信動画が再生可能かご確認いただけます。
									ネット席購入前に必ずこちらで動画が問題なく再生できることをご確認ください。</p>
								</li>
								<li className="guide-list__item">
									<h4>プライバシーポリシー</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用されるプライバシーポリシーをご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>個人情報の取り扱いについて</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用される個人情報の取り扱いに関する情報をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>特定商取引法に基づく表記</h4>
									<p className="guide-text">本サービスに関する特定商取引法に関する情報をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>利用規約</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用される利用規約をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>ご利用ガイド</h4>
									<p className="guide-text">本サービスのご利用方法やアプリの使い方などをご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>よくあるご質問</h4>
									<p className="guide-text">お問い合わせの多い質問事項をご覧いただけます。</p>
								</li>
							</ul>
						</section>
					</section>
				</section>
			) : (
				<section className="guide" id="guide-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">User's Guide</h1>
					<section className="guide-section">
						<h2 className="common-title">How to use the app</h2>
						<section className="guide-section">
							<p className="guide-text guide-text--attention">
								※If you have already installed the application, you need to update it to the latest version at the Google Play or the App Store before use.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 Account registration</h2>
							<p className="guide-text">
								Before using the app, you need to register for an account on this website.
								<br />
								You cannot register as a new member from this application.<br/>
								Please access the "Spring Festival in Tokyo LIVE Streaming" website from your computer or smartphone browser and register as a member.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 Download the app</h2>
							<h3>For iPhone / iPad users:</h3>
							<p className="guide-text">
								Launch the <a href="https://appstore.com/" target="_blank" rel="noreferrer">App Store app</a> and search for "Tokyo HARUSAI".
								<br />
								Or click <a href="https://apps.apple.com/jp/app/itunes-connect/id1594997423" target="_blank" rel="noreferrer">here</a> to go to the download page.<br/>
								Tap the "Install" button to install the software.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP3 Specify your payment method</h2>
							<p className="guide-text">This app uses in-app payments to purchase tickets for performances, so please register and select a payment method with your Apple account.</p>
							<h3>For iPhone / iPad users</h3>
							<p className="guide-text">
								Please refer to the following page to set up in-app payments.<br />
								<a href="https://support.apple.com/ja-jp/HT201266" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
							<h3>Refund for iOS users.</h3>
							<p className="guide-text">
								If a performance is cancelled, please submit a request for refund on the following page.<br/>
								No refunds will be made without an application.<br />
								<a href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP4 Using the app</h2>
							<p className="guide-text">An app called "Tokyo HARUSAI" has been added to your home screen, so tap it to launch it.</p>
							<h3>Screen Description: Home</h3>
							<p className="guide-text">
								You can see the list of news posted on the official website of the Spring Festival in Tokyo.
								<br />
								After viewing the news details on the home screen, tap the house-shaped button in the upper right corner to return to the list of announcements.
							</p>
							<h3>Screen explanation: Distribution performance</h3>
							<p className="guide-text">
								A list of performances to be distributed will be displayed.<br />
								Tap the performance you want to see in detail to see the detailed information of the performance.<br/>
								To return to the list, tap the "X" button in the upper right corner.
							</p>
							<h3>Screen Description: Purchased</h3>
							<p className="guide-text">
								You can check the list of purchased performances.<br />
								You must be logged in to view it.
							</p>

							<h3>Menu</h3>
							<p className="guide-text">Tap the three-line button in the upper left corner to display the menu screen. To close the menu, tap the "X" button at the top of the screen.</p>
							<ul className="guide-list">
								<li className="guide-list__item">
									<h4>Login</h4>
									<p className="guide-text">Tap on the screen to log in. Enter the necessary information and log in.<br/>
									The app does not allow you to register a new account. <br/>Please access the website with your browser and create an account before using the app.</p>
								</li>
								<li className="guide-list__item">
									<h4>Check video playback</h4>
									<p className="guide-text">You can check if the streaming video can be played on your device.
									Please make sure that the video can be played on your device before purchasing the distribution ticket.</p>
								</li>
								<li className="guide-list__item">
									<h4>Privacy Policy</h4>
									<p className="guide-text">You can view the privacy policy that applies to the use of this service.</p>
								</li>
								<li className="guide-list__item">
									<h4>Handling of Personal Information</h4>
									<p className="guide-text">Information regarding the handling of personal information applicable to the use of this service is available here.</p>
								</li>
								<li className="guide-list__item">
									<h4>Notation based on the Act on Specified Commercial Transactions</h4>
									<p className="guide-text">Information on the Act on Specified Commercial Transactions regarding this service is available here.</p>
								</li>
								<li className="guide-list__item">
									<h4>Terms of Use</h4>
									<p className="guide-text">You can view the terms of use that apply to the use of this service.</p>
								</li>
								<li className="guide-list__item">
									<h4>User's Guide</h4>
									<p className="guide-text">You can learn how to use this service and how to use the application.</p>
								</li>
								<li className="guide-list__item">
									<h4>Frequently Asked Questions</h4>
									<p className="guide-text">Here you will find the most frequently asked questions.</p>
								</li>
							</ul>
						</section>
					</section>
				</section>
			)}
*/}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
