import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';

function PrivacyPolicy(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);
	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="terms">
					<h1 className="common-title">プライバシーポリシー</h1>
					<section>
						<h2 className="terms-title2">当社の個人情報保護に関する基本方針</h2>
						<div className="terms-text">
							<p>
								当社の個人情報保護に関する基本方針は、「
								<a className="local-link" href="http://www.iij.ad.jp/privacy/?__CAMCID=SMqhbNIsoA-778&__CAMI=3.3.0.0.dIPKmCFiBHL.iGpkbgiwhIGgD-73&__CAMSID=iGpkbgiwhIGgD-73&__CAMVID=dIPKmCFiBHL&_c_d=1&_ct=1611279353846" target="_blank" rel="noreferrer">
									個人情報保護ポリシー
								</a>
								」に明記しております。
							</p>
						</div>
					</section>
				</section>
			) : (
				<section className="terms">
					<h1 className="common-title">Privacy Policy</h1>
					<section>
						<h2 className="terms-title2">Our Basic Policy on Personal Information Protection</h2>
						<div className="terms-text">
							<p>
								Our basic policy on the protection of personal information is clearly stated{' '}
								<a className="local-link" href="http://www.iij.ad.jp/en/privacy/" target="_blank" rel="noreferrer">
									here
								</a>
								.
							</p>
						</div>
					</section>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
