import React,{useState} from "react"

function OfferChangePlayer(props) {
    function changePlayerLocal(type){
        props.setCurrentScriptString(type)
        setIsActive(false)
    }
    const [is_active,setIsActive] = useState(false)
    return (
        <>
            <style jsx="true">
                {`
                .pulldown.active {
    height: 200px;
}
`}
            </style>
            <div className="player-config">
                <button className="player-config-button" onClick={() => setIsActive(!is_active)}>
                    <span className="player-config-button__icon"/>
                    <span className="player-config-button__text">{props.current_script_string}</span>
                </button>
                <div className={`${is_active ? "pulldown active" : "pulldown"}`} >
                    <div className={`${is_active ? "pulldown__bg active" : "pulldown__bg"}`}/>
                    <ul className={`${is_active ? "pulldown-list active" : "pulldown-list"}`}>
                        <li className={`${props.current_script_string === "通常配信" ? "pulldown-item active" : "pulldown-item"}`} onClick={() => changePlayerLocal("通常配信")}>通常配信</li>
                        {props.is_multi ? <li className={`${props.current_script_string === "マルチアングル" ? "pulldown-item active" : "pulldown-item"}`} onClick={() => changePlayerLocal("マルチアングル")}>マルチアングル</li> :""}
                        {props.is_4_K ? <li className={`${props.current_script_string === "4K" ? "pulldown-item active" : "pulldown-item"}`} onClick={() => changePlayerLocal("4K")}>4K</li> :""}
                        {props.is_caption ? <li className={`${props.current_script_string === "字幕" ? "pulldown-item active" : "pulldown-item"}`} onClick={() => changePlayerLocal("字幕")}>字幕</li> :""}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default OfferChangePlayer
