import React from "react"
import Papa from "papaparse"

function OfferProgram(props) {
  return <dd className="detail-info__text" id="pl_programme_info_text">{parseProgram(props.program)}</dd>
}

const parseProgram = (program) => {
  if (program !== undefined) {
    let description = Papa.parse(program)
    if (description !== undefined) {
      return (
        <>
          {description.data[0].map((item, index) => {
            return <p key={index}> {item} </p>
          })}
        </>
      )
    }
  }
}
export default OfferProgram
