import React, { useEffect, useState } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';
import { CustomizeFileConst } from '../common/CustomizeFileConst';
import { Const } from '../common/Const';
import { getFileData, isFileExist } from '../common/CommonUtil';

function Faq(props) {
	const [topics, setTopics] = useState(null);
	const [notFound, setNotFound] = useState(false);

	const errMsgJp = process.env.REACT_APP_GET_FILE_ERROR_MSG.replace(Const.wildcardString, "「よくあるご質問」");
	const errMsgEn = process.env.REACT_APP_GET_FILE_ERROR_MSG_EN.replace(Const.wildcardString, "\"Frequently Asked Questions\" ");

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	// faq.json取得
	useEffect(() => {
		if (props.customize) {
			if (isFileExist(props.customize.s3_path + CustomizeFileConst.FAQ_JSON)) {
				getTopics();
			} else {
				setNotFound(true);
			}
		}
	}, [props.customize])

	const getTopics = async() => {
		try {
			const topics = await getFileData(props.customize.s3_path + CustomizeFileConst.FAQ_JSON);
			if (Object.keys(topics).length > 0) {
				setTopics(topics);
			}
		} catch (error) {
			console.error(error);
		}
	}

	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						`}
					</style>
					<h1 className="common-title">よくあるご質問</h1>
					
					{notFound ? <dt className="qa-a">{errMsgJp}</dt> : ''} 

    				<ul className="qa-links">
						{topics && topics.lang_jp.links.map(link => {
							return <li className="qa-links__item"><a href={Const.sharp+link.title_id}>{link.title}</a></li>
						})}
    				</ul>

					{topics && topics.lang_jp.title.map(title => {
						return (
							<React.Fragment>
								<h2 className="common-title" id={title.title_id}>{title.title}</h2>
								<dl className="qa-list" id={"qalist_" + title.title_id}>
									{title.lists.map(titleList => {
										return (
											<React.Fragment>
												<dt className="qa-q" id={"qaq_" + title.title_id}>{titleList.subtitle}</dt>
												<dd className="qa-a" id={"qaa_" + title.title_id} dangerouslySetInnerHTML={{ __html: titleList.answer }}></dd>
											</React.Fragment>
										)
									})}
								</dl>
							</React.Fragment>
						)			
					})}
				</section>
			) : (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						`}
					</style>
					<h1 className="common-title">Frequently Asked Questions</h1>

    				{notFound ? <dt className="qa-a">{errMsgEn}</dt> : ''} 

    				<ul className="qa-links">
						{topics && topics.lang_en.links.map(link => {
							return <li className="qa-links__item"><a href={Const.sharp+link.title_id}>{link.title}</a></li>
						})}
    				</ul>

					{topics && topics.lang_en.title.map(title => {
						return (
							<React.Fragment>
								<h2 className="common-title" id={title.title_id}>{title.title}</h2>
								<dl className="qa-list" id={"qalist_" + title.title_id}>
									{title.lists.map(titleList => {
										return (
											<React.Fragment>
												<dt className="qa-q" id={"qaq_" + title.title_id}>{titleList.subtitle}</dt>
												<dd className="qa-a" id={"qaa_" + title.title_id} dangerouslySetInnerHTML={{ __html: titleList.answer }}></dd>
											</React.Fragment>
										)
									})}
								</dl>
							</React.Fragment>
						)			
					})}
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
		customize: state.customize.customize,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
