import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import FileNameConst from '../common/FileNameConst';
import { isFileExist } from "../common/CommonUtil";


function PersonalInformation(props) {

	const [emailImgPass, setEmailImgPass] = useState('');

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
		//メールアドレスのイメージファイルをS3から取得する
		getEmailImg();
	}, []);

	const getEmailImg = () => {
		// ファイル存在チェック
		const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.EMAIL_IMG);
		if (FileExistFlag) {
			setEmailImgPass(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.EMAIL_IMG);
		} else {
			//デフォルトイメージを取得する。
			setEmailImgPass(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.EMAIL_IMG);
		}
	}
	return (
		<section className="terms">
			{props.language === 'ja_JP' ? (
				<>
					<h1 className="common-title">個人情報の取扱いについて</h1>
					<section>
						<div className="terms-text">
							<p>当社は、JIS Q 15001の規格に準拠した個人情報保護マネジメントシステムを構築、運用し、プライバシーマークを取得しております。以下に掲げる事項は、同規格の要求事項に基づき、お客様に関する個人情報を取得するにあたり、当社がご本人に対して明示すべきものを列挙しています。</p>
						</div>
					</section>

					<section>
						<h2 className="terms-title2">事業者の名称、所在地及び代表者</h2>
						<div className="terms-text">
							<p>株式会社インターネットイニシアティブ<br />
								東京都千代田区富士見2-10-2　飯田橋グラン・ブルーム</p>
							<p>代表取締役社長 勝 栄二郎</p>
						</div>
						<h2 className="terms-title2">個人情報保護管理者</h2>
						<div className="terms-text">
							<p>三膳 孝通（個人情報保護管理者への連絡については、下記に掲げるお問い合わせ窓口にて承ります）</p>
						</div>

						<h2 className="terms-title2">利用目的</h2>
						<div className="terms-text">
							<ol className="pi-list-ol">
								<li>本サービスの提供にかかる業務を行うこと。（業務上必要な連絡、通知等をお客様に対して行うことを含みます。）</li>
								<li>本サービスレベルの維持向上を図るため、アンケート調査及びその分析を行うこと。</li>
								<li>当社の商品、サービスに関する情報（本サービスに限らず、当社の別商品、サービス又は当社の新規商品、サービス紹介情報等を含みます。）又は提携先の商品、サービス等の情報を、お客様がアクセスした当社のWebページその他お客様の端末装置上に表示し、もしくはメール、郵便等により送付し、又は電話すること。なお、お客様は、当社が別途定める方法により、これらの取り扱いを中止又は再開することができます。</li>
								<li>前各号に付属する業務を行うこと。</li>
								<li>その他お客様から得た同意の範囲内で利用すること。</li>
							</ol>
						</div>

						<h2 className="terms-title2">第三者への提供</h2>
						<div className="terms-text">
							<p>当社は、イベント主催者よりイベント情報等をご案内することを目的として、必要なセキュリティ措置を講じた上で、お客様がストリーミングチケットを購入したイベント主催者へ、お客様の会員登録情報を第三者提供いたします。その他、お客様の同意に基づき必要な限度において個人情報を第三者に提供する場合があります。</p>
						</div>

						<h2 className="terms-title2">個人情報の取扱いの委託</h2>
						<div className="terms-text">
							<p>本サービスの提供に係る業務における個人情報の取扱いの全部又は一部を第三者に委託する場合にあっては、当社は、当社の監督責任下において個人情報を第三者に委託するものとします。</p>
						</div>

						<h2 className="terms-title2">個人情報の取扱いに関するお問い合わせ（苦情及び相談等）窓口</h2>
						<div className="terms-text">
							<p>
								本申し込みにおける個人情報の取扱いに関する苦情及び相談等は、次の窓口までお申し出ください。
								<br />
								＜お問い合わせ窓口＞
								<br />
								東京･春･音楽祭 Live Streaming ヘルプセンター
								<br />
								<img className="local-img" src={emailImgPass} alt="" />
								<br />
								または、本サイト問い合わせフォームよりお問い合わせください。
							</p>

							<p>
								なお、個人情報の開示・訂正・利用停止等の求めに関する手続きについては、
								<a className="local-link" href="http://www.iij.ad.jp/safe-procedure/?__CAMCID=SMqhbNIsoA-778&__CAMI=3.3.0.0.dIPKmCFiBHL.iGpkbgiwhIGgD-73&__CAMSID=iGpkbgiwhIGgD-73&__CAMVID=dIPKmCFiBHL&_c_d=1&_ct=1611279340716" target="_blank" rel="noreferrer">
									こちらの当該手続きに関する説明ページ
								</a>
								をご覧ください。
								<br />
								また、当社は、個人情報保護法による認定個人情報保護団体である一般財団法人日本データ通信協会及び一般財団法人日本情報経済社会推進協会の対象事業者であるため、一般財団法人日本データ通信協会内に設けられている
								<a className="local-link" href="http://www.dekyo.or.jp/kojinjyoho/" target="_blank" rel="noreferrer">
									電気通信個人情報保護推進センター
								</a>
								又は
								<a className="local-link" href="https://www.jipdec.or.jp/project/protection_org/" target="_blank" rel="noreferrer">
								一般財団法人日本情報経済社会推進協会認定個人情報保護団体事務局
								</a>
								へ苦情の解決の申出をすることもできます。イベント主催者における個人情報の取扱いについては、イベント主催者へお問い合わせください。
							</p>
						</div>

						<h2 className="terms-title2">個人情報を与えることの任意性等</h2>
						<div className="terms-text">
							<p>個人情報を当社にご提供いただけるかどうかはお客様の任意のご判断によりますが、必要な情報をご提供いただけない場合は、会員登録申請の受付及びサービスのご提供を正常に行うことができませんので、あらかじめご承知ください。</p>
						</div>

						<h2 className="terms-title2">当社の個人情報保護に関する基本方針</h2>
						<div className="terms-text">
							<p>
								当社の個人情報保護に関する基本方針は、「
								<a className="local-link" href="http://www.iij.ad.jp/privacy/?__CAMCID=SMqhbNIsoA-778&__CAMI=3.3.0.0.dIPKmCFiBHL.iGpkbgiwhIGgD-73&__CAMSID=iGpkbgiwhIGgD-73&__CAMVID=dIPKmCFiBHL&_c_d=1&_ct=1611279353846" target="_blank" rel="noreferrer">
									個人情報保護ポリシー
								</a>
								」に明記しております。
							</p>
						</div>
					</section>
				</>
			) : (
				<>
					<h1 className="common-title">Concerning the Handling of Personal Information</h1>
					<section>
						<div className="terms-text">
							<p>The Company has created and operates a personal information protection management system compliant with the JIS Q 15001 standard, and has acquired the Privacy Mark of the Japan Institute for Promotion of Digital Economy and Community. Based on the requirements of the said standard, the following list comprises information which the Company is to point out to personal information owners with respect to the acquisition of personal information.</p>
						</div>
					</section>

					<section>
						<h2 className="terms-title2">Name of the business operator</h2>
						<div className="terms-text">
							<p>Internet Initiative Japan Inc.<br />
								Iidabashi Grand Bloom 2-10-2 Fujimi, Chiyoda-ku, Tokyo 102-0071, JAPAN</p>
							<p>President: Eijiro Katsu (Co-CEO & COO)</p>
						</div>
						<h2 className="terms-title2">Personal information protection manager</h2>
						<div className="terms-text">
							<p>Mr. Takamichi Miyoshi (Contact through the information desk indicated below.)</p>
						</div>

						<h2 className="terms-title2">Purposes of use</h2>
						<div className="terms-text">
							<ol className="pi-list-ol">
								<li>Execution of operations related to the provision of the Service (including contacting or notifying, etc., a Customer when necessary for operations)</li>
								<li>Questionnaire surveys and analyses thereof to maintain and enhance the Service level</li>
								<li>Communication of information about the Company's products and services (including, without limitation to the Service, introductory information on other products and services of the Company or new products and services of the Company) or products and services of partners of the Company, by display on a Company web page accessed by a Customer or on other terminal devices of a Customer, or by e-mail, postal mail, etc., or telephone call. A Customer can terminate or resume such handling by the Company using the Company’s separately stipulated method.</li>
								<li>Operations associated with each of the foregoing items.</li>
								<li>Other uses within the scope of consent obtained from other a Customer.</li>
							</ol>
						</div>

						<h2 className="terms-title2">Provision to third parties</h2>
						<div className="terms-text">
							<p>For the dissemination of an Event Organizer’s event information, etc., after taking necessary security measures the Company will with respect to a third party provide a Customer’s customer registration information to an Event Organizer where a Customer has purchased a Live Streaming Ticket. In addition, the Company may provide personal information to a third party to the extent necessary based on the consent of a Customer.</p>
						</div>

						<h2 className="terms-title2">Outsourcing of personal information handling</h2>
						<div className="terms-text">
							<p>If the handling of personal information occurring in the course of operations related to the provision of the Service is in part or in total outsourced to a third party, the Company shall furnish a third party with personal information under the supervisory responsibility of the Company.</p>
						</div>

						<h2 className="terms-title2">Contact for queries concerning the handling of personal information (complaints and queries, etc.)</h2>
						<div className="terms-text">
							<p>
								For complaints and queries, etc., concerning the handling of personal information in connection with this application the following contact should be used.
								<br />
								[ Contact information ]
								<br />
								Spring Festival in Tokyo Live Streaming Help Center
								<br />
								<img className="local-img" src={emailImgPass} alt="" />
								<br />
								Alternatively the inquiry form of this website can be used.
							</p>

							<p>
								Procedures for requests of disclosure, correction, and suspension of use, etc., of personal information, are explained on
								<a className="local-link" href="http://www.iij.ad.jp/safe-procedure/?__CAMCID=SMqhbNIsoA-778&__CAMI=3.3.0.0.dIPKmCFiBHL.iGpkbgiwhIGgD-73&__CAMSID=iGpkbgiwhIGgD-73&__CAMVID=dIPKmCFiBHL&_c_d=1&_ct=1611279340716" target="_blank" rel="noreferrer">
									this page.
								</a>
								<br />
								Since the Company is a business operator covered by the Japan Data Communications Association –a recognized personal information protection organization under the Personal Information Protection Law – complaints can also be filed with the
								<a className="local-link" href="http://www.dekyo.or.jp/kojinjyoho/" target="_blank" rel="noreferrer">
									Telecommunications Personal Information Protection Promotion Center
								</a>
								established at the Japan Data Communications Association. Queries regarding the handling of personal information by an Event Organizer should be directed at the Event Organizer.
							</p>
						</div>

						<h2 className="terms-title2">Voluntary nature of personal information provision</h2>
						<div className="terms-text">
							<p>The provision of personal information to the Company by a Customer is voluntary. However, note should be taken that the Company will not be able to accept a customer registration application and provide normal service without the provision of the necessary personal information.</p>
						</div>

						<h2 className="terms-title2">Fundamental policies of the Company concerning the protection of personal information</h2>
						<div className="terms-text">
							<p>
								The fundamental policies of the Company concerning the protection of personal information is stated in the [
								<a className="local-link" href="http://www.iij.ad.jp/privacy/?__CAMCID=SMqhbNIsoA-778&__CAMI=3.3.0.0.dIPKmCFiBHL.iGpkbgiwhIGgD-73&__CAMSID=iGpkbgiwhIGgD-73&__CAMVID=dIPKmCFiBHL&_c_d=1&_ct=1611279353846" target="_blank" rel="noreferrer">
									Personal Information Protection Policy.
								</a>
								]
							</p>
						</div>
					</section>
				</>
			)}
		</section>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
