import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { logout } from "../actions/piano"

function Logout(props) {
  useEffect(() => {
    props.logout()
  }, [props])
  return (
    <div>
      <Redirect to="/" />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(Logout)
