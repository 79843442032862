import React, { useState, useEffect } from 'react';
import Papa from "papaparse"
import SafeSrcDocIframe from '../../customHooks/safe-src-doc-iframe';
import ProgramStateConst from '../../common/ProgramStateConst';

function OfferPlayer(props) {
	let description = '';
	let ppp = '';
	if (props.program !== undefined ) {
		description = Papa.parse(props.program);
		for(var i=0; i<description.data[0].length; i++) {
			ppp += '<p>' + description.data[0][i] + '</p>';
		}
	} else {
		ppp = '<p>曲目がある場合はこちらに表示されます。</p><p>If a program list is available, it will be displayed here.</p>';
	}

	var program = '<div id="pl_programme_info_text">' + ppp + '</div>';

	var addedScript = '';
	if (props.format === '4K') {
		addedScript = `
			<script type="text/javascript" src="../assets/js/jquery-3.5.1.min.js"></script>
			<script src="../assets/js/shaka-player.ui.debug.js"></script>
			<link rel="stylesheet" type="text/css" href="../assets/css/controls.css">
			<link rel="stylesheet" type="text/css" href="../assets/css/style.css">
			<div class="player">
				<div class="player-header">
					<p class="setting"></p>
				</div>
				<div class="container">
					<div data-shaka-player-container data-shaka-player-cast-receiver-id="D2E16040" shaka-controls="true" class="shaka-video-container">
						<video autoplay data-shaka-player id="video" style="width:100%; height:100%" class="shaka-video"/>
					</div>
				</div>
			</div>
		`;
	} else if (props.format === 'test') {
		addedScript = `
			<style>
				.store-info { display:none; }
				#demo { height: 0px; overflow:hidden; margin:0; padding:0;}
			</style>
		`;
	} else {
		addedScript = '';
	}
	let uid = "not_set";
	if (document.cookie.split(';').some((item) => item.trim().startsWith('uid='))) {
		uid = document.cookie.split('; ').find(row => row.startsWith('uid')).split('=')[1];
	}
	const html =
		addedScript +
		`
			<style>
				html { overflow:hidden; }
				body { margin: 0; }
				.store-info { display:none; }
				#pl_programme_info_text { height: 0px; overflow:hidden; margin:0; padding:0;}
				.mt-frame { padding-right:0 !important; margin-right:0 !important;}
				.mt-frame ol { padding:1rem;}
				.mt-frame ol li p { margin:0;}
			</style>
			<div className="player__thumbnail" style="width:100%;padding-top:57.0%;overflow:hidden;position:absolute;">
			</div>
			<div id="msp_frame">
				<input id="pid" value="${uid}" type="hidden"></input>
				<input id="title" value="${props.muxPerformanceTitle}" type="hidden"></input>
		    	<script src="${props.script}"></script>
			</div>

			<script>
				const resizeObserver = new ResizeObserver(entries => {
		  		for (const entry of entries) {
		    		let h = entry.target.clientHeight;
		      		let msp_frame = window.top.document.getElementById("msp_frame");
		      		msp_frame.style.height = h + "px";
		  		}
				});
				const target = document.querySelector('div');
				resizeObserver.observe(target);
			</script>
		`;
	let thumbnailURL;
	if (props.performanceThumbnail === '') {
		thumbnailURL = process.env.REACT_APP_IMAGE_DIR_URL + 'ensemble/DEFAULT_ENSEMBLE_THUMBNAIL.jpeg';
	} else {
		thumbnailURL = props.performanceThumbnail;
	}

	return (
		<>
			<div className="player" id="fvPlayer">
				{props.isTester ?
					(
						props.script ?
						(
							<div id="msp_frame">
								<SafeSrcDocIframe id="myFrame" title="test-player" key={props.ifrm} srcDoc={'<body>' + html + '</body>'} sandbox="allow-same-origin allow-top-navigation allow-scripts allow-modals" width="100%" height="100%" frameBorder="0" marginHeight="0px" marginWidth="0px" />
							</div>
						) : (
							<div className="player__thumbnail">
								<img src={thumbnailURL} alt="Thumbnail" />
							</div>
						)
					) : (
						props.programState === ProgramStateConst.streaming && props.script ?
						(
							<div id="msp_frame">
								<SafeSrcDocIframe id="myFrame" title="test-player" key={props.ifrm} srcDoc={'<body>' + html + '</body>'} sandbox="allow-same-origin allow-top-navigation allow-scripts allow-modals" width="100%" height="100%" frameBorder="0" marginHeight="0px" marginWidth="0px" />
							</div>
						) : (
						<div className="player__thumbnail">
							<img src={thumbnailURL} alt="Thumbnail" />
						</div>
						)
					)
				}
			</div>
			{/*{props.is_multiple_angles ? (*/}
			{/*  getMenu()*/}
			{/*) : (*/}
			{/*  <div className="player-config">*/}
			{/*    <button className="player-config-button active">*/}
			{/*      <span className="player-config-button__icon" />*/}
			{/*      <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*  </div>*/}
			{/*)}*/}
		</>
	);
}

function getMenu() {
	return (
		<>
			<div className="player-config">
				<button className="player-config-button active">
					<span className="player-config-button__icon" />
					<span className="player-config-button__text">通常配信</span>
				</button>
			</div>
			{/* active */}
			{/*<div className="player-config">*/}
			{/*    <button className="player-config-button active">*/}
			{/*        <span className="player-config-button__icon"/>*/}
			{/*        <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*    <div className="pulldown" onClick={menuActive()}>*/}
			{/*        <div className="pulldown__bg"/>*/}
			{/*        <ul className="pulldown-list active">*/}
			{/*            <li className="pulldown-item active">通常配信</li>*/}
			{/*            <li className="pulldown-item">マルチアングル</li>*/}
			{/*        </ul>*/}
			{/*    </div>*/}
			{/*</div>*/}
			{/*// no active*/}
			{/*<div className="player-config">*/}
			{/*    <button className="player-config-button">*/}
			{/*        <span className="player-config-button__icon"/>*/}
			{/*        <span className="player-config-button__text">通常配信</span>*/}
			{/*    </button>*/}
			{/*    <div className="pulldown">*/}
			{/*        <div className="pulldown__bg"/>*/}
			{/*        <ul className="pulldown-list">*/}
			{/*            <li className="pulldown-item active">通常配信</li>*/}
			{/*            <li className="pulldown-item">マルチアングル</li>*/}
			{/*        </ul>*/}
			{/*    </div>*/}
			{/*</div>*/}
		</>
	);
}

export default OfferPlayer;
