export const Const = {
    flgPremiumPlan: 1,
    newDistributorQuery: '/?distributor_id=',
    newDistributorQueryNonSlash: '?distributor_id=',
    addDistributorQuery: '&distributor_id=',
    hrefPurchased: '/?sortType=purchased',
    hrefDate: '/?sortType=date',
    hrefSearch: '/?search=date&q=',
    sharp: '#',
    httpsProtocol: 'https://',
    slash: '/',
    wildcardString: '%s',
    search: 'search',
	purchased: 'purchased',
	date: 'date',
    halfSpace: ' ',
    Fullspace: '　',
}

export default Const;