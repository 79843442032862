import React, { useEffect } from "react"
import { connect } from "react-redux"
import { showMyAccount } from "../actions/piano"
import { setIsStreaming, donePageLoading } from "../actions/offer"
import { initialize } from "../actions/whiteUi"

function MyPage(props) {
  useEffect(() => {
    props.donePageLoading(false)
    props.initialize()
    props.showMyAccount()
    props.setIsStreaming(false)
  }, [])
  window.setTimeout(function () {
    props.donePageLoading(true)
  }, 1000)
  return (
    <>
      <section className="account">
        <div id={"my-account"} style={{ margin: "15px" }} />
        <div id="myPage" />
        {props.loggedIn ? (
          props.language === 'ja_JP' ? (
            <section className="unsubscribe--box">
              <div>
                <p>退会をご希望される方は<a href="/unsubscribe" className={"unsubscribe-link"}>こちら</a></p>
              </div>
            </section>
          ) : (
            <section className="unsubscribe--box">
              <div>
                <p>If you wish to cancel your membership, please click <a href="/unsubscribe" className={"unsubscribe-link"}>here</a>.</p>
              </div>
            </section>
          )
        ) : (
          ''
        )}
      </section>
    </>
  )
}
const mapStateToProps = state => {
  return {
    language: state.piano.language,
    donePageLoading: state.offer.donePageLoading,
    loggedIn: state.piano.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  showMyAccount: () => dispatch(showMyAccount()),
  setIsStreaming: (state) => dispatch(setIsStreaming(state)),
  donePageLoading: (state) => dispatch(donePageLoading(state))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyPage)
