import React, { useState, useEffect } from 'react';
import { Const } from '../../common/Const'

function SearchBar(props) {
	const [active, setActive] = useState('');
	const [title, setTitle] = useState('');
	const [titleEn, setTitleEn] = useState('');
	const [keyword, setKeyword] = useState(props.keyword);
	const handleActive = () => {
		if (active === '') {
			setActive('active');
		} else if (active === 'active') {
			setActive('');
		}
	};

	useEffect(()=>{
		// タイトル取得
		if (props.selectedDistributorId !== null && props.distributors !== null) {
			let title = props.distributors.filter(distributor => {return distributor.distributor_id === props.selectedDistributorId})[0].title;
			if (title) {
				setTitle(title);
			}
			let titleEn = props.distributors.filter(distributor => {return distributor.distributor_id === props.selectedDistributorId})[0].title_en;
			if (titleEn) {
				setTitleEn(titleEn);
			}
		}

	},[props.selectedDistributorId, props.distributors])

	return (
		<header className="home-header">
			<style jsx="true">
				{`
				h2.info-title {padding-top: 0rem; font-size: 0.9rem; line-height:2rem; }
				`}
			</style>
			{
				<h2 className="info-title" >
					{props.language === 'ja_JP' ? '※開演時間は、視聴する現地の時刻で表示されます。' : '※The curtain time is displayed in the local time of your viewing location.'}
				</h2>
			}
			{(props.language === 'ja_JP' && title === '') || (props.language === 'en_US' && titleEn === '')?
				<h1 className="home-title">
					{props.language === 'ja_JP' ? '東京･春･音楽祭 2024 ' : 'Spring Festival in Tokyo 2024 '}
					{props.language === 'ja_JP' ? '' : ''}
					<br className="sp-only" />
					{props.language === 'ja_JP' ? 'ライブ・ストリーミング' : 'Live Streaming'}
				</h1>
				:
				<h1 className="home-title">
				 	{props.language === 'ja_JP' ?
						title : titleEn
					}
				</h1>
			}
			<nav className="home-navi">
				{props.sortType !== 'date' ?
					<ul className="home-tabs">
						<li className="home-tab" >
							<a href={props.selectedDistributorId === null ? Const.hrefPurchased : Const.hrefPurchased + Const.addDistributorQuery + props.selectedDistributorId} title="購入済み">
								<p>{props.language === 'ja_JP' ? '購入済み' : 'Purchased'}</p>
							</a>
						</li>
						<li className="home-tab" >
							<a href={props.selectedDistributorId === null ? Const.hrefDate : Const.hrefDate + Const.addDistributorQuery + props.selectedDistributorId} title="公演日">
								<p>{props.language === 'ja_JP' ? '公演一覧に戻る' : 'Program List'}</p>
							</a>
						</li>
					</ul>
					: ''
				}
				<button className={`home-search-open-button sp-only button-transparent ${active}`} onClick={() => handleActive()}></button>
				<form className={`search-form search-form--active ${active}`}>
					<div className="search-form__inner">
						<div className="search-form__input-wrapper">
							<input
								className="search-form__input"
								placeholder={`${props.language === 'ja_JP' ? 'キーワードを入力' : 'Enter keyword here'}`}
								name="keyword"
								value={keyword}
								onChange={event => {
									setKeyword(event.target.value);
									props.setKeyword(event.target.value);
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										e.preventDefault();
										props.onEnterPressed(true);
									}
								}}
							/>
							<div className="search-form__open-button"></div>
						</div>
						<input type="button" className="search-form__submit-button" defaultValue={`${props.language === 'ja_JP' ? '検索' : 'Search'}`} 
							onClick={e => { 
								props.onEnterPressed(true); 
						}} />
					</div>
				</form>
			</nav>
		</header>
	);
}

export default SearchBar;
