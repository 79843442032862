import React, { useEffect } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';

function Faq(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	return (
		<>
{/*

			{props.language === 'ja_JP' ? (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">よくあるご質問</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#q1">アカウントについて</a></li>
      					<li className="qa-links__item"><a href="#q2">視聴環境／仕様について</a></li>
      					<li className="qa-links__item"><a href="#q3">購入について</a></li>
      					<li className="qa-links__item"><a href="#q4">視聴について</a></li>
      					<li className="qa-links__item"><a href="#q5">アプリについて</a></li>
    				</ul>

					<h2 className="common-title" id="q1">アカウントについて</h2>
					<dl className="qa-list">
						<input id="qa1" type="checkbox" /><label for="qa1"><dt className="qa-q">昨年作成したアカウントは利用できますか？</dt></label>
						<dd className="qa-a">
							2021年/2022年の東京･春･音楽祭 LIVE Streamingで登録されたアカウントは引き続きご利用いただけます。
						</dd>
						<input id="qa2" type="checkbox" /><label for="qa2"><dt className="qa-q">アプリでアカウントの登録はできますか？</dt></label>
						<dd className="qa-a">
							アカウントの新規登録はアプリではできません。
						</dd>
					</dl>

					<h2 className="common-title" id="q2">視聴環境／仕様について</h2>
					<dl className="qa-list">
						<input id="qa3" type="checkbox" /><label for="qa3"><dt className="qa-q">ライブ・ストリーミング配信を視聴できる環境を教えてください。</dt></label>
						<dd className="qa-a">
							視聴できる環境は以下となります。
							<br />
							<br />
							■ Apple(iOS、iPadOS) 対応環境
							<br />
							•iOS 15以後 Safari最新
							<br />
							•iPad OS 15以後 Safari最新
						</dd>
						<input id="qa4" type="checkbox" /><label for="qa4"><dt className="qa-q">ライブ・ストリーミング配信はいつまでに購入すれば視聴できますか？</dt></label>
						<dd className="qa-a">公演毎に購入期限があります。各公演詳細ページでご確認ください。</dd>

						<input id="qa5" type="checkbox" /><label for="qa5"><dt className="qa-q">見逃し配信／アーカイブ配信はありますか？</dt></label>
						<dd className="qa-a">見逃し配信、アーカイブ配信はございません。</dd>
						<input id="qa6" type="checkbox" /><label for="qa6"><dt className="qa-q">チケットを購入した機器と別の機器でライブ・ストリーミング配信を見ることができますか？</dt></label>
						<dd className="qa-a">購入した機器とは異なる機器でもライブ・ストリーミング配信を見ることができます。 購入時に利用したアカウントでログインが必要です。</dd>
					</dl>

					<h2 className="common-title" id="q3">購入について</h2>

					<dl className="qa-list">
					<input id="qa7" type="checkbox" /><label for="qa7"><dt className="qa-q">購入方法がわかりません。</dt></label>
						<dd className="qa-a">
							<a className="qa-links__item" href="/guide_m">
								ご利用ガイド
							</a>
							にてご確認ください。
						</dd>
						<input id="qa8" type="checkbox" /><label for="qa8"><dt className="qa-q">手数料はいくらかかりますか？</dt></label>
						<dd className="qa-a">視聴チケット購入時の手数料はかかりません。ただし購入及び視聴の際は別途通信料がかかり、お客様のご負担となります。</dd>
						<input id="qa9" type="checkbox" /><label for="qa9"><dt className="qa-q">支払方法は何が使えますか？</dt></label>
						<dd className="qa-a">
							アプリ内のお支払い方法については、以下のページで Apple のサービスをご確認ください。<br/>
							<br/>
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT202023" target="_blank" rel="noreferrer">iPhone / iPad</a>
						</dd>

						<input id="qa10" type="checkbox" /><label for="qa10"><dt className="qa-q">決済ができません。</dt></label>
						<dd className="qa-a">支払い方法の設定をご確認ください。</dd>

						<input id="qa11" type="checkbox" /><label for="qa11"><dt className="qa-q">キャンセル（払い戻し）できますか？</dt></label>
						<dd className="qa-a">お客様のご都合によるキャンセル（払戻し）は、承りません。主催者側の都合により公演が中止・延期になった場合に限り、購入いただいた方へ後日払い戻しさせていただきます。</dd>
						<input id="qa12" type="checkbox" /><label for="qa12"><dt className="qa-q">間違って違う公演を購入したのですが、変更することはできますか？</dt></label>
						<dd className="qa-a">決済完了後におけるお客様のご都合による変更・キャンセルは承りません。</dd>
					</dl>

					<h2 className="common-title" id="q4">視聴について</h2>
					<dl className="qa-list">
						<input id="qa13" type="checkbox" /><label for="qa13"><dt className="qa-q">視聴方法がわかりません。</dt></label>
						<dd className="qa-a">
							視聴方法は
							<a className="qa-links__item" href="/guide_m">
								ご利用ガイド
							</a>
							にてご確認ください。
						</dd>
						<input id="qa14" type="checkbox" /><label for="qa14"><dt className="qa-q">ライブ・ストリーミング配信視聴に通信料はかかりますか？</dt></label>
						<dd className="qa-a">ライブ・ストリーミング配信視聴の際にかかる通信料は、お客様のご負担となります。</dd>
						<input id="qa15" type="checkbox" /><label for="qa15"><dt className="qa-q">複数機器で同時に視聴はできますか？</dt></label>
						<dd className="qa-a">複数機器での同時視聴はできません。</dd>

						<input id="qa16" type="checkbox" /><label for="qa16"><dt className="qa-q">テレビ画面でライブ・ストリーミング配信を見ることができますか？</dt></label>
						<dd className="qa-a">テレビ画面に接続しての視聴は対応しておりません。</dd>
						<input id="qa17" type="checkbox" /><label for="qa17"><dt className="qa-q">投げ銭はできますか？</dt></label>
						<dd className="qa-a">投げ銭はできません。<br/>
						東京･春･音楽祭へのご寄付については、<a href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer">公式サイト</a>にて受け付けております。</dd>
						<input id="qa18" type="checkbox" /><label for="qa18"><dt className="qa-q">ライブ・ストリーミング配信のページにアクセスしましたが、動画を見ることができません。</dt></label>
						<dd className="qa-a">動画再生確認ページにて、お使いの機器が視聴できる環境か、また動画が止まることなく再生されるかをご確認ください。</dd>
						<input id="qa19" type="checkbox" /><label for="qa19"><dt className="qa-q">音が聞こえません。</dt></label>
						<dd className="qa-a">
							【ご利用の機器の音量をご確認ください】
							<br />
							・ミュート(消音)、もしくは音量が小さい場合は、端末の音量をあげてください。
							<br />
							・スマートフォン・タブレットをご利用の場合は、マナーモード・サイレントモードになっていないことをご確認ください。
							<br />
							・イヤホンに繋がっていないかご確認ください。(イヤホンをご利用の場合は接続ができているかご確認ください)
							<br />
							<br />
							【 プレーヤーの音量をご確認ください】
							<br />
							・プレーヤー下部にあるスピーカーアイコンを操作して音量を上げてください。
							<br />
							・タブレットをご利用の場合は、初期設定ではミュート(消音)となっている可能性があります。
							<br />
							<br />
							【以上を確認しても解決しない場合】
							<br />
							・ご利用の端末を再起動することで解決する場合があります。
							<br />
							・配信側に問題が発生している可能性があります。ヘルプセンターにお問い合わせください。
						</dd>
						<input id="qa20" type="checkbox" /><label for="qa20"><dt className="qa-q">映像を見ることができません。</dt></label>
						<dd className="qa-a">
							視聴に問題がある場合は、動画再生確認ページにて、お使いの機器が視聴できる環境か、また動画が止まることなく再生されるかをご確認ください。<br/>
							解決しない場合は、以下の操作をお試しください。
							<br />
							<br />
							■iPhone/iPadの場合
							<br />
							・東京･春･音楽祭 LIVE Streaming の推奨環境を満たしているかご確認ください。
							<br />
							<a className="qa-links__item" href="/test">
								動画再生確認ページ
							</a>
							<br />
							<br />
							・端末の再起動
							<br />
							再起動方法はこちらをご確認ください
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201559" target="_blank" rel="noreferrer">
								iPhone
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT210631" target="_blank" rel="noreferrer">
								iPad
							</a>
							<br />
							<br />
							・モバイルデータ通信を利用している場合は、Wi-Fi回線に切り替えてください。
							<br />
							<br />
							・Wi-Fi回線をご利用している場合は、ネットワーク設定をリセットしてください。
							<br />
							設定方法は以下のページの「2.オプションを選択する：」の「ネットワーク設定をリセット」をご確認ください。
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios" target="_blank" rel="noreferrer">
								iPhoneユーザガイド iPhoneの設定をデフォルトに戻す
							</a>
						</dd>
						<input id="qa21" type="checkbox" /><label for="qa21"><dt className="qa-q">字幕が表示されません。</dt></label>
						<dd className="qa-a">
							1.各公演概要に記載されている公演タイトルに「字幕付」と書かれているかをご確認ください。書かれている場合は字幕表示がある公演となります。
							<br />
							2.「字幕付」の公演で視聴しているライブ・ストリーミング配信に字幕が表示されていない場合、視聴しているプレイヤー右下にある字幕表示ボタンをクリックすると、字幕のオン／オフを切り替えることができます。
						</dd>
						<input id="qa22" type="checkbox" /><label for="qa22"><dt className="qa-q">ライブ・ストリーミング配信を見逃しました、返金はできますか？</dt></label>
						<dd className="qa-a">主催者の都合による公演中止の場合、払い戻しに対応いたします。それ以外の理由での払い戻しは対応いたしません。</dd>
						<input id="qa23" type="checkbox" /><label for="qa23"><dt className="qa-q">配信開始時間を過ぎてしまいましたが、冒頭から全編視聴することはできますか？</dt></label>
						<dd className="qa-a">ライブ配信のため、巻き戻しての視聴はできません。</dd>
						<input id="qa24" type="checkbox" /><label for="qa24"><dt className="qa-q">悪天候による停電で視聴できませんでした。</dt></label>
						<dd className="qa-a">悪天候による停電で視聴できなかった場合、大変申し訳ございませんが払い戻しなどの対応はできません。主催者の都合による公演中止の場合、払い戻しに対応いたします。</dd>
						<input id="qa25" type="checkbox" /><label for="qa25"><dt className="qa-q">友人・家族などにネット席をプレゼントしたい。</dt></label>
						<dd className="qa-a">購入したチケットの贈与はできません。アカウントは重要な個人情報を含むため、他者との共有はお控えください。（アカウント情報を第三者へ開示したことによるいかなる損害も東京･春･音楽祭実行委員会およびIIJは一切の責任を負いません。）</dd>
						<input id="qa26" type="checkbox" /><label for="qa26"><dt className="qa-q">JavaScriptについて</dt></label>
						<dd className="qa-a">
							当サイトでは、JavaScriptを使用しています。
							<br />
							お使いのブラウザでJavaScript機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。
							<br />
							ご覧になる際にはブラウザ設定でJavaScriptを有効にしてご覧ください。
						</dd>
						<input id="qa27" type="checkbox" /><label for="qa27"><dt className="qa-q">Cookieについて</dt></label>
						<dd className="qa-a">
							当サイトでは、Cookieを使用しています。
							<br />
							お使いのブラウザでCookie機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。
							<br />
							ご覧になる際にはブラウザ設定でCookieを有効にしてご覧ください。
							<br />
							<br />
							Mac
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/safari/sfri35610/14.0/mac/11.0" target="_blank" rel="noreferrer">
								Apple Safari
							</a>{' '}
							<br />
							iOS / iPadOS
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201265" target="_blank" rel="noreferrer">
								Safari
							</a>
						</dd>

						<input id="qa28" type="checkbox" /><label for="qa28"><dt className="qa-q">その他のお問い合わせ</dt></label>
						<dd className="qa-a">
							「よくあるご質問」に無いお問い合わせの場合は、下記「東京･春･音楽祭 Live Streaming ヘルプセンター」窓口までお問い合わせください。
							<br />
							<br />
							<h2>「東京･春･音楽祭 Live Streaming ヘルプセンター」</h2>
							<br />
							電話番号 : 03-5205-4462（受付時間 : 12:00 - 21:00）
							<br />
							<br />
							※ お問合せ受付期間2月15日(木)〜4月21日(日)<br/>
							4月22日(月)以降は電話によるお問い合わせは受け付けておりません。お問い合わせはマイページのヘルプをご利用ください。
						</dd>
					</dl>

					<h2 className="common-title" id="q5">アプリについて</h2>
					<dl className="qa-list">
						<input id="qa29" type="checkbox" /><label for="qa29"><dt className="qa-q">アプリがインストールできません。</dt></label>
						<dd className="qa-a">「東京春祭」アプリ（以下、本アプリ）は iOS 15.0 / iPadOS 15.0以上のスマートフォンおよびタブレットにてご利用いただけます。
						以下のストアページにて「インストール」ボタンが表示されない場合、お使いのスマートフォンおよびタブレットでは本アプリをご利用いただけません。<br/><br/>

						<a className="qa-links__item" href="https://www.apple.com/jp/app-store/" target="_blank" rel="noreferrer">App Store (iOS / iPadOS)</a><br/><br/>

						アプリのダウンロードにはそれぞれAppleのアカウントが必要になります。</dd>
						<input id="qa30" type="checkbox" /><label for="qa30"><dt className="qa-q">このアプリは無料で使えますか。</dt></label>
						<dd className="qa-a">本アプリのダウンロードおよびインストールは無料です。<br/>
						公演情報やお知らせなどの情報も無料でご覧いただけます。<br/>
						アプリ内でのネット席の購入は有料となります。</dd>
						<input id="qa31" type="checkbox" /><label for="qa31"><dt className="qa-q">最初の画面に戻れません。</dt></label>
						<dd className="qa-a">画面下部または画面右上の家の形をしたボタンをタップすることで戻ることができます。ボタンをタップしても変化がない場合は、一度アプリを終了しお使いの機器を再起動してから再度お試しください。</dd>


						<input id="qa32" type="checkbox" /><label for="qa32"><dt className="qa-q">パスワードを忘れました。</dt></label>
						<dd className="qa-a">ログイン画面上の「パスワードを忘れた方はこちら」をクリックしてパスワードの再設定を行ってください。</dd>
						<input id="qa33" type="checkbox" /><label for="qa33"><dt className="qa-q">Apple IDのアカウントがわかりません。</dt></label>
						<dd className="qa-a">ご利用中のスマートフォンの管理をされている方にご確認ください。</dd>
						<input id="qa34" type="checkbox" /><label for="qa34"><dt className="qa-q">アプリで何ができますか。</dt></label>
						<dd className="qa-a">本アプリでは、以下のことができます。
							<ul>
								<li>公式のお知らせ閲覧</li>
								<li>公演情報の閲覧</li>
								<li>ネット席の購入</li>
								<li>購入済みネット席の確認</li>
								<li>配信映像の視聴</li>
							</ul></dd>
						<input id="qa35" type="checkbox" /><label for="qa35"><dt className="qa-q">本アプリに関する問い合わせ先はありますか。</dt></label>
						<dd className="qa-a">その他のお問い合わせ　の問い合わせ先にお問合せください。</dd>
						<input id="qa36" type="checkbox" /><label for="qa36"><dt className="qa-q">「東京春祭」アプリを誤って削除してしまいました。</dt></label>
						<dd className="qa-a">再度本アプリをダウンロードしてログインしていただければ引き続きご利用いただけます。</dd>
						<input id="qa37" type="checkbox" /><label for="qa37"><dt className="qa-q">スマートフォンの機種変更をした後も利用できますか。</dt></label>
						<dd className="qa-a">新しいスマートフォンで App Store から本アプリをダウンロードしてログインしていただければそのままご利用いただけます。</dd>
						<input id="qa38" type="checkbox" /><label for="qa38"><dt className="qa-q">ネット席が購入できません。</dt></label>
						<dd className="qa-a">本アプリではアプリ内決済でネット席を購入することができます。<br/>
						すでにアカウントをお持ちの方で、配信サイトにお支払い方法を登録されている方でもアプリ内決済の設定が別途必要になります。<br/>
						お使いのスマートフォンのアプリ内決済の設定方法については、以下のサイトからご確認ください。<br/>
						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT202023" target="_blank" rel="noreferrer">iOS</a></dd>
						<input id="qa39" type="checkbox" /><label for="qa39"><dt className="qa-q">予約コードは利用できますか。</dt></label>
						<dd className="qa-a">本アプリ内でネット席を購入される場合、予約コードは利用できません。<br/>
						予約コードを利用してのご購入は、パソコン又はスマートフォンのブラウザからのみとなります。</dd>
						<input id="qa40" type="checkbox" /><label for="qa40"><dt className="qa-q">スマートフォンの他にタブレットにもインストールできますか。</dt></label>
						<dd className="qa-a">サポートしている環境であれば、iOSやiPadOSデバイスにインストールできます。</dd>
						<input id="qa41" type="checkbox" /><label for="qa41"><dt className="qa-q">ネット席が購入できたかどうかわかりません。</dt></label>
						<dd className="qa-a">画面下部にある「購入済」ボタンをタップすると本アプリで購入された公演の一覧をご確認いただけます。<br/>
						※確認にはログインが必要です。</dd>
						<input id="qa42" type="checkbox" /><label for="qa42"><dt className="qa-q">公演中止の場合払い戻しはありますか。</dt></label>
						<dd className="qa-a">主催者都合で公演が中止になった場合のみ払い戻しが可能です。<br/>
						払い戻しには申請が必要となりますので、お使いのスマートフォンに応じて以下のサイトからご確認ください。<br/>

						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iOS</a></dd>
					</dl>
				</section>
			) : (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">Frequently Asked Questions</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#q1">About Accounts</a></li>
      					<li className="qa-links__item"><a href="#q2">Viewing environment/specifications</a></li>
      					<li className="qa-links__item"><a href="#q3">About Purchasing</a></li>
      					<li className="qa-links__item"><a href="#q4">About viewing</a></li>
      					<li className="qa-links__item"><a href="#q5">About the Spring Festival in Tokyo App</a></li>
    				</ul>

					<h2 className="common-title" id="q1">About Accounts</h2>
					<dl className="qa-list">

						<input id="qae1" type="checkbox" /><label for="qae1"><dt className="qa-q">Can I use the account I created last year?</dt></label>
						<dd className="qa-a">
							Accounts registered for Spring Festival in Tokyo LIVE Streaming will continue to be available.
						</dd>
						<input id="qae2" type="checkbox" /><label for="qae2"><dt className="qa-q">Can I register an account with the app?</dt></label>
						<dd className="qa-a">
							New account registration is not available in the application.
						</dd>
					</dl>

					<h2 className="common-title" id="q2">Viewing environment/specifications</h2>
					<dl className="qa-list">
						<input id="qae3" type="checkbox" /><label for="qae3"><dt className="qa-q">What environment can I use to view the live streaming feed?</dt></label>
						<dd className="qa-a">
							The following environment is available for viewing.
							<br />
							<br />
							- Apple (iOS, iPadOS) Supported Environment
							<br />
							iOS 15 or later Safari latest
							<br />
							iPad OS 15 or later Safari latest
						</dd>
						<input id="qae4" type="checkbox" /><label for="qae4"><dt className="qa-q">Are there any restrictions on the purchase of live streaming tickets?</dt></label>
						<dd className="qa-a">There is a deadline for purchase for each performance. Please check the details page of each performance.</dd>

						<input id="qae5" type="checkbox" /><label for="qae5"><dt className="qa-q">Are there any missed broadcasts/archived broadcasts?</dt></label>
						<dd className="qa-a">There will be no missed broadcasts or archived broadcasts.</dd>
						<input id="qae6" type="checkbox" /><label for="qae6"><dt className="qa-q">Can I view the live streaming feed on a different device than the one I purchased the ticket on?</dt></label>
						<dd className="qa-a">You can view the live streaming feed on a different device than the one you purchased. You will need to log in with the account you used at the time of purchase.</dd>
					</dl>

					<h2 className="common-title" id="q3">About Purchasing</h2>
					<dl className="qa-list">
						<input id="qae7" type="checkbox" /><label for="qae7"><dt className="qa-q">I don't know how to purchase.</dt></label>
						<dd className="qa-a">
							Please refer to
							<a className="qa-links__item" href="/guide_m">
								User's Guide
							</a>.
						</dd>
						<input id="qae8" type="checkbox" /><label for="qae8"><dt className="qa-q">How much is the fee?</dt></label>
						<dd className="qa-a">There is no handling fee for purchasing viewing tickets. However, you will be charged a separate communication fee for the purchase and viewing of tickets, which will be borne by you.</dd>
						<input id="qae9" type="checkbox" /><label for="qae9"><dt className="qa-q">What payment methods do you accept?</dt></label>
						<dd className="qa-a">
							For in-app payment methods, please check the following page for Apple services.<br/>
							<br/>

							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT202023" target="_blank" rel="noreferrer">iPhone / iPad</a>
						</dd>

						<input id="qae10" type="checkbox" /><label for="qae10"><dt className="qa-q">Payment cannot be made.</dt></label>
						<dd className="qa-a">Please check your payment method settings.</dd>

						<input id="qae11" type="checkbox" /><label for="qae11"><dt className="qa-q">Can I cancel (get a refund) for my ticket?</dt></label>
						<dd className="qa-a">Cancellations (refunds) due to customer's convenience will not be accepted. Refunds will only be made in the event that the performance is cancelled or postponed due to circumstances beyond the control of the organizer.</dd>
						<input id="qae12" type="checkbox" /><label for="qae12"><dt className="qa-q">I purchased a different show by mistake, can I change it?</dt></label>
						<dd className="qa-a">No changes or cancellations will be accepted by the customer after payment is completed.</dd>
					</dl>

					<h2 className="common-title" id="q4">About viewing</h2>
					<dl className="qa-list">
						<input id="qae13" type="checkbox" /><label for="qae13"><dt className="qa-q">I don't know how to watch it.</dt></label>
						<dd className="qa-a">
							Please refer to
							<a className="qa-links__item" href="/guide_m">
								User's Guide
							</a>.
						</dd>
						<input id="qae14" type="checkbox" /><label for="qae14"><dt className="qa-q">Are there any communication charges for watching live streaming?</dt></label>
						<dd className="qa-a">You will be responsible for any communication charges incurred when viewing the live streaming feed.</dd>
						<input id="qae15" type="checkbox" /><label for="qae15"><dt className="qa-q">Can I watch on multiple devices at the same time?</dt></label>
						<dd className="qa-a">Simultaneous viewing on multiple devices is not possible.</dd>

						<input id="qae16" type="checkbox" /><label for="qae16"><dt className="qa-q">Can I watch the live streaming feed on my TV screen?</dt></label>
						<dd className="qa-a">Viewing by connecting to a TV screen is not supported.</dd>
						<input id="qae17" type="checkbox" /><label for="qae17"><dt className="qa-q">Can I make a donation?</dt></label>
						<dd className="qa-a">No donations will be accepted.<br/>
						Donations to the Spring Festival in Tokyo can be made at the<a className="qa-links__item" href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer">official website</a>.</dd>
						<input id="qae18" type="checkbox" /><label for="qae18"><dt className="qa-q">I accessed the live streaming page, but I can't see the video.</dt></label>
						<dd className="qa-a">Make sure that you can watch video on <a className="qa-links__item" href="/test">
								Video Playback Page
							</a>.</dd>
						<input id="qae19" type="checkbox" /><label for="qae19"><dt className="qa-q">I can't hear a sound.</dt></label>
						<dd className="qa-a">
							Please check the volume on your device.
							<br />
							If the volume is too low or muted, turn up the volume on your device.
							<br />
							If you are using a smartphone or tablet, please make sure that it is not in silent mode.
							<br />
							Please check if earphones or headphones are not connected. (If you are using earphones, please make sure they are connected.)
							<br />
							<br />
							Please check the volume of your player.
							<br />
							Turn up the volume by using the speaker icon at the bottom of the player.
							<br />
							If you are using a tablet, the sound may be muted by default.
							<br />
							<br />
							If the problem is still not resolved after checking the above:
							<br />
							The problem may be solved by rebooting the device you are using.
							<br />
							There may be a problem with the delivery side. Please contact our help center.
						</dd>
						<input id="qae20" type="checkbox" /><label for="qae20"><dt className="qa-q">I can't see the video.</dt></label>
							<dd className="qa-a">
							If you are experiencing problems with video or audio and are having trouble viewing, please try the following：
							<br />
							<br />
							- iPhone/iPad
							<br />
							Please make sure that your computer meets the recommended environment for Spring Festival in Tokyo LIVE  Streaming.
							<br />
							Access Video playback confirmation page from the menu.
							<br />
							<br />
							- Reboot your device
							<br />
							Please refer to the following link:
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201559" target="_blank" rel="noreferrer">
								iPhone
							</a>
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT210631" target="_blank" rel="noreferrer">
								iPad
							</a>
							<br />
							<br />
							If you are using mobile data communication, please switch to Wi-Fi connection.
							<br />
							If you are using a Wi-Fi connection, please reset your network settings.
							<br />
							For instructions on how to set this up, please see 'Reset network settings' in '2. Select options:' on the following page.
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios" target="_blank" rel="noreferrer">
								https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios
							</a>
						</dd>
						<input id="qae21" type="checkbox" /><label for="qae21"><dt className="qa-q">Subtitles are not displayed.</dt></label>
						<dd className="qa-a">
							1.Please check if the title of the performance listed in the performance summary says "with subtitles". If it is, the performance will be subtitled.
							<br />
							2.If you are watching a "subtitled" performance and the subtitles are not displayed on the live streaming feed, you can turn them on or off by clicking the [Show Subtitles] button at the bottom right of the player you are watching.
						</dd>
						<input id="qae22" type="checkbox" /><label for="qae22"><dt className="qa-q">I missed the live streaming feed, can I get a refund?</dt></label>
						<dd className="qa-a">Refunds will be made in the event that the performance is cancelled due to reasons beyond the control of the organizer. No refunds will be made for any other reason.</dd>
						<input id="qae23" type="checkbox" /><label for="qae23"><dt className="qa-q">Can I still watch the whole movie from the beginning if I missed?</dt></label>
						<dd className="qa-a">As this is a live broadcast, you cannot rewind the video.</dd>
						<input id="qae24" type="checkbox" /><label for="qae24"><dt className="qa-q">We were unable to view the video due to a power outage caused by bad weather.</dt></label>
						<dd className="qa-a">If you are unable to view the show due to a power outage caused by bad weather, we are very sorry, but we cannot offer refunds. If the performance is cancelled due to reasons of the organizer, refunds will be provided.</dd>
						<input id="qae25" type="checkbox" /><label for="qae25"><dt className="qa-q">I want to give away distribution tickets to friends and family.</dt></label>
						<dd className="qa-a">Purchased tickets cannot be given as gifts. As your account contains important personal information, please do not share it with others. (The Spring Festival in Tokyo Executive Committee and IIJ will not be held responsible for any damages resulting from the disclosure of account information to third parties.)</dd>
						<input id="qae26" type="checkbox" /><label for="qae26"><dt className="qa-q">JavaScript</dt></label>
						<dd className="qa-a">
							JavaScript is used in this site.
							<br />
							If the JavaScript function is disabled in your browser, the site may not function or display properly.
							<br />
							Please enable JavaScript in your browser settings to view this site.
						</dd>
						<input id="qae27" type="checkbox" /><label for="qae27"><dt className="qa-q">Cookie</dt></label>
						<dd className="qa-a">
							Cookies are used on this site.
							<br />
							If you have disabled the cookie function in your browser, the site may not function or display properly.
							<br />
							To view this site, please enable cookies in your browser settings.
							<br />
							<br />
							Mac
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/safari/sfri35610/14.0/mac/11.0" target="_blank" rel="noreferrer">
								Apple Safari
							</a>{' '}
							<br />
							iOS / iPadOS
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201265" target="_blank" rel="noreferrer">
								Safari
							</a>
						</dd>

						<input id="qae28" type="checkbox" /><label for="qae28"><dt className="qa-q">Other inquiries</dt></label>
						<dd className="qa-a">
							If you have any questions, please contact our help center.
						</dd>
					</dl>

					<h2 className="common-title" id="q5">About the Spring Festival in Tokyo App</h2>
					<dl className="qa-list">
						<input id="qae29" type="checkbox" /><label for="qae29"><dt className="qa-q">I can't install the app.</dt></label>
						<dd className="qa-a">The "Tokyo Shunsai" application (hereinafter referred to as the "Application") can be used on smartphones and tablets running iOS 15.0 / iPad OS 15.0 or higher. If you do not see the "Install" button on the following store page, you cannot use this app on your smartphone or tablet.<br/><br/>

						<a className="qa-links__item" href="https://www.apple.com/jp/app-store/" target="_blank" rel="noreferrer">App Store (iOS / iPadOS)</a><br/><br/>

						To download the app, you will need an Apple ID account, respectively.</dd>
						<input id="qae30" type="checkbox" /><label for="qae30"><dt className="qa-q">Is this app free to use?</dt></label>
						<dd className="qa-a">This app is free to download and install.<br/>
						Information on performances and announcements are also available free of charge.<br/>
						There is a fee to purchase distribution tickets in the app.</dd>
						<input id="qae31" type="checkbox" /><label for="qae31"><dt className="qa-q">I can't go back to the first screen.</dt></label>
						<dd className="qa-a">You can go back by tapping the house-shaped button at the bottom of the screen or the upper right corner of the screen. If there is no change after tapping the button, please exit the app and restart your device, then try again.</dd>

						<input id="qae32" type="checkbox" /><label for="qae32"><dt className="qa-q">Can I use the account I created last year with this application?</dt></label>
						<dd className="qa-a">If you created an account for last year's "Spring Festival in Tokyo LIVE Streaming 2022" you can use the same account for this application.</dd>
						<input id="qae33" type="checkbox" /><label for="qae33"><dt className="qa-q">I forgot my password.</dt></label>
						<dd className="qa-a">Click "Forgot your password?" on the login screen to reset your password.</dd>
						<input id="qae34" type="checkbox" /><label for="qae34"><dt className="qa-q">I don't know my Apple ID account.</dt></label>
						<dd className="qa-a">Please check with the person who is managing your smartphone.</dd>
						<input id="qae35" type="checkbox" /><label for="qae35"><dt className="qa-q">What can I do with the app?</dt></label>
						<dd className="qa-a">This application allows you to do the following:
							<ul>
								<li>Browse official announcements</li>
								<li>Viewing performance information</li>
								<li>Purchase distribution tickets</li>
								<li>Confirmation of purchased delivery tickets</li>
								<li>Viewing the video distribution</li>
							</ul></dd>
						<input id="qae36" type="checkbox" /><label for="qae36"><dt className="qa-q">Is there anyone I can contact about this application?</dt></label>
						<dd className="qa-a">If you have any questions, please use the help function on My Page.</dd>
						<input id="qae37" type="checkbox" /><label for="qae37"><dt className="qa-q">I accidentally deleted the "Spring Festival in Tokyo" app.</dt></label>
						<dd className="qa-a">If you download the app again and log in, you can continue to use it.</dd>
						<input id="qae38" type="checkbox" /><label for="qae38"><dt className="qa-q">Can I still use the service after I change my smartphone model?</dt></label>
						<dd className="qa-a">You can download this app from App Store on your new smartphone and login to use it.</dd>
						<input id="qae39" type="checkbox" /><label for="qae39"><dt className="qa-q">Unable to purchase distribution tickets.</dt></label>
						<dd className="qa-a">This app allows you to purchase distribution tickets via in-app payment.<br/>
						Even if you already have an account and have registered a payment method on the distribution site, you will need to set up in-app payment separately. For more information on how to set up in-app payment for your smartphone, please visit the following website.<br/>
						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT202023">iOS</a></dd>
						<input id="qae40" type="checkbox" /><label for="qae40"><dt className="qa-q">Can I use a coupon?</dt></label>
						<dd className="qa-a">Coupons cannot be used when purchasing distribution tickets within this application.<br/>
						Purchases using the coupon can only be made from a computer or smartphone browser.</dd>
						<input id="qae41" type="checkbox" /><label for="qae41"><dt className="qa-q">Can I install the software on my tablet as well as my smartphone?</dt></label>
						<dd className="qa-a">It can be installed on iOS and iPadOS devices as long as the environment supports it.</dd>
						<input id="qae42" type="checkbox" /><label for="qae42"><dt className="qa-q">I don't know if I was able to purchase a delivery ticket.</dt></label>
						<dd className="qa-a">Tap the "Purchased" button at the bottom of the screen to see a list of performances that have been purchased with this app. <br/>
						You must be logged in to view the list.</dd>
						<input id="qae43" type="checkbox" /><label for="qae43"><dt className="qa-q">If a performance is cancelled, will there be a refund?</dt></label>
						<dd className="qa-a">Refunds are available only in the event that the performance is cancelled due to reasons beyond the control of the organizer.<br/>
						You will need to apply for a refund, so please check the following sites depending on the smartphone you are using.<br/>
						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iOS</a></dd>
					</dl>

				</section>
			)}
*/}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
