import React, { useState, useEffect } from 'react';
import OfferDisplayStatusConst from '../../common/OfferDisplayStatusConst';
import {getTaxPrice, getTaxFromSettings, getSystemRequirementsFromSettings} from '../../common/CommonUtil';

function OfferDetailInfo(props) {
	let datepet = new Date(props.purchase_end_time);
	let archiveDateepet = new Date(props.archive_purchase_end_time)
  	const [dayOfWeek, setDayOfWeek] = useState()
	const [archiveDayOfWeek, setArchiveDayOfWeek] = useState()
	const [tax, setTax] = useState(1)
	const [systemRequirements, setSystemRequirements] = useState()
	
  	useEffect(() => {
		getSystemRequirements();
		getTax();
		if (props.language === "ja_JP") {
			setDayOfWeek(["日", "月", "火", "水", "木", "金", "土"][datepet.getDay()])
			setArchiveDayOfWeek(["日", "月", "火", "水", "木", "金", "土"][archiveDateepet.getDay()])
		}
		if (props.language === "en_US") {
		  setDayOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][datepet.getDay()])
		  setArchiveDayOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][archiveDateepet.getDay()])
	  	}
  	}, [props.settingJson])

	const getTax = () => {
		if (!props.settingJson) {return;}
		let tax = getTaxFromSettings(props.settingJson);
		if (tax) {setTax(tax);}
	}

	const getSystemRequirements = () => {
		if (!props.settingJson) {return;}
		setSystemRequirements(getSystemRequirementsFromSettings(props.settingJson));
	}
	return (
		<dl className="detail-info">
			{ !props.is_ensemble_cancelled ?
				(
					<>
					{props.display_status === OfferDisplayStatusConst.archive ?
						<>
							<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '価格' : 	'Price'}</dt>
							<dd className="detail-info__text before-live-only">
								{getTaxPrice(props.isJapan, props.archivePianoPrice, tax)}
								{props.language === 'ja_JP' ? '円（税込み）' : props.foreign_currency !== undefined ? 'yen (' + props.foreign_currency + ')' : 'yen'}
							</dd>
							<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '購入期限' : 'Available until'}</dt>
							<dd className="detail-info__text before-live-only">{`${props.language === 'ja_JP' ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][archiveDateepet.getMonth()]}/${archiveDateepet.getDate()} [${archiveDayOfWeek}] ${('0' + archiveDateepet.getHours()).slice(-2)}:${('0' + archiveDateepet.getMinutes()).slice(-2)}` : `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][archiveDateepet.getMonth()]},${archiveDateepet.getDate()} [${archiveDayOfWeek}] ${('0' + archiveDateepet.getHours()).slice(-2)}:${('0' + archiveDateepet.getMinutes()).slice(-2)}`}`} </dd>
						</>
					:
						<>
							<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '価格' : 	'Price'}</dt>
							<dd className="detail-info__text before-live-only">
								{GetPriceList(props.language , props.isJapan, props.pianoPrice, tax)}
								<br/>
								<div className="detail-body__attention">{GetProgramBookAttention(props.language , props.isJapan, props.pianoPrice, tax)}</div>
							</dd>
							<br/>
							<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '購入期限' : 'Available until'}</dt>
							<dd className="detail-info__text before-live-only">{`${props.language === 'ja_JP' ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datepet.getMonth()]}/${datepet.getDate()} [${dayOfWeek}] ${('0' + datepet.getHours()).slice(-2)}:${('0' + datepet.getMinutes()).slice(-2)}` : `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][datepet.getMonth()]},${datepet.getDate()} [${dayOfWeek}] ${('0' + datepet.getHours()).slice(-2)}:${('0' + datepet.getMinutes()).slice(-2)}`}`} </dd>
							<br/>
						 </>
					}
					</>
				) : (
					<>
						<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '価格' : 	'Price'}</dt>
						<dd className="detail-info__text before-live-only">
							{props.language === 'ja_JP' ? '公演中止' : 'Cancelled'}
						</dd>
						<br/><br/>
					</>
				)
			}

			<br/><br/>
			<dt></dt>
			<dd className="detail-info__text">
				<div>
						{props.language === 'ja_JP' ? (
							<p>
								<a href="/test">動画再生環境確認ページ</a>でご利用する端末およびブラウザで動画が再生できることを確認してください。
							</p>
							) : (
							<p>
								Please confirm that the video can be played on the device and browser you will be using on the video playback environment <a href="/test">confirmation page</a>.
							</p>
						)}
				</div>
			</dd>
			<br/><br/>
			{systemRequirements && Object.keys(systemRequirements).length ? (
			<>
				<dt className="detail-info__text-title"> {props.language === 'ja_JP' ? '推奨環境' : 'System Requirements'}</dt>
				<dd className="detail-info__text">
					{props.language === 'ja_JP' ? (
						<>
						<div>
						{systemRequirements.Windows || systemRequirements.Mac ? (
							<>
							<div className="detail-info__text-title">【PC】</div>
							{systemRequirements.Windows? 
								<>
								<div className="detail-info__text-title">Windows {systemRequirements.Windows.os}</div>
								{systemRequirements.Windows.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.Mac ? 
								<>
								<div className="detail-info__text-title">Mac OSX Catalina({systemRequirements.Mac.os}以降)</div>
								{systemRequirements.Mac.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
						{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
							<>
							<div className="detail-info__text-title">【スマートフォン】</div>
							{systemRequirements.Android ? 
								<>
								<div className="detail-info__text-title">Android （Android {systemRequirements.Android.os}以降）</div>
								{systemRequirements.Android.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.iOS && systemRequirements.iPad ? 
								<>
								<div className="detail-info__text-title">Apple（iOS {systemRequirements.iOS.os}以降、iPadOS {systemRequirements.iPad.os}以降）</div>
								{systemRequirements.iOS.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
						<p>&nbsp;</p>
						</div>
						</>
					) : (
						''
					)}
					{props.language === 'en_US' ? (
						<div>
						{systemRequirements.Windows || systemRequirements.Mac ? (
							<>
							<div className="detail-info__text-title">【PC】</div>
							{systemRequirements.Windows ? 
								<>
								<div className="detail-info__text-title">Windows {systemRequirements.Windows.os}</div>
								{systemRequirements.Windows.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.Mac ? 
								<>
								<div className="detail-info__text-title">Mac OSX Catalina({systemRequirements.Mac.os} or later)</div>
								{systemRequirements.Mac.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
						{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
							<>
							<div className="detail-info__text-title">【Smart Phone】</div>
							{systemRequirements.Android ? 
								<>
								<div className="detail-info__text-title">Android （Android {systemRequirements.Android.os} or later）</div>
								{systemRequirements.Android.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.iOS && systemRequirements.iPad ? 
								<>
								<div className="detail-info__text-title">Apple（iOS {systemRequirements.iOS.os} or later、iPadOS {systemRequirements.iPad.os} or later）</div>
								{systemRequirements.iOS.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
						</div>
					) : (
						''
					)}
				</dd>
			</>
			):('')}
		</dl>
	);

	function GetPriceList(lang, isJP , price , tax,) {
		let _price =  getTaxPrice(isJP, price, tax)
		let _price_disp_list = [];
		//通常のチケット料金
		_price_disp_list.push( (lang == "ja_JP") ? "" + GetPriceFromWithBooklet(_price) + "円(税込)" : "" + GetPriceFromWithBooklet(_price) + "yen" )
		if (_price == "2,270" || _price == "1,970" || _price == "2,063" || _price == "1,790") {
			//公式プログラム付きがある場合
			_price_disp_list.push((lang == "ja_JP") ? "公式プログラム付:" + _price + "円(税込)" : "with offical program book:" + _price + "yen")

		}
		return <div>{_price_disp_list.map(t => (<div>{t}</div>))}</div>
		
	}
	function GetPriceFromWithBooklet(_price) {
		if (_price == "2,270") return "1,200";
		if (_price == "1,970") return "900";
		if (_price == "2,063") return "1,090";
		if (_price == "1,790") return "818";
		//公式プログラムではない場合はそのまま返却
		return _price;
	}

	function GetProgramBookAttention(lang, isJP , price , tax,) {
		let _price =  getTaxPrice(isJP, price, tax)
		let _ret = "";
		if (_price == "2,270" || _price == "1,970" || _price == "2,063" || _price == "1,790") {
			if(lang == "ja_JP") {
				_ret = "※公式プログラムは音楽祭全体を網羅するものとなり、各公演毎にご用意した仕様ではございません。複数の公演をご購入される場合は、1公演のみで「公式プログラム付」を選択してください。";
			}
			else {
				_ret = "※The official program book is Spring Festival in Tokyo 2024's book. The official program book is are not prepared for each show. Please be careful when purchasing multiple shows.";
			}
		}
		return _ret;
	}
	
	
}

export default OfferDetailInfo;
