import React from 'react';

function OfferSponsors(props) {
	return (
		<div className="detail-body__sponsors">
			{props.language === 'ja_JP' ? getJapanese(props.organizer, props.co_organizer, props.support, props.special_co_operation) : ''}
			{props.language === 'en_US' ? getEnglish(props.organizer_en, props.co_organizer_en, props.support_en, props.special_co_operation_en) : ''}
			{props.support_logo_url ? (
				<a href={props.support_url}>
					<img src={props.support_logo_url} width="90" height="94" alt={props.support} />
				</a>
			) : (
				``
			)}
			{props.special_co_operation_logo_url ? <img src={props.special_co_operation_logo_url} width="90" height="94" alt={props.special_co_operation} /> : ``}
		</div>
	);
}

function getEnglish(organizer, co_organizer, support, special_co_operation) {
	return (
		<>
			{organizer ? <p>Organizer：{organizer}</p> : ''}
			{co_organizer ? <p>Co-Organizer：{co_organizer}</p> : ''}
			{support ? <p>Support：{support}</p> : ''}
			{special_co_operation ? <p>Special Co Operation：{special_co_operation}</p> : ''}
		</>
	);
}

function getJapanese(organizer, co_organizer, support, special_co_operation) {
	return (
		<>
			{organizer ? <p>主催：{organizer}</p> : ''}
			{co_organizer ? <p>共催：{co_organizer}</p> : ''}
			{support ? <p>後援：{support}</p> : ''}
			{special_co_operation ? <p>特別協力：{special_co_operation}</p> : ''}
		</>
	);
}

export default OfferSponsors;
