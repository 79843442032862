import React, { useState, useEffect } from "react"

function OfferDeliveryPeriod(props) {
  let datest = new Date(props.startTime);
  let dateen = new Date(props.endTime);

  const [startDayOfWeek, setStartDayOfWeek] = useState()
  const [endDayOfWeek, setEndDayOfWeek] = useState()
  useEffect(() => {
    let datest = new Date(props.startTime);
     let dateen = new Date(props.endTime);
    if (props.language === "ja_JP") {
      setStartDayOfWeek(["日", "月", "火", "水", "木", "金", "土"][datest.getDay()])
      setEndDayOfWeek(["日", "月", "火", "水", "木", "金", "土"][dateen.getDay()])
    }
    if (props.language === "en_US") {
      setStartDayOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][datest.getDay()])
      setEndDayOfWeek(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dateen.getDay()])
    }
  }, [props])

  return (
      <dd className="detail-info__text">
        {props.language === "ja_JP"
            ? `${datest.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${startDayOfWeek}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} ～ ${dateen.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][dateen.getMonth()]}/${dateen.getDate()} [${endDayOfWeek}] ${('0' + dateen.getHours()).slice(-2)}:${('0' + dateen.getMinutes()).slice(-2)}`
            : ""}
        {props.language === "en_US"
            ? `${datest.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${startDayOfWeek}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} ～ ${dateen.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][dateen.getMonth()]}/${dateen.getDate()} [${endDayOfWeek}] ${('0' + dateen.getHours()).slice(-2)}:${('0' + dateen.getMinutes()).slice(-2)}`
            : ""}
      </dd>
  )
}

export default OfferDeliveryPeriod
