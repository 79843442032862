import React from "react"
import { connect } from "react-redux"
import { langSwap } from "../../actions/piano.js"

function LanguageSwapButton(props) {
  return (
    <ul
      className="language language--ja pc-only"
      onClick={() => {
        props.langSwap()
      }}
    >
        <li className={props.language === "ja_JP" ? "language__ja" : "language__en"}>
            {/*<li className="language__ja" style={props.language === "ja_JP" ? InActiveStyle : ActiveStyle}>*/}
            <a href="#" title="日本語">日本語</a>
        </li>
        <li className="language__slash"> / </li>
        <li className={props.language === "en_US" ? "language__ja" : "language__en"}>
            {/*<li className="language__en" style={props.language === "en_US" ? InActiveStyle : ActiveStyle}>*/}
            <a href="#" title="English">English</a>
        </li>
    </ul>
  )
}


//<ul class="language language--ja pc-only">
// <li class="language__ja"><a title="日本語">日本語</a></li>
// <li class="language__slash">/</li>
// <li class="language__en"><a title="English">English</a></li>
// </ul>

//<ul class="language language--ja pc-only">
//   <li class="language__ja"><a href="01-1_home.html" title="日本語">日本語</a></li>
//   <li class="language__slash">/</li>
//   <li class="language__en"><a href="" title="English">English</a></li>
// </ul>

const ActiveStyle = {
  display: "inline-block",
  textDecoration: "none",
  color: "#EDEDED",
  cursor: "pointer",
}

const InActiveStyle = {
  display: "inline-block",
  color: "#D56096",
  textDecoration: "none",
  pointerEvents: "none",
}

const mapDispatchToProps = (dispatch) => ({
  langSwap: () => dispatch(langSwap()),
})

export default connect(null, mapDispatchToProps)(LanguageSwapButton)
