import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { initialize } from './actions/piano';
import { getCustomize } from './actions/customize';

import Index from './pages/Index';
import PremiumIndex from './pages/PremiumIndex';
import Logout from './pages/Logout';
import MyPage from './pages/MyPage';
import Test from './pages/Test';
import Offer from './pages/OfferPage';
import Offerm from './pages/OfferPagem';

import PrivacyPolicy from './pages/PrivacyPolicy';
import PersonalInformation from './pages/PersonalInformation';
import SpecifiedCommercialTransactionAct from './pages/SpecifiedCommercialTransactionAct';
import TermOfService from './pages/TermOfService';
import Faq from './pages/Faq';
import PremiumFaq from './pages/PremiumFaq';
import FaqM from './pages/FaqM';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import MobileMenu from './components/MobileMenu/MobileMenu';
import PremiumMobileMenu from './components/MobileMenu/PremiumMobileMenu';
import ScrollToTop from './components/Header/ScrollToTop';
import NotFound from './pages/NotFound';
import InternalServerError from './pages/InternalServerError';
import Guide from './pages/Guide';
import GuideM from './pages/GuideM';
import PasswordReset from './pages/PasswordReset';
import Refund from './pages/Refund';
import Unsubscribe from './pages/Unsubscribe';
import Const from './common/Const';
import PremiunHeader from './components/Header/PremiunHeader';
import PremiunFooter from './components/Footer/PremiunFooter';
import PremiumPersonalInformation from './pages/PremiumPersonalInformation';
import PremiumPrivacyPolicy from './pages/PremiumPrivacyPolicy';


function App(props) {

	// プレミアムプラン用ホスト判定フラグ（0:共通/1:プレミアムプラン）
	const isPremium = parseInt(process.env.REACT_APP_IS_PREMIUM, 10) === Const.flgPremiumPlan;

	useEffect(() => {
		props.initialize();
		// プレミアムプランの場合、URLからdistributor_idを取得しカスタマイズ情報を取得する
		if (isPremium) {
			let hostname = window.location.hostname;
			props.customize(hostname.replace(process.env.REACT_APP_DOMAIN,''));
		}
	}, []);

	return (
		<React.Fragment>
			<div className={`${props.isStreaming ? 'detail-live' : ''}`}>
				{isPremium?
					<Router>
						<ScrollToTop />
						<PremiunHeader />
						<div className="container">
							<Switch>
								<Route exact path="/" component={PremiumIndex} />
								<Route exact path="/logout" component={Logout} />
								<Route exact path="/mypage" component={MyPage} />
								<Route path="/offer/:offer_id" component={Offer} />

								<Route exact path="/privacy_policy" component={PremiumPrivacyPolicy} />
								<Route exact path="/personal_information" component={PremiumPersonalInformation} />
								<Route exact path="/specified_commercial_transaction_law" component={SpecifiedCommercialTransactionAct} />
								<Route exact path="/term_of_service" component={TermOfService} />
								<Route exact path="/faq" component={PremiumFaq} />
								<Route exact path="/faq_m" component={FaqM} />

								<Route exact path="/test" component={Test} />
								<Route exact path="/500" component={InternalServerError} />
								<Route exact path="/404" component={NotFound} />
								<Route exact path="/guide" component={Guide} />
								<Route exact path="/guide_m" component={GuideM} />
								<Route exact path="/refund" component={Refund} />
								<Route exact path="/unsubscribe" component={Unsubscribe} />
								<Route component={NotFound} />
							</Switch>
						</div>
						<PremiunFooter />
						<PremiumMobileMenu />
					</Router>
				:
					<Router>
						<ScrollToTop />
						<Header />
						<div className="container">
							<Switch>
								<Route exact path="/" component={Index} />
								<Route exact path="/logout" component={Logout} />
								<Route exact path="/mypage" component={MyPage} />
								<Route path="/offer/:offer_id" component={Offer} />

								<Route exact path="/privacy_policy" component={PrivacyPolicy} />
								<Route exact path="/personal_information" component={PersonalInformation} />
								<Route exact path="/specified_commercial_transaction_law" component={SpecifiedCommercialTransactionAct} />
								<Route exact path="/term_of_service" component={TermOfService} />
								<Route exact path="/faq" component={Faq} />
								<Route exact path="/faq_m" component={FaqM} />
								{/*<Route exact path="/password_reset" component={PasswordReset} />*/}

								<Route exact path="/test" component={Test} />
								<Route exact path="/500" component={InternalServerError} />
								<Route exact path="/404" component={NotFound} />
								<Route exact path="/guide" component={Guide} />
								<Route exact path="/guide_m" component={GuideM} />
								<Route exact path="/refund" component={Refund} />
								<Route exact path="/unsubscribe" component={Unsubscribe} />
								<Route component={NotFound} />
							</Switch>
						</div>
						<Footer />
						<MobileMenu />
					</Router>
				}
			</div>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	return {
		isStreaming: state.offer.isStreaming,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	initialize: () => dispatch(initialize()),
	customize: (distributorId) => dispatch(getCustomize(distributorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
