export const FileNameConst = {
    INFORMATION_JSON:'information.json',
    EMAIL_IMG:'email.png',
    JASRAC_IMG:'jasrac.jpg',
    LOGO_JA_IMG:'logo-ja.png',
    LOGO_EN_IMG:'logo-en.png',
    LOGO_SQ_IMG:'logo-sq.png',
    LOGO_20TH_IMG:'harusai20th.png',
    SETTING_JSON:'setting.json'
}

export default FileNameConst;