import React, { useState, useEffect } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';
import {getSystemRequirementsFromSettings} from '../common/CommonUtil';
import FileNameConst from '../common/FileNameConst.js';

function Faq(props) {
	// 設定情報（消費税率・動作推奨環境）
	const [settingJson, setSettingJson] = useState(null);
	const [systemRequirements, setSystemRequirements] = useState()

	const languageJP = "JP";
	const languageEN = "EN";

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
		// 設定情報取得
		if (settingJson === null) {getSettingJson();} 
	
	}, []);

	useEffect(() => {
		getSystemRequirements();
	}, [settingJson])

	const getSettingJson = () => {
		let ts = new Date().getTime();
		fetch(process.env.REACT_APP_JS_DIR_URL + FileNameConst.SETTING_JSON + "?" + ts)
		.then(res => res.json())
		.then(
			(result) => {
				setSettingJson(result.settings)
			},
			(error) => {
				console.log('NG');
			}
		)
	}

	const getSystemRequirements = () => {
		if (!settingJson) {return;}
		let systemRequirements = getSystemRequirementsFromSettings(settingJson);
		setSystemRequirements(systemRequirements);
	}

	const createBrowserList = (browserList, language) => {
		let list = "";
		for (let i = 0; i < browserList.length; i++) {
			list += browserList[i]
			if (i < browserList.length - 1) {
				if (language === "JP") {
					list += "・"
				} else {
					list += ", "
				}
			}
		}
		return list;
	}

	const rendaringSystemRequirementsJP = (systemRequirements) => {
		if (!(systemRequirements && Object.keys(systemRequirements).length)) {
			return (<dd className="qa-a"><a href="#q6" className="qa-links__item">お問い合わせ</a>に記載のある窓口までお問い合わせください。</dd>)
		}

		let systemRequirementWindows = "";
		let systemRequirementMac = "";
		let systemRequirementAndroid = "";
		let systemRequirementiOS = "";
		let systemRequirementiPadOS = "";
		if (systemRequirements.Windows) {
			systemRequirementWindows = createBrowserList(systemRequirements.Windows.omissionBrowserList, languageJP);
		}
		if (systemRequirements.Mac) {
			systemRequirementMac = createBrowserList(systemRequirements.Mac.omissionBrowserList, languageJP);
		}
		if (systemRequirements.Android) {
			systemRequirementAndroid = createBrowserList(systemRequirements.Android.omissionBrowserList, languageJP);
		}
		if (systemRequirements.iOS) {
			systemRequirementiOS = createBrowserList(systemRequirements.iOS.omissionBrowserList, languageJP);
		}
		if (systemRequirements.iPad) {
			systemRequirementiPadOS = createBrowserList(systemRequirements.iPad.omissionBrowserList, languageJP);
		}
		// 動作確認環境(日本語)のレイアウトを返却
		return (
			<dd className="qa-a">
			視聴できる環境は以下となります。
			<br />
			{systemRequirements.Windows || systemRequirements.Mac ? 
				<>
				<br />
				■ PC対応環境
				<br />
				{systemRequirements.Windows && systemRequirementWindows ? 
					<>
					•Windows {systemRequirements.Windows.os}: {systemRequirementWindows} （Internet Explorerではご利用いただけません。）
					<br />
					</>
					: ''}
				{systemRequirements.Mac && systemRequirementMac ? 
					<>
					•Mac OSX: Catalina({systemRequirements.Mac.os})以降 {systemRequirementMac}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.Android ? 
				<>
				<br />
				■ Android（スマホ、タブレット）対応環境
				<br />
				{systemRequirements.Android && systemRequirementAndroid ? 
					<>
					•Android {systemRequirements.Android.os}以降 {systemRequirementAndroid}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.iOS && systemRequirements.iPad ? 
				<>
				<br />
				■ Apple(iOS、iPadOS) 対応環境
				<br />
				{systemRequirements.iOS && systemRequirementiOS ? 
					<>
					•iOS {systemRequirements.iOS.os}以後 {systemRequirementiOS}
					<br />
					</>
					: ''}
				{systemRequirements.iPad && systemRequirementiPadOS ? 
					<>
					•iPad OS {systemRequirements.iPad.os}以後 {systemRequirementiPadOS}
					<br />
					</>
					: ''}
				</>
			: ''}
			</dd>
		)
	}

	const rendaringSystemRequirementsSpJP = (systemRequirements) => {
		if (!(systemRequirements && Object.keys(systemRequirements).length && (systemRequirements.Android || (systemRequirements.iOS && systemRequirements.iPad)))) {
			return (
			<dd className="qa-a">
				<a href="#q6" className="qa-links__item">お問い合わせ</a>に記載のある窓口までお問い合わせください。
				<br />
				<br />
				また、事前に動画再生確認ページで動画が再生されることをご確認ください。<br />
				※ライブ・ストリーミング配信視聴の際にかかる通信料は、お客様のご負担となります。
			</dd>
		)}

		let systemRequirementAndroid = "";
		let systemRequirementiOS = "";
		let systemRequirementiPadOS = "";
		if (systemRequirements.Android) {
			systemRequirementAndroid = createBrowserList(systemRequirements.Android.omissionBrowserList, languageJP);
		}
		if (systemRequirements.iOS) {
			systemRequirementiOS = createBrowserList(systemRequirements.iOS.omissionBrowserList, languageJP);
		}
		if (systemRequirements.iPad) {
			systemRequirementiPadOS = createBrowserList(systemRequirements.iPad.omissionBrowserList, languageJP);
		}
		// 動作確認環境(日本語)のレイアウトを返却
		return (
			<dd className="qa-a">
			以下の環境で視聴が可能です。
			<br />
			{systemRequirements.Android ? 
				<>
				<br />
				■ Android（スマホ、タブレット）対応環境
				<br />
				{systemRequirements.Android && systemRequirementAndroid ? 
					<>
					•Android {systemRequirements.Android.os}以降 {systemRequirementAndroid}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.iOS && systemRequirements.iPad ? 
				<>
				<br />
				■ Apple(iOS、iPadOS) 対応環境
				<br />
				{systemRequirements.iOS && systemRequirementiOS ? 
					<>
					•iOS {systemRequirements.iOS.os}以後 {systemRequirementiOS}
					<br />
					</>
					: ''}
				{systemRequirements.iPad && systemRequirementiPadOS ? 
					<>
					•iPad OS {systemRequirements.iPad.os}以後 {systemRequirementiPadOS}
					<br />
					</>
					: ''}
				</>
			: ''}
			<br />
			事前に、動画再生確認ページで動画が再生されることをご確認ください。<br />
			※ライブ・ストリーミング配信視聴の際にかかる通信料は、お客様のご負担となります。
			</dd>
		)
	}

	const rendaringSystemRequirementsEn = (systemRequirements) => {
		if (!(systemRequirements && Object.keys(systemRequirements).length)) {
			return (<dd className="qa-a">Please use the help function on My Page.</dd>)
		}

		let systemRequirementWindows = "";
		let systemRequirementMac = "";
		let systemRequirementAndroid = "";
		let systemRequirementiOS = "";
		let systemRequirementiPadOS = "";
		if (systemRequirements.Windows) {
			systemRequirementWindows = createBrowserList(systemRequirements.Windows.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.Mac) {
			systemRequirementMac = createBrowserList(systemRequirements.Mac.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.Android) {
			systemRequirementAndroid = createBrowserList(systemRequirements.Android.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.iOS) {
			systemRequirementiOS = createBrowserList(systemRequirements.iOS.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.iPad) {
			systemRequirementiPadOS = createBrowserList(systemRequirements.iPad.omissionBrowserEnList, languageEN);
		}
		// 動作確認環境(英語)のレイアウトを返却
		return (
			<dd className="qa-a">
			The following environment is available for viewing.
			<br />
			{systemRequirements.Windows || systemRequirements.Mac ? 
				<>
				<br />
				- PC
				<br />
				{systemRequirements.Windows && systemRequirementWindows ? 
					<>
					•Windows {systemRequirements.Windows.os}: {systemRequirementWindows} (Unavailable on Internet Explorer)
					<br />
					</>
					: ''}
				{systemRequirements.Mac && systemRequirementMac ? 
					<>
					•Mac OSX: Catalina({systemRequirements.Mac.os}) or later: {systemRequirementMac}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.Android ? 
				<>
				<br />
				- Android (smartphone, tablet) support environment
				<br />
				{systemRequirements.Android && systemRequirementAndroid ? 
					<>
					•Android {systemRequirements.Android.os} or later: {systemRequirementAndroid}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.iOS && systemRequirements.iPad ? 
				<>
				<br />
				- Apple (iOS, iPadOS) Supported Environment
				<br />
				{systemRequirements.iOS && systemRequirementiOS ? 
					<>
					•iOS {systemRequirements.iOS.os} or later: {systemRequirementiOS}
					<br />
					</>
					: ''}
				{systemRequirements.iPad && systemRequirementiPadOS ? 
					<>
					•iPad OS {systemRequirements.iPad.os} or later: {systemRequirementiPadOS}
					<br />
					</>
					: ''}
				</>
			: ''}
			</dd>
		)
	}

	const rendaringSystemRequirementsSpEn = (systemRequirements) => {
		if (!(systemRequirements && Object.keys(systemRequirements).length && (systemRequirements.Android || (systemRequirements.iOS && systemRequirements.iPad)))) {
			return (
			<dd className="qa-a">
				Please use the help function on My Page.
				<br />
				<br />
				And please make sure that the video plays on the video playback confirmation page before you start.<br />
				* You will be responsible for any communication charges incurred when viewing the live streaming feed.
			</dd>)
		}

		let systemRequirementAndroid = "";
		let systemRequirementiOS = "";
		let systemRequirementiPadOS = "";
		if (systemRequirements.Android) {
			systemRequirementAndroid = createBrowserList(systemRequirements.Android.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.iOS) {
			systemRequirementiOS = createBrowserList(systemRequirements.iOS.omissionBrowserEnList, languageEN);
		}
		if (systemRequirements.iPad) {
			systemRequirementiPadOS = createBrowserList(systemRequirements.iPad.omissionBrowserEnList, languageEN);
		}
		// 動作確認環境(英語)のレイアウトを返却
		return (
			<dd className="qa-a">
			The following environment is available for viewing.
			<br />
			{systemRequirements.Android ? 
				<>
				<br />
				- Android (smartphone, tablet) support environment
				<br />
				{systemRequirements.Android && systemRequirementAndroid ? 
					<>
					•Android {systemRequirements.Android.os} or above + {systemRequirementAndroid}
					<br />
					</>
					: ''}
				</>
			: ''}
			{systemRequirements.iOS && systemRequirements.iPad ? 
				<>
				<br />
				- Apple (iOS, iPadOS) Supported Environment
				<br />
				{systemRequirements.iOS && systemRequirementiOS ? 
					<>
					•iOS {systemRequirements.iOS.os} or above + {systemRequirementiOS}
					<br />
					</>
					: ''}
				{systemRequirements.iPad && systemRequirementiPadOS ? 
					<>
					•iPad OS {systemRequirements.iPad.os} or above + {systemRequirementiPadOS}
					<br />
					</>
					: ''}
				</>
			: ''}
			<br />
			And please make sure that the video plays on the video playback confirmation page before you start.<br />
			* You will be responsible for any communication charges incurred when viewing the live streaming feed.
			</dd>
		)
	}
	
	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">よくあるご質問</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#q1">アカウントについて</a></li>
      					<li className="qa-links__item"><a href="#q2">視聴環境／仕様について</a></li>
      					<li className="qa-links__item"><a href="#q3">購入について</a></li>
      					<li className="qa-links__item"><a href="#q4">視聴について</a></li>
{/*
      					<li className="qa-links__item"><a href="#q5">アプリについて</a></li>
*/}
    					<li className="qa-links__item"><a href="#q6">お問い合わせ</a></li>
    				</ul>


					<h2 className="common-title" id="q1">アカウントについて</h2>
					<dl className="qa-list">
						<input id="qa1" type="checkbox" />
						<label htmlFor="qa1">
							<dt className="qa-q">アカウントの登録方法を教えてください。</dt>
						</label>
						<dd className="qa-a">
							アカウントの登録方法は
							<a className="qa-links__item" href="/guide">
								ご利用ガイド
							</a>
							にてご確認ください。
						</dd>
						<input id="qa2" type="checkbox" />
						<label htmlFor="qa2">
							<dt className="qa-q">昨年作成したアカウントは利用できますか？</dt>
						</label>
						<dd className="qa-a">
							過去に東京･春･音楽祭 LIVE Streamingで登録されたアカウントは引き続きご利用いただけます。
						</dd>
						<input id="qa3" type="checkbox" />
{/*
						<label htmlFor="qa3">
							<dt className="qa-q">アプリでアカウントの登録はできますか？</dt>
						</label>
						<dd className="qa-a">
							アカウントの新規登録はアプリではできません。<br/>
							本サイトをパソコンやスマートフォンのブラウザでアクセスしていただき、新規アカウント登録をお願いします。<br/>
							登録の手順は「ご利用ガイド」をご覧ください。
						</dd>
*/}
						<input id="qa4" type="checkbox" />
						<label htmlFor="qa4">
							<dt className="qa-q">ログインするためのパスワードがわからなくなってしまいました、どうすればよいでしょうか？</dt>
						</label>
						<dd className="qa-a">
							メールアドレスとパスワードを入力する画面に上の「パスワードを忘れた方はこちら」をクリックしてパスワードの再設定を行ってください。
						</dd>
						<input id="qa5" type="checkbox" />
						<label htmlFor="qa5">
							<dt className="qa-q">登録したメールアドレスなどを変更することはできますか？</dt>
						</label>
						<dd className="qa-a">
							ログイン後、「マイページ」内のプロフィール画面で変更することができます。
						</dd>
						<input id="qa6" type="checkbox" />
						<label htmlFor="qa6">
							<dt className="qa-q">登録せずにチケットの購入はできますか？</dt>
						</label>
						<dd className="qa-a">
							ネット席の購入には本サイトでのアカウント登録が必要となります。<br/> 東京･春･音楽祭オンライン・チケットサービス（来場チケット購入用）のIDとパスワードとは別にご登録いただく必要がございますので、ご注意ください。
						</dd>
						<input id="qa7" type="checkbox" />
						<label htmlFor="qa7">
							<dt className="qa-q">ログインポップアップが表示されません。<br/>パスワードリセットのリンクをクリックしてもリセットできません。</dt>
						</label>
						<dd className="qa-a">
							当サイトは広告ブロック機能が有効となっているブラウザでアクセスすると正しく動作しない場合があります。<br/>
							お使いの環境やブラウザによって広告ブロック機能（OSやブラウザによって名称も異なる場合があります）の解除方法が異なります。<br/>
							<a href="#faq__adblock" className="qa-links__item">こちら</a>をお読みいただき、使用されている環境の広告ブロック機能を無効化してください。　
						</dd>

						<h2 className="common-title" id="q2">視聴環境／仕様について</h2>

						<input id="qa8" type="checkbox" />
						<label htmlFor="qa8">
							<dt className="qa-q">ライブ・ストリーミング配信を視聴できる環境を教えてください。</dt>
						</label>
						{rendaringSystemRequirementsJP(systemRequirements)}
						
						<input id="qa9" type="checkbox" />
						<label htmlFor="qa9">
							<dt className="qa-q">ライブ・ストリーミング配信はいつまでに購入すれば視聴できますか？</dt>
						</label>
						<dd className="qa-a">公演毎に購入期限があります。各公演詳細ページでご確認ください。</dd>
						<input id="qa10" type="checkbox" />
						<label htmlFor="qa10">
							<dt className="qa-q">スマートフォンまたはタブレット機器で視聴できますか？</dt>
						</label>
						{rendaringSystemRequirementsSpJP(systemRequirements)}

						<input id="qa11" type="checkbox" />
						<label htmlFor="qa11">
							<dt className="qa-q">持っている機器で視聴が可能か確認できる方法はありますか？</dt>
						</label>
						<dd className="qa-a">ご利用の機器で再生可能かどうかを以下のページでチェックすることができます。 <br/>
							動画の再生ができれば、ライブ・ストリーミングの視聴は可能です。 ※チェックの際にかかる通信料はお客様のご負担となります。<br/>
							<a className="qa-links__item" href="/test">
								動画再生確認ページ
							</a></dd>
						<input id="qa12" type="checkbox" />
						<label htmlFor="qa12">
							<dt className="qa-q">海外からも購入・視聴できますか？</dt>
						</label>
						<dd className="qa-a">国内限定配信の公演を除き、海外からもご購入・ご視聴いただけます。国内限定配信の公演は購入を済ませていても、公演開催時に海外に滞在している場合は視聴ができません。</dd>
						<input id="qa13" type="checkbox" />
						<label htmlFor="qa13">
							<dt className="qa-q">見逃し配信／アーカイブ配信はありますか？</dt>
						</label>
						<dd className="qa-a">見逃し配信、アーカイブ配信はございません。</dd>
						<input id="qa14" type="checkbox" />
						<label htmlFor="qa14">
							<dt className="qa-q">チケットを購入した機器と別の機器でライブ・ストリーミング配信を見ることができますか？</dt>
						</label>
						<dd className="qa-a">購入した機器とは異なる機器でもライブ・ストリーミング配信を見ることができます。 視聴する機器で本サイトにアクセスし、購入時に利用したアカウントでログインしてご利用ください。</dd>

						<h2 className="common-title" id="q3">購入について</h2>

						<input id="qa15" type="checkbox" />
						<label htmlFor="qa15">
							<dt className="qa-q">購入方法がわかりません。</dt>
						</label>
						<dd className="qa-a">
							<a className="qa-links__item" href="/guide">
								ご利用ガイド
							</a>
							にてご確認ください。
						</dd>
						<input id="qa16" type="checkbox" />
						<label htmlFor="qa16">
							<dt className="qa-q">手数料はいくらかかりますか？</dt>
						</label>
						<dd className="qa-a">視聴チケット購入時の手数料はかかりません。ただし購入及び視聴の際は別途通信料がかかり、お客様のご負担となります。</dd>
						<input id="qa17" type="checkbox" />
						<label htmlFor="qa17">
							<dt className="qa-q">支払方法は何が使えますか？</dt>
						</label>
						<dd className="qa-a">
							各種クレジットカード及びApple Payがご利用いただけます。 Apple Payの詳細については
							<a className="qa-links__item" href="https://www.apple.com/jp/apple-pay/" target="_blank" rel="noreferrer">
								こちら
							</a>
							をご確認ください。 ※Apple Pay は、米国および他の国々で登録されたApple Inc. の商標です。<br/>
{/*
							アプリ内のお支払い方法については、以下のページでGoogle 又は Apple のサービスをご確認ください。<br/>
							<br/>
							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a><br/>

							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">iPhone / iPad</a>
*/}
							</dd>
						<input id="qa18" type="checkbox" />
						<label htmlFor="qa18">
							<dt className="qa-q">支払いに利用できるカードはなにがありますか？</dt>
						</label>
						<dd className="qa-a">Visa、MasterCard、American Express、JCB、Discover、Diners Clubのクレジットカードがご利用いただけます。</dd>
						<input id="qa19" type="checkbox" />
						<label htmlFor="qa19">
							<dt className="qa-q">決済ができません。</dt>
						</label>
						<dd className="qa-a">決済が失敗した場合、クレジットカード会社の自動化システムでの支払いの可否において、承認がされなかった可能性がございます。 詳しくはクレジットカード会社にご確認ください。{/*<br/>アプリ内での決済に失敗した場合は、Google または Apple にお問合せください。*/}</dd>
						<input id="qa20" type="checkbox" />
						<label htmlFor="qa20">
							<dt className="qa-q">Apple Payで決済ができません。</dt>
						</label>
						<dd className="qa-a">Apple Payに対応したクレジットカードを登録してください。お使いのカードがApple Payに対応しているかは、クレジットカード会社にご確認ください。</dd>
						<input id="qa21" type="checkbox" />
						<label htmlFor="qa21">
							<dt className="qa-q">MacまたはiPhoneのSafariでApple Payを選択できません。</dt>
						</label>
						<dd className="qa-a">MacまたはiPhoneのSafariでApple Payによるお支払いの設定をするには以下のサイトをご確認ください。<br/><br/>
						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204506" target="_blank" rel="noreferrer">Apple Pay を設定する</a><br/><br/>
						<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/safari/ibrw8e207504/mac" target="_blank" rel="noreferrer">MacのSafariでApple Payを使って支払いをする</a></dd>
						<input id="qa22" type="checkbox" />
						<label htmlFor="qa22">
							<dt className="qa-q">購入完了メールが届きません。</dt>
						</label>
						<dd className="qa-a">登録されているメールアドレスが正しいかをご確認ください。また、迷惑メールと判定され、迷惑メールフォルダに入っていることもございますのでご確認ください。</dd>
						<input id="qa23" type="checkbox" />
						<label htmlFor="qa23">
							<dt className="qa-q">ネット席は発券されますか？</dt>
						</label>
						<dd className="qa-a">ネット席は発券されません。</dd>
						<input id="qa24" type="checkbox" />
						<label htmlFor="qa24">
							<dt className="qa-q">購入した公演を確認したい。</dt>
						</label>
						<dd className="qa-a">ログイン後、トップページの「購入済み」タブからご確認いただけます。また、マイページの決済履歴でも確認が可能です。</dd>
						<input id="qa25" type="checkbox" />
						<label htmlFor="qa25">
							<dt className="qa-q">キャンセル（払い戻し）できますか？</dt>
						</label>
						<dd className="qa-a">お客様のご都合によるキャンセル（払戻し）は、承りません。主催者側の都合により公演が中止・延期になった場合に限り、購入いただいた方へ後日払い戻しさせていただきます。</dd>
						<input id="qa26" type="checkbox" />
						<label htmlFor="qa26">
							<dt className="qa-q">間違って違う公演を購入したのですが、変更することはできますか？</dt>
						</label>
						<dd className="qa-a">決済完了後におけるお客様のご都合による変更・キャンセルは承りません。</dd>
						<input id="qa27" type="checkbox" />
						<label htmlFor="qa27">
							<dt className="qa-q">ウェブサイトでチケットを購入したのか{/*、アプリで購入したのか*/}わかりません。</dt>
						</label>
						<dd className="qa-a">
							<a className="qa-links__item" href="/mypage">マイページ</a>
							の「ご契約内容」をクリックしていただき、そこに購入した公演名が表示されていることを確認してください。<br/>
							その上で、「決済履歴」をクリックして、購入した公演のタイトル及び金額が表示されている場合は、配信サイトで購入されています。<br/>
							{/*「決済履歴」に購入した公演のタイトル及び金額が表示されていない場合は、アプリで購入されています。*/}
						</dd>
						<input id="qa28" type="checkbox" />
						<label htmlFor="qa28">
							<dt className="qa-q">キャンセルされた公演は払い戻しされますか？</dt>
						</label>
						<dd className="qa-a">公演中止が決定いたしました公演のチケットにつきましては、払戻しを承ります。<br/><br/>
							<h4>【Webサイトで購入した場合】</h4>
							自動的に払戻しが行われますので、お客様による手続きは必要ありません。<br/>
							ご返金までには、約２週間お時間をいただきます。<br/><br/>
{/*							
							<h4>【アプリで購入した場合（iPhone／iPad）】</h4>
							<strong>必ず、購入から90日以内にお客様からの申請が必要です。</strong><br/>
							お手続き方法は以下をご確認ください。<br/><br/>
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">Apple から購入した App やコンテンツの返金手続きをする</a><br/><br/>
							申請がない場合はご返金手続きを進めることができませんのでご注意ください。<br/>
							申請いただいた後、購入時に使ったのと同じお支払い方法で返金が行われます。<br/>
							ご返金までの所要時間は、お支払い方法によって異なります。詳細は<a href="https://support.apple.com/ja-jp/HT210904" target="_blank" rel="noreferrer">こちら</a>をご確認ください。<br/><br/>
							<h4>【アプリで購入した場合（Android）】</h4>
							自動的に払戻しが行われますので、お客様によるお手続きは必要ありません。<br/>
							ご返金までの所要時間は、お支払い方法によって異なります。詳細は<a href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">こちら</a>の後半部にある「払い戻しのスケジュール」をご確認ください。
*/}
						</dd>
						<input id="qa29" type="checkbox" />
						<label htmlFor="qa29">
							<dt className="qa-q">払い戻しされたかどうか確認する方法はありますか？</dt>
						</label>
						<dd className="qa-a">
							<a className="qa-links__item" href="/mypage">マイページ</a>
							の「ご契約内容」をクリックしていただき、そこに購入した公演名が表示されていることを確認してください。<br/>
							その上で、「決済履歴」をクリックして、購入した公演のタイトルの横に「返金済」と表示されていることをご確認ください。<br/>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/faq/faq-r-1.png" alt="返金" />
								<img className="sp-only" src="/assets/img/faq/faq-sr-1.png" alt="返金" />
							</div>
							「返金済」と表示される場合は返金処理が行われています。<br/>
							お客様の口座にいつ振り込まれるかはご利用中のカード会社にお問い合わせください。<br/><br/>
							万が一公演中止の発表から２週間経っても「返金済」とならない場合は、お手数ですがマイページのヘルプよりご連絡ください。
						</dd>

						<h2 className="common-title" id="q4">視聴について</h2>

						<input id="qa30" type="checkbox" />
						<label htmlFor="qa30">
							<dt className="qa-q">視聴方法がわかりません。</dt>
						</label>
						<dd className="qa-a">
							視聴方法は
							<a className="qa-links__item" href="/guide">
								ご利用ガイド
							</a>
							にてご確認ください。
						</dd>
						<input id="qa31" type="checkbox" />
						<label htmlFor="qa31">
							<dt className="qa-q">ライブ・ストリーミング配信視聴に通信料はかかりますか？</dt>
						</label>
						<dd className="qa-a">ライブ・ストリーミング配信視聴の際にかかる通信料は、お客様のご負担となります。</dd>
						<input id="qa32" type="checkbox" />
						<label htmlFor="qa32">
							<dt className="qa-q">複数機器で同時に視聴はできますか？</dt>
						</label>
						<dd className="qa-a">複数機器での同時視聴はできません。</dd>
						<input id="qa33" type="checkbox" />
						<label htmlFor="qa33">
							<dt className="qa-q">ネット席購入後、スマートフォンの機種変更をしても大丈夫ですか？</dt>
						</label>
						<dd className="qa-a">購入時とは異なるスマートフォンやパソコンでも、購入時に利用したアカウントでログインすることで、視聴することができます。<br/>
						ただし、機種変更後は、再度、動画再生確認を行うことをお勧めいたします。</dd>
						<input id="qa34" type="checkbox" />
						<label htmlFor="qa34">
							<dt className="qa-q">テレビ画面でライブ・ストリーミング配信を見ることができますか？</dt>
						</label>
						<dd className="qa-a">テレビ画面に接続しての視聴は対応しておりません。</dd>
						<input id="qa35" type="checkbox" />
						<label htmlFor="qa35">
							<dt className="qa-q">投げ銭はできますか？</dt>
						</label>
						<dd className="qa-a">投げ銭はできません。<br/>
						東京･春･音楽祭へのご寄付については、<a className="qa-links__item" href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer">公式サイト</a>にて受け付けております。</dd>
						<input id="qa36" type="checkbox" />
						<label htmlFor="qa36">
							<dt className="qa-q">ライブ・ストリーミング配信のページにアクセスしましたが、動画を見ることができません。</dt>
						</label>
						<dd className="qa-a"><a className="qa-links__item" href="/test">
								動画再生確認ページ
							</a>にて、お使いの機器が視聴できる環境か、また動画が止まることなく再生されるかをご確認ください。</dd>
						<input id="qa37" type="checkbox" />
						<label htmlFor="qa37">
							<dt className="qa-q">音が聞こえません。</dt>
						</label>
						<dd className="qa-a">
							【ご利用の端末の音量をご確認ください】
							<br />
							・ミュート(消音)、もしくは音量が小さい場合は、端末の音量をあげてください。
							<br />
							・スマートフォン・タブレットをご利用の場合は、マナーモード・サイレントモードになっていないことをご確認ください。
							<br />
							・イヤホンに繋がっていないかご確認ください。(イヤホンをご利用の場合は接続ができているかご確認ください)
							<br />
							<br />
							【 プレイヤーの音量をご確認ください】
							<br />
							・プレイヤー下部にあるスピーカーアイコンを操作して音量を上げてください。
							<br />
							・タブレットをご利用の場合は、初期設定ではミュート(消音)となっている可能性があります。
							<br />
							<br />
							【以上を確認しても解決しない場合】
							<br />
							・ご利用の端末を再起動することで解決する場合があります。
							<br />
							・配信側に問題が発生している可能性があります。マイページのヘルプからお問い合わせください。
						</dd>
						<input id="qa38" type="checkbox" />
						<label htmlFor="qa38">
							<dt className="qa-q">映像を見ることができません。</dt>
						</label>
						<dd className="qa-a">
							以下の操作をお試しください。
							<br />
							<br />
							■PCの場合
							<br />
							・<a className="qa-links__item" href="/test">動画再生確認ページ</a>にてお使いの機器が視聴できる環境か、また、動画が止まることなく再生されるかをご確認ください。
							<br />
							<br />
							・ブラウザを最新版に更新してください。
							<br />
							各ブラウザのアップデート方法は以下のリンクをご確認ください。
							<br />
							<a className="qa-links__item" href="https://support.google.com/chrome/answer/95414" target="_blank" rel="noreferrer">
								Google Chrome
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/update-firefox-latest-release" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://www.microsoft.com/ja-jp/edge" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204416" target="_blank" rel="noreferrer">
								Safari
							</a>
							<br />
							<br />
							・ブラウザのキャッシュを消去してください
							<br />
							各ブラウザのキャッシュ消去手順は以下のリンクをご確認ください
							<br />
							<a className="qa-links__item" href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noreferrer">
								Google Chrome
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/how-clear-firefox-cache" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%AE%E9%96%B2%E8%A6%A7%E5%B1%A5%E6%AD%B4%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%BE%E3%81%9F%E3%81%AF%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/mac-help/mchl3b6c3720/mac" target="_blank" rel="noreferrer">
								Safari
							</a>
							<br />
							<br />
							・Wi-Fiを使用している場合は、有線接続に切り替えてください
							<br />
							<br />
							■iPhone/iPadの場合
							<br />
							・<a className="qa-links__item" href="/test">動画再生確認ページ</a>にてお使いの機器が視聴できる環境か、また、動画が止まることなく再生されるかをご確認ください。
							<br />
							<br />
							・端末の再起動
							<br />
							再起動方法はこちらをご確認ください
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201559" target="_blank" rel="noreferrer">
								iPhone
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT210631" target="_blank" rel="noreferrer">
								iPad
							</a>
							<br />
							<br />
							・モバイルデータ通信を利用している場合は、Wi-Fi回線に切り替えてください。
							<br />
							<br />
							・Wi-Fi回線をご利用している場合は、ネットワーク設定をリセットしてください。
							<br />
							設定方法は以下のページの「2.オプションを選択する：」の「ネットワーク設定をリセット」をご確認ください。
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios" target="_blank" rel="noreferrer">
								iPhoneユーザガイド iPhoneの設定をデフォルトに戻す
							</a>
							<br />
							<br />
							■Androidスマートフォン/タブレットの場合
							<br />
							・<a className="qa-links__item" href="/test">動画再生確認ページ</a>にてお使いの機器が視聴できる環境か、また、動画が止まることなく再生されるかをご確認ください。
							<br />
							<br />
							・端末の再起動
							<br />
							再起動方法は各端末メーカーによって違いますので、ご利用の端末のメーカーまでお問い合わせください。
							<br />
							<br />
							・モバイルデータ通信を利用している場合は、Wi-Fi回線に切り替えてください。
							<br />
							<br />
							・Wi-Fi回線をご利用している場合は、ネットワーク設定をリセットしてください。
							<br />
							再起動方法は各端末メーカーによって違いますので、ご利用の端末のメーカーまでお問い合わせください。
						</dd>
						<input id="qa39" type="checkbox" />
						<label htmlFor="qa39">
							<dt className="qa-q">字幕が表示されません。</dt>
						</label>
						<dd className="qa-a">
							1.各公演概要に記載されている公演タイトルに「字幕付」と書かれているかをご確認ください。書かれている場合は字幕表示がある公演となります。
							<br />
							2.「字幕付」の公演で視聴しているライブ・ストリーミング配信に字幕が表示されていない場合、視聴しているプレイヤー右下にある字幕表示ボタンをクリックすると、字幕のオン／オフを切り替えることができます。
						</dd>
						<input id="qa40" type="checkbox" />
						<label htmlFor="qa40">
							<dt className="qa-q">プレイヤーの拡大ボタンが表示されません。</dt>
						</label>
						<dd className="qa-a">
							休憩時間中は拡大機能は使えなくなります。<br/>
							休憩後使えるようになります。
						</dd>
						<input id="qa41" type="checkbox" />
						<label htmlFor="qa41">
							<dt className="qa-q">プレイヤーの停止ボタンが表示されません。</dt>
						</label>
						<dd className="qa-a">
							休憩時間中は映像の停止ボタンは使えなくなります。<br/>
							休憩後使えるようになります。
						</dd>
						<input id="qa42" type="checkbox" />
						<label htmlFor="qa42">
							<dt className="qa-q">ライブ・ストリーミング配信を見逃しました、返金はできますか？</dt>
						</label>
						<dd className="qa-a">主催者の都合による公演中止の場合、払い戻しに対応いたします。それ以外の理由での払い戻しは対応いたしません。</dd>
						<input id="qa43" type="checkbox" />
						<label htmlFor="qa43">
							<dt className="qa-q">配信開始時間を過ぎてしまいましたが、冒頭から全編視聴することはできますか？</dt>
						</label>
						<dd className="qa-a">ライブ配信のため、巻き戻しての視聴はできません。</dd>
						<input id="qa44" type="checkbox" />
						<label htmlFor="qa44">
							<dt className="qa-q">悪天候による停電で視聴できませんでした。</dt>
						</label>
						<dd className="qa-a">悪天候による停電で視聴できなかった場合、大変申し訳ございませんが払い戻しなどの対応はできません。主催者の都合による公演中止の場合、払い戻しに対応いたします。</dd>
						<input id="qa45" type="checkbox" />
						<label htmlFor="qa45">
							<dt className="qa-q">友人・家族などにネット席をプレゼントしたい。</dt>
						</label>
						<dd className="qa-a">購入したチケットの贈与はできません。アカウントは重要な個人情報を含むため、他者との共有はお控えください。（アカウント情報を第三者へ開示したことによるいかなる損害も東京･春･音楽祭実行委員会およびIIJは一切の責任を負いません。）</dd>
						<input id="qa46" type="checkbox" />
						<label htmlFor="qa46">
							<dt className="qa-q">JavaScriptについて</dt>
						</label>
						<dd className="qa-a">
							当サイトでは、JavaScriptを使用しています。
							<br />
							お使いのブラウザでJavaScript機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。
							<br />
							ご覧になる際にはブラウザ設定でJavaScriptを有効にしてご覧ください。
						</dd>
						<input id="qa47" type="checkbox" />
						<label htmlFor="qa47">
							<dt className="qa-q" id="faq__adblock">広告ブロック機能について</dt>
						</label>
						<dd className="qa-a">
							当サイトは広告ブロック機能が有効なブラウザでアクセスすると正しく動作いない場合があります。<br/>
							お使いのブラウザで広告ブロック機能が有効になっている場合、広告ブロック機能を解除してご利用ください。（当サイトでは広告表示はありません。）
							<br />
							※ お使いのOSやバージョン、ブラウザの種類やバージョンによって無効にする方法が異なる場合があります。
							<br /><br />
							<dl>
								<dt><h3>Google Chrome</h3></dt>
								<dd><a href="https://support.google.com/chrome/answer/7632919?hl=ja&co=GENIE.Platform%3DDesktop" className="qa-links__item" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/7632919?hl=ja&co=GENIE.Platform%3DDesktop</a>
								</dd><br />
								<dt><h3>Mozilla Firefox</h3></dt>
								<dd><a href="https://support.mozilla.org/ja/kb/pop-blocker-settings-exceptions-troubleshooting" className="qa-links__item" target="_blank" rel="noreferrer">https://support.mozilla.org/ja/kb/pop-blocker-settings-exceptions-troubleshooting</a><br/>
								ポップアップブロックが有効になっている場合は無効にしてください。
								</dd><br />
								<dt><h3>Microsoft Edge</h3></dt>
								<dd>
									・Microsoft Edgeの画面右上のメニュー（・・・）を選択し、表示されたメニュー「設定」をクリック<br/>
									・「設定」画面の左にある「三」をクリック<br/>
									・表示されたメニューの中にある、「サイトのアクセス許可」をクリック<br/>
									・項目一覧の中にある「ポップアップとリダイレクト」をクリック<br/>
									・ブロック（推奨）の横にあるのスイッチをクリックして「オフ」に切り替える
								</dd><br />
								<dt><h3>Android Chrome</h3></dt>
								<dd>
									・Chromeを開く<br/>
									・右上の「その他」アイコン（縦に・が三つ）をタップ<br/>
									・「情報」をタップ<br/>
									・「サイト設定」をタップ<br/>
									・「広告」の横にある下向き矢印をタップ<br/>
									・「許可」をタップ<br/>
									・ページを再読み込み
								</dd><br />
								<dt><h3>Mac Safari</h3></dt>
								<dd>
									・画面トップにあるメニューバーの「Safari」から「環境設定」を選択<br/>
									・「Webサイト」を選択<br/>
									・左側の一覧から「コンテンツブロッカー」を選択<br/>
									・「https://www.harusailive.jp」が含まれている場合は選択肢「削除」をクリック<br/>
									・ページを再読み込み
								</dd><br />
								<dt><h3>iPhone / iPad</h3></dt>
								<dd>
									・ホーム画面から「設定」を開く<br/>
									・下にスクロールし「Safari」をタップ<br/>
									・下にスクロールし「Webサイトの設定」にある「コンテンツブロッカー」をタップ<br/>
									・「すべてのサイト」の右にあるスイッチをオフ
								</dd>
							</dl>
						</dd>
						<input id="qa48" type="checkbox" />
						<label htmlFor="qa48">
							<dt className="qa-q">Cookieについて</dt>
						</label>
						<dd className="qa-a">
							当サイトでは、Cookieを使用しています。
							<br />
							お使いのブラウザでCookie機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。
							<br />
							ご覧になる際にはブラウザ設定でCookieを有効にしてご覧ください。
							<br />
							<br />
							PC
							<br />
							<a className="qa-links__item" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=ja" target="_blank" rel="noreferrer">
								Google Chrome
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/cookies-information-websites-store-on-your-computer#w_cookie-she-ding" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E5%89%8A%E9%99%A4%E3%81%A8%E7%AE%A1%E7%90%86%E3%82%92%E8%A1%8C%E3%81%86-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/safari/sfri35610/14.0/mac/11.0" target="_blank" rel="noreferrer">
								Apple Safari
							</a>{' '}
							<br />
							Android
							<br />
							<a className="qa-links__item" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=ja" target="_blank" rel="noreferrer">
								Google Chrome
							</a>
							<br />
							iOS / iPadOS
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201265" target="_blank" rel="noreferrer">
								Safari
							</a>
						</dd>
						<input id="qa49" type="checkbox" />
						<label htmlFor="qa49">
							<dt className="qa-q">退会（アカウントの削除）について</dt>
						</label>
						<dd className="qa-a">退会（アカウントの削除）をご希望の場合は、マイページの右下にある「退会をご希望される方はこちら」から退会の申し込みが可能です。<br/>
							退会されると、登録されている個人情報及び過去の購入履歴、お問合せ履歴など全ての情報が削除されます。<br/>
							退会処理完了後のアカウントについてはお問い合わせいただいても回答することができませんのでご了承の上、お申し込みをお願い致します。
						</dd>
{/*
						<h2 className="common-title" id="q5">アプリについて</h2>
						<input id="qa50" type="checkbox" />
						<label htmlFor="qa50">
							<dt className="qa-q">アプリがインストールできません。</dt>
						</label>
						<dd className="qa-a">「東京春祭」アプリ（以下、本アプリ）は Android 9 以上もしくは iOS 15.0 / iPadOS 15.0以上のスマートフォンおよびタブレットにてご利用いただけます。
						以下のストアページにて「インストール」ボタンが表示されない場合、お使いのスマートフォンおよびタブレットでは本アプリをご利用いただけません。<br/><br/>

						<a className="qa-links__item" href="https://play.google.com/store?hl=ja&gl=US" target="_blank" rel="noreferrer">Google Play (Android)</a><br/>
						<a className="qa-links__item" href="https://www.apple.com/jp/app-store/" target="_blank" rel="noreferrer">App Store (iOS / iPadOS)</a><br/><br/>

						アプリのダウンロードにはそれぞれGoogle又はAppleのアカウントが必要になります。</dd>
						<input id="qa51" type="checkbox" />
						<label htmlFor="qa51">
							<dt className="qa-q">このアプリは無料で使えますか。</dt>
						</label>
						<dd className="qa-a">本アプリのダウンロードおよびインストールは無料です。<br/>
						公演情報やお知らせなどの情報も無料でご覧いただけます。<br/>
						アプリ内でのネット席の購入は有料となります。</dd>
						<input id="qa52" type="checkbox" />
						<label htmlFor="qa52">
							<dt className="qa-q">最初の画面に戻れません。</dt>
						</label>
						<dd className="qa-a">画面下部または画面右上の家の形をしたボタンをタップすることで戻ることができます。ボタンをタップしても変化がない場合は、一度アプリを終了しお使いの機器を再起動してから再度お試しください。</dd>
						<input id="qa53" type="checkbox" />
						<label htmlFor="qa53">
							<dt className="qa-q">会員登録ができません。</dt>
						</label>
						<dd className="qa-a">本アプリからは新規の会員登録はできません。
						お使いのパソコン又はスマートフォンのブラウザから 「東京･春･音楽祭 LIVE Streaming」のサイトにアクセスして会員登録をお願いします。</dd>
						<input id="qa55" type="checkbox" />
						<label htmlFor="qa55">
							<dt className="qa-q">パスワードを忘れました。</dt>
						</label>
						<dd className="qa-a">お使いのパソコン又はスマートフォンから「東京･春･音楽祭 LIVE Streaming」のサイトにアクセスして画面右上のログインボタンをクリックしてください。
						表示されるメールアドレスとパスワードを入力する画面上の「パスワードを忘れた方はこちら」をクリックしてパスワードの再設定を行ってください。</dd>
						<input id="qa56" type="checkbox" />
						<label htmlFor="qa56">
							<dt className="qa-q">Googleのアカウント又はAppleのアカウントがわかりません。</dt>
						</label>
						<dd className="qa-a">ご利用中のスマートフォンの管理をされている方にご確認ください。</dd>
						<input id="qa57" type="checkbox" />
						<label htmlFor="qa57">
							<dt className="qa-q">アプリで何ができますか。</dt>
						</label>
						<dd className="qa-a">本アプリでは、以下のことができます。
							<ul>
								<li>公式のお知らせ閲覧</li>
								<li>公演情報の閲覧</li>
								<li>ネット席の購入</li>
								<li>購入済みネット席の確認</li>
								<li>配信映像の視聴</li>
							</ul></dd>
						<input id="qa58" type="checkbox" />
						<label htmlFor="qa58">
							<dt className="qa-q">本アプリに関する問い合わせ先はありますか。</dt>
						</label>
						<dd className="qa-a">本ページ最下部の「お問い合わせ」より承ります。</dd>
						<input id="qa59" type="checkbox" />
						<label htmlFor="qa59">
							<dt className="qa-q">「東京春祭」アプリを誤って削除してしまいました。</dt>
						</label>
						<dd className="qa-a">再度本アプリをダウンロードしてログインしていただければ引き続きご利用いただけます。</dd>
						<input id="qa60" type="checkbox" />
						<label htmlFor="qa60">
							<dt className="qa-q">スマートフォンの機種変更をした後も利用できますか。</dt>
						</label>
						<dd className="qa-a">新しいスマートフォンで Google Play 又は App Store から本アプリをダウンロードしてログインしていただければそのままご利用いただけます。</dd>
						<input id="qa61" type="checkbox" />
						<label htmlFor="qa61">
							<dt className="qa-q">ネット席が購入できません。</dt>
						</label>
						<dd className="qa-a">本アプリではアプリ内決済でネット席を購入することができます。<br/>
						すでにアカウントをお持ちの方で、配信サイトにお支払い方法を登録されている方でもアプリ内決済の設定が別途必要になります。<br/>
						お使いのスマートフォンのアプリ内決済の設定方法については、以下のサイトからご確認ください。<br/>
						<a className="qa-links__item" href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a><br/>

						<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">iOS</a></dd>
						<input id="qa62" type="checkbox" />
						<label htmlFor="qa62">
							<dt className="qa-q">予約コードは利用できますか。</dt>
						</label>
						<dd className="qa-a">本アプリ内でネット席を購入される場合、予約コードは利用できません。<br/>
						予約コードを利用してのご購入は、パソコン又はスマートフォンのブラウザからのみとなります。</dd>
						<input id="qa63" type="checkbox" />
						<label htmlFor="qa63">
							<dt className="qa-q">スマートフォンの他にタブレットにもインストールできますか。</dt>
						</label>
						<dd className="qa-a">サポートしている環境であれば、AndroidタブレットやiPadにもインストールできます。</dd>
						<input id="qa64" type="checkbox" />
						<label htmlFor="qa64">
							<dt className="qa-q">ネット席が購入できたかどうかわかりません。</dt>
						</label>
						<dd className="qa-a">画面下部にある「購入済」ボタンをタップすると本アプリで購入された公演の一覧をご確認いただけます。<br/>
						※確認にはログインが必要です。</dd>
						<input id="qa65" type="checkbox" />
						<label htmlFor="qa65">
							<dt className="qa-q">公演中止の場合払い戻しはありますか。</dt>
						</label>
						<dd className="qa-a">主催者都合で公演が中止になった場合のみ払い戻しが可能です。<br/>
						払い戻しには申請が必要となりますので、お使いのスマートフォンに応じて以下のサイトからご確認ください。<br/>

						<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">Android</a><br/>

						<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iOS</a></dd>
*/}
						<h2 className="common-title" id="q6">お問い合わせ</h2>

						<input id="qa66" type="checkbox" />
						<label htmlFor="qa66">
							<dt className="qa-q">その他のお問い合わせ</dt>
						</label>
						<dd className="qa-a">
							「よくあるご質問」に無いお問い合わせの場合は、下記「東京･春･音楽祭 Live Streaming ヘルプセンター」窓口までお問い合わせください。
							<br />
							<br />
							<h2>「東京･春･音楽祭 Live Streaming ヘルプセンター」</h2>
							<br />
							　TEL: 03-5205-4462　営業時間　月・水・金　12:00～19:00<br />
							　※お問合せ受付期間2月16日(金)～4月21日(日)<br />
							　※アカウントをお持ちの方はマイページのヘルプタブにある「問い合わせ」ボタンからもお問い合わせいただけます。（回答は営業時間内のみ）<br />
							　※音楽祭期間中は、土・日・祝含め全日営業（12:00～21:00）<br /><br />
							　4月22日(月)以降は電話によるお問い合わせは受け付けておりません。<br />　お問い合わせはマイページのヘルプをご利用ください。
						</dd>
					</dl>
				</section>
			) : (
				<section className="qa">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						a, a:visited {color: white;}
						a.login-button, a.logout-button {color:#242728;}
						`}
					</style>
					<h1 className="common-title">Frequently Asked Questions</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#q1">About Accounts</a></li>
      					<li className="qa-links__item"><a href="#q2">Viewing environment/specifications</a></li>
      					<li className="qa-links__item"><a href="#q3">About Purchasing</a></li>
      					<li className="qa-links__item"><a href="#q4">About viewing</a></li>
      					<li className="qa-links__item"><a href="#q5">About the Spring Festival in Tokyo App</a></li>
						<li className="qa-links__item"><a href="#q6">Inquiries</a></li>
    				</ul>

					<h2 className="common-title" id="q1">About Accounts</h2>
					<dl className="qa-list">
						<input id="qae1" type="checkbox" /><label htmlFor="qae1">
						<dt className="qa-q">How do I register for an account?</dt></label>
						<dd className="qa-a">
							Please refer to the
							<a className="qa-links__item" href="/guide">
								User's Guide
							</a>
							 for instructions on how to register an account.
						</dd>
						<input id="qae2" type="checkbox" /><label htmlFor="qae2">
						<dt className="qa-q">Can I use the account I created before?</dt></label>
						<dd className="qa-a">
							Any accounts registered for Spring Festival in Tokyo LIVE Streaming will continue to be available.
						</dd>
{/*
						<input id="qae3" type="checkbox" /><label htmlFor="qae3">
						<dt className="qa-q">Can I register an account with the app?</dt></label>
						<dd className="qa-a">
							New account registration is not available in the application.<br/>
							Please access this site with your computer or smartphone browser and register for a new account.<br/>
							Please refer to the <a className="qa-links__item" href="/guide">
								User's Guide
							</a> for the registration procedure.
						</dd>
*/}
						<input id="qae4" type="checkbox" /><label htmlFor="qae4">
						<dt className="qa-q">I don't know my password to log in anymore, what should I do?</dt></label>
						<dd className="qa-a">
							On the screen where you enter your email address and password, click on "Forgot your password?" above to reset your password.
						</dd>
						<input id="qae5" type="checkbox" /><label htmlFor="qae5">
						<dt className="qa-q">Can I change my registered email address or other information?</dt></label>
						<dd className="qa-a">
							Yes. You can change it on your profile page in "My Page" after logging in.
						</dd>
						<input id="qae6" type="checkbox" /><label htmlFor="qae6">
						<dt className="qa-q">Can I purchase tickets without registering?</dt></label>
						<dd className="qa-a">
							To purchase tickets for live streaming, you will need to register for an account on this site. <br/>
							Please note that you will need to register an account separately from your ID and password for Spring Festival in Tokyo online ticket service (for purchasing tickets to the venue).
						</dd>
						<input id="qae7" type="checkbox" /><label htmlFor="qae7">
						<dt className="qa-q">Login popup does not appear.<br/>I cannot reset my password by clicking on the password reset link in the email.</dt></label>
						<dd className="qa-a">
							This site may not function properly if accessed with a browser that has ad blocking enabled.<br/>
							Depending on your environment and browser, the method of disabling the ad-blocking function (the name may also vary depending on your OS and browser) may vary.<br/>
						</dd>

						<h2 className="common-title" id="q2">Viewing environment/specifications</h2>

							<input id="qae8" type="checkbox" /><label htmlFor="qae8">
						<dt className="qa-q">What environment can I use to view the live streaming feed?</dt></label>
						{rendaringSystemRequirementsEn(systemRequirements)}

						<input id="qae9" type="checkbox" /><label htmlFor="qae9">
						<dt className="qa-q">Are there any restrictions on the purchase of live streaming tickets?</dt></label>
						<dd className="qa-a">There is a deadline for purchase for each performance. Please check the details page of each performance.</dd>
						<input id="qae10" type="checkbox" /><label htmlFor="qae10">
						<dt className="qa-q">Can I watch it on my smartphone or tablet device?</dt></label>
						{rendaringSystemRequirementsSpEn(systemRequirements)}

						<input id="qae11" type="checkbox" /><label htmlFor="qae11">
						<dt className="qa-q">Is there any way I can check if I can watch it on my device?</dt></label>
						<dd className="qa-a">You can check if it can be played on your device at the following page. <br/>
							If you can play the video, you can watch the live streaming. You will be responsible for any communication charges incurred during the check.<br/>
							<a className="qa-links__item" href="/test">
								Video Playback Check Page
							</a></dd>
						<input id="qae12" type="checkbox" /><label htmlFor="qae12">
						<dt className="qa-q">Can I purchase and watch the videos from overseas?</dt></label>
						<dd className="qa-a">With the exception of performances distributed only in Japan, you can purchase and view them from overseas. If you are outside Japan at the time of the performance, you will not be able to view the performance, even if you have already purchased it.</dd>
						<input id="qae13" type="checkbox" /><label htmlFor="qae13">
						<dt className="qa-q">Are there any missed broadcasts/archived broadcasts?</dt></label>
						<dd className="qa-a">There will be no missed broadcasts or archived broadcasts.</dd>
						<input id="qae14" type="checkbox" /><label htmlFor="qae14">
						<dt className="qa-q">Can I view the live streaming feed on a different device than the one I purchased the ticket on?</dt></label>
						<dd className="qa-a">You can view the live streaming feed on a different device than the one you purchased. Please access this site with the device you wish to view, and log in with the account you used at the time of purchase.</dd>

						<h2 className="common-title" id="q3">About Purchasing</h2>

						<input id="qae15" type="checkbox" /><label htmlFor="qae15">
						<dt className="qa-q">I don't know how to purchase.</dt></label>
						<dd className="qa-a">
							Please refer to
							<a className="qa-links__item" href="/guide">
								User's Guide
							</a>.
						</dd>
						<input id="qae16" type="checkbox" /><label htmlFor="qae16">
						<dt className="qa-q">How much is the fee?</dt></label>
						<dd className="qa-a">There is no handling fee for purchasing viewing tickets. However, you will be charged a separate communication fee for the purchase and viewing of tickets, which will be borne by you.</dd>
						<input id="qae17" type="checkbox" /><label htmlFor="qae17">
						<dt className="qa-q">What payment methods do you accept?</dt></label>
						<dd className="qa-a">
							We accept various credit cards and Apple Pay. For more information about Apple Pay, please click 							<a className="qa-links__item" href="https://www.apple.com/jp/apple-pay/" target="_blank" rel="noreferrer">
								here
							</a>. Apple Pay is a trademark of Apple Inc. registered in the United States and other countries.<br/>
{/*
							For in-app payment methods, please check the following page for Google or Apple services.<br/>
							<br/>
							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a><br/>

							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">iPhone / iPad</a>
*/}
							</dd>
						<input id="qae18" type="checkbox" /><label htmlFor="qae18">
						<dt className="qa-q">What kind of cards can I use for payment?</dt></label>
						<dd className="qa-a">Visa, MasterCard, American Express, JCB, Discover, and Diners Club credit cards are accepted.</dd>
						<input id="qae19" type="checkbox" /><label htmlFor="qae19">
						<dt className="qa-q">Payment cannot be made.</dt></label>
						<dd className="qa-a">If the payment fails, it is possible that the credit card company did not approve the payment in their automated system. Please check with your credit card company for details.<br/> {/* If the in-app payment fails, please contact Google or Apple for assistance. */} </dd>
						<input id="qae20" type="checkbox" /><label htmlFor="qae20">
						<dt className="qa-q">I can't make a payment using Apple Pay.</dt></label>
						<dd className="qa-a">Register a credit card that is compatible with Apple Pay. Please check with your credit card company to see if your card is compatible with Apple Pay.</dd>
						<input id="qae21" type="checkbox" /><label htmlFor="qae21">
						<dt className="qa-q">I can't select Apple Pay in Safari on my Mac or iPhone.</dt></label>
						<dd className="qa-a">To set up Apple Pay payments in Safari on your Mac or iPhone, please visit the following website.<br/><br/>
						<a className="qa-links__item" href="https://support.apple.com/en-us/HT204506" target="_blank" rel="noreferrer">Set up Apple Pay</a><br/><br/>
						<a className="qa-links__item" href="https://support.apple.com/guide/safari/pay-with-apple-pay-ibrw8e207504/mac" target="_blank" rel="noreferrer">Pay with Apple Pay in Safari on Mac</a></dd>
						<input id="qae22" type="checkbox" /><label htmlFor="qae22">
						<dt className="qa-q">I did not receive an email to complete my purchase.</dt></label>
						<dd className="qa-a">Please make sure that the e-mail address you have registered is correct. Also, please check if the email is in your junk mail folder.</dd>
						<input id="qae23" type="checkbox" /><label htmlFor="qae23">
						<dt className="qa-q">Will delivery tickets be issued?</dt></label>
						<dd className="qa-a">Delivery tickets will not be issued.</dd>
						<input id="qae24" type="checkbox" /><label htmlFor="qae24">
						<dt className="qa-q">I want to confirm the performance I purchased.</dt></label>
						<dd className="qa-a">After logging in, you can check from the "Purchased" tab on the top page. You can also check your payment history on My Page.</dd>
						<input id="qae25" type="checkbox" /><label htmlFor="qae25">
						<dt className="qa-q">Can I cancel (get a refund) for my ticket?</dt></label>
						<dd className="qa-a">Cancellations (refunds) due to customer's convenience will not be accepted. Refunds will only be made in the event that the performance is cancelled or postponed due to circumstances beyond the control of the organizer.</dd>
						<input id="qae26" type="checkbox" /><label htmlFor="qae26">
						<dt className="qa-q">I purchased a different show by mistake, can I change it?</dt></label>
						<dd className="qa-a">No changes or cancellations will be accepted by the customer after payment is completed.</dd>
						<input id="qae27" type="checkbox" /><label htmlFor="qae27">
						<dt className="qa-q">Will cancelled performances be refunded?</dt></label>
						<dd className="qa-a">Tickets for performances that have been cancelled will be refunded.<br/><br/>
							Refunds will be made automatically, so there is no need for customers to follow the procedure.<br/>
							Please allow approximately 2 weeks for your refund.<br/><br/>
						</dd>
						<input id="qae28" type="checkbox" /><label htmlFor="qae28">
						<dt className="qa-q">Is there any way to check if I have been refunded?</dt></label>
						<dd className="qa-a">
							Please click on "Library" on <a className="qa-links__item" href="/mypage">My Page</a> and confirm that the name of the performance you purchased is displayed there.<br/>
							Then click on "Transactions" and confirm that "refunded" is displayed next to the title of the performance you purchased.<br/><br/>
							If "refunded" is displayed, the refund has been processed.<br/>
							Please contact your credit card company to find out when your account will be credited.<br/><br/>
							If the "refunded" status is not displayed after two weeks from the announcement of the cancellation, please contact us through the "Help" page on <a className="qa-links__item" href="/mypage">My Page</a>.
						</dd>

						<h2 className="common-title" id="q4">About viewing</h2>

						<input id="qae29" type="checkbox" /><label htmlFor="qae29">
						<dt className="qa-q">I don't know how to watch it.</dt></label>
						<dd className="qa-a">
							Please refer to
							<a className="qa-links__item" href="/guide">
								User's Guide
							</a>.
						</dd>
						<input id="qae30" type="checkbox" /><label htmlFor="qae30">
						<dt className="qa-q">Are there any communication charges for watching live streaming?</dt></label>
						<dd className="qa-a">You will be responsible for any communication charges incurred when viewing the live streaming feed.</dd>
						<input id="qae31" type="checkbox" /><label htmlFor="qae31">
						<dt className="qa-q">Can I watch on multiple devices at the same time?</dt></label>
						<dd className="qa-a">Simultaneous viewing on multiple devices is not possible.</dd>
						<input id="qae32" type="checkbox" /><label htmlFor="qae32">
						<dt className="qa-q">Can I change the model of my smartphone after purchasing a distribution ticket?</dt></label>
						<dd className="qa-a">You can watch the movie on a different smartphone or computer from the one you purchased it on by logging in with the account you used at the time of purchase.<br/>
						However, we recommend that you check the video playback again after changing the model.</dd>
						<input id="qae33" type="checkbox" /><label htmlFor="qae33">
						<dt className="qa-q">Can I watch the live streaming feed on my TV screen?</dt></label>
						<dd className="qa-a">Viewing by connecting to a TV screen is not supported.</dd>
						<input id="qae34" type="checkbox" /><label htmlFor="qae34">
						<dt className="qa-q">Can I make a donation?</dt></label>
						<dd className="qa-a">No donations will be accepted.<br/>
						Donations to the Spring Festival in Tokyo can be made at the<a className="qa-links__item" href="https://www.tokyo-harusai.com/support/" target="_blank" rel="noreferrer">official website</a>.</dd>
						<input id="qae35" type="checkbox" /><label htmlFor="qae35">
						<dt className="qa-q">I accessed the live streaming page, but I can't see the video.</dt></label>
						<dd className="qa-a">Make sure that you can watch video on <a className="qa-links__item" href="/test">
								Video Playback Page
							</a>.</dd>
						<input id="qae36" type="checkbox" /><label htmlFor="qae36">
						<dt className="qa-q">I can't hear a sound.</dt></label>
						<dd className="qa-a">
							Please check the volume on your device.
							<br />
							If the volume is too low or muted, turn up the volume on your device.
							<br />
							If you are using a smartphone or tablet, please make sure that it is not in silent mode.
							<br />
							Please check if earphones or headphones are not connected. (If you are using earphones, please make sure they are connected.)
							<br />
							<br />
							Please check the volume of your player.
							<br />
							Turn up the volume by using the speaker icon at the bottom of the player.
							<br />
							If you are using a tablet, the sound may be muted by default.
							<br />
							<br />
							If the problem is still not resolved after checking the above:
							<br />
							The problem may be solved by rebooting the device you are using.
							<br />
							There may be a problem with the delivery side. Please contact us from the Help menu on My Page.
						</dd>
						<input id="qa37" type="checkbox" /><label htmlFor="qae37">
						<dt className="qa-q">I can't see the video.</dt></label>
						<dd className="qa-a">
							If you are experiencing problems with video or audio and are having trouble viewing, please try the following：
							<br />
							<br />
							- PC
							<br />
							Please make sure that your computer meets the recommended environment for Spring Festival in Tokyo LIVE Streaming.
							<br />
							<a className="qa-links__item" href="/test">
								Video playback confirmation page
							</a>
							<br />
							<br />
							Update your browser to the latest version.
							<br />
							For instructions on how to update each browser, please check the following link.
							<br />
							<a className="qa-links__item" href="https://support.google.com/chrome/answer/95414" target="_blank" rel="noreferrer">
								Google Chrome
							</a>
							<br />
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/update-firefox-latest-release" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>
							<br />
							<a className="qa-links__item" href="https://www.microsoft.com/ja-jp/edge" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204416" target="_blank" rel="noreferrer">
								Safari
							</a>
							<br />
							<br />
							Clear your browser cache.
							<br />
							For instructions on how to clear your browser's cache, please refer to the following link:
							<br />
							<a className="qa-links__item" href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noreferrer">
								Google Chrome
							</a>
							<br />
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/how-clear-firefox-cache" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>
							<br />
							<a className="qa-links__item" href="https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%AE%E9%96%B2%E8%A6%A7%E5%B1%A5%E6%AD%B4%E3%82%92%E8%A1%A8%E7%A4%BA%E3%81%BE%E3%81%9F%E3%81%AF%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B-00cf7943-a9e1-975a-a33d-ac10ce454ca4" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/mac-help/mchl3b6c3720/mac" target="_blank" rel="noreferrer">
								Safari
							</a>
							<br />
							<br />
							If you are using Wi-Fi, please switch to a wired connection.
							<br />
							<br />
							- iPhone/iPad
							<br />
							Please make sure that your computer meets the recommended environment for Spring Festival in Tokyo LIVE Streaming.
							<br />
							<a className="qa-links__item" href="/test">
								Video playback confirmation page
							</a>
							<br />
							<br />
							- Reboot your device
							<br />
							Please refer to the following link:
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201559" target="_blank" rel="noreferrer">
								iPhone
							</a>
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT210631" target="_blank" rel="noreferrer">
								iPad
							</a>
							<br />
							<br />
							If you are using mobile data communication, please switch to Wi-Fi connection.
							<br />
							If you are using a Wi-Fi connection, please reset your network settings.
							<br />
							For instructions on how to set this up, please see 'Reset network settings' in '2. Select options:' on the following page.
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios" target="_blank" rel="noreferrer">
								https://support.apple.com/ja-jp/guide/iphone/iphea1c2fe48/ios
							</a>
							<br />
							<br />
							- For Android smartphones and tablets
							<br />
							Please make sure that your computer meets the recommended environment for Spring Festival in Tokyo LIVE Streaming.
							<br />
							<a className="qa-links__item" href="/test">
								Video playback confirmation page
							</a>
							<br />
							<br />
							Rebooting the device
							<br />
							The rebooting method varies with each device manufacturer, so please contact the manufacturer of the device you are using.
							<br />
							<br />
							If you are using a mobile data connection, please switch to a Wi-Fi connection.
							<br />
							If you are using a Wi-Fi connection, please reset your network settings.
						</dd>
						<input id="qae38" type="checkbox" /><label htmlFor="qae38">
						<dt className="qa-q">Subtitles are not displayed.</dt></label>
						<dd className="qa-a">
							1.Please check if the title of the performance listed in the performance summary says "with subtitles". If it is, the performance will be subtitled.
							<br />
							2.If you are watching a "subtitled" performance and the subtitles are not displayed on the live streaming feed, you can turn them on or off by clicking the [Show Subtitles] button at the bottom right of the player you are watching.
						</dd>
						<input id="qae39" type="checkbox" /><label htmlFor="qae39">
						<dt className="qa-q">Zoom button on video player does not appear.</dt></label>
						<dd className="qa-a">
							The magnification function will not be available during breaks.<br/>
							You will be able to use it after the break.
						</dd>
						<input id="qae40" type="checkbox" /><label htmlFor="qae40">
						<dt className="qa-q">Stop button on video player is not displayed.</dt></label>
						<dd className="qa-a">
							During breaks, the video stop button will not be available.<br/>
							You will be able to use it after the break.
						</dd>

						<input id="qae41" type="checkbox" /><label htmlFor="qae41">
						<dt className="qa-q">I missed the live streaming feed, can I get a refund?</dt></label>
						<dd className="qa-a">Refunds will be made in the event that the performance is cancelled due to reasons beyond the control of the organizer. No refunds will be made for any other reason.</dd>
						<input id="qae42" type="checkbox" /><label htmlFor="qae42">
						<dt className="qa-q">Can I still watch the whole movie from the beginning if I missed?</dt></label>
						<dd className="qa-a">As this is a live broadcast, you cannot rewind the video.</dd>
						<input id="qae43" type="checkbox" /><label htmlFor="qae43">
						<dt className="qa-q">We were unable to view the video due to a power outage caused by bad weather.</dt></label>
						<dd className="qa-a">If you are unable to view the show due to a power outage caused by bad weather, we are very sorry, but we cannot offer refunds. If the performance is cancelled due to reasons of the organizer, refunds will be provided.</dd>
						<input id="qae44" type="checkbox" /><label htmlFor="qae44">
						<dt className="qa-q">I want to give away distribution tickets to friends and family.</dt></label>
						<dd className="qa-a">Purchased tickets cannot be given as gifts. As your account contains important personal information, please do not share it with others. (The Spring Festival in Tokyo Executive Committee and IIJ will not be held responsible for any damages resulting from the disclosure of account information to third parties.)</dd>
						<input id="qae45" type="checkbox" /><label htmlFor="qae45">
						<dt className="qa-q">JavaScript</dt></label>
						<dd className="qa-a">
							JavaScript is used in this site.
							<br />
							If the JavaScript function is disabled in your browser, the site may not function or display properly.
							<br />
							Please enable JavaScript in your browser settings to view this site.
						</dd>
						<input id="qae46" type="checkbox" /><label htmlFor="qae46">
						<dt className="qa-q" id="faq__adblock">Ad Blocker</dt></label>
						<dd className="qa-a">
							This site may not function properly when accessed with a browser that has an ad blocker enabled.<br/>
							If your browser has ad-blocking enabled, please disable ad-blocking before using the site. (There are no ads displayed on this site.)
							<br />
						</dd>
						<input id="qae47" type="checkbox" /><label htmlFor="qae47">
						<dt className="qa-q">Cookie</dt></label>
						<dd className="qa-a">
							Cookies are used on this site.
							<br />
							If you have disabled the cookie function in your browser, the site may not function or display properly.
							<br />
							To view this site, please enable cookies in your browser settings.
							<br />
							<br />
							PC
							<br />
							<a className="qa-links__item" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=ja" target="_blank" rel="noreferrer">
								Google Chrome
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.mozilla.org/ja/kb/cookies-information-websites-store-on-your-computer#w_cookie-she-ding" target="_blank" rel="noreferrer">
								Mozilla Firefox
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.microsoft.com/ja-jp/topic/cookie-%E3%81%AE%E5%89%8A%E9%99%A4%E3%81%A8%E7%AE%A1%E7%90%86%E3%82%92%E8%A1%8C%E3%81%86-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">
								Microsoft Edge
							</a>{' '}
							/{' '}
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/guide/safari/sfri35610/14.0/mac/11.0" target="_blank" rel="noreferrer">
								Apple Safari
							</a>{' '}
							<br />
							Android
							<br />
							<a className="qa-links__item" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=ja" target="_blank" rel="noreferrer">
								Google Chrome
							</a>
							<br />
							iOS / iPadOS
							<br />
							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT201265" target="_blank" rel="noreferrer">
								Safari
							</a>
						</dd>
						<input id="qae48" type="checkbox" /><label htmlFor="qae48">
						<dt className="qa-q">Withdrawal (deletion of account)</dt></label>
						<dd className="qa-a">If you wish to cancel your membership (delete your account), you can request to cancel your membership by clicking "Click here if you wish to cancel your membership" in the lower right corner of My Page.<br/>
						When you cancel your membership, all of your registered personal information, past purchase history, inquiry history, and other information will be deleted.<br/>
						Please note that we will not be able to respond to any inquiries regarding your account after the withdrawal process is complete.
						</dd>

						<h2 className="common-title" id="q5">About the Spring Festival in Tokyo App</h2>

						<input id="qae49" type="checkbox" /><label htmlFor="qae49">
						<dt className="qa-q">I can't install the app.</dt></label>
						<dd className="qa-a">The "Tokyo Shunsai" application (hereinafter referred to as the "Application") can be used on smartphones and tablets running Android 9 or higher, or iOS 15.0 / iPad OS 15.0 or higher. If you do not see the "Install" button on the following store page, you cannot use this app on your smartphone or tablet.<br/><br/>
							<a className="qa-links__item" href="https://play.google.com/store?hl=ja&gl=US" target="_blank" rel="noreferrer">Google Play (Android)</a><br/>
							<a className="qa-links__item" href="https://www.apple.com/jp/app-store/" target="_blank" rel="noreferrer">App Store (iOS / iPadOS)</a><br/><br/>
						To download the app, you will need a Google or Apple account, respectively.</dd>
						<input id="qae50" type="checkbox" /><label htmlFor="qae50">
						<dt className="qa-q">Is this app free to use?</dt></label>
						<dd className="qa-a">This app is free to download and install.<br/>
						Information on performances and announcements are also available free of charge.<br/>
						There is a fee to purchase distribution tickets in the app.</dd>
						<input id="qae51" type="checkbox" /><label htmlFor="qae51">
						<dt className="qa-q">I can't go back to the first screen.</dt></label>
						<dd className="qa-a">You can go back by tapping the house-shaped button at the bottom of the screen or the upper right corner of the screen. If there is no change after tapping the button, please exit the app and restart your device, then try again.</dd>
						<input id="qae52" type="checkbox" /><label htmlFor="qae52">
						<dt className="qa-q">I cannot register as a member.</dt></label>
						<dd className="qa-a">You cannot register as a new member from this application.
						Please access the "Spring Festival in Tokyo LIVE Streaming" website from your computer or smartphone browser and register as a member.</dd>
						<input id="qae53" type="checkbox" /><label htmlFor="qae53">
						<dt className="qa-q">Can I use the account I created last year with this application?</dt></label>
						<dd className="qa-a">If you created an account for last year's "Spring Festival in Tokyo LIVE Streaming" you can use the same account for this application.</dd>
						<input id="qae54" type="checkbox" /><label htmlFor="qae54">
						<dt className="qa-q">I forgot my password.</dt></label>
						<dd className="qa-a">Access the "Spring Festival in Tokyo LIVE Streaming" website from your computer or smartphone, and click the login button in the upper right corner of the screen.
						Click "Forgot your password?" on the screen that appears to enter your email address and password to reset your password. </dd>
						<input id="qae55" type="checkbox" /><label htmlFor="qae55">
						<dt className="qa-q">I don't know my Google account or Apple account.</dt></label>
						<dd className="qa-a">Please check with the person who is managing your smartphone.</dd>
						<input id="qae56" type="checkbox" /><label htmlFor="qae56">
						<dt className="qa-q">What can I do with the app?</dt></label>
						<dd className="qa-a">This application allows you to do the following:
							<ul>
								<li>Browse official announcements</li>
								<li>Viewing performance information</li>
								<li>Purchase distribution tickets</li>
								<li>Confirmation of purchased delivery tickets</li>
								<li>Viewing the video distribution</li>
							</ul></dd>
						<input id="qae57" type="checkbox" /><label htmlFor="qae57">
						<dt className="qa-q">Is there anyone I can contact about this application?</dt></label>
						<dd className="qa-a">If you have any questions, please use the help function on My Page.</dd>
						<input id="qae58" type="checkbox" /><label htmlFor="qae58">
						<dt className="qa-q">I accidentally deleted the "Spring Festival in Tokyo" app.</dt></label>
						<dd className="qa-a">If you download the app again and log in, you can continue to use it.</dd>
						<input id="qae59" type="checkbox" /><label htmlFor="qae59">
						<dt className="qa-q">Can I still use the service after I change my smartphone model?</dt></label>
						<dd className="qa-a">You can download this app from Google Play or App Store on your new smartphone and login to use it.</dd>
						<input id="qae60" type="checkbox" /><label htmlFor="qae60">
						<dt className="qa-q">Unable to purchase distribution tickets.</dt></label>
						<dd className="qa-a">This app allows you to purchase distribution tickets via in-app payment.<br/>
						Even if you already have an account and have registered a payment method on the distribution site, you will need to set up in-app payment separately. For more information on how to set up in-app payment for your smartphone, please visit the following website.<br/>
							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a><br/>

							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">iOS</a></dd>
						<input id="qae61" type="checkbox" /><label htmlFor="qae61">
						<dt className="qa-q">Can I use a reservation code?</dt></label>
						<dd className="qa-a">Reservation code cannot be used when purchasing distribution tickets within this application.<br/>
						Purchases using the reservation code can only be made from a computer or smartphone browser.</dd>
						<input id="qae62" type="checkbox" /><label htmlFor="qae62">
						<dt className="qa-q">Can I install the software on my tablet as well as my smartphone?</dt></label>
						<dd className="qa-a">It can be installed on Android tablets and iPads as long as the environment supports it.</dd>
						<input id="qae63" type="checkbox" /><label htmlFor="qae63">
						<dt className="qa-q">I don't know if I was able to purchase a delivery ticket.</dt></label>
						<dd className="qa-a">Tap the "Purchased" button at the bottom of the screen to see a list of performances that have been purchased with this app. <br/>
						You must be logged in to view the list.</dd>
						<input id="qae64" type="checkbox" /><label htmlFor="qae64">
						<dt className="qa-q">If a performance is cancelled, will there be a refund?</dt></label>
						<dd className="qa-a">Refunds are available only in the event that the performance is cancelled due to reasons beyond the control of the organizer.<br/>
						You will need to apply for a refund, so please check the following sites depending on the smartphone you are using.<br/>

							<a className="qa-links__item" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">Android</a><br/>

							<a className="qa-links__item" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iOS</a></dd>

						<h2 className="common-title" id="q6">Inquiries</h2>

						<input id="qae65" type="checkbox" /><label htmlFor="qae65">
						<dt className="qa-q">Other inquiries</dt></label>
						<dd className="qa-a">
							If you have any questions, please use the help function on My Page.
						</dd>
					</dl>

				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
