import React, { useEffect, useState } from "react"
import { donePageLoading, setIsStreaming } from '../actions/offer';
import FileNameConst from "../common/FileNameConst"
import { isFileExist } from "../common/CommonUtil";
import { connect } from 'react-redux';

function Closed(props) {

	const [logo20thImgPath, setlogo20thImgPath] = useState('');

	useEffect(() => {
		// get 20th image logo (ja / eng same image)
		logo20thImg();
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	const logo20thImg = () => {
		// ファイル存在チェック
		const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_20TH_IMG);
		if (FileExistFlag) {
			setlogo20thImgPath(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_20TH_IMG);
		} else {
			//デフォルトイメージを取得する。
			setlogo20thImgPath(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.LOGO_20TH_IMG);
		}
	}

	return (
		<>
			<style>
				{`
					.guide {
						padding: 52px 0px 120px !important;
						text-align: center;
						font-size: 16px;
					}
					.guide-section {
						max-width: 100% !important;
						text-align: center;
					}
					.guide-title { border-bottom: none; font-size: 2.0em; font-family:serif;}
					.guide-text { font-weight: normal; line-height: 2.0em; font-size: 1.2em; padding-bottom:1.5em;}
						.guide-text span { font-weight: bold; }
					.guide-text.black { width:80%; background-color: #0004; padding: 2rem; border-radius: 1rem; margin: 4rem 10%; }
					h2 { font-size: 2.0em; line-height: 1.6em; font-family:serif;}
					.guide-section p { line-height: 2.0em; }
					.mt { margin-top: 1.0em; }
					.guide-linkbox {
						padding: 2rem;
						width: 80%;
						margin: 4rem 10%;
					}
					.guide-linkbox a {
						text-decoration: none;
						color: white;
						font-weight: bold;
						font-size: 2rem;
					}
					.harusai_20th_logo  {
						width: 300px; height: 300px;
					}
					@media screen and (max-width: 900px) {
						.guide { font-size: 14px;}
						.guide-linkbox a { font-size: 1.6rem; }
						.guide-linkbox { width: 100%; margin: 0; padding: 0.5rem; }
						.guide-text.black { width: 100%; padding: 1rem; margin: 4rem 0; }
					}
					@media screen and (max-width: 640px) {
						.guide { font-size: 12px;}
						.guide-linkbox a { font-size: 1.2rem; }
						.guide-linkbox { width: 100%; margin: 0; padding: 0.5rem; }
						.guide-text.black { width: 100%; padding: 1rem; margin: 4rem 0; }
					}
					@media screen and (max-width: 480px) {
						.guide { font-size: 10px; }
						.guide-linkbox a { font-size: 1.0rem; }
						.guide-linkbox { width: 100%; margin: 0; }
					}
				`}
			</style>
			{props.language === 'ja_JP' ? (
				<section className="guide" id="guide-basic">
					<h1 className="common-title">東京･春･音楽祭 Live Streaming 2024</h1>
					<section className="guide-section">
						<p className="guide-text"><span>2024年2月公開予定</span></p>
						<div className="harusai_20th_logo" style={{ display:'inline' }}>
							<img className="harusai_20th_logo" src={logo20thImgPath} alt="東京･春･音楽祭 Live Streaming 20th" />
						</div>
						<div className="guide-linkbox">最新の公演情報は、東京･春･音楽祭公式サイトをご覧ください<br/><br/><a href="https://www.tokyo-harusai.com/">https://www.tokyo-harusai.com</a></div>
						<p className="guide-text black">
							東京･春･音楽祭 LIVE Streaming 2024に関するお問い合わせは<a href="/mypage">こちら</a>からログイン後、「ヘルプ」よりお問い合わせください。<br/>
						</p>
					</section>
				</section>
			) : (
				<section className="guide" id="guide-basic">
					<h1 className="common-title">Spring Festival in Tokyo Live Streaming 2024</h1>
					<section className="guide-section">
						<p className="guide-text"><span>Open in February, 2024</span></p>
						<div className="harusai_20th_logo" style={{ display:'inline' }}>
							<img className="harusai_20th_logo" src={logo20thImgPath} alt="Tokyo Spring Music Festival LIVE Streaming 2024"/>
						</div>
						<div className="guide-linkbox">
						Check the latest information on our official website.<br/><br/><a href="https://www.tokyo-harusai.com/">https://www.tokyo-harusai.com/</a></div>
						<p className="guide-text black">
							For inquiries about Tokyo Spring Music Festival LIVE Streaming 2024,&nbsp;
							go to <a href="/mypage">My Page</a>&nbsp;and click "Help" after logged in to contact us.<br/>
						</p>
					</section>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Closed);
