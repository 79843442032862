import React, { useState, useEffect } from "react"

function OfferListTopData(props) {
  const [dayOfWeek3, setDayOfWeek3] = useState()
  const [day,setDay] = useState(0)
  const [month,setMonth] = useState(0)
  const [year,setYear] = useState(0)


  useEffect(() => {
    let propsdate = props.date;
    let _date = props.date.split('/');
    if(_date.length == 2)propsdate+='/1';
    if(_date[2] === undefined || _date[2] === '')propsdate+='1';
    let date = new Date(propsdate);
    if (props.language === "ja_JP") {
      setDayOfWeek3(["日", "月", "火", "水", "木", "金", "土"][date.getDay()])
    }
    if (props.language === "en_US") {
      setDayOfWeek3(
          ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]
      )
    }
    setDay(date.getDate());
    setMonth(date.getMonth() + 1);
    setYear(date.getFullYear());
  }, [props])
  return (
      <>
        {props.language === "ja_JP"
            ? `${year}年${month}月`
//            ? `${year}年${month}月${day}日 [${dayOfWeek3}]`
            : ""}
        {props.language === "en_US"
            ? `${year}/${month}`
//            ? `${year}/${month}/${day} [${dayOfWeek3}]`
            : ""}
      </>
  )
}

export default OfferListTopData
