import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import FileNameConst from '../common/FileNameConst';
import { isFileExist } from "../common/CommonUtil";

function NotFound(props) {
  const [logoSqImgPass, setLogoSqImgPass] = useState('');

  useEffect(() => {
    props.setIsStreaming(false);
    props.donePageLoading(true);
    //イメージファイルをS3から取得する
    getLogoSqImg();
  }, []);
  const getLogoSqImg = () => {
    // ファイル存在チェック
    const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG);
    if (FileExistFlag) {
      setLogoSqImgPass(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG);
    } else {
      //デフォルトイメージを取得する。
      setLogoSqImgPass(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.LOGO_SQ_IMG);
    }
  }
  return (
    <section className="unavailable">
      <style jsx="true">
        {`
                    .unavailable {
                      width: 60%;
                      margin: 4em auto;
                      text-align: center;
                      background-size: 100% 100%;
                      background-position: center bottom;
                    }
                      .unavailable img {
                        width: 120px;
                        height: auto;
                      }
                      .unavailable h1 {
                        font-size: 38px;
                        margin-top: 1em;
                        font-weight: bold;
                        line-height: 3em;
                      }
                      .unavailable h2 {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 2em;
                      }
                      .unavailable .unavailable-page {
                        width: 100%;
                        text-align: center;
                      }
                      .unavailable .unavailable-page p {
                        text-align: center;
                        line-height: 1.6em;
                        margin-bottom: 1.6em;
                      }
                        .unavailable .unavailable-page p.last {
                          margin-bottom: 4em;
                        }
                        .unavailable .unavailable-page p.list {
                          font-weight: bold;
                        }
                      .unavailable-page .btn-basic {
                        position: relative;
                        background: #666;
                        margin-top: 3rem;
                        text-align: center;
                        color: #fff;
                        line-height: 2em;
                        padding: 1em;
                        border-radius: 40px;
                        text-decoration: none;
                        font-weight: bold;
                      }
                      @media (max-width: 1149px) {
                        .unavailable {
                          width: 80%;
                        }
                        .unavailable h1 {
                          font-size: 24px;
                        }
                        .unavailable h2 {
                          font-size: 12px;
                        }
                      }
                `}
      </style>
      <>
        {props.language === 'ja_JP' ? (
          <div className="unavailable-page">
            <img src={logoSqImgPass} alt="東京･春･音楽祭" />
            <h1>お探しのページは見つかりませんでした</h1>
            <p>
              ご利用いただきありがとうございます。
              <br />
              指定されたページが見つかりませんでした。
            </p>
            <p>もう一度、アドレスをご確認いただき、指定されたアドレスにお間違いがなければ</p>
            <p className="list">
              ・ページが別のアドレスに移動している
              <br />
              ・ページが削除されている
            </p>
            <p className="last">
              などが考えられます。
              <br />
              お手数ですが、トップページからお探しください。
            </p>
            <a href="/" className="btn-basic">
              トップページへ戻る
            </a>
          </div>
        ) : (
          <div className="unavailable-page">
            <img src={logoSqImgPass} alt="東京･春･音楽祭" />
            <h1>Page Not Found</h1>
            <p>We couldn't find the page you're looking for.</p>
            <p className="last">
              It seems you've requested a program that has been canceled, rescheduled or never existed.
              <br />
              You may want to return top and start again. Sorry :(
            </p>
            <a href="/" className="btn-basic">
              Back to Top
            </a>
          </div>
        )}
      </>
    </section>
  );
}
const mapStateToProps = state => {
  return {
    language: state.piano.language,
    donePageLoading: state.offer.donePageLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  setIsStreaming: state => dispatch(setIsStreaming(state)),
  donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
