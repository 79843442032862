import React, { useState, useEffect } from 'react';
import OfferTopData from "./OfferTopDate";
import Const from '../../common/Const'
import { encodeKeyword } from '../../common/CommonUtil';
import queryString from 'query-string';

function OfferTitle(props) {
    const query = "query";
    const [topURL, setTopURL] = useState('');
    useEffect(() => {
        //const queryString = require('query-string');
        let parsed = queryString.parse(window.location.search);
        if (parsed.distributor_id) {
            //公演詳細のクエリパラメータありのリンクを作成
            setTopURL(Const.hrefSearch + query + Const.addDistributorQuery + parsed.distributor_id)
        } else {
            setTopURL(Const.hrefSearch + query)
        }
    }, []);

    return (
        <header className="detail-header">
            <div className="detail-header__date">{OfferTopData(props)}</div>
            <div className="detail-header__category">{props.series}</div>
            <h1 className="detail-header__title">{props.title}</h1>
            <h2 className="detail-header__subtitle">{props.subTitle}</h2>
            <ul>
                {props.tags.length > 0 ? (
                    props.tags.map(tag => {
                        return (
                            <li className="detail-header__tag" key={tag}>
                                <a href={topURL.replace(query, encodeKeyword(Const.sharp + tag))} >{Const.sharp + tag}</a>
                            </li>
                        )
                    })
                ) : (
                    props.frame_string !== "" ? (
                        <div className="detail-header__movie-type">{props.frame_string}</div>
                    ) :
                        ("")
                )}
            </ul>
        </header>
    )
}

export default OfferTitle
