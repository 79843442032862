import React, { useEffect, useState } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';
import OfferPlayer from '../components/OfferComponents/OfferPlayer';
import {getSystemRequirementsFromSettings} from '../common/CommonUtil';
import FileNameConst from '../common/FileNameConst.js';

function Test(props) {
	// 設定情報（消費税率・動作推奨環境）
	const [settingJson, setSettingJson] = useState(null);
	const [systemRequirements, setSystemRequirements] = useState()

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
		// 設定情報取得
		if (settingJson === null) {getSettingJson();} 
	}, []);

	useEffect(() => {
		getSystemRequirements();
	}, [settingJson])

	const getSettingJson = () => {
		let ts = new Date().getTime();
		fetch(process.env.REACT_APP_JS_DIR_URL + FileNameConst.SETTING_JSON + "?" + ts)
		.then(res => res.json())
		.then(
			(result) => {
				setSettingJson(result.settings)
			},
			(error) => {
				console.log('NG');
			}
		)
	}

	const getSystemRequirements = () => {
		if (!settingJson) {return;}
		let systemRequirements = getSystemRequirementsFromSettings(settingJson);
		setSystemRequirements(systemRequirements);
	}

	var addedScript = '';

	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="environment">
					<div className="environment-thumbnail">

						<OfferPlayer
							format={'test'}
							is_multiple_angles={false}
							programState={'Streaming'}
							//script={'../assets/js/ups-player.js?player=harusai1dev&resolution=4k&m3u8=https://stream99.harusailive.jp/c86u0m6celd94171mt8g/c86u0m6celd94171mt8g.m3u8&z=false'}
							script={'https://public.mediasp.jp/v1/player?hash=c0mfckithkuanvu7ejjg&media_type=hls&player=iijplayer&plcc=true&plef=3&token=eyJhbGciOiJSUzI1NiJ9.eyJpcCI6IjE2MC4yMzcuMTQ0LjE0NCIsImV4cCI6MzAwMDAwMDAwMH0.xT7U_4vszZ_XUUqk9rDKz2YZQsqGhrIb9RMAWJzpVAY4eMlMQr-tLC6sAksUqYC8dfcyLSxOnzyFZr5NOaqJbrB8uR5wEQ-Fqkkg33PIQWGNAWQ2QyRalb6T7LYhv3CRJBRclSLwuBgg8oM-5Bso2jx9K3y1q6G2J067Vr1VPuV_UY62_yBI1pcaPvLt7az-bfujKVnFft8dRS54tcRBlljnFwPOrtC0lrf143tQVqDQ5dH5HeeAYKbWfzoBOulv61VrNRjqI7eyuJxO_-xIxygQW95-8brM7uKzRTs8Ud0hW-EFSFTmNgQrKPiXAcJu6Lhiiag2SqLRm8HPwh4_279t7esT1ot52XMRB_sQiI88MwMinP7Z_fnr64JHDVHQH_JllY9UbleNsphO4URkA6McNch69YHqGjtdpLIN_dNXEp0R_H99T6YM9s4GsgWPCuB7KVaaTKrZwAr2Y4IptZwQlZNn2L24dtn6U3Hz75Ag9kd-vUZW6UvxYkOaDAs95w6YIY6uZArxChh8nEFzgx1f9xVGOzM0VAqXxzZKH-aY6AJfktsuq1Tk3Dc0O2f6023JaXaSkoISPUnCR3o2kZ_yqamKNsNPcR6keBNB0x5Nd_JKcrnurIL0SbXx3TkBNMuH5pnaVPsrjK60QcE6P8PGzQCXSoM1R8YoIxgLrRw'}
							performanceThumbnail={process.env.REACT_APP_IMAGE_DIR_URL + 'ensemble/DEFAULT_ENSEMBLE_THUMBNAIL.jpeg'}
						/>

					</div>
					<div className="environment-content">
						<div className="environment-body">
						<h1 className="environment-title">動画再生確認</h1>
							{systemRequirements && Object.keys(systemRequirements).length ? 
								<>
								<p className="environment-text">以下のWebブラウザーの使用を推奨しています。</p>
								<h2 className="environment-title2">動作確認環境</h2>
								<dl className="environment-info">
									{systemRequirements.Windows || systemRequirements.Mac ? (
										<>
										<dt className="environment-info__title">【PC】</dt>
										{systemRequirements.Windows ? 
											<>
											<div className="environment-info__title">Windows {systemRequirements.Windows.os}</div>
											{systemRequirements.Windows.browserList.map(browser => {
												return (<dd className="environment-info__text">・{browser}</dd>)
											})}
											</>
										: ''}
										{systemRequirements.Mac ? 
											<>
											<div className="environment-info__title">Mac OSX Catalina({systemRequirements.Mac.os}以降)</div>
											{systemRequirements.Mac.browserList.map(browser => {
												return (<dd className="environment-info__text">・{browser}</dd>)
											})}
											</>
										: ''}
										</>
									):''}
									{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
										<>
										<div className="environment-info__title">【スマートフォン】</div>
										{systemRequirements.Android ? 
											<>
											<div className="detail-info__text-title">Android （Android {systemRequirements.Android.os}以降）</div>
											{systemRequirements.Android.browserList.map(browser => {
												return (<dd className="environment-info__text">・{browser}</dd>)
											})}
											</>
										: ''}
										{systemRequirements.iOS && systemRequirements.iPad ? 
											<>
											<div className="environment-info__title">Apple（iOS {systemRequirements.iOS.os}以降、iPadOS {systemRequirements.iPad.os}以降）</div>
											{systemRequirements.iOS.browserList.map(browser => {
												return (<dd className="environment-info__text">・{browser}</dd>)
											})}
											</>
										: ''}
										</>
									) : ('')}
								</dl>
								<p className="environment-attention">※推奨環境下でもお客様のwebブラウザの設定によっては、正しく表示されない場合があります。</p>
								<p className="environment-attention">※通信状況、通信能力及びPCのスペック性能によりページの表示に時間がかかる、あるいは正常に動作しない場合があります。</p>
								<p className="environment-attention">※アプリの提供は2023年をもって終了しました。</p>
								<div id="pl_programme_info_text" className="hidden_programme">
									<p>曲目がある場合はこちらに表示されます。</p>
								</div>
								</>
							: ''}
						</div>
						<aside className="environment-side">
							<h2 className="environment-title2">JavaScriptについて</h2>
							<p className="environment-text">当サイトでは、JavaScriptを使用しています。 お使いのブラウザでJavaScript機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。 ご覧になる際にはブラウザ設定でJavaScriptを有効にしてご覧ください。</p>
							<h2 className="environment-title2">Cookieについて</h2>
							<p className="environment-text">当サイトでは、Cookieを使用しています。 お使いのブラウザでCookie機能を無効にされている場合、正しく機能しない、もしくは正しく表示されないことがあります。 ご覧になる際にはブラウザ設定でCookieを有効にしてご覧ください。</p>
						</aside>
					</div>
				</section>
			) : (
				<section className="environment">
					<div className="environment-thumbnail">
						<OfferPlayer
							format={'test'}
							is_multiple_angles={false}
							programState={'Streaming'}
							//script={'../assets/js/ups-player.js?player=harusai1dev&resolution=4k&m3u8=https://stream99.harusailive.jp/c86u0m6celd94171mt8g/c86u0m6celd94171mt8g.m3u8&z=false'}
							script={'https://public.mediasp.jp/v1/player?hash=c0mfckithkuanvu7ejjg&media_type=hls&player=iijplayer&plcc=true&plef=3&token=eyJhbGciOiJSUzI1NiJ9.eyJpcCI6IjE2MC4yMzcuMTQ0LjE0NCIsImV4cCI6MzAwMDAwMDAwMH0.xT7U_4vszZ_XUUqk9rDKz2YZQsqGhrIb9RMAWJzpVAY4eMlMQr-tLC6sAksUqYC8dfcyLSxOnzyFZr5NOaqJbrB8uR5wEQ-Fqkkg33PIQWGNAWQ2QyRalb6T7LYhv3CRJBRclSLwuBgg8oM-5Bso2jx9K3y1q6G2J067Vr1VPuV_UY62_yBI1pcaPvLt7az-bfujKVnFft8dRS54tcRBlljnFwPOrtC0lrf143tQVqDQ5dH5HeeAYKbWfzoBOulv61VrNRjqI7eyuJxO_-xIxygQW95-8brM7uKzRTs8Ud0hW-EFSFTmNgQrKPiXAcJu6Lhiiag2SqLRm8HPwh4_279t7esT1ot52XMRB_sQiI88MwMinP7Z_fnr64JHDVHQH_JllY9UbleNsphO4URkA6McNch69YHqGjtdpLIN_dNXEp0R_H99T6YM9s4GsgWPCuB7KVaaTKrZwAr2Y4IptZwQlZNn2L24dtn6U3Hz75Ag9kd-vUZW6UvxYkOaDAs95w6YIY6uZArxChh8nEFzgx1f9xVGOzM0VAqXxzZKH-aY6AJfktsuq1Tk3Dc0O2f6023JaXaSkoISPUnCR3o2kZ_yqamKNsNPcR6keBNB0x5Nd_JKcrnurIL0SbXx3TkBNMuH5pnaVPsrjK60QcE6P8PGzQCXSoM1R8YoIxgLrRw'}
							performanceThumbnail={process.env.REACT_APP_IMAGE_DIR_URL + 'ensemble/DEFAULT_ENSEMBLE_THUMBNAIL.jpeg'}
						/>
					</div>
					<div className="environment-content">
						<div className="environment-body">
							<h1 className="environment-title">Video playback environment confirmation</h1>
							{systemRequirements && Object.keys(systemRequirements).length ? 
								<>
								<p className="environment-text">We recommend using the following web browsers.</p>
								<h2 className="environment-title2">System Requirements</h2>
								<dl className="environment-info">
								{systemRequirements.Windows || systemRequirements.Mac ? (
									<>
									<div className="environment-info__title">【PC】</div>
									{systemRequirements.Windows ? 
										<>
										<div className="environment-info__title">Windows {systemRequirements.Windows.os}</div>
										{systemRequirements.Windows.browserEnList.map(browser => {
											return (<dd className="environment-info__text">・{browser}</dd>)
										})}
										</>
									: ''}
									{systemRequirements.Mac ? 
										<>
										<div className="environment-info__title">Mac OSX Catalina({systemRequirements.Mac.os} or later)</div>
										{systemRequirements.Mac.browserEnList.map(browser => {
											return (<dd className="environment-info__text">・{browser}</dd>)
										})}
										</>
									: ''}
									</>
								) : ('')}
								{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
									<>
									<div className="environment-info__title">【Smart Phone】</div>
									{systemRequirements.Android ? 
										<>
										<div className="environment-info__title">Android （Android {systemRequirements.Android.os} or later）</div>
										{systemRequirements.Android.browserEnList.map(browser => {
											return (<dd className="environment-info__text">・{browser}</dd>)
										})}
										</>
									: ''}
									{systemRequirements.iOS && systemRequirements.iPad ? 
										<>
										<div className="environment-info__title">Apple（iOS {systemRequirements.iOS.os} or later、iPadOS {systemRequirements.iPad.os} or later）</div>
										{systemRequirements.iOS.browserEnList.map(browser => {
											return (<dd className="environment-info__text">・{browser}</dd>)
										})}
										</>
									: ''}
									</>
								) : ('')}
								</dl>
								<p className="environment-attention">*This site may not be displayed correctly even in the recommended environment, depending on the settings of your web browser.</p>
								<p className="environment-attention">*This website may take time to display or may not operate properly due to communication conditions, communication capacity, or PC specifications.</p>
								<p className="environment-attention">※The app is no longer available.</p>
								
								<div id="pl_programme_info_text" className="hidden_programme">
									<p>If a program list is available, it will be displayed here.</p>
								</div>
								</>
							: ''}
						</div>
						<aside className="environment-side">
							<h2 className="environment-title2">About JavaScript</h2>
							<p className="environment-text">JavaScript is used in this website. If JavaScript is disabled in your browser, the site may not function or display properly. Please enable JavaScript in your browser settings when viewing this site.</p>
							<h2 className="environment-title2">About Cookies</h2>
							<p className="environment-text">Cookies are used on this website. If the cookie function is disabled in your browser, the site may not function or display properly. Please enable cookies in your browser settings when viewing this site.</p>
						</aside>
					</div>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Test);
