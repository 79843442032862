import React, { useState, useEffect } from 'react';
import {connect} from "react-redux"
import {purchaseTicket} from "../../actions/piano"
import OfferDisplayStatusConst from "../../common/OfferDisplayStatusConst";
import ProgramStateConst from "../../common/ProgramStateConst";
import SettingConst from "../../common/SettingConst";
import {getTaxPrice, getTaxFromSettings} from '../../common/CommonUtil';

function OfferSpOnlyPurchaseButtonAndInfo(props) {
    const [tax, setTax] = useState(1)

    let liveDate = new Date(props.purchase_end_time)
    let archiveDate = new Date(props.archive_purchase_end_time)

    useEffect (() => {
        getTax();

    }, [props.settingJson])

    const getTax = () => {
		if (!props.settingJson) {return;}
		let tax = getTaxFromSettings(props.settingJson);
		if (tax) {setTax(tax);}
	}

    return (
        <>
            {getButtonSpSwitch(props)}
			{ !props.is_ensemble_cancelled?
				(
                    props.display_status === OfferDisplayStatusConst.archive ?
                        <dl className="purchase-info sp-only before-live-only">
                            <dt className="purchase-info__title">
                                {props.language === "ja_JP" ? "価格" : "Price"}
                            </dt>
                            <dd className="purchase-info__text">
                                {getTaxPrice(props.isJapan,props.archivePianoPrice, tax)}{props.language === "ja_JP" ? "円（税込み）" : "yen"}
                            </dd>
                            <dt className="purchase-info__title">
                                {props.language === "ja_JP" ? "購入期限" : "Available until"}
                            </dt>
                            <dd className="purchase-info__text">
                                {`${
                                    props.language === "ja_JP"
                                        ? `${
                                            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"][
                                                archiveDate.getMonth()
                                                ]
                                        }/${archiveDate.getDate()} ${archiveDate.getHours()}:${(
                                            "0" + archiveDate.getMinutes()
                                        ).slice(-2)}`
                                        : `${
                                            [
                                                "Jan",
                                                "Feb",
                                                "Mar",
                                                "Apr",
                                                "May",
                                                "Jun",
                                                "Jul",
                                                "Aug",
                                                "Sep",
                                                "Oct",
                                                "Nov",
                                                "Dec",
                                            ][archiveDate.getMonth()]
                                        },${archiveDate.getDate()} ${archiveDate.getHours()}:${(
                                            "0" + archiveDate.getMinutes()
                                        ).slice(-2)}`
                                }`}
                            </dd>
                        </dl>
                    :
                        <dl className="purchase-info sp-only before-live-only">
                            <dt className="purchase-info__title">
                                {props.language === "ja_JP" ? "価格" : "Price"}
                            </dt>
                            <dd className="purchase-info__text" style={{ verticalAlign:'top' }}>
								{GetPriceList(props.language , props.isJapan, props.pianoPrice, tax)}
								<div className="detail-body__attention">{GetProgramBookAttention(props.language , props.isJapan, props.pianoPrice, tax)}</div>
                                <br/>
                            </dd>
                            <dt className="purchase-info__title">
                                {props.language === "ja_JP" ? "購入期限" : "Available until"}
                            </dt>
                            <dd className="purchase-info__text">
                                {`${
                                    props.language === "ja_JP"
                                        ? `${
                                            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"][
                                                liveDate.getMonth()
                                                ]
                                        }/${liveDate.getDate()} ${liveDate.getHours()}:${(
                                            "0" + liveDate.getMinutes()
                                        ).slice(-2)}`
                                        : `${
                                            [
                                                "Jan",
                                                "Feb",
                                                "Mar",
                                                "Apr",
                                                "May",
                                                "Jun",
                                                "Jul",
                                                "Aug",
                                                "Sep",
                                                "Oct",
                                                "Nov",
                                                "Dec",
                                            ][liveDate.getMonth()]
                                        },${liveDate.getDate()} ${liveDate.getHours()}:${(
                                            "0" + liveDate.getMinutes()
                                        ).slice(-2)}`
                                }`}
                            </dd>
                        </dl>
				) : (
					<dl className="purchase-info sp-only before-live-only">
						<dt className="detail-info__title sp-only before-live-only">{props.language === 'ja_JP' ? '価格' : 	'Price'}</dt>
						<dd className="detail-info__text sp-only before-live-only">
							{props.language === 'ja_JP' ? '公演中止' : 'Cancelled'}
						</dd>
						<br/><br/>
					</dl>
				)
			}
        </>
    )
}

function getButtonSpSwitch(props) {
    if (props.isLocationNG){
        return getButtonSP(props, "購入不可地域","Non-purchasable area",true)
    }
    switch (props.programState) {
        case ProgramStateConst.purchased:
            return getButtonSP(props, "購入済み", "Purchased", true)
        case ProgramStateConst.streaming:
            return "" // 何も表示しない
        case ProgramStateConst.done:
            return ""  // 何も表示しない
        case ProgramStateConst.waitSale:
            return getButtonSP(props, "販売開始前", "Before sales start", true)
        case ProgramStateConst.unavailable:
            return getButtonSP(props, "販売終了", "end of sales", true)
        case ProgramStateConst.onSale:
            return getButtonSP(props, "購入する", "Purchase Ticket", false)
        default:
            return ""
    }
}

function getButtonSP(props, body, body_en, isInvalidation) {
    // 購入不可
    if (isInvalidation) {
        return (
            <div
                className="detail-side__buy-button sp-only before-live-only"
                title={body}
                style={{cursor: "default"}}
            >
                {props.language === "ja_JP"
                    ? body
                    : body_en}
            </div>
        )
    } else {
        // 購入可
        return (
            <a
                className="detail-side__buy-button sp-only before-live-only"
                title={body}
                onClick={() => {
                    props.purchaseTicket(props.offerId)
                }}
            >
                {props.language === "ja_JP"
                    ? body
                    : body_en}
            </a>
        )
    }
}

function GetPriceList(lang, isJP , price , tax,) {
    let _price =  getTaxPrice(isJP, price, tax)
    let _price_disp_list = [];
    //通常のチケット料金
    _price_disp_list.push( (lang == "ja_JP") ? "" + GetPriceFromWithBooklet(_price) + "円（税込)" : "" + GetPriceFromWithBooklet(_price) + "yen" )
    if (_price == "2,270" || _price == "1,970" || _price == "2,063" || _price == "1,790") {
        //公式プログラム付きがある場合
        _price_disp_list.push((lang == "ja_JP") ? "公式プログラム付:" + _price + "円(税込)" : "with offical program book:" + _price + "yen")

    }
    return <div>{_price_disp_list.map(t => (<div>{t}</div>))}</div>
    
}
function GetPriceFromWithBooklet(_price) {
    if (_price == "2,270") return "1,200";
    if (_price == "1,970") return "900";
    if (_price == "2,063") return "1,090";
    if (_price == "1,790") return "818";
    //公式プログラムではない場合はそのまま返却
    return _price;
}

function GetProgramBookAttention(lang, isJP , price , tax,) {
    let _price =  getTaxPrice(isJP, price, tax)
    let _ret = "";
    if (_price == "2,270" || _price == "1,970" || _price == "2,063" || _price == "1,790") {
        if(lang == "ja_JP") {
            _ret = "※公式プログラムは音楽祭全体を網羅するものとなり、各公演毎にご用意した仕様ではございません。複数の公演をご購入される場合は、1公演のみで「公式プログラム付」を選択してください。";
        }
        else {
            _ret = "※The official program book is Spring Festival in Tokyo 2024's book. The official program book is are not prepared for each show. Please be careful when purchasing multiple shows.";
        }
    }
    return _ret;
}

const mapDispatchToProps = (dispatch) => ({
    purchaseTicket: (offerId) => dispatch(purchaseTicket(offerId)),
})

export default connect(null, mapDispatchToProps)(OfferSpOnlyPurchaseButtonAndInfo)
