import React from 'react';
import OfferTopData from './OfferTopDate';

function OfferTitle(props) {
	return (
		<header className="detail-header">
			<div className="detail-header__category">{props.series}</div>
			<h1 className="detail-header__title">{props.title}</h1>
			<h2 className="detail-header__subtitle">{props.subTitle}</h2>
			{props.frame_string !== '' ? <div className="detail-header__movie-type">{props.frame_string}</div> : ''}
		</header>
	);
}

export default OfferTitle;
