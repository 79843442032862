import React from "react"
import { createRoot } from 'react-dom/client';
import thunk from "redux-thunk"
import App from "./App"
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"

import reducer from "./reducers"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}><App /></Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
