export const ProgramStateConst = {
    streaming: 'Streaming',
    purchased: 'Purchased',
    done: 'Done',
    waitSale: 'WaitSale',
    onSale: 'OnSale',
    unavailable: 'Unavailable',
    cancelled: 'Cancelled',
    rerun: 'Rerun',
    archive: 'Archive',
    archiveInPreparation: 'ArchiveInPreparation'
}

export default ProgramStateConst;