import React from "react"
import Papa from "papaparse"

function OfferProgram(props) {
    return <dd className="detail-body__text">{parseFreeText(props.freeText)}</dd>
}

const parseFreeText = (program) => {
    if (program !== undefined) {
        let description = Papa.parse(program)
        if (description !== undefined) {
            return (
                <>
                    {description.data[0].map((item, index) => {
                        return <p key={index}> {item} </p>
                    })}
                </>
            )
        }
    }
}
export default OfferProgram
