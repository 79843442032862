import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SearchBar from '../components/SearchBar/SearchBar';
import ShowCase from '../components/ShowCase/ShowCase';
import Supporting from '../components/Footer/Supporting';
import TopInformation from '../components/Header/TopInformation';
import AppRelease from '../components/Header/AppRelease';
import Closed from './Closed';
import Prepair from './Prepair';
import Banner from '../components/Banner/Banner';
import "./DebugLog.js"

import { login, showPasswordReset } from '../actions/piano';
import { getOffers, searchOffers, getPurchasedOffers, setIsStreaming, donePageLoading } from '../actions/offer';
import { getProfessionalDistributors, getPremiumDistributors } from '../actions/distributor';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Const from '../common/Const';
import FileNameConst from '../common/FileNameConst';
import { isFileExist, encodeKeyword } from '../common/CommonUtil';
import queryString from 'query-string';

function Index(props) {
	const [isLoading, setLoading] = useState(true);
	const [sortType, setSortType] = useState('');
	const [keyword, setKeyword] = useState('');
	const location = useLocation();
	const [isQueryCheck, setIsQueryCheck] = useState(false);
	const [isRedirect, setIsRedirect] = useState(false);
	const [isDistributorSelected, setIsDistributorSelected] = useState(false);
	const [selectedDistributorId, setSelectedDistributorId] = useState(null);
	const [professionalDistributors, SetprofessionalDistributors] = useState(null);
	const [smallLogoUrl, setSmallLogoUrl] = useState('');

	// お知らせ表示
	let isInfoVisibility = (process.env.REACT_APP_INFORMATION_VISIVILITY === "true") ? true : false ;
	const [isInformation, setIsInformation] = useState(false);
	const [isInformationCalled, setIsInformationCalled] = useState(false);
	// アプリお知らせ
	let isAppInfoVisibility = (process.env.REACT_APP_APP_RELEASE_INFORMATION_VISIVILITY === "true") ? true : false ;
	const [isAppReleaseNotification, setIsAppReleaseNotification] = useState(isAppInfoVisibility);

	// 終了表示
	const [isEventEnded, setIsEventEnded] = useState(false);
	const isSiteStatus = process.env.REACT_APP_SITE_STATUS;
	const isSponserVisibility = (process.env.REACT_APP_SPONSER_VISIVILITY === "true") ? true : false ;
	
	const smallBanners = {
		slidesToShow : parseInt(process.env.REACT_APP_SMALL_BANNERS_SLIDESTOSHOW, 10),
		slidesToShowMobile : parseInt(process.env.REACT_APP_SMALL_BANNERS_SLIDESTOSHOW_MOBILE, 10),
		slidesToScroll : parseInt(process.env.REACT_APP_SMALL_BANNERS_SLIDESTOSCROLL, 10),
		showDots : process.env.REACT_APP_SMALL_BANNERS_SHOW_DOTS,
		autoplaySpeed : parseInt(process.env.REACT_APP_SMALL_BANNERS_AUTO_PLAY_SPEED, 10)
	}

	const largeBanners = {
		slidesToShow: parseInt(process.env.REACT_APP_LARGE_BANNERS_SLIDESTOSHOW, 10),
		slidesToShowMobile: parseInt(process.env.REACT_APP_LARGE_BANNERS_SLIDESTOSHOW_MOBILE, 10),
		slidesToScroll: parseInt(process.env.REACT_APP_LARGE_BANNERS_SLIDESTOSCROLL, 10),
		showDots: process.env.REACT_APP_LARGE_BANNERS_SHOW_DOTS,
		autoplaySpeed: parseInt(process.env.REACT_APP_LARGE_BANNERS_AUTO_PLAY_SPEED, 10)
	}

	useEffect(() => {
		if(isSiteStatus != "open") {
			setSortType('date');
			return;
		}
		// 公演カードのロゴをローカルステートに保持
		if (isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG)) {
			setSmallLogoUrl(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.LOGO_SQ_IMG + "?" + new Date().getTime());
		} else {
			//デフォルトイメージを取得する。
			setSmallLogoUrl(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.LOGO_SQ_IMG);
		}
	}, [])

	useEffect(() => {
		// クエリパラメーター取得
		//const queryString = require('query-string');
		let parsed = queryString.parse(props.location.search);
		if(Object.keys(parsed).length === 0) window.location.href = '/?sortType=date';

		// 配信事業者クエリの場合
		if (parsed.distributor_id) {
			setSelectedDistributorId(parsed.distributor_id);
		} else {
			setSelectedDistributorId(null);
		}

		// 検索クエリの場合
		if (!isQueryCheck && parsed.q !== '') {
			setKeyword(parsed.q);
			parsed.sortType = 'search';

			// 稀にsetKeywordでセットされない場合がある@リトライで治る
			if (keyword === '') {
				setIsQueryCheck(false);
			} else {
				setIsQueryCheck(true);
			}
			handleSearch(false);
			return;
		}
		if (parsed.sortType === 'date') {
			setSortType('date');
			return;
		}
		if (parsed.sortType === 'purchased') {
			setSortType('purchased');
			return;
		}
	});
	useEffect(() => {
		// クエリパラメーターにqがある場合は必ずsearchに切り替えさせる
		//const queryString = require('query-string');
		let parsed = queryString.parse(props.location.search);
		if (parsed.q !== '' && sortType !== 'search' && parsed.q !== undefined && props.offers !== null) {
			setSortType('search');
		}
	}, [sortType]);

	useEffect(() => {
		// クエリパラメーター取得
		//const queryString = require('query-string');
		let query = queryString.parse(props.location.search);
		if (query !== undefined) {
			props.showPasswordReset(query, gotoTopPage);
		}
	});
	const gotoTopPage = () => {
		window.location.href = '/';
	};

	useEffect(() => {
		if(isSiteStatus != "open") {
			setLoading(false);
			return;
		}

		props.donePageLoading(false);
		if (sortType === 'date') {
			props.getOffers(props.loggedIn, selectedDistributorId);
		}
		props.setIsStreaming(false);
		if (location.state) {
			setSortType(location.state.sortType);
		}
		if (sortType === 'purchased') props.getPurchasedOffers(props.loggedIn, selectedDistributorId);
		if (sortType === 'purchased' && !props.loggedIn) {
			props.login();
		}
		setLoading(false);
	}, [props.loggedIn, sortType]);

	useEffect(() => {
		if(isSiteStatus != "open") {
			return;
		}
		// プロフェッショナル契約の配信事業者一覧を取得
		props.getProfessionalDistributors();
		// プレミアム契約の配信事業者一覧を取得
		props.getPremiumDistributors();
		if(selectedDistributorId !== null) {
			setIsDistributorSelected(true);
		} else {
			setIsDistributorSelected(false);
		}
	}, [selectedDistributorId])

	useEffect(() => {
		if (props.professionalDistributors !== null) {
			SetprofessionalDistributors(props.professionalDistributors);
		}
	}, [props.professionalDistributors])

	// エラーハンドリング
	useEffect(() => {
		if (props.error !== null && props.error !== undefined) {
			// ネットワークエラー
			if (props.error.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.error.response !== undefined) {
				if (props.error.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.error.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
		// props.err
		if (props.err !== null && props.err !== undefined) {
			// ネットワークエラー
			if (props.err.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.err.response !== undefined) {
				if (props.err.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.err.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
	}, [props.error, props.err]);

	// Get JSON file
	useEffect(() => {
		let ts = new Date().getTime();
		console.log(ts);
		if(!isInformationCalled){
			setIsInformationCalled(true);
			//fetch("https://localhost:3000/assets/js/data.json")
			fetch(process.env.REACT_APP_S3_PASS_DEFAULT_NEWS + FileNameConst.INFORMATION_JSON + "?" + ts)
			.then(res => res.json())
			.then(
			(result) => {
				window.topics = result;
				setIsInformation(true);
			},
			(error) => {
				console.log('NG');
			}
			)
		}
	});

	const handleSearch = isEnter => {
		if (keyword === '' || keyword === undefined) {
			return;
		}
		setSortType('search');
		if (isEnter) {
			setIsRedirect(true);
			return;
		}
		props.searchOffers(keyword, props.loggedIn, selectedDistributorId);
	};

	const handleSortType = functionSortType => {
		if (functionSortType === 'purchased') {
			if (!props.loggedIn) {
				props.login();
				return;
			}
		}
		setSortType(functionSortType);
	};
	const redirectScript = (keyword, selectedDistributorId) => {
		if (selectedDistributorId === null) {
			window.location.href = Const.hrefSearch + encodeKeyword(keyword);
		} else {
			window.location.href = Const.hrefSearch + encodeKeyword(keyword) + Const.addDistributorQuery + selectedDistributorId;
		}
	};

	function redirectSearch(keyword, selectedDistributorId) {
		redirectScript(keyword, selectedDistributorId);
	}

	function setMainContents(status) {
		if (status === "open") {
			return (
				<>
					{isRedirect ? redirectSearch(keyword, selectedDistributorId) : ''}
					<SearchBar sortType={sortType} keyword={keyword} handleSortType={handleSortType} setKeyword={setKeyword} onEnterPressed={handleSearch} language={props.language} selectedDistributorId={selectedDistributorId} distributors={professionalDistributors}/>
					<section className='contents-tags'>
						{props.isFetching ? '' : <ShowCase offers={props.offers} sortType={sortType} keyword={keyword} language={props.language} donePageLoading={props.donePageLoading} selectedDistributorId={selectedDistributorId} isPremium={false} smallLogoUrl={smallLogoUrl} query={props.location.search}/>}
					</section>
				</>
			)
		}
		else {
			if(status === "close") {
				return (
					<>
						<Closed language={props.language}></Closed>
						{props.isFetching ? '' : <ShowCase isEventEnded={true} offers={props.offers} sortType={sortType} keyword={keyword} language={props.language} donePageLoading={props.donePageLoading} selectedDistributorId={selectedDistributorId} isPremium={false} smallLogoUrl={smallLogoUrl} query={props.location.search} />}
					</>
				)
			}
			else if(status === "prepair") {
				return (
					<>
						<Prepair language={props.language}></Prepair>
						{props.isFetching ? '' : <ShowCase isEventEnded={true} offers={props.offers} sortType={sortType} keyword={keyword} language={props.language} donePageLoading={props.donePageLoading} selectedDistributorId={selectedDistributorId} isPremium={false} smallLogoUrl={smallLogoUrl} query={props.location.search} />}
					</>
				)
			}
		}

	}


	if (isLoading) {
		return (
			<div>
				<style jsx="true">{'\
        .global-footer{\
          display:none;\
        }\
      '}</style>
			</div>
		);
	}

	return (
		<div>
			{isDistributorSelected ? '' : <Banner distributors={props.premiumDistributors} query={null} slidesToShow={largeBanners.slidesToShow} slidesToShowMobile={largeBanners.slidesToShowMobile} slidesToScroll={largeBanners.slidesToScroll} autoplaySpeed={largeBanners.autoplaySpeed} showDots={largeBanners.showDots} />}
			{isInfoVisibility && isInformation ? <TopInformation language={props.language} topics={window.topics} informationUrl='' isPremium={false}/> : ''}
			{isDistributorSelected ? '' : <Banner distributors={props.professionalDistributors} query={props.location.search} slidesToShow={smallBanners.slidesToShow} slidesToShowMobile={smallBanners.slidesToShowMobile} slidesToScroll={smallBanners.slidesToScroll} autoplaySpeed={smallBanners.autoplaySpeed} showDots={smallBanners.showDots} />}
			{isSiteStatus == "open" && isAppReleaseNotification ? <AppRelease language={props.language} /> : ''}

				<section className="home">
					<div className="home__inner">
				{ setMainContents(isSiteStatus) }
				</div>
				</section>
				{ (isSiteStatus == "open" || isSiteStatus == "close") && isSponserVisibility == true ? <Supporting sortType={sortType} /> : ''}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		isFetching: state.offer.isFetching,
		offers: state.offer.offers,
		language: state.piano.language,
		loggedIn: state.piano.loggedIn,
		professionalDistributors: state.distributors.professionalDistributors,
		premiumDistributors: state.distributors.premiumDistributors,
		error: state.offer.error,
		err: state.offer.err,
	};
};

const mapDispatchToProps = dispatch => ({
	login: () => dispatch(login()),
	getOffers: (isLoggedIn, distributorId) => dispatch(getOffers(isLoggedIn, distributorId)),
	searchOffers: (keyword, isLoggedIn, distributorId) => dispatch(searchOffers(keyword, isLoggedIn, distributorId)),
	getPurchasedOffers: (isLoggedIn, distributorId) => dispatch(getPurchasedOffers(isLoggedIn, distributorId)),
	showPasswordReset: (query, gotoTopPage) => dispatch(showPasswordReset(query, gotoTopPage)),
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
	getProfessionalDistributors: async() => await dispatch(getProfessionalDistributors()),
	getPremiumDistributors: async() => await dispatch(getPremiumDistributors())
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
