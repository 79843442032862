import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { donePageLoading, getSingleOffer, setIsStreaming } from '../actions/offer.js';
import OfferPlayer from '../components/OfferComponents/OfferPlayer';
import OfferTitle from '../components/OfferComponents/OfferTitle';
import OfferTester from '../components/OfferComponents/OfferTester';
import OfferVenue from '../components/OfferComponents/OfferVenue';
import OfferPerformer from '../components/OfferComponents/OfferPerformer';
import OfferProgram from '../components/OfferComponents/OfferProgram';
import OfferOfficialSite from '../components/OfferComponents/OfferOfficialSite';
import OfferSurvey from '../components/OfferComponents/OfferSurvey';
import OfferCancelled from '../components/OfferComponents/OfferCancelled';
import OfferSponsors from '../components/OfferComponents/OfferSponsors';
import OfferDetailInfo from '../components/OfferComponents/OfferDetailInfo';
import PurchaseButton from '../components/Card/PurchaseButton';
import OfferPurchaseButton from '../components/OfferComponents/OfferPurchaseButton.js';
import OfferSpOnlyPurchaseButtonAndInfo from '../components/OfferComponents/OfferSpOnlyPurchaseButtonAndInfo';
import OfferFreeText from '../components/OfferComponents/OfferFreeText';
import OfferRecommendBrowserSp from '../components/OfferComponents/OfferRecommendBrowserSp';
import OfferAttention from '../components/OfferComponents/OfferAttention';
import OfferChangePlayer from '../components/OfferComponents/OfferChangePlayer';
import OfferDeliveryPeriod from '../components/OfferComponents/OfferDeliveryPeriod.js';
import Cookies from 'js-cookie';
import MetaFieldConst from '../common/MetaFieldConst.js';
import ProgramStateConst from '../common/ProgramStateConst.js';
import OfferDisplayStatusConst from '../common/OfferDisplayStatusConst.js';
import BackendResponseProgramStatusConst from '../common/BackendResponsePerformanceStatusConst.js';
import { getProfessionalDistributors } from '../actions/distributor';
import FileNameConst from '../common/FileNameConst.js';


function OfferPage(props) {
	// ====== useState/useRef ======
	const isFirstRender = useRef(false);
	const isFirstLanguage = useRef(true);
	const [isLoading, setLoading] = useState(true);
	const { offer_id } = props.match.params;

	const [performanceTitle, setPerformanceTitle] = useState();
	const [performanceSubTitle, setPerformanceSubTitle] = useState();
	const [performanceSeries, setPerformanceSeries] = useState();
	const [performanceThumbnail, setPerformanceThumbnail] = useState();
	const [muxPerformanceTitle, setMuxPerformanceTitle] = useState();

	const [performanceDate, setPerformanceDate] = useState();
	const [performanceOpenTime, setPerformanceOpenTime] = useState();
	const [performanceStartTime, setPerformanceStartTime] = useState();
	const [performanceVenue, setPerformanceVenue] = useState();

	const [performer, setPerformer] = useState();
	const [program, setProgram] = useState();

	// 価格
	const [pianoPrice, setPianoPrice] = useState();

	const [pianoOfferId, setPianoOfferId] = useState();

	const [programState, setProgramState] = useState();
	const [purchase_end_time, setPurchaseEndTime] = useState();
	const [purchase_start_time, setPurchaseStartTime] = useState();

	// 公式サイトURL
	const [official_site_url, setOfficialSiteUrl] = useState();

	// スポンサー
	const [organizer, setOrganizer] = useState();
	const [co_organizer, setCoOrganizer] = useState();
	const [support, setSupport] = useState();
	const [special_co_operation, setSpecialCoOperation] = useState();
	const [organizer_en, setOrganizerEn] = useState();
	const [co_organizer_en, setCoOrganizerEn] = useState();
	const [support_en, setSupportEn] = useState();
	const [special_co_operation_en, setSpecialCoOperationEn] = useState();
	const [support_url, setSupportURL] = useState();
	const [special_co_operation_url, setSpecialCoOperationURL] = useState();
	const [support_logo_url, setSupportLogoURL] = useState();
	const [special_co_operation_logo_url, setSpecialCoOperationLogoURL] = useState();
	const [cooperation, setCooperation] = useState();
	const [cooperation_en, setCooperationEn] = useState();
	const [subsidize, setSubsidize] = useState();
	const [subsidize_en, setSubsidizeEn] = useState();

	// マルチアングル
	const [is_multiple_angles, setIsMultipleAngles] = useState('');
	const [frame_string, setFrameString] = useState('');

	// 日本か判定
	const [is_location_japan, setIsLocationJapan] = useState(true);

	// 公演中止
	const [is_ensemble_cancelled, setIsEnsembleCancelled] = useState('');
	const [performance_cancelled_text, setPerformanceCancelledText] = useState();
	const [performance_cancelled_url, setPerformanceCancelledUrl] = useState();
	const [performance_cancelled_text_en, setPerformanceCancelledTextEn] = useState();
	const [performance_cancelled_url_en, setPerformanceCancelledUrlEn] = useState();

	// フリーテキスト
	const [free_text, setFreeText] = useState();

	// アンケート
	const [survey_link_text, setSurveyText] = useState();
	const [survey_link_url, setSurveyUrl] = useState();
	const [survey_applicable, setSurveyApplicable] = useState();

	// JASRAC
	const [jasrac, setJasrac] = useState();
	const [jasrac_en, setJasracEn] = useState();

	// 外貨表示（英語のみ）
	const [foreign_currency, setForeignCurrency] = useState();

	// プレイヤーハッシュ 通常配信・4K・マルチアングル・字幕
	const [current_script_url, setCurrentScriptUrl] = useState();
	const [current_script_string, setCurrentScriptString] = useState('通常配信');
	const [is_4_K, setIs4K] = useState(false);
	const [is_multi, setMulti] = useState(false);
	const [is_caption, setCaption] = useState(false);

	// 見逃し配信、アーカイブ配信関連
	const [rerunFlg, setRerunFlg] = useState(false);
	const [performanceEndDate, setPerformanceEndDate] = useState();
	const [rerunEndDate, setRerunEndDate] = useState();
	const [archiveFlg, setArchiveFlg] = useState(false);
	const [archivePianoPrice, setArchivePianoPrice] = useState();
	const [archiveStartDate, setArchiveStartDate] = useState();
	const [archiveEndDate, setArchiveEndDate] = useState();
	const [archivePurchaseEndTime, setArchivePurchaseEndTime] = useState();

	//タグ
	const [tags, setTags] = useState([]);

	// 表示ステータス(通常表示、見逃し配信表示、アーカイブ配信表示)
	const [displaySatus, setDisplayStatus] = useState(OfferDisplayStatusConst.normal);

	// 設定情報（消費税率・動作推奨環境）
	const [settingJson, setSettingJson] = useState(null);

	// user_token クエリパラメータ取得
	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const user_token = query.get('user_token');

	// testerチェック
	const [isTester, setIsTester] = useState(false);

	// エラーハンドリング
	useEffect(() => {
		if (props.error !== null && props.error !== undefined) {
			// ネットワークエラー
			if (props.error.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.error.response !== undefined) {
				if (props.error.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.error.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
		// props.err
		if (props.err !== null && props.err !== undefined) {
			// ネットワークエラー
			if (props.err.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.err.response !== undefined) {
				if (props.err.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.err.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
	}, [props.error, props.err]);

	// コピーライト
	const [thum_copyright, setThumCopyright] = useState();

	// アクセス制限
	const [aclType, setAclType] = useState('');
	const [aclTarget, setAclTarget] = useState('');

	useEffect(() => {
		if (isFirstRender.current) {
			props.getSingleOffer(props.loggedIn, offer_id, user_token);
		}
	}, [props.loggedIn]);

	useEffect(() => {
		if (isFirstLanguage.current !== true) {
			props.getSingleOffer(props.loggedIn, offer_id, user_token);
			isFirstLanguage.current = false;
		}
	}, [props.language]);

	useEffect(() => {
		if (programState === ProgramStateConst.streaming) {
			props.setIsStreaming(true);
		} else {
			props.setIsStreaming(false);
		}
	});

	// 国セット
	useEffect(() => {
		if (getCookie('_pc_country') === 'JP') {
			setIsLocationJapan(true);
		} else {
			setIsLocationJapan(false);
		}
	});

	useEffect(() => {
		isFirstRender.current = true;
	}, []);

	useEffect(() => {
		// 設定情報取得
		if (settingJson === null) {getSettingJson();} 
	}, [])

	useEffect(() => {
		setCurrentScriptUrl(props.script);
		if (props.script4K !== undefined && props.script4K !== '') {
			setIs4K(true);
		}
		if (props.scriptMulti !== undefined && props.scriptMulti !== '') {
			setMulti(true);
		}
		if (props.scriptCaption !== undefined && props.scriptCaption !== '') {
			setCaption(true);
		}
	}, [props.script, props.script4K, props.scriptMulti, props.scriptCaption]);

	useEffect(() => {
		switch (current_script_string) {
			case '通常配信':
				setCurrentScriptUrl(props.script);
				break;
			case '4K':
				setCurrentScriptUrl(props.script4K);
				break;
			case 'マルチアングル':
				setCurrentScriptUrl(props.scriptMulti);
				break;
			case '字幕':
				setCurrentScriptUrl(props.scriptCaption);
				break;
			default:
				setCurrentScriptUrl(props.script);
		}
	}, [current_script_string]);

	useEffect(() => {

		let localPurchaseEndTime = ''; // userEffectの中で変数を定義しておくとなんか知らんけどちゃんと表示される
		let localPurchaseStartTime = ''; // userEffectの中で変数を定義しておくとなんか知らんけどちゃんと表示される
		let localPerformanceEndDate = '';
		let localRerunEndDate = '';
		let local_is_ensemble_cancelled; //新しく追加したメタは二回目の1回目の呼び出し時に存在しない・・・
		let localArchiveFlg = false;
		let localRerunFlg = false;
		let _open_time;
		let _test_time;
		if (props.offer !== {} && props.offer !== null) {
			setMuxPerformanceTitle(props.offer.performance_title + '|' + props.offer.performance_sub_title)
			if (props.language === 'ja_JP') {
				setPerformanceTitle(props.offer.performance_title);
				setPerformanceSubTitle(props.offer.performance_sub_title);
				setPerformanceSeries(props.offer.series);
				setPerformanceThumbnail(props.offer.performance_thumbnail);
				setPianoPrice(props.offer.piano_price);
				setPianoOfferId(props.offer.piano_offer_id);
				setArchivePianoPrice(props.offer.archive_piano_price);
				setTags(props.offer.tags);
				props.offer.meta_struct.forEach(meta => {
					if (meta.meta_field_name === MetaFieldConst.performanceDate) setPerformanceDate(meta.value);
					if (meta.meta_field_name === MetaFieldConst.openTime) {
						setPerformanceOpenTime(meta.value);
						_open_time = new Date(meta.value);
					}
					if (meta.meta_field_name === MetaFieldConst.performanceStartTime) setPerformanceStartTime(meta.value);
					if (meta.meta_field_name === MetaFieldConst.venue) setPerformanceVenue(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performer) setPerformer(meta.value);
					if (meta.meta_field_name === MetaFieldConst.program) setProgram(meta.value);
					if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) setPurchaseEndTime(meta.value);
					if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) setPurchaseStartTime(meta.value);
					// 詳細URL
					if (meta.meta_field_name === MetaFieldConst.officialSiteUrl) setOfficialSiteUrl(meta.value);

					// スポンサー
					if (meta.meta_field_name === MetaFieldConst.organizer) setOrganizer(meta.value);
					if (meta.meta_field_name === MetaFieldConst.coOrganizer) setCoOrganizer(meta.value);
					if (meta.meta_field_name === MetaFieldConst.support) setSupport(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperation) setSpecialCoOperation(meta.value);
					if (meta.meta_field_name === MetaFieldConst.supportUrl) setSupportURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperationUrl) setSpecialCoOperationURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.supportLogoUrl) setSupportLogoURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperationLogoUrl) setSpecialCoOperationLogoURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
					if (meta.meta_field_name === MetaFieldConst.cooperation) setCooperation(meta.value);
					if (meta.meta_field_name === MetaFieldConst.cooperationEn) setCooperationEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.subsidize) setSubsidize(meta.value);
					if (meta.meta_field_name === MetaFieldConst.subsidizeEn) setSubsidizeEn(meta.value);

					// アクセス制限
					if (meta.meta_field_name === MetaFieldConst.aclType) setAclType(meta.value);
					if (meta.meta_field_name === MetaFieldConst.aclTarget) setAclTarget(meta.value);
					if (meta.meta_field_name === MetaFieldConst.isMultipleAngles) setIsMultipleAngles(meta.value);
					if (meta.meta_field_name === MetaFieldConst.frameString) setFrameString(meta.value);
					// 購入ボタン用
					if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) localPurchaseEndTime = meta.value;
					if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) localPurchaseStartTime = meta.value;
					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) local_is_ensemble_cancelled = meta.value;
					if (meta.meta_field_name === MetaFieldConst.performanceEndDate) localPerformanceEndDate = meta.value;
					if (meta.meta_field_name === MetaFieldConst.rerunEndDate) localRerunEndDate = meta.value;

					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performanceCancelledText) setPerformanceCancelledText(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performanceCancelledUrl) setPerformanceCancelledUrl(meta.value);
					if (meta.meta_field_name === MetaFieldConst.archiveFlg) localArchiveFlg = (meta.value === 'true');
					if (meta.meta_field_name === MetaFieldConst.rerunFlg) localRerunFlg = (meta.value === 'true');

					// フリーテキスト
					if (meta.meta_field_name === MetaFieldConst.freeText) setFreeText(meta.value);
					// コピーライト
					if (meta.meta_field_name === MetaFieldConst.thumCopyright) setThumCopyright(meta.value);
					// アンケート
					if (meta.meta_field_name === MetaFieldConst.surveyLinkText) setSurveyText(meta.value);
					if (meta.meta_field_name === MetaFieldConst.surveyLinkUrl) setSurveyUrl(meta.value);
					// JASRAC
					if (meta.meta_field_name === MetaFieldConst.jasrac) setJasrac(meta.value);
					// 見逃し配信フラグ
					if (meta.meta_field_name === MetaFieldConst.rerunFlg) setRerunFlg(meta.value === 'true');
					// ライブ公演終了日時
					if (meta.meta_field_name === MetaFieldConst.performanceEndDate) setPerformanceEndDate(meta.value);
					// 見逃し配信終了日時
					if (meta.meta_field_name === MetaFieldConst.rerunEndDate) setRerunEndDate(meta.value);
					// アーカイブ配信フラグ
					if (meta.meta_field_name === MetaFieldConst.archiveFlg) setArchiveFlg(meta.value === 'true');
					// アーカイブ配信開始日時
					if (meta.meta_field_name === MetaFieldConst.archiveStartDate) setArchiveStartDate(meta.value);
					// アーカイブ配信終了日時
					if (meta.meta_field_name === MetaFieldConst.archiveEndDate) setArchiveEndDate(meta.value);
					// アーカイブ配信販売終了日時
					if (meta.meta_field_name === MetaFieldConst.archivePurchaseEndTime) setArchivePurchaseEndTime(meta.value);
				});
				setLoading(false);
				setSurveyApplicable(false);
				props.donePageLoading(true);
			} else if (props.language === 'en_US') {
				setPianoOfferId(props.offer.piano_offer_id);
				setPerformanceThumbnail(props.offer.performance_thumbnail);
				setPianoPrice(props.offer.piano_price);
				setArchivePianoPrice(props.offer.archive_piano_price);
				setTags(props.offer.tags);
				props.offer.meta_struct.forEach(meta => {
					if (meta.meta_field_name === MetaFieldConst.titleEn) setPerformanceTitle(meta.value);
					if (meta.meta_field_name === MetaFieldConst.episodeEn) setPerformanceSubTitle(meta.value);
					if (meta.meta_field_name === MetaFieldConst.seriesTitleEn) setPerformanceSeries(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performanceDate) setPerformanceDate(meta.value);
					if (meta.meta_field_name === MetaFieldConst.openTime) {
						setPerformanceOpenTime(meta.value);
						_open_time = new Date(meta.value);
					}
					if (meta.meta_field_name === MetaFieldConst.performanceStartTime) setPerformanceStartTime(meta.value);
					if (meta.meta_field_name === MetaFieldConst.venueEn) setPerformanceVenue(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performerEn) setPerformer(meta.value);
					if (meta.meta_field_name === MetaFieldConst.programEn) setProgram(meta.value);
					if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) setPurchaseEndTime(meta.value);
					if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) setPurchaseStartTime(meta.value);
					// 詳細URL
					if (meta.meta_field_name === MetaFieldConst.officialSiteUrl) setOfficialSiteUrl(meta.value);
					// スポンサー
					if (meta.meta_field_name === MetaFieldConst.organizerEn) setOrganizerEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.coOrganizerEn) setCoOrganizerEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.supportEn) setSupportEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperationEn) setSpecialCoOperationEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.supportUrl) setSupportURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperationUrl) setSpecialCoOperationURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.supportLogoUrl) setSupportLogoURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.specialCoOperationLogoUrl) setSpecialCoOperationLogoURL(meta.value);
					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
					if (meta.meta_field_name === MetaFieldConst.cooperation) setCooperation(meta.value);
					if (meta.meta_field_name === MetaFieldConst.cooperationEn) setCooperationEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.subsidize) setSubsidize(meta.value);
					if (meta.meta_field_name === MetaFieldConst.subsidizeEn) setSubsidizeEn(meta.value);
					// アクセス制限
					if (meta.meta_field_name === MetaFieldConst.aclType) setAclType(meta.value);
					if (meta.meta_field_name === MetaFieldConst.aclTarget) setAclTarget(meta.value);
					if (meta.meta_field_name === MetaFieldConst.isMultipleAngles) setIsMultipleAngles(meta.value);
					if (meta.meta_field_name === MetaFieldConst.frameStringEn) setFrameString(meta.value);
					// 購入ボタン用
					if (meta.meta_field_name === MetaFieldConst.purchaseEndTime) localPurchaseEndTime = meta.value;
					if (meta.meta_field_name === MetaFieldConst.purchaseStartTime) localPurchaseStartTime = meta.value;
					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) local_is_ensemble_cancelled = meta.value;
					if (meta.meta_field_name === MetaFieldConst.performanceEndDate) localPerformanceEndDate = meta.value;
					if (meta.meta_field_name === MetaFieldConst.rerunEndDate) localRerunEndDate = meta.value;

					if (meta.meta_field_name === MetaFieldConst.isEnsembleCancelled) setIsEnsembleCancelled(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performanceCancelledTextEn) setPerformanceCancelledTextEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.performanceCancelledUrlEn) setPerformanceCancelledUrlEn(meta.value);
					if (meta.meta_field_name === MetaFieldConst.archiveFlg) localArchiveFlg = (meta.value === 'true');
					if (meta.meta_field_name === MetaFieldConst.rerunFlg) localRerunFlg = (meta.value === 'true');
					// フリーテキスト
					if (meta.meta_field_name === MetaFieldConst.freeTextEn) setFreeText(meta.value);
					// コピーライト
					if (meta.meta_field_name === MetaFieldConst.thumCopyright) setThumCopyright(meta.value);
					// JASRAC
					if (meta.meta_field_name === MetaFieldConst.jasracEn) setJasracEn(meta.value);
					// 外貨表示
					if (meta.meta_field_name === MetaFieldConst.currencyEn) setForeignCurrency(meta.value);
					// 見逃し配信フラグ
					if (meta.meta_field_name === MetaFieldConst.rerunFlg) setRerunFlg(meta.value === 'true');
					// ライブ公演終了日時
					if (meta.meta_field_name === MetaFieldConst.performanceEndDate) setPerformanceEndDate(meta.value);
					// 見逃し配信終了日時
					if (meta.meta_field_name === MetaFieldConst.rerunEndDate) setRerunEndDate(meta.value);
					// アーカイブ配信フラグ
					if (meta.meta_field_name === MetaFieldConst.archiveFlg) setArchiveFlg(meta.value === 'true');
					// アーカイブ配信開始日時
					if (meta.meta_field_name === MetaFieldConst.archiveStartDate) setArchiveStartDate(meta.value);
					// アーカイブ配信終了日時
					if (meta.meta_field_name === MetaFieldConst.archiveEndDate) setArchiveEndDate(meta.value);
					// アーカイブ配信販売終了日時
					if (meta.meta_field_name === MetaFieldConst.archivePurchaseEndTime) setArchivePurchaseEndTime(meta.value);
				});
				setLoading(false);
				setSurveyApplicable(false);
				props.donePageLoading(true);
			}
			let startDate = new Date(props.offer.live_start_date);
			let endDate = new Date(props.offer.live_end_date);
			let purchaseEndTime = new Date(localPurchaseEndTime);
			let purchaseStartTime = new Date(localPurchaseStartTime);
			let performanceEndDate = new Date(localPerformanceEndDate);
			let rerunEndDate = new Date(localRerunEndDate);
			let _chk_datetime = {
				'01 purchase_start_time:': purchaseStartTime,
				'02 live_start_date:': startDate,
				'03 open_time:': _open_time,
				'05 purchase_end_time:': purchaseEndTime,
				'06 live_end_date:': endDate,
				'(1) purchase_start_time < live_start_date': purchaseStartTime < startDate,	//purchase_start_time < live_start_date,
				'(2) live_start_date < open_time': startDate < _open_time,			//live_start_date < open_time,
				'(3) purchase_end_time < live_end_date': purchaseEndTime < endDate			//purchase_end_time < live_end_date,
			}
			console.log('_chk_datetime:');
			console.log(_chk_datetime);
			let _is_tester;
			if (window.tp.pianoId.isUserValid()) {
				_is_tester = OfferTester(window.tp.pianoId.getUser().email);
			} else {
				_is_tester = false;
			}
			setIsTester(_is_tester);

			// 公演ステータス判定
			switch (props.offer.performance_status) {
				case BackendResponseProgramStatusConst.waitSale:
					setProgramState(ProgramStateConst.waitSale); //販売前
					break;
				case BackendResponseProgramStatusConst.onSale:
					if (props.offer.isLivePurchased) {
						if (props.offer.is_streaming) {
							setProgramState(ProgramStateConst.streaming); // 配信中
							setSurveyApplicable(true); // 公演終了日の翌日までアンケートリンク表示
						} else {
							setProgramState(ProgramStateConst.purchased); // 購入済み
							if (_is_tester) setSurveyApplicable(true);
						}
					} else {
						setProgramState(ProgramStateConst.onSale); //販売中
					}
					break;
				case BackendResponseProgramStatusConst.unavailable:
					if (props.offer.isLivePurchased) {
						if (props.offer.is_streaming) {
							setProgramState(ProgramStateConst.streaming); // 配信中
							setSurveyApplicable(true); // 公演終了日の翌日までアンケートリンク表示
						} else {
							setProgramState(ProgramStateConst.purchased); // 購入済み
							if (_is_tester) setSurveyApplicable(true);
						}
					} else {
						setProgramState(ProgramStateConst.unavailable); //販売終了
					}
					break;
				case BackendResponseProgramStatusConst.rerun:
					if (props.offer.isLivePurchased) {
						setDisplayStatus(OfferDisplayStatusConst.rerun); // 見逃し配信用レイアウト
						setProgramState(ProgramStateConst.streaming); // 配信中
						setSurveyApplicable(true); // 公演終了日の翌日までアンケートリンク表示
					} else {
						setProgramState(ProgramStateConst.done); //公演終了
						setSurveyApplicable(true);
					}
					break;
				case BackendResponseProgramStatusConst.archiveInPreparation:
					setDisplayStatus(OfferDisplayStatusConst.archive); // アーカイブ配信用レイアウト
					setProgramState(ProgramStateConst.waitSale); //販売前
					break;
				case BackendResponseProgramStatusConst.archiveOnSale:
					setDisplayStatus(OfferDisplayStatusConst.archive); // アーカイブ配信用レイアウト
					if (props.offer.isArchivePurchased) {
						if (props.offer.is_streaming) {
							setProgramState(ProgramStateConst.streaming); // 配信中
						} else {
							setProgramState(ProgramStateConst.purchased); // 購入済み
						}
					} else {
						setProgramState(ProgramStateConst.onSale); //販売中
					}
					break;
				case BackendResponseProgramStatusConst.archiveUnavailable:
					setDisplayStatus(OfferDisplayStatusConst.archive); // アーカイブ配信用レイアウト
					if (props.offer.isArchivePurchased) {
						if (props.offer.is_streaming) {
							setProgramState(ProgramStateConst.streaming); // 配信中
						} else {
							setProgramState(ProgramStateConst.purchased); // 購入済み
						}
					} else {
						setProgramState(ProgramStateConst.unavailable); //販売終了
					}
					break;
				default:
					setProgramState(ProgramStateConst.done); //公演終了
					if (localArchiveFlg) {
						setDisplayStatus(OfferDisplayStatusConst.archive); // アーカイブ用レイアウト
					}
					if (props.offer.isLivePurchased) {
						setSurveyApplicable(true);
					}
					break;
			}
			//中止処理
			if (local_is_ensemble_cancelled) {
				setProgramState(ProgramStateConst.cancelled); // 公演中止
			}
			isFirstRender.current = false;
		}
	}, [props.offer, props.language]);

	const getSettingJson = () => {
		let ts = new Date().getTime();
		fetch(process.env.REACT_APP_JS_DIR_URL + FileNameConst.SETTING_JSON + "?" + ts)
		.then(res => res.json())
		.then(
			(result) => {
				setSettingJson(result.settings)
			},
			(error) => {
				console.log('NG');
			}
		)
	}

	if (isLoading) {
		return (
			<div>
				<style jsx="true">{'\
        .global-footer{\
          display:none;\
        }\
      '}</style>
			</div>
		);
	}
	let ifrm = new Date();
	return (
		//dev

		<section className="detail">
			<div className="detail__inner">
				<OfferPlayer is_multiple_angles={is_multiple_angles} programState={programState} script={current_script_url} format={current_script_string} performanceThumbnail={performanceThumbnail} ifrm={ifrm} program={program} isTester={isTester} muxPerformanceTitle={muxPerformanceTitle} />
				{/*
				<div className="player-copyright">{thum_copyright}</div>
				<OfferChangePlayer setCurrentScriptString={setCurrentScriptString} current_script_string={current_script_string} is_4_K={is_4_K} is_multi={is_multi} is_caption={is_caption} />
*/}
				{survey_applicable ? <OfferSurvey survey_link_text={survey_link_text} survey_link_url={survey_link_url} programState={programState} language={props.language} isTester={isTester} /> : ''}
				{is_ensemble_cancelled ? <OfferCancelled performance_cancelled_text={performance_cancelled_text} performance_cancelled_url={performance_cancelled_url} performance_cancelled_text_en={performance_cancelled_text_en} performance_cancelled_url_en={performance_cancelled_url_en} programState={programState} language={props.language} /> : ''}
				<div className="detial-content">
					<div className="detail-body">
						<OfferTitle date={performanceDate} startTime={performanceStartTime} language={props.language} title={performanceTitle} subTitle={performanceSubTitle} series={performanceSeries} is_multiple_angles={is_multiple_angles} tags={tags} frame_string={frame_string} />
						<OfferSpOnlyPurchaseButtonAndInfo offerId={pianoOfferId} language={props.language} isSP={true} programState={programState} purchase_end_time={purchase_end_time} pianoPrice={pianoPrice} isLocationNG={isLocationNG(aclType, aclTarget)} isJapan={is_location_japan} is_ensemble_cancelled={is_ensemble_cancelled} display_status={displaySatus} archivePianoPrice={archivePianoPrice} archive_purchase_end_time={archivePurchaseEndTime} settingJson={settingJson} />
						<dl className="detail-info">
							<dt className="detail-info__title">{props.language === 'ja_JP' ? '公演日時・会場' : 'Performance Date・Venue'}</dt>
							<OfferVenue date={performanceDate} openTime={performanceOpenTime} startTime={performanceStartTime} venue={performanceVenue} language={props.language} display_status={displaySatus} />
							{displaySatus === OfferDisplayStatusConst.rerun ?
								<>
									<dt className="detail-info__title">{props.language === 'ja_JP' ? '見逃し配信期間' : 'Rerun Period'}</dt>
									<OfferDeliveryPeriod language={props.language} startTime={performanceEndDate} endTime={rerunEndDate} />
								</>
								: ''}
							{displaySatus === OfferDisplayStatusConst.archive ?
								<>
									<dt className="detail-info__title">{props.language === 'ja_JP' ? 'アーカイブ配信期間' : 'Archive Period'}</dt>
									<OfferDeliveryPeriod language={props.language} startTime={archiveStartDate} endTime={archiveEndDate} />
								</>
								: ''}
							<dt className="detail-info__title">{props.language === 'ja_JP' ? '出演' : 'Performer'}</dt>
							<OfferPerformer performer={performer} />
							<dt className="detail-info__title">{props.language === 'ja_JP' ? '曲目' : 'Program'}</dt>
							<OfferProgram program={program} />
							<OfferRecommendBrowserSp language={props.language} settingJson={settingJson}/>
						</dl>
						<OfferFreeText language={props.language} freeText={free_text} />
						<OfferOfficialSite official_site_url={official_site_url} language={props.language} />
						{displaySatus === OfferDisplayStatusConst.normal && rerunFlg ?
							<>
								<dt className="detail-info__title">{props.language === 'ja_JP' ? '見逃し配信期間' : 'Rerun Period'}</dt>
								<OfferDeliveryPeriod language={props.language} startTime={performanceEndDate} endTime={rerunEndDate} />
							</>
							: ''}
						{displaySatus === OfferDisplayStatusConst.normal && archiveFlg ?
							<>
								<dt className="detail-info__title">{props.language === 'ja_JP' ? 'アーカイブ配信期間' : 'Archive Period'}</dt>
								<OfferDeliveryPeriod language={props.language} startTime={archiveStartDate} endTime={archiveEndDate} />
							</>
							: ''}
						<OfferSponsors organizer={organizer} co_organizer={co_organizer} support={support} special_co_operation={special_co_operation} support_url={support_url} special_co_operation_url={special_co_operation_url} support_logo_url={support_logo_url} special_co_operation_logo_url={special_co_operation_logo_url} language={props.language} organizer_en={organizer_en} co_organizer_en={co_organizer_en} support_en={support_en} special_co_operation_en={special_co_operation_en} cooperation={cooperation} cooperation_en={cooperation_en} subsidize={subsidize} subsidize_en={subsidize_en} />
						<OfferAttention language={props.language} customize={props.customize} />
						{props.language === 'ja_JP' ? <div className="detail-body__attention-small">{jasrac}</div> : ''}
						{props.language === 'en_US' ? <div className="detail-body__attention-small">{jasrac_en}</div> : ''}
					</div>
					<aside className="detail-side">
						{props.offer ? <OfferPurchaseButton offerId={pianoOfferId} language={props.language} isSP={false} programState={programState} isLocationNG={isLocationNG(aclType, aclTarget)} /> : ''}
						<OfferDetailInfo language={props.language} purchase_end_time={purchase_end_time} pianoPrice={pianoPrice} isJapan={is_location_japan} is_ensemble_cancelled={is_ensemble_cancelled} foreign_currency={foreign_currency} display_status={displaySatus} archivePianoPrice={archivePianoPrice} archive_purchase_end_time={archivePurchaseEndTime} settingJson={settingJson}/>
					</aside>
				</div>
			</div>
		</section>
	);
}

function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function isLocationNG(aclType, aclTarget) {
	if (aclType === 'whitelist') {
		if (aclTarget === 'JP') {
			let cookieLocation = Cookies.get('_pc_country');
			if (cookieLocation !== 'JP') {
				return true;
			}
		}
	} else if (aclType === 'blacklist') {
		if (aclTarget === 'DE') {
			let cookieLocation = Cookies.get('_pc_country');
			if (cookieLocation === 'DE') {
				return true;
			}
		}
	}
	return false;
}

const mapStateToProps = state => {
	return {
		offer: state.offer.singleOffer,
		language: state.piano.language,
		loggedIn: state.piano.loggedIn,
		hash: state.offer.hash,
		key: state.offer.key,
		error: state.offer.error,
		err: state.offer.err,
		script: state.offer.script,
		script4K: state.offer.script4K,
		scriptMulti: state.offer.scriptMulti,
		scriptCaption: state.offer.scriptCaption,
		professionalDistributors: state.distributors.professionalDistributors,
	};
};

const mapDispatchToProps = dispatch => ({
	getSingleOffer: (isLoggedIn, offer_id, user_token) => dispatch(getSingleOffer(isLoggedIn, offer_id, user_token)),
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
