import React, { useEffect, useState } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from "react-router-dom"
import { logout } from "../actions/piano"
import { isFileExist } from "../common/CommonUtil"

function Unsubscribe(props) {
	const [unsubscribeLastConfirmIsOpen, setUnsubscribeLastConfirmIsOpen] = useState(false);
	const [unsubscribeCompleteIsOpen, setUnsubscribeCompleteIsOpen] = useState(false);
	const [unsubscribeFailedIsOpen, setUnsubscribeFailedIsOpen] = useState(false);
	const [unsubscribeWarningAgreeCheckIsDisable, setUnsubscribeWarningAgreeCheckIsDisable] = useState(true);
	const [unsubscribeMessageWarning, setUnsubscribeMessageWarning] = useState('');
	const [unsubscribeMessageLastConfirm, setUnsubscribeMessageLastConfirm] = useState('');
	const [unsubscribeMessageComplete, setUnsubscribeMessageComplete] = useState('');
	const [unsubscribeMessageFailed, setUnsubscribeMessageFailed] = useState('');
	const [unsubscribeWarningCheckBoxIsDisable, setUnsubscribeWarningCheckBoxIsDisable] = useState(false);

	const history = useHistory();
	const rootPath = '/';
	const myPagePath = '/mypage';
	const cookieGetKeyUtp = '__utp';

	const unsubscribeMessageWarning_jp = 'unsubscribeMessageWarning_jp.txt';
	const unsubscribeMessageWarning_en = 'unsubscribeMessageWarning_en.txt';
	const unsubscribeMessageLastConfirm_jp = 'unsubscribeMessageLastConfirm_jp.txt';
	const unsubscribeMessageLastConfirm_en = 'unsubscribeMessageLastConfirm_en.txt';
	const unsubscribeMessageComplete_jp = 'unsubscribeMessageComplete_jp.txt';
	const unsubscribeMessageComplete_en = 'unsubscribeMessageComplete_en.txt';
	const unsubscribeMessageFailed_jp = 'unsubscribeMessageFailed_jp.txt';
	const unsubscribeMessageFailed_en = 'unsubscribeMessageFailed_en.txt';
	
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
		// 各画面及びポップアップ文言を表示する
		setMessage();
	}, []);

	useEffect(() => {
		// メッセージ切り替え
		setMessage();
	}, [props.language]);

	useEffect(() => {
		// メッセージ切り替え
		setMessage();
		let unsubscribeWarningCheckBox = document.getElementById('unsubscribe-confirm');
		if (unsubscribeWarningCheckBox.checked) {
			unsubscribeWarningCheckBox.checked = false;
			//退会するボタンを非活性にする。
			setUnsubscribeWarningAgreeCheckIsDisable(true);
		}
	}, [props.language]);

	const setMessage = () => {
		getUnsubscribeMessageWarning();
		getUnsubscribeMessageLastConfirm();
		getUnsubscribeMessageComplete();
		getUnsubscribeMessageFailed();
	}

	// 退会注意事項ファイルからメッセージを取得
	const getUnsubscribeMessageWarning = () => {
		var fileName = unsubscribeMessageWarning_en;
		var errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_WARNING_EN;
		if (props.language === 'ja_JP') {
			fileName = unsubscribeMessageWarning_jp;
			errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_WARNING_JP;
		}
		var url = process.env.REACT_APP_UNSUBSCRIBE_MESSAGE_DIR_URL + fileName;
		// ファイル存在チェック
		let FileExistFlag = isFileExist(url);

		fetch(url)
			.then(res => res.text())
			.then(
				(text) => {
					if (FileExistFlag) {
						setUnsubscribeMessageWarning(text);
						setUnsubscribeWarningCheckBoxIsDisable(false);
					} else {
						setUnsubscribeMessageWarning(errMsg);
						setUnsubscribeWarningCheckBoxIsDisable(true);
					}
				})
			.catch(error => {
				console.log(error);
			})
	}

	// 退会最終確認ファイルからメッセージを取得
	const getUnsubscribeMessageLastConfirm = () => {
		var fileName = unsubscribeMessageLastConfirm_en;
		var errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_LAST_CONFIRM_EN;
		if (props.language === 'ja_JP') {
			fileName = unsubscribeMessageLastConfirm_jp;
			errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_LAST_CONFIRM_JP;
		}
		var url = process.env.REACT_APP_UNSUBSCRIBE_MESSAGE_DIR_URL + fileName;
		// ファイル存在チェック
		let FileExistFlag = isFileExist(url);

		fetch(url)
			.then(res => res.text())
			.then(
				(text) => {
					if (FileExistFlag) {
						setUnsubscribeMessageLastConfirm(text);
					} else {
						setUnsubscribeMessageLastConfirm(errMsg);
					}
				})
	}

	// 退会処理完了ファイルからメッセージを取得
	const getUnsubscribeMessageComplete = () => {
		var fileName = unsubscribeMessageComplete_en;
		var errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_COMPLETE_EN;
		if (props.language === 'ja_JP') {
			fileName = unsubscribeMessageComplete_jp;
			errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_COMPLETE_JP;
		}
		var url = process.env.REACT_APP_UNSUBSCRIBE_MESSAGE_DIR_URL + fileName;
		// ファイル存在チェック
		let FileExistFlag = isFileExist(url);

		fetch(url)
			.then(res => res.text())
			.then(
				(text) => {
					if (FileExistFlag) {
						setUnsubscribeMessageComplete(text);
					} else {
						setUnsubscribeMessageComplete(errMsg);
					}
				})
	}

	// 退会処理失敗ファイルからメッセージを取得
	const getUnsubscribeMessageFailed = () => {
		var fileName = unsubscribeMessageFailed_en;
		var errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_FAILED_EN;
		if (props.language === 'ja_JP') {
			fileName = unsubscribeMessageFailed_jp;
			errMsg = process.env.REACT_APP_UNSUBSCRIBE_DEFAULT_MESSAGE_FAILED_JP;
		}
		var url = process.env.REACT_APP_UNSUBSCRIBE_MESSAGE_DIR_URL + fileName;
		// ファイル存在チェック
		let FileExistFlag = isFileExist(url);

		fetch(url)
			.then(res => res.text())
			.then(
				(text) => {
					if (FileExistFlag) {
						setUnsubscribeMessageFailed(text);
					} else {
						setUnsubscribeMessageFailed(errMsg);
					}
				})
	}

	//退会注意事項画面:チェックボックス
	const onChangeUnsubscribeWarningAgreeCheck = () => {
		if (unsubscribeWarningAgreeCheckIsDisable) {
			//退会するボタンを活性にする。
			setUnsubscribeWarningAgreeCheckIsDisable(false);
		} else {
			//退会するボタンを非活性にする。
			setUnsubscribeWarningAgreeCheckIsDisable(true);
		}

	}

	//退会注意事項画面:退会するボタンクリック
	const onClickUnsubscribeWarningDoUnsubscribe = () => {
		//退会最終確認ポップアップを開く
		setUnsubscribeLastConfirmIsOpen(true);
	}

	//退会最終確認ポップアップ:退会しないボタンクリック
	const onClickUnsubscribeLastConfirmDoNotUnsubscribe = () => {
		//退会最終確認ポップアップを閉じる
		setUnsubscribeLastConfirmIsOpen(false);
	}
	//退会最終確認ポップアップ：退会するボタンクリック
	const onClickUnsubscribeLastConfirmDoUnsubscribe = () => {
		let utp = getCookie(cookieGetKeyUtp);
		let params = new URLSearchParams()
		params.append("jwt",utp)
		axios
			.post(
				process.env.REACT_APP_API_DIR_URL + `v1/user/delete`,params
			)
			.then(() => {
				//正常レスポンス
				props.logout(false);
				//退会最終確認ポップアップを閉じる
				setUnsubscribeLastConfirmIsOpen(false);
				//退会完了ポップアップを表示する
				setUnsubscribeCompleteIsOpen(true);

			})
			.catch(() => {
				//異常レスポンス
				//退会最終確認ポップアップを閉じる
				setUnsubscribeLastConfirmIsOpen(false);
				//退会処理失敗ポップアップを表示する
				setUnsubscribeFailedIsOpen(true);
			});
	}
	//退会完了ポップアップ:OKボタンクリック
	const onClickUnsubscribeCompleteOK = () => {
		//退会完了ポップアップを閉じる
		setUnsubscribeCompleteIsOpen(false);
		// トップページに遷移する
		history.push(rootPath);
	}

	//退会処理失敗ポップアップ:OKボタンクリック
	const onClickUnsubscribeFailedOK = () => {
		//退会完了ポップアップを閉じる
		setUnsubscribeCompleteIsOpen(false);
		// マイページに遷移する
		history.push(myPagePath);
	}

	function getCookie(cname) {
		let name = cname + '=';
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return '';
	}


	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="unsubscribe" id="unsubscribe-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						h3.common-title {padding-top: 1rem; font-size: 1.2rem; line-height:2rem; }
						pre{ white-space: pre-wrap; }
						`}
					</style>
					<div className="unsubscribe__inner">

						<h1 className="common-title">退会</h1>

						<section className="unsubscribe-section">
							<h2 className="unsubscribe-title">退会に関する内容をご確認の上、退会される場合は「上記内容に同意する」にチェックを入れて「退会する」ボタンをクリックしてください。</h2>
							<div className="unsubscribe--textbox">
								<pre>
									{unsubscribeMessageWarning}
								</pre>
							</div>
							<div className="unsubscribe--confirm">
								<input type="checkbox" id="unsubscribe-confirm" disabled={unsubscribeWarningCheckBoxIsDisable} onChange={onChangeUnsubscribeWarningAgreeCheck} /><label htmlFor="unsubscribe-confirm">上記内容に同意する</label>
							</div>
							<div className="unsubscribe--footer">
								<button className="normal-button" type="button" disabled={unsubscribeWarningAgreeCheckIsDisable} onClick={onClickUnsubscribeWarningDoUnsubscribe} >退会する</button>
							</div>
						</section>

					</div>
					{unsubscribeLastConfirmIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_LAST_CONFIRM_JP}</h2>
										<pre>
											{unsubscribeMessageLastConfirm}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeLastConfirmDoNotUnsubscribe} >退会しない</button>
										<button className="normal-button attention" onClick={onClickUnsubscribeLastConfirmDoUnsubscribe} >退会する</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}
					{unsubscribeCompleteIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_COMPLETE_JP}</h2>
										<pre>
											{unsubscribeMessageComplete}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeCompleteOK}>OK</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}
					{unsubscribeFailedIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_FAILED_JP}</h2>
										<pre>
											{unsubscribeMessageFailed}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeFailedOK}>OK</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}

				</section>
			) : (
				<section className="unsubscribe" id="unsubscribe-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						h3.common-title {padding-top: 1rem; font-size: 1.2rem; line-height:2rem; }
						pre{ white-space: pre-wrap; }
						`}
					</style>
					<div className="unsubscribe__inner">

						<h1 className="common-title">Unsubscribe</h1>

						<section className="unsubscribe-section">
							<h2 className="unsubscribe-title">Please check the "I agree to the above" box and click the "Unsubscribe" button if you wish to cancel your membership.</h2>
							<div className="unsubscribe--textbox">
								<pre>
									{unsubscribeMessageWarning}
								</pre>
							</div>
							<div className="unsubscribe--confirm">
								<input type="checkbox" id="unsubscribe-confirm" disabled={unsubscribeWarningCheckBoxIsDisable} onChange={onChangeUnsubscribeWarningAgreeCheck} /><label htmlFor="unsubscribe-confirm">I agree to the above</label>
							</div>
							<div className="unsubscribe--footer">
								<button className="normal-button" type="button" disabled={unsubscribeWarningAgreeCheckIsDisable} onClick={onClickUnsubscribeWarningDoUnsubscribe} >Unsubscribe</button>
							</div>
						</section>

					</div>
					{unsubscribeLastConfirmIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_LAST_CONFIRM_EN}</h2>
										<pre>
											{unsubscribeMessageLastConfirm}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeLastConfirmDoNotUnsubscribe} >Cancel unsubscribe</button>
										<button className="normal-button attention" onClick={onClickUnsubscribeLastConfirmDoUnsubscribe} >Unsubscribe</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}
					{unsubscribeCompleteIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_COMPLETE_EN}</h2>
										<pre>
											{unsubscribeMessageComplete}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeCompleteOK}>OK</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}
					{unsubscribeFailedIsOpen ? (
						<React.Fragment>
							<div className="popup-overlay"></div>
							<section className="unsubscribe--popup">
								<div className="popup__base">
									<div className="popup__text">
										<h2 className="common-title">{process.env.REACT_APP_UNSUBSCRIBE_POPUP_TITLE_FAILED_EN}</h2>
										<pre>
											{unsubscribeMessageFailed}
										</pre>
									</div>
									<div className="popup__footer">
										<button className="normal-button" onClick={onClickUnsubscribeFailedOK}>OK</button>
									</div>
								</div>
							</section>
						</React.Fragment>
					) : (
						''
					)}
				</section>
			)}
		</>
	);
}



const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
	logout: isToTop => dispatch(logout(isToTop)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
