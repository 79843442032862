import {
    GET_CUSTOMIZE_REQUEST,
    GET_CUSTOMIZE_SUCCESS,
    GET_CUSTOMIZE_FAILURE
  } from "../actions/customize"

  const initialState = {
    isCustomizeFetching: false,
    customize: null
  }

  const customize = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_CUSTOMIZE_REQUEST:
            return {
                ...state,
                isCustomizeFetching: true,
                error: actions.error
            }
        case GET_CUSTOMIZE_SUCCESS:
            return { ...state, isCustomizeFetching: false, customize: actions.customize , error: actions.error}
        case GET_CUSTOMIZE_FAILURE:
            return { ...state, isCustomizeFetching: false, error: actions.error }
        default:
            return state
    }
  }

  export default customize