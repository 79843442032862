
export const initialize = () => {
    return (dispatch) => {
        document.getElementsByTagName("body")[0].classList.add("white-ui");

        // 言語変更を進出して色をリプレース
        let mutationObserver = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutationRecord) {
                for (let e of document.getElementsByClassName("langButton")) {
                    if (e.style.color === "rgb(237, 237, 237)"){
                        e.style.color = "rgb(33, 33, 33)";
                    }
                }
            });
        });
        // 初期ボタンの色入れ替え
        for (let e of document.getElementsByClassName("langButton")) {
            mutationObserver.observe(e, { attributes : true, attributeFilter : ['style'] });
            if (e.style.color === "rgb(237, 237, 237)"){
                e.style.color = "rgb(33, 33, 33)";
            }
        }

        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.target.classList.contains("white-ui-now")){
                    if (document.getElementById("myPage") === null){
                        document.getElementsByTagName("body")[0].classList.remove("white-ui");
                        //ボタンの色戻す
                        for (let e of document.getElementsByClassName("langButton")) {
                            mutationObserver.observe(e, { attributes : true, attributeFilter : ['style'] });
                            if (e.style.color === "rgb(33, 33, 33)"){
                                e.style.color = "rgb(237, 237, 237)";
                            }
                        }
                        mutationObserver.disconnect(); //切断
                    }
                }else{
                    entry.target.classList.add("white-ui-now")
                }
            }
        });
        const target = document.querySelector('#myPage');
        resizeObserver.observe(target);
    }
}