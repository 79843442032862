import React from 'react';

function TopInformation(props) {
	if (props.language === 'ja_JP') {
		return (
			<>
				<div className="information">
					<div className="information__inner">
						<div className="information__title">お知らせ</div>
						<ul className="information__items">
							{props.topics.topic_jp.map( ( _line, index) => {
								return (
									<li className="information__item" key={index}>
										<a className="information__item-link" href={_line.url} title="NEWS" target="_blank" rel="noreferrer">
											{_line.text}
										</a>
									</li>
								)
							})}
						</ul>
						{props.isPremium ? 
							props.informationUrl ?
								<a className="information__more-button" href={props.informationUrl} title="すべてのお知らせを見る" target="_blank" rel="noreferrer">
									すべてのお知らせを見る
								</a>
							: '' 
						:
							<a className="information__more-button" href="https://www.tokyo-harusai.com/news_jp/" title="すべてのお知らせを見る" target="_blank" rel="noreferrer">
								すべてのお知らせを見る
							</a>
						}
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="information">
					<div className="information__inner">
					<div className="information__title">Information</div>
					<ul className="information__items">
						{props.topics.topic_en.map((_line,index) => {
							return (
								<li className="information__item" key={index}>
									<a className="information__item-link" href={_line.url} title="NEWS" target="_blank" rel="noreferrer">
										{_line.text}
									</a>
								</li>
							)
						})}
					</ul>
					{props.isPremium ? 
							props.informationUrl ?
								<a className="information__more-button" href={props.informationUrl} title="View all NEWS" target="_blank" rel="noreferrer">
									View all NEWS
								</a>
							: '' 
						:
						<a className="information__more-button" href="https://www.tokyo-harusai.com/news_en/" title="View all NEWS" target="_blank" rel="noreferrer">
								View all NEWS
						</a>
					}
					</div>
				</div>
			</>
		);
	}
}

export default TopInformation;
