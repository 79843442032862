import React, { useState, useEffect } from 'react';

function OfferVenue(props) {
	let datest = new Date(props.startTime);
	let dateot = new Date(props.openTime);
	let dateots = new Date(props.openTime).toTimeString().slice(9);

	const [dayOfWeek, setDayOfWeek] = useState();
	useEffect(() => {
		let date = new Date(props.date);
		if (props.language === 'ja_JP') {
			setDayOfWeek(['日', '月', '火', '水', '木', '金', '土'][date.getDay()]);
		}
		if (props.language === 'en_US') {
			setDayOfWeek(
				['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
			);
		}
	}, [props]);
	return (
		<dd className="detail-info__text">
			{props.language === "ja_JP"
            	? `${datest.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${dayOfWeek}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} 開演  [ ${('0' + dateot.getHours()).slice(-2)}:${('0' + dateot.getMinutes()).slice(-2)} ${dateots} 配信開始 ]`
            	: ""}
        	{props.language === "en_US"
            	? `${datest.getFullYear()}/${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datest.getMonth()]}/${datest.getDate()} [${dayOfWeek}] ${('0' + datest.getHours()).slice(-2)}:${('0' + datest.getMinutes()).slice(-2)} Start  [ ${('0' + dateot.getHours()).slice(-2)}:${('0' + dateot.getMinutes()).slice(-2)} ${dateots} LiveStart ]`
            	: ""}<br/>
        	{props.venue}
		</dd>
	);
}

export default OfferVenue;
