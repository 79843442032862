import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import LoginLogoutForm from "./LoginLogoutForm"
import LanguageSwapButton from "./LanguageSwapButton"
import PremiumOfficialSiteButton from "./PremiumOfficialSiteButton"
import MyPageButton from "./MyPageButton"
import CustomizeFileConst from '../../common/CustomizeFileConst';
import { isFileExist } from "../../common/CommonUtil"


import { connect } from "react-redux"

function PremiunHeader(props) {
  const [mainLogoPass, setMainLogoPass] = useState('');
  const [officialUrl, setOfficialUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');

  const defaultMainLogoPass = `${process.env.PUBLIC_URL}/assets/img/common/logo-ja.png`

  useEffect(() => {
    //デフォルトのロゴのパスをセット
    setMainLogoPass(defaultMainLogoPass)
    if (props.customize) {
      setOfficialUrl(props.customize.official_url)
      setFacebookUrl(props.customize.facebook_url)
      setTwitterUrl(props.customize.twitter_url)

      //ロゴを取得する処理
      getMainLogo()
    }
  }, [props.customize]);

  const getMainLogo = () => {
    const url = props.customize.s3_path + CustomizeFileConst.MAIN_LOGO_JPEG;
    // ファイル存在チェック
    const FileExistFlag = isFileExist(url);
    if (FileExistFlag) {
      //S3に保存されているロゴのパスを設定
      setMainLogoPass(url)
    }
  }

  return (
    <header className="global-header">
      <div className="global-header__inner">
        <div className="global-header__logo">
          <a href="/" title="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO">
            <img
              src={mainLogoPass}
              alt="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO"
            />
          </a>
        </div>
        <nav className="global-header-navi">
          <div className="global-header-navi__pc">
            <LanguageSwapButton language={props.language} />
            <PremiumOfficialSiteButton language={props.language} officialUrl={officialUrl} facebookUrl={facebookUrl} twitterUrl={twitterUrl} />
            <MyPageButton isLoggedIn={props.loggedIn} language={props.language} />
            <LoginLogoutForm
              isLoggedIn={props.loggedIn}
              language={props.language}
              isSp={false}
            />
            {/*<ul className="login-logout tp-cloak">*/}
            {/*  <li className="show-if-logged-out">*/}
            {/*    <a href="javascript:void(0);" className="login-button tp-login">ログイン</a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            <LoginLogoutForm
              isLoggedIn={props.loggedIn}
              language={props.language}
              isSp={true}
            />
          </div>
        </nav>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => {
  return {
    language: state.piano.language,
    loggedIn: state.piano.loggedIn,
    customize: state.customize.customize,
  }
}

export default connect(mapStateToProps)(PremiunHeader)
