import React from "react"
import {Link} from "react-router-dom"

function MyPageButton(props) {
    if (props.isLoggedIn) {
        return (
            <>
                <Link className="mypage-link" to="/mypage" title="マイページ">
                    {props.language === "ja_JP" ? "マイページ" : "MyPage"}
                </Link>
            </>
        )
    } else {
        return ""
    }
}

export default MyPageButton
