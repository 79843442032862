export const LOGIN = "LOGIN"
export const LOGGEDIN = "LOGGEDIN"
export const LOGOUT = "LOGOUT"
export const LOGGEDOUT = "LOGGEDOUT"
export const REGISTER = "REGISTER"
export const INITIALIZE = "INITIALIZE"
export const LANGSWAP = "LANGSWAP"
export const LANGJP = "LANGJP"
export const LANGEN = "LANGEN"
export const PURCHASETICKET = "PURCHASETICKET"
export const SHOWMYACCOUNT = "SHOWMYACCOUNT"
export const SHOW_PASSWORD_RESET = "SHOW_PASSWORD_RESET"

export let uid

export const login = () => {
  return (dispatch) => {
    let tp = window.tp || []
    tp.push(["setUsePianoIdUserProvider", true])
    tp.push([
      "init",
      function () {
        tp.pianoId.show({
          screen: "login",
          loggedIn: function (data) {
            dispatch(loggedIn())
          },
          loggedOut: function () {
            console.log("user logged out")
          },
          loginSuccess: function () {
            //window.location.reload()
            window.location.reload()
          }
        })
      }
    ])
    return {
      type: LOGIN
    }
  }
}

export const loggedIn = () => {
  return {
    type: LOGGEDIN
  }
}

export const logout = (isToTop = true) => {
  let tp = window.tp || []
  tp.push(["setUsePianoIdUserProvider", true])
  //Pianoにログアウト後行う処理を登録する。
  tp.push(["addHandler", "logout", function () {
    if (isToTop) {
      window.location.href = "/";
    }
  }])
  tp.push([
    "init",
    function () {
      if (tp.pianoId.isUserValid()) tp.pianoId.logout()
    }
  ])
  return {
    type: LOGOUT
  }
}

export const loggedOut = () => {
  return {
    type: LOGGEDOUT
  }
}

export const register = () => {
  return {
    type: REGISTER
  }
}

export const initialize = () => {
  return (dispatch) => {
    // composerセットアップ
    let isSandBox = (process.env.REACT_APP_PIANO_SANDBOX === "true") ? true : false ;
    let isUseTinypassAccounts = (process.env.REACT_APP_PIANO_TINY_PASS_ACCOUNTS === "true") ? true : false ;
    let isDebug = (process.env.REACT_APP_PIANO_DEBUG === "true") ? true : false ;
    let isUsePianoIdUserProvider = (process.env.REACT_APP_PIANO_ID_USER_PROVIDER === "true") ? true : false ;

    let tp = window.tp || []
    
    tp.push(["setAid", process.env.REACT_APP_PIANO_AID])
    //   // Is application in sandbox?
    tp.push(["setSandbox", isSandBox])
    //   // Does application use Piano ID?
    tp.push(["setUseTinypassAccounts", isUseTinypassAccounts ])
    tp.push(["setDebug", isDebug ])
    tp.push(["setUsePianoIdUserProvider", isUsePianoIdUserProvider ])
    //   // Does application use Piano ID?
    if(!isSandBox) {
      tp.push(["setEndpoint", process.env.REACT_APP_PIANO_ENDPOINT]);
      tp.push(["setPianoIdUrl", process.env.REACT_APP_PIANO_ID_URL]);
    }
    tp.push(["setCookieDomain", process.env.REACT_APP_CUSTOM_COOKIE_DOMAIN])
    tp.push(["init", function () {
      tp.experience.init();
    }]);
    tp.push([
      "init",
      function () {
        //tp.experience.init()
        uid = "not set";
        if (tp.pianoId.isUserValid()) {
          uid = tp.pianoId.getUser().uid;
          document.cookie = "uid=" + uid;
          window.dataLayer.push(function() {
            this.set('uid', uid);
          })
          dispatch(loggedIn())
        } else {
          dispatch(loggedOut())
        }
        //send GA4 (all piano action)
        tp.setExcludedGAEvents({
          experienceExecute: false,
          meterActive: false,
          meterExpired: false,
          beforeBrowserEvent: false,
          setResponseVariable: false,
          startCheckout: false,
          showOffer: false,
          showTemplate: false,
          termSelected: false,
          promoApplied: false,
          promoInvalid: false,
          submitPayment: false,
          checkoutCustomEvent: false,
          checkoutStateChange: false,
          receiptDisplayed: false,
          checkoutSuccess: false,
          checkoutRestarted: false,
          checkoutError: false,
          checkoutClose: false,
          loginRequired: false,
          loginDisplayed: false,
          registrationDisplayed: false,
          loginSuccess: false,
          registrationSuccess: false,
          logout: false,
        });

        tp.setGA4Config({
          measurementId: process.env.REACT_APP_GA4_MEASURE_ID,
          eventParameters: {
              send_page_view: false,
          },
        });

        if (tp.pianoId.getLang() === "") {
          let pil = getCookie('__pil');
          if (pil === '') {
            document.cookie = '__pil=ja_JP; Max-Age=2592000; Domain=' + encodeURIComponent(process.env.REACT_APP_DOMAIN) + ';path=/';
          }
          tp.setLocale("ja_JP")
          tp.pianoId.updateLang("ja_JP")
        }

        if (tp.pianoId.getLang() === "en_US") {
          tp.setLocale("en_US")
          dispatch(langEN())
          tp.push(["setCustomVariable", "selectedLang","en_US"])
          tp.pianoId.updateLang("en_US")
          return
        }
        if (tp.pianoId.getLang() === "ja_JP") {
          tp.setLocale("ja_JP")
          dispatch(langJP())
          tp.push(["setCustomVariable", "selectedLang","ja_JP"])
          tp.pianoId.updateLang("ja_JP")
          return
        }
      }
    ])
    return {
      type: INITIALIZE
    }
  }
}

export const langSwap = () => {
  return (dispatch) => {
    let tp = window.tp || []
    tp.push([
      "init",
      function () {
        if (tp.pianoId.getLang() === "en_US") {
          tp.setLocale("ja_JP")
          tp.pianoId.updateLang("ja_JP")
          tp.push(["setCustomVariable", "selectedLang","ja_JP"])
          document.cookie = '__pil=ja_JP; Max-Age=2592000; Domain=' + encodeURIComponent(process.env.REACT_APP_DOMAIN) + ';path=/';
          dispatch(langJP())
          return
        }
        if (tp.pianoId.getLang() === "ja_JP") {
          tp.setLocale("en_US")
          tp.pianoId.updateLang("en_US")
          tp.push(["setCustomVariable", "selectedLang","en_US"])
          document.cookie = '__pil=en_US; Max-Age=2592000; Domain=' + encodeURIComponent(process.env.REACT_APP_DOMAIN) + ';path=/';
          dispatch(langEN())
          return
        }
      }
    ])
    return {
      type: LANGSWAP
    }
  }
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const langJP = () => {
  return {
    type: LANGJP
  }
}

export const langEN = () => {
  return {
    type: LANGEN
  }
}

export const purchaseTicket = (offer_id) => {
  let tp = window.tp || []
  tp.push(["setUsePianoIdUserProvider", true])
  tp.push([
    "init",
    function () {
      tp.pianoId.show({
        screen: 'login',
        loggedIn: function(data) {
          tp.offer.show({
            offerId: offer_id,
            templateId: process.env.REACT_APP_PIANO_TEMPLATE_ID
          });
        },
      });
    }
  ])
  return {
    type: PURCHASETICKET
  }
}

export const showMyAccount = () => {
  let tp = window.tp || []
  tp.push([
    "init",
    function () {
      tp.myaccount.show({
        displayMode: "inline",
        containerSelector: "#my-account"
      })
    }
  ])
  return {
    type: SHOWMYACCOUNT
  }
}

export const showPasswordReset = (query,gotoTopPage) => {
  let tp = window.tp || []
  tp.push(['init', function() {
    // Password can be reset only if user is anonymous
    if (!tp.user.isUserValid()) {
      // If URL has reset_token parameter
      if (query.reset_token !== undefined) {
        // Get value of the token
        let token = query.reset_token;
        // Present password reset form with the found token
        tp.pianoId.show({
          'resetPasswordToken': token, loggedIn: function () {
            // Once user logs in - refresh the page
            gotoTopPage();
          }
        });
      }
    }
  }]);
  return {
    type:SHOW_PASSWORD_RESET
  }
}