import React, { useEffect } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';

function Guide(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="guide" id="guide-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						h3.common-title {padding-top: 1rem; font-size: 1.2rem; line-height:2rem; }
						`}
					</style>
					<h1 className="common-title">ご利用ガイド</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#g1">視聴までの流れ</a></li>
      					<li className="qa-links__item"><a href="#g2">予約コードの利用</a></li>
{/*
						<li className="qa-links__item"><a href="#g3">アプリの利用方法</a></li>
*/}
						<li className="qa-links__item"><a href="#g4">動画再生プレイヤーの使い方</a></li>
						<li className="qa-links__item"><a href="#g5">お問い合わせ</a></li>
    				</ul>

					<h2 className="common-title" id="g1">視聴までの流れ</h2>
					<section className="guide-section">
						<h2 className="guide-title">STEP1 アカウント登録 (はじめて利用する方)</h2>
						<p className="guide-text">
							ネット席の購入には、アカウントの登録が必要です。
							<br />
							東京･春･音楽祭オンライン・チケットサービス（来場用チケット購入用のIDとパスワード）とは別になります。※登録料は無料です。<br/>
							※すでにアカウント登録を済ませている方はSTEP2へお進みください。
						</p>
						<h3 className="guide-title2">1. トップの右上にある「ログイン」ボタンをクリックしてください。</h3>
						<p className="guide-text">モバイルの場合は右上にある「&#8801;」を押して表示されるメニューから「ログイン」をタップしてください。</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide1-1.png" alt="ログインモーダル" />
							<img className="sp-only" src="/assets/img/guide/guide1-1.png" alt="ログインモーダル" />
						</div>
						<p className="guide-text">ログインボタンの下にある「アカウントをお持ちでない方はこちらから登録」をクリックします。</p>
						<h3 className="guide-title2">2. お客様のアカウント情報を設定します。</h3>
						<ul className="guide-list">
							<li className="guide-list__item">姓</li>
							<li className="guide-list__item">名</li>
							<li className="guide-list__item">メールアドレス</li>
							<li className="guide-list__item">
								パスワード（半角英数を混ぜて8文字以上、大文字小文字含めて設定してください）
								<br />
								<span>※同じ文字の繰り返し・生年月日・車のナンバー・電話番号など、第三者に推測されやすい文字のご使用は避けてください。</span>
							</li>
							<li className="guide-list__item">生年月日</li>
							<li className="guide-list__item">性別</li>
							<li className="guide-list__item">お住まいの都道府県名</li>
							<li className="guide-list__item">電話番号</li>
						</ul>
						<p className="guide-text">
							 「ログイン状態を保持」にチェックすると24時間はログイン状態が保持されます。
							<br />
							<a href="/term_of_service" title="利用規約">
								利用規約
							</a>
							、
							<a href="/privacy_policy" title="プライバシーポリシー">
								プライバシーポリシー
							</a>
							に同意の上、東京･春･音楽祭のメーリングリストへのご登録を希望される場合はチェックしてください。
							<br />
							<br />
							お客様情報を入力後、「登録」ボタンをクリックすると、設定されたメールアドレスに本人確認のメールが送信されます。
							<br />
							送信されたメールに記載されているリンクをクリックして、本人確認を行うことで、アカウントの登録が完了します。
						</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP2 サンプル動画の再生確認</h2>
						<p className="guide-text">ネット席ご購入の前に、ご利用の環境で動画が再生できることをご確認ください。</p>
						<p className="guide-single-link">
							<a href="/test" title="再生確認ページ">
								<strong>再生確認ページはこちら</strong>
							</a>
						</p>
						<p>上記ページに記載されている再生環境をご確認いただき、動画が正常に再生されたことをご確認の上、購入に進んでください。</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP3 ネット席の購入</h2>
						<p className="guide-text">
							<a href="/" title="公演一覧">
								公演一覧
							</a>
							から購入したい公演をクリックすると詳細ページが表示されます。
						</p>
						<h3 className="guide-title2">1. 画像の右下にある「購入する」ボタンをクリックします。</h3>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-1.png" alt="「配信視聴チケットを購入する」ボタン" />
							<img className="sp-only" src="/assets/img/guide/guide3-1.png" alt="「配信視聴チケットを購入する」ボタン" />
						</div>
						<p className="guide-text">
							購入する公演のタイトルおよび開催日時が画面に表示されます。
							<br />
							<span className="guide-attention">※同名の公演が複数存在する場合があるため、お間違えのないようご注意ください。</span>
						</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-2.png" alt="購入する公演の名称および開催日時" />
							<img className="sp-only" src="/assets/img/guide/guide3-2.png" alt="購入する公演の名称および開催日時" />
						</div>
						<h3 className="guide-title2">2. 料金が表示されているボタンをクリックします。（まだ購入は完了しません）</h3>
						<p className="guide-text">公演タイトルと料金が表示されます。</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-3.png" alt="公演名と金額" />
							<img className="sp-only" src="/assets/img/guide/guide3-3.png" alt="公演名と金額" />
						</div>
						<h3 className="guide-title2">3. クレジットカードが登録されていない場合は、新しいクレジットカード情報を入力します。</h3>
						<p className="guide-text">以降、同じクレジットカードを利用してネット席をご購入される場合は、「次回の支払時のためにカードを保存」にチェックをしてください。</p>
						<p className="guide-text">登録済みのクレジットカードが有効期限を迎えた場合は再度登録が必要です。</p>
						<p className="guide-text">サンプル動画の再生確認欄にチェックを入れてください。<br />
							<span className="guide-attention">※ご購入前にサンプル動画が問題なく再生されることをご確認ください。</span></p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-4.png" alt="新しいクレジットカードを追加" />
							<img className="sp-only" src="/assets/img/guide/guide3-4.png" alt="新しいクレジットカードを追加" />
						</div>
						<h3 className="guide-title2">4. 確認ボタンをクリックします。</h3>
						<p className="guide-text">
							公演タイトル、開催日時、料金を確認します。
							<br />
							<span className="guide-attention"> ※お客様のご都合による変更・キャンセルは承りません。購入前に再度公演タイトル、開催日時および料金をご確認ください。</span>
						</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-5.png" alt="購入画面" />
							<img className="sp-only" src="/assets/img/guide/guide3-5.png" alt="購入画面" />
						</div>
						<h3 className="guide-title2">5. 購入ボタンをクリックすると、購入完了となります。</h3>
						<p className="guide-text">ご登録のメールアドレス宛に購入完了メールが届きますので、ご確認ください。</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP4 購入したチケットの確認</h2>
						<p className="guide-text">
							購入されたチケットは、右上の「
							<a href="/mypage" title="マイページ">
								マイページ
							</a>
							」ボタンをクリックして確認できます。
						</p>
						<p className="guide-text">モバイルの場合は右上にある「&#8801;」を押して表示されるメニューから「マイページ」をタップしてください。</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide4-1.png" alt="「マイページ」ボタン" />
							<img className="sp-only" src="/assets/img/guide/guide4-1.png" alt="「マイページ」ボタン" />
						</div>
						<p className="guide-text">マイページの「決済履歴」をクリックすると、購入済みの公演が表示されます。</p>
						<p className="guide-text">
							マイページでは
							<br />
							・カード情報の追加（カード情報の編集はできません）
							<br />
							・利用するカードの指定
							<br />
							・プロフィール（氏名、メールアドレス、パスワード）
							<br />
							などの情報を編集できます。
						</p>

						<p className="guide-text">
							 また、購入したチケットや購入に関するお問い合わせは「ヘルプ」ボタンをクリックして「問い合わせ」ボタンからお進みください。
							<br />
							 購入済みの公演に関する個別のお問い合わせも可能です。
						</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP5 ライブ・ストリーミング配信を視聴する</h2>
						<p className="guide-text">
							購入したチケットは、右上の「
							<a href="/mypage" title="マイページ">
								マイページ
							</a>
							」ボタンをクリックして確認できます。
						</p>
						<p className="guide-text">モバイルの場合は右上にある「&#8801;」を押して表示されるメニューから「マイページ」をタップしてください。</p>
						<p className="guide-text">
							<strong> ネット席を購入した公演の詳細ページを開きます。公演開始時間になると、写真が表示されている部分でライブ・ストリーミング配信が開始されます。</strong>
							<br />
							※時間になっても開始されない場合は、ブラウザの再読み込みボタンをクリックしてください。
						</p>
						<p className="guide-text guide-text--attention">
							<strong>配信は開催日時のみのライブ配信となっており、見逃し配信はございませんのでご注意ください。</strong>
							<br />
							<span>※お客様の都合で配信が視聴できなかった場合については、払い戻しは承りません。</span>
						</p>
					</section>

					<section className="guide-section">
						<h2 className="common-title" id="g2">予約コードの利用</h2>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 予約コード番号の準備</h2>
							<p className="guide-text">
							東京･春･音楽祭より予約コードを入手した場合は、公演の購入にご利用いただくことができます。<br/>
								予約コードは対象となる公演が限られているため、お間違えないようにお願いします。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 予約コードを使用して購入する</h2>
							<p className="guide-text">公演詳細ページで「購入ボタン」をクリックすると購入対象の公演タイトルが表示されます。</p>
							<p className="guide-text">右下の金額が表示されているボタンをクリックします。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-1.png" alt="予約コードの利用" />
								<img className="sp-only" src="/assets/img/guide/guide6-1.png" alt="予約コードの利用" />
							</div>
							<p className="guide-text">次に表示される画面の中央あたりに「予約コード」と表示されている場所があります。</p>
							<p className="guide-text">下の入力エリアにお持ちの予約コードを入力して「適用」ボタンをクリックします。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-2.png" alt="予約コードの利用" />
								<img className="sp-only" src="/assets/img/guide/guide6-2.png" alt="予約コードの利用" />
							</div>
							<p className="guide-text">正しい予約コードが入力されていると、表示が「XXXXXXXXXXXXXXXX が適用されます」と変わります。</p>
							<p className="guide-text">この時、公演情報が表示されている場所の金額が予約コード適用後の金額に変わっていることを必ずご確認ください。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-4.png" alt="予約コードの利用" />
								<img className="sp-only" src="/assets/img/guide/guide6-4.png" alt="予約コードの利用" />
							</div>
							<p className="guide-text">右下の「完了」ボタンをクリックすると予約コードでのご購入が完了します。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-5.png" alt="予約コードの利用" />
								<img className="sp-only" src="/assets/img/guide/guide6-5.png" alt="予約コードの利用" />
							</div>
						</section>
					</section>
{/*
					<section className="guide-section" id="guide-academy-program">
						<br />
						<br />
					</section>
					<section className="guide-section">
						<h1 className="common-title">アカデミー聴講プログラム／リハーサル 視聴方法</h1>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 アカウント登録</h2>
							<p className="guide-text">
								視聴するにはアカウント登録が必要です。
								<br />
								まずは
								<a href="#guide-basic" title="会員登録">
									こちら
								</a>
								の手順でアカウント登録を完了してください。※登録料は無料です。
								<br />
								アカウント登録がお済みの方は「 STEP2 視聴登録 」へお進みください。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 視聴登録</h2>
							<p className="guide-text">アカウントの登録とは別に配信映像を視聴するための視聴登録が必要となります。</p>
							<h3 className="guide-title2">1. アカデミー聴講プログラム／リハーサル視聴ページを表示します。</h3>
							<p className="guide-text">
								<a href="/muti/OFSFYJTY94XU" title="アカデミー聴講プログラム／リハーサル">
									アカデミー聴講プログラム／リハーサル視聴ページ
								</a>
							</p>
							<h3 className="guide-title2">2. 配信視聴の登録</h3>
							<p className="guide-text">登録前にページ内に記載されている内容をよくお読みになってからご登録をお願いします。</p>
							<p className="guide-text">視聴ページにある「登録する」ボタンをクリックし、視聴登録をしてください。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide5-1.png" alt="「登録する」ボタン" />
								<img className="sp-only" src="/assets/img/guide/guide5-1.png" alt="「登録する」ボタン" />
							</div>
							<h3 className="guide-title2">3. 配信映像の視聴</h3>
							<p className="guide-text">ボタンが「登録済み」となっているにも関わらず、視聴ページに掲載されている配信時間になっても再生が開始されない場合は、ブラウザの再読み込みボタンをクリックしてください。</p>
						</section>
					</section>
*/}
{/*
					<section className="guide-section">
						<h2 className="common-title" id="g3">アプリの利用方法</h2>
						<section className="guide-section">
							<p className="guide-text guide-text--attention">
								※既にアプリをインストールしている場合は、ご利用前にGoogle PlayもしくはApp Storeにて、アプリを最新版に更新していただく必要がございます。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 アカウント登録</h2>
							<p className="guide-text">
								アプリをご利用いただく前に、サイトでアカウント登録をしていただく必要があります。
								<br />
								アプリからは新規の会員登録はできません。<br/>
								お使いのパソコン又はスマートフォンのブラウザから 「東京･春･音楽祭 LIVE Streaming」のサイトにアクセスして会員登録をお願いします。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 アプリのダウンロード</h2>
							<h3>Androidをお使いの方</h3>
							<p className="guide-text">
								<a href="https://play.google.com/store" target="_blank" rel="noreferrer">Google Play ストア</a>にアクセスし「東京春祭」で検索してください。
								<br />
								もしくは<a href="https://play.google.com/store/apps/details?id=jp.harusailive.harusai_app" target="_blank" rel="noreferrer">こちら</a>からダウンロードページに遷移します。<br/>
								「インストール」ボタンをタップしてインストールしてください。
							</p>
							<h3>iPhone / iPad をお使いの方</h3>
							<p className="guide-text">
							<a href="https://appstore.com/" target="_blank" rel="noreferrer">App Storeアプリ</a>を立ち上げて、「東京春祭」で検索してください。
								<br />
								もしくは<a href="https://apps.apple.com/jp/app/itunes-connect/id1594997423" target="_blank" rel="noreferrer">こちら</a>からダウンロードページに遷移します。<br/>
								「インストール」ボタンをタップしてインストールしてください。
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP3 お支払い方法の指定</h2>
							<p className="guide-text">本アプリはアプリ内決済で公演チケットを購入するため、お使いのGoogle アカウント又はApple アカウントでお支払い方法を登録、選択してください。</p>
							<h3>Android の支払い方法の設定</h3>
							<p className="guide-text">
								アプリ内決済の設定は以下のページをご確認の上、設定してください。<br/>
								<a href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a>
							</p>
							<h3>iPhone / iPad をお使いの方</h3>
							<p className="guide-text">
								アプリ内決済の設定は以下のページをご確認の上、設定してください。<br />
								<a href="https://support.apple.com/ja-jp/HT201266" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
							<h3>Android 払い戻し</h3>
							<p className="guide-text">
								公演がキャンセルされた場合は以下のページから払い戻しの申請をしてください。<br/>
								申請していただかないと払い戻しはされません。<br/>
								<a href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">Android</a>
							</p>
							<h3>iOS 払い戻し</h3>
							<p className="guide-text">
								公演がキャンセルされた場合は以下のページから払い戻しの申請をしてください。<br/>
								申請していただかないと払い戻しはされません。<br />
								<a href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP4 アプリの利用</h2>
							<p className="guide-text">東京春祭というアプリがホーム画面に追加されているので、タップして起動してください。</p>
							<h3>画面説明：ホーム</h3>
							<p className="guide-text">
								春祭公式サイトに掲載されているニュース一覧をご覧いただけます。
								<br />
								ホーム画面でニュース詳細をご覧いただいた後は、右上の家の形をしたボタンをタップしていただくと、お知らせ一覧に戻ります。
							</p>
							<h3>画面説明：配信公演</h3>
							<p className="guide-text">
								配信される公演の一覧が表示されます。<br />
								詳細を見たい公演をタップすると公演の詳細情報をご覧いただけます。<br/>
								一覧に戻る場合は右上の「×」ボタンをタップしてください。
							</p>
							<h3>画面説明：購入済</h3>
							<p className="guide-text">
								購入された公演の一覧をご確認いただけます。<br />
								※表示にはログインする必要があります。
							</p>

							<h3>メニュー</h3>
							<p className="guide-text">左上の三本線のボタンをタップするとメニュー画面が表示されます。メニューを閉じる場合は画面上部にある「×」ボタンをタップしてください。</p>
							<ul className="guide-list">
								<li className="guide-list__item">
									<h4>ログイン</h4>
									<p className="guide-text">タップするとログインする画面が表示されるので、必要な情報を入力してログインしてください。<br/>
									※アプリではアカウントの新規登録はできません。ウェブサイトにブラウザでアクセスし、アカウントを作成してからご利用ください。</p>
								</li>
								<li className="guide-list__item">
									<h4>動画再生確認</h4>
									<p className="guide-text">お使いの機器で配信動画が再生可能かご確認いただけます。
									ネット席購入前に必ずこちらで動画が問題なく再生できることをご確認ください。</p>
								</li>
								<li className="guide-list__item">
									<h4>プライバシーポリシー</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用されるプライバシーポリシーをご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>個人情報の取り扱いについて</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用される個人情報の取り扱いに関する情報をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>特定商取引法に基づく表記</h4>
									<p className="guide-text">本サービスに関する特定商取引法に関する情報をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>利用規約</h4>
									<p className="guide-text">本サービスをご利用いただくにあたって適用される利用規約をご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>ご利用ガイド</h4>
									<p className="guide-text">本サービスのご利用方法やアプリの使い方などをご覧いただけます。</p>
								</li>
								<li className="guide-list__item">
									<h4>よくあるご質問</h4>
									<p className="guide-text">お問い合わせの多い質問事項をご覧いただけます。</p>
								</li>
							</ul>
						</section>
					</section>
*/}
					<section className="guide-section">
						<h2 className="common-title" id="g4">動画再生プレイヤーの使い方</h2>
						<section className="guide-section">
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								「東京･春･音楽祭 LIVE Streaming 2024」では、新しい体験を提供するために専用のプレイヤーで公演をお楽しみいただけます。<br/>
							</p>
							<h3 className="guide-title2"><a href="#g4-p1">見たいところを拡大して視聴</a></h3>
							<h3 className="guide-title2"><a href="#g4-p2">拡大してカメラを移動</a></h3>
							<h3 className="guide-title2"><a href="#g4-p3">曲目をいつでも確認</a></h3>
							<h3 className="guide-title2"><a href="#g4-p4">字幕の表示・非表示を選択</a></h3>
							<h3 className="guide-title2"><a href="#g4-p5">映像解像度の変更</a></h3>
						</section>
						<section className="guide-section" id="g4-p1">
							<h2 className="guide-title">拡大機能</h2>
							<h3>拡大機能をオンにする</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1-1.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1-1.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								右上にある拡大ボタンをクリックすると、拡大ボタンが青になり拡大機能がオンになります。<br/>
								オフにする場合はもう一度クリックしてください。
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1-2.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1-2.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								そのままお好きなところをクリックするとクリックされたあたりが拡大され、より大きく見たいところを見ることができます。<br/>
								※拡大できる回数は公演によって異なる場合があります。
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-2.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-2.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								拡大ボタンの下にあるバーを操作することでも拡大したり元に戻したりすることができます。
							</p>
							<h3>拡大を解除する</h3>
							<p className="guide-text">
								元の状態に戻したい場合は、拡大ボタンの左にある「拡大解除」ボタンをクリックしてください。
							</p>
						</section>
						<section className="guide-section" id="g4-p2">
							<h2 className="guide-title">カメラの移動</h2>
							<p className="guide-text">最大まで拡大した後、視点を自由に動かすことができます。</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-8.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-8.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								マウスカーソルが手のアイコンに変わっている時は視点を動かすことができます。<br/>
								ドラッグしながらお好きなところに視点を動かしてお楽しみいただけます。
							</p>
							<h3>元の状態に戻す</h3>
							<p className="guide-text">
								元の状態に戻したい場合は、拡大ボタンの左にある「拡大解除」ボタンをクリックしてください。
							</p>
						</section>
						<section className="guide-section" id="g4-p3">
							<h2 className="guide-title">曲目の表示</h2>
							<p className="guide-text">動画をお楽しみいただきながら、いつでも気軽に曲目を確認することができます。</p>
							<h3>曲目を表示する</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-14.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-14.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								左上にある「曲目」ボタンをクリックしてください。
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-3.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-3.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								視聴している公演の曲目をご覧いただけます。<br/>
								※曲目をご覧いただく機能で、現在どの曲を演奏中かは表示されません。
							</p>
							<h3>曲目を閉じる</h3>
							<p className="guide-text">
								曲目が表示されているエリアの右上にある「×」ボタンをクリックしてください。
							</p>
						</section>
						<section className="guide-section" id="g4-p4">
							<h2 className="guide-title">字幕の表示</h2>
							<p className="guide-text">字幕つきの公演の場合は、動画上に字幕が表示されます。</p>
							<h3>字幕を表示する</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-11.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-11.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								右下にある「字幕」ボタンをクリックして、字幕の表示／非表示を選択できます。
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-11-2.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-11-2.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								字幕が表示されるのは字幕付き公演のみとなります。<br/>
								字幕が表示されない場合、視聴中の公演が字幕付きかどうかご確認ください。<br/>
								字幕付き公演にも関わらず字幕が表示されない場合、字幕がオンになっているかどうか念のためご確認いただき、ページを再度読み込みなおしてお試しください。
							</p>
						</section>
						<section className="guide-section" id="g4-p5">
							<h2 className="guide-title">映像解像度の変更</h2>
							<p className="guide-text">映像の画質を任意で選択することができます。</p>
							<h3>選択できる映像の解像度</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-15.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-15.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">
								右下にある歯車のボタンをクリックすると、選択できる映像の解像度を選択できます。
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-16.jpg" alt="プレイヤーの使い方" />
								<img className="sp-only" src="/assets/img/guide/guide-p-16.jpg" alt="プレイヤーの使い方" />
							</div>
							<p className="guide-text">数字が大きいほど解像度が高く、より高画質な映像をお楽しみいただけます。（※）</p>
							<p className="guide-text">※ 高解像度の映像を視聴される場合、お客様のインターネット接続環境によっては、映像が途中で止まったり音が途切れたりする可能性があります。</p>
							<p className="guide-text">通常は「自動」を選択していただければ、環境に応じて最適な解像度の映像をお楽しみいただけます。</p>
						</section>
						<section className="guide-section" id="g4-p6">
							<h2 className="guide-title">注意事項</h2>
							<p className="guide-text">※ 公演休憩中は拡大機能は利用できません。</p>
							<p className="guide-text">※ 休憩後、公演が再開しても拡大機能が利用できない場合は、ページを再度読み込んでください。</p>
							<p className="guide-text">※ 公演休憩中は映像の停止はできません。</p>
							<p className="guide-text">※ iPhoneでは解像度の変更はできません。</p>
						</section>
						<section className="guide-section">
							<a href="/test" title="動画再生確認">
								動画再生確認
							</a>
							ページでお試しいただけます。
						</section>
					</section>

					<section className="guide-section">
						<h2 className="common-title" id="g5">お問い合わせ</h2>
						<section className="guide-section">
							<h3>
								「東京･春･音楽祭 LIVE Streaming ヘルプセンター」<br/>
							</h3>
							<br/>
							<p className="guide-text">
							　TEL: 03-5205-4462　営業時間　月・水・金　12:00～19:00<br/>
							　※お問合せ受付期間2月16日(金)～4月21日(日)<br/>
							　※アカウントをお持ちの方はマイページのヘルプタブにある<br/>　　「問い合わせ」ボタンからもお問い合わせいただけます。（回答は営業時間内のみ）<br/>
							　※音楽祭期間中は、土・日・祝含め全日営業（12:00～21:00）<br/><br/>
							　4月22日(月)以降は電話によるお問い合わせは受け付けておりません。<br/>
							　お問い合わせはマイページのヘルプをご利用ください。<br/>
							</p>
						</section>
					</section>
				</section>
			) : (
				<section className="guide" id="guide-basic">
					<style jsx="true">
						{`
                        h2.common-title {padding-top: 3rem; font-size: 1.8rem; line-height:2rem; }
						h3.common-title {padding-top: 1rem; font-size: 1.2rem; line-height:2rem; }
						`}
					</style>
					<h1 className="common-title">User's Guide</h1>

    				<ul className="qa-links">
      					<li className="qa-links__item"><a href="#g1">How to watch the live streaming</a></li>
      					<li className="qa-links__item"><a href="#g2">Use coupon</a></li>
{/*
						<li className="qa-links__item"><a href="#g3">How to use the app</a></li>
*/}
						<li className="qa-links__item"><a href="#g4">How to use the video player</a></li>
						<li className="qa-links__item"><a href="#g5">Need help?</a></li>
    				</ul>

					<h2 className="common-title" id="g1">How to watch the live streaming</h2>
					<section className="guide-section">
						<h2 className="guide-title">STEP1 Account registration (for first-time users)</h2>
						<p className="guide-text">
							To purchase distribution tickets, you need to register an account.
							<br />
							This is separate from Spring Festival in Tokyo online ticketing service (ID and password for purchasing tickets for visitors).<br/>
							There is no registration fee.<br/>
							If you have already registered for an account, please proceed to STEP 2.
						</p>
						<h3 className="guide-title2">1. Click on the "Login" button in the upper right corner of the top page.</h3>
						<p className="guide-text">If you are on a mobile device, press &#8801; in the upper right corner and tap "Login" from the menu that appears.</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide1-1_en.png" alt="Login" />
							<img className="sp-only" src="/assets/img/guide/guide1-1_en.png" alt="Login" />
						</div>
						<p className="guide-text">Click the "Register here if you don't have an account" link below the login button.</p>
						<h3 className="guide-title2">2. Set up your account information.</h3>
						<ul className="guide-list">
							<li className="guide-list__item">Name</li>
							<li className="guide-list__item">
								Password (at least 8 characters, including upper and lower case letters)
								<br />
								<span>Please avoid using characters that can be easily guessed by a third party, such as repeating the same character, date of birth, car number, or phone number.</span>
							</li>
							<li className="guide-list__item">Birth date</li>
							<li className="guide-list__item">gender</li>
							<li className="guide-list__item">Name of prefecture where you live</li>
							<li className="guide-list__item">Phone number</li>
						</ul>
						<p className="guide-text">
							 If you check the "Keep me logged in" checkbox, your login status will be kept for 24 hours.
							<br />
							Please check if you agree <a href="/term_of_service" title="Terms of Use">
								Terms of Use
							</a>
							 and
							<a href="/privacy_policy" title="Privacy Policy">
								Privacy Policy
							</a>. Also if you wish to be added to the Spring Festival in Tokyo mailing list.
							<br />
							<br />
							After entering your information, click on the "Register" button, and a confirmation email will be sent to the email address you have set.
							<br />
							Click on the link in the email sent to you to verify your identity and complete the registration of your account.
						</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP2 Check the playback of the sample video.</h2>
						<p className="guide-text">Before purchasing a distribution ticket, please make sure that the video can be played in your environment.</p>
						<p className="guide-single-link">
							<a href="/test" title="playback">
								<strong>Click here for playback confirmation page</strong>
							</a>
						</p>
						<p>Please check the playback environment listed on the above page and confirm that the video plays properly before proceeding to purchase.</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP3 Purchase distribution tickets</h2>
						<p className="guide-text">
							Click on the performance you wish to purchase from the <a href="/" title="List of Performances">
								List of Performances
							</a> to see the detailed page.
						</p>
						<h3 className="guide-title2">1. Click the "Buy" button at the bottom right of the image.</h3>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-1_en.png" alt="Click on the Buy Streaming Ticket button." />
							<img className="sp-only" src="/assets/img/guide/guide3-1_en.png" alt="Click on the Buy Streaming Ticket button." />
						</div>
						<p className="guide-text">
							The title and date of the performance to be purchased will be displayed on the screen.
							<br />
							<span className="guide-attention">Please note that there may be more than one performance with the same name, so please be careful not to make a mistake.</span>
						</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-2_en.png" alt="Name and date of the performance to be purchased" />
							<img className="sp-only" src="/assets/img/guide/guide3-2_en.png" alt="Name and date of the performance to be purchased" />
						</div>
						<h3 className="guide-title2">2. Click on the button with the price displayed. (The purchase is not yet complete.)</h3>
						<p className="guide-text">The performance title and price will be displayed.</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-3_en.png" alt="Name and price of performance" />
							<img className="sp-only" src="/assets/img/guide/guide3-3_en.png" alt="Name and price of performance" />
						</div>
						<h3 className="guide-title2">3. If you do not have a credit card registered, enter your new credit card information.</h3>
						<p className="guide-text">If you wish to use the same credit card to purchase distribution tickets in the future, please check the "Save card for next payment" box.</p>
						<p className="guide-text">If your registered credit card has expired, you will need to register it again.</p>
						<p className="guide-text">Check the Playback confirmation box for the sample video.<br />
							<span className="guide-attention">Please make sure that the sample video plays without problems before purchasing.</span></p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-4_en.png" alt="Add a new credit card" />
							<img className="sp-only" src="/assets/img/guide/guide3-4_en.png" alt="Add a new credit card" />
						</div>
						<h3 className="guide-title2">4. Click the Confirm button.</h3>
						<p className="guide-text">
							Check the performance title, date and time, and price.
							<br />
							<span className="guide-attention"> Please note that no changes or cancellations will be accepted at your convenience. Please check the performance title, date and time, and price again before purchasing.</span>
						</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide3-5_en.png" alt="Purchase Screen" />
							<img className="sp-only" src="/assets/img/guide/guide3-5_en.png" alt="Purchase Screen" />
						</div>
						<h3 className="guide-title2">5. Click on the Purchase button to complete your purchase.</h3>
						<p className="guide-text">You will receive a purchase confirmation email to your registered email address.</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP4 Confirmation of purchased tickets</h2>
						<p className="guide-text">
							You can check your purchased tickets by clicking the "My Page" button in the upper right corner.
						</p>
						<p className="guide-text">If you are on a mobile device, press the &#8801; button in the upper right corner and tap "My Page" from the menu that appears.</p>
						<div className="guide-image">
							<img className="pc-only" src="/assets/img/guide/guide4-1_en.png" alt="My Page button" />
							<img className="sp-only" src="/assets/img/guide/guide4-1_en.png" alt="My Page button" />
						</div>
						<p className="guide-text">Click on "Payment History" in My Page to see the performances you have already purchased.</p>
						<p className="guide-text">
							In My Page, you can edit the following information:
							<br />
							- Add card information (card information cannot be edited)
							<br />
							- Specify the card to be used
							<br />
							- Profile (name, email address, password)
						</p>

						<p className="guide-text">
							 Also, if you have any questions about your purchased tickets or purchases, please click on the "Help" button and proceed to the "Contact Us" button.
							<br />
							 You can also make individual inquiries about performances you have already purchased.
						</p>
					</section>
					<section className="guide-section">
						<h2 className="guide-title">STEP5 Watch the live streaming feed.</h2>
						<p className="guide-text">
							You can check your purchased tickets by clicking the "My Page" button in the upper right corner.
						</p>
						<p className="guide-text">If you are on a mobile device, press the &#8801; button in the upper right corner and tap "My Page" from the menu that appears.</p>
						<p className="guide-text">
							<strong> Open the details page of the performance for which you have purchased a distribution ticket. At the start time of the performance, the live streaming will start in the area where the photo is displayed.</strong>
							<br />
							If it does not start when the time is up, click the Reload button on your browser.
						</p>
						<p className="guide-text guide-text--attention">
							<strong>Please note that there will be live streaming only on the date and time of the event, and there will be no missed streaming.</strong>
							<br />
							<span>No refunds will be made if the delivery cannot be viewed due to customer's circumstances.</span>
						</p>
					</section>

					<h2 className="common-title" id="g2">Use reservation code</h2>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 Prepare the reservation code</h2>
							<p className="guide-text">
								If you receive a reservation code from Spring Festival in Tokyo, you may use it to purchase a performance.<br/>
								The reservation code may be valid for a limited number of performances, so please make sure you have the right one.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 Purchase using a reservation code</h2>
							<p className="guide-text">Clicking the "Buy" button on the performance details page will display the performance title to be purchased.</p>
							<p className="guide-text">Click on the button with the amount in the lower right corner.</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-1_en.png" alt="Using reservation code" />
								<img className="sp-only" src="/assets/img/guide/guide6-1_en.png" alt="Using reservation code" />
							</div>
							<p className="guide-text">In the middle of the next screen that appears, there is a place where "reservation code" is displayed.</p>
							<p className="guide-text">Enter your coupon code in the input area below and click the "Apply" button.</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-2_en.png" alt="Using reservation code" />
								<img className="sp-only" src="/assets/img/guide/guide6-2_en.png" alt="Using reservation code" />
							</div>
							<p className="guide-text">When the correct coupon code has been entered, the display will change to "(Your Reservation Code) will be applied". </p>
							<p className="guide-text">At this time, <strong>please be sure to check that the amount in the place where the performance information is displayed has changed to the amount after the coupon is applied.</strong></p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-4_en.png" alt="Using coupon" />
								<img className="sp-only" src="/assets/img/guide/guide6-4_en.png" alt="Using coupon" />
							</div>
							<p className="guide-text">Click the "Done" button at the bottom right to complete your purchase with the coupon code.</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide6-5_en.png" alt="Using reservation code" />
								<img className="sp-only" src="/assets/img/guide/guide6-5_en.png" alt="Using reservation code" />
							</div>
						</section>

{/*					<section className="guide-section" id="guide-academy-program">
						<br />
						<br />
					</section>
					<section className="guide-section">
						<h1 className="common-title">How to watch - Academy Auditing Program / Rehearsal </h1>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 Account registration</h2>
							<p className="guide-text">
								You will need to register an account to watch.
								<br />
								Please follow <a href="#guide-basic" title="会員登録">
									こちら
								</a> procedure to complete your account registration. There is no registration fee.
								<br />
								If you have already registered for an account, please proceed to "STEP 2: Register to Watch".
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 Registration to watch</h2>
							<p className="guide-text">In addition to registering for an account, you will need to register to watch the streaming video.</p>
							<h3 className="guide-title2">1. Go to the Academy Auditing Program / Rehearsal Viewing page.</h3>
							<p className="guide-text">
								<a href="/muti/OFSFYJTY94XU" title="Academy Auditing Program / Rehearsal">
									Academy Auditing Program / Rehearsal
								</a>
							</p>
							<h3 className="guide-title2">2. Register to watch the distribution</h3>
							<p className="guide-text">Please read the information on the page carefully before registering.</p>
							<p className="guide-text">Click the "Register" button on the viewing page to register for viewing.</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide5-1.png" alt="Register" />
								<img className="sp-only" src="/assets/img/guide/guide5-1.png" alt="Register" />
							</div>
							<h3 className="guide-title2">3. Viewing the video distribution</h3>
							<p className="guide-text">If the button is set to "Registered," but playback does not start at the delivery time listed on the viewing page, click the Reload button on your browser.</p>
						</section>
					</section>
*/}
{/*
					<h2 className="common-title" id="g3">How to use the app</h2>
						<section className="guide-section">
							<p className="guide-text guide-text--attention">
								※If you have already installed the application, you need to update it to the latest version at the Google Play or the App Store before use.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP1 Account registration</h2>
							<p className="guide-text">
								Before using the app, you need to register for an account on this website.
								<br />
								You cannot register as a new member from this application.<br/>
								Please access the "Spring Festival in Tokyo LIVE Streaming" website from your computer or smartphone browser and register as a member.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP2 Download the app</h2>
							<h3>Android users:</h3>
							<p className="guide-text">
								Go to the <a href="https://play.google.com/store" target="_blank" rel="noreferrer"> Google Play Store</a>  and search for "Tokyo HARUSAI".
								<br />
								Or click <a href="https://play.google.com/store/apps/details?id=jp.harusailive.harusai_app" target="_blank" rel="noreferrer">here</a> to go to the download page.<br/>
								Tap the "Install" button to install the software.
							</p>
							<h3>For iPhone / iPad users:</h3>
							<p className="guide-text">
								Launch the <a href="https://appstore.com/" target="_blank" rel="noreferrer">App Store app</a> and search for "Tokyo HARUSAI".
								<br />
								Or click <a href="https://apps.apple.com/jp/app/itunes-connect/id1594997423" target="_blank" rel="noreferrer">here</a> to go to the download page.<br/>
								Tap the "Install" button to install the software.
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP3 Specify your payment method</h2>
							<p className="guide-text">This app uses in-app payments to purchase tickets for performances, so please register and select a payment method with your Google account or Apple account.</p>
							<h3>Android Payment Method Settings</h3>
							<p className="guide-text">
								Please refer to the following page to set up in-app payments.<br/>
								<a href="https://support.google.com/googleplay/answer/4646404?hl=ja&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">Android</a>
							</p>
							<h3>For iPhone / iPad users</h3>
							<p className="guide-text">
								Please refer to the following page to set up in-app payments.<br />
								<a href="https://support.apple.com/ja-jp/HT201266" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
							<h3>Refund for Android users.</h3>
							<p className="guide-text">
								If a performance is cancelled, please submit a request for refund on the following page.<br/>
								No refunds will be made without an application.<br/>
								<a href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">Android</a>
							</p>
							<h3>Refund for iOS users.</h3>
							<p className="guide-text">
								If a performance is cancelled, please submit a request for refund on the following page.<br/>
								No refunds will be made without an application.<br />
								<a href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">iPhone / iPad</a>
							</p>
						</section>
						<section className="guide-section">
							<h2 className="guide-title">STEP4 Using the app</h2>
							<p className="guide-text">An app called "Tokyo HARUSAI" has been added to your home screen, so tap it to launch it.</p>
							<h3>Screen Description: Home</h3>
							<p className="guide-text">
								You can see the list of news posted on the official website of the Spring Festival in Tokyo..
								<br />
								After viewing the news details on the home screen, tap the house-shaped button in the upper right corner to return to the list of announcements.
							</p>
							<h3>Screen explanation: Distribution performance</h3>
							<p className="guide-text">
								A list of performances to be distributed will be displayed.<br />
								Tap the performance you want to see in detail to see the detailed information of the performance.<br/>
								To return to the list, tap the "X" button in the upper right corner.
							</p>
							<h3>Screen Description: Purchased</h3>
							<p className="guide-text">
								You can check the list of purchased performances.<br />
								You must be logged in to view it.
							</p>

							<h3>Menu</h3>
							<p className="guide-text">Tap the three-line button in the upper left corner to display the menu screen. To close the menu, tap the "X" button at the top of the screen.</p>
							<ul className="guide-list">
								<li className="guide-list__item">
									<h4>Login</h4>
									<p className="guide-text">Tap on the screen to log in. Enter the necessary information and log in.<br/>
									The app does not allow you to register a new account. <br/>Please access the website with your browser and create an account before using the app.</p>
								</li>
								<li className="guide-list__item">
									<h4>Check video playback</h4>
									<p className="guide-text">You can check if the streaming video can be played on your device.
									Please make sure that the video can be played on your device before purchasing the distribution ticket.</p>
								</li>
								<li className="guide-list__item">
									<h4>Privacy Policy</h4>
									<p className="guide-text">You can view the privacy policy that applies to the use of this service.</p>
								</li>
								<li className="guide-list__item">
									<h4>Handling of Personal Information</h4>
									<p className="guide-text">Information regarding the handling of personal information applicable to the use of this service is available here.</p>
								</li>
								<li className="guide-list__item">
									<h4>Notation based on the Act on Specified Commercial Transactions</h4>
									<p className="guide-text">Information on the Act on Specified Commercial Transactions regarding this service is available here.</p>
								</li>
								<li className="guide-list__item">
									<h4>Terms of Use</h4>
									<p className="guide-text">You can view the terms of use that apply to the use of this service.</p>
								</li>
								<li className="guide-list__item">
									<h4>User's Guide</h4>
									<p className="guide-text">You can learn how to use this service and how to use the application.</p>
								</li>
								<li className="guide-list__item">
									<h4>Frequently Asked Questions</h4>
									<p className="guide-text">Here you will find the most frequently asked questions.</p>
								</li>
							</ul>
						</section>
*/}
					<section className="guide-section">
						<h2 className="common-title" id="g4">How to use the video player</h2>
						<section className="guide-section">
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								The "Spring Festival in Tokyo LIVE Streaming" will offer a new experience by allowing you to enjoy the performances on a dedicated player.<br/>
							</p>
							<h3 className="guide-title2"><a href="#g4-p1">- Zoom in on what you want to see.</a></h3>
							<h3 className="guide-title2"><a href="#g4-p2">- Move the camera</a></h3>
							<h3 className="guide-title2"><a href="#g4-p3">- Check the program list at any time</a></h3>
							<h3 className="guide-title2"><a href="#g4-p4">- Select to show or hide subtitles.</a></h3>
							<h3 className="guide-title2"><a href="#g4-p5">- Change video resolution</a></h3>
						</section>
						<section className="guide-section" id="g4-p1">
							<h2 className="guide-title">Magnification function</h2>
							<h3>Turn on the magnification function.</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1-1.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1-1.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								Click the magnify button in the upper right corner to turn the magnify button blue and turn on the magnify function.<br/>
								To turn it off, click it again.
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-1-2.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-1-2.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								If you click on the area you want, the area you clicked on will be enlarged and you will be able to see the area you want in a larger size.<br/>
								The number of times it can be enlarged may vary depending on the performance.
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-2.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-2.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								You can also zoom in and out by using the bar below the zoom button.
							</p>
							<h3>Reset the zoom.</h3><br/>
							<p className="guide-text">
								If you want to return to the original state, click the "Unmagnify" button to the left of the magnify button.
							</p>
						</section>
						<section className="guide-section" id="g4-p2">
							<h2 className="guide-title">Moving the camera</h2>
							<p className="guide-text">After zooming in to the maximum, you can move the viewpoint freely.</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-8.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-8.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								When the mouse cursor turns into a hand icon, you can move the viewpoint.<br/>
								You can drag and move your viewpoint to wherever you want.
							</p>
							<h3>Restore the original state</h3><br/>
							<p className="guide-text">
								If you want to return to the original state, click the "Unmagnify" button to the left of the magnify button.
							</p>
						</section>
						<section className="guide-section" id="g4-p3">
							<h2 className="guide-title">Display of program list</h2>
							<p className="guide-text">You can easily check the song titles at any time while enjoying the video.</p>
							<h3>Show program list</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-14.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-14.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								Click on the "Songs" button in the upper left corner.
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-3_en.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-3_en.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								You can view the song titles for the performance you are watching.<br/>
								This is a function that allows you to view the song titles, and does not display which song is currently playing.
							</p>
							<h3>Close the program list</h3><br/>
							<p className="guide-text">
								Click the "X" button in the upper right corner of the area where the song title is displayed.
							</p>
						</section>
						<section className="guide-section" id="g4-p4">
							<h2 className="guide-title">Displaying subtitles</h2>
							<p className="guide-text">For performances with subtitles, the subtitles will be displayed on the video.</p>
							<p className="guide-text">* Only Japanese subtitle is available.</p>
							<h3>Show subtitles</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-11.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-11.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								You can choose to show or hide subtitles by clicking the "Subtitle" button in the lower right corner.
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-11-2_en.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-11-2_en.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								Subtitles will only be displayed for performances with subtitles.<br/>
								If you cannot see the subtitles, please check if the performance you are watching has subtitles.<br/>
								If you cannot see the subtitles even though the performance is subtitled, please make sure that the subtitles are turned on and try reloading the page again.
							</p>
						</section>
						<section className="guide-section" id="g4-p5">
							<h2 className="guide-title">Change video resolution</h2>
							<p className="guide-text">Video quality can be selected as desired.</p>
							<h3>Selectable video resolution</h3>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-15.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-15.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">
								Click on the gear button in the lower right corner to select the video resolution you can choose from.
							</p>
							<div className="guide-image">
								<img className="pc-only" src="/assets/img/guide/guide-p-16.jpg" alt="How to use the video player" />
								<img className="sp-only" src="/assets/img/guide/guide-p-16.jpg" alt="How to use the video player" />
							</div>
							<p className="guide-text">The higher the number, the higher the resolution and the higher the quality of the video. (*)</p>
							<p className="guide-text">*  When viewing high-resolution video, depending on your Internet connection environment, there is a possibility that the video may stop midway or the sound may be interrupted.</p>
							<p className="guide-text">If you select "Auto" you can enjoy images at the optimum resolution for your environment.</p>
						</section>
						<section className="guide-section" id="g4-p6">
							<h2 className="guide-title">Notes</h2>
							<p className="guide-text">* The magnification function is not available during breaks in the performance.</p>
							<p className="guide-text">* If the magnification function is not available when the performance resumes after the break, please reload the page.</p>
							<p className="guide-text">* The video cannot be stopped during the intermission of the performance.</p>
							<p className="guide-text">* The resolution cannot be changed on the iPhone.</p>
						</section>
						<section className="guide-section">
							You can try it on the <a href="/test" title="Playback test">
								Video Playback Test
							</a>
							&nbsp;page.
						</section>
					</section>

					<section className="guide-section">
						<h2 className="common-title" id="g5">Need help?</h2>
						<section className="guide-section">
							<h3>
								[Spring Festival in Tokyo LIVE Streaming Help Center]<br/>
							</h3>
							<br/>
							<p className="guide-text">Please send message from help tab on <a href="/mypage" title="My page">my page.</a></p>
						</section>
					</section>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
