import React, { Component } from "react"
import PropTypes from "prop-types"

class SafesrcDocIframe extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    srcDoc: PropTypes.string.isRequired,
    sandbox: PropTypes.string,
    referrerPolicy: PropTypes.string,
    src: PropTypes.string
  }

  static defaultProps = {
    // set all restrictions for sandbox except same origin
    // to allow us to inject the safe guards.
    sandbox: "allow-same-origin",
    referrerPolicy: "no-referrer",
    // will be omitted from props passed to the iframe
    src: ""
  }

  constructor(...args) {
    super(...args)
    this.iframeElement = null
  }

  componentDidMount() {
    if (this.iframeElement) {
      this.iframeElement.onload = () => {
        this.applySafeguards()
      }
    }
  }

  componentWillUnmount() {
    this.disableStylesTextNode = null
  }

  applySafeguards() {
    if (!this.iframeElement.contentDocument) {
      return
    }
  }

  render() {
    const {
      title,
      referrerPolicy,
      sandbox,
      srcDoc,
      src: omit /* eslint-disable-line no-unused-vars */,
      ...rest
    } = this.props
    return (
      <iframe
        title={title}
        srcDoc={srcDoc}
        referrerPolicy={referrerPolicy}
        sandbox={sandbox}
        ref={(el) => {
          this.iframeElement = el
        }}
        {...rest}
      />
    )
  }
}

export default SafesrcDocIframe
