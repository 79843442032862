import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import CustomizeFileConst from '../common/CustomizeFileConst';
import Const from '../common/Const';
import { isFileExist, getFileDataText } from "../common/CommonUtil"

function PremiumPrivacyPolicy(props) {

	const [privacyPolicy, setPrivacyPolicy] = useState('');
	const [notFound, setNotFound] = useState(false)	;
	const [noData, setNoData] = useState(false);

	const ErrMsgJp = process.env.REACT_APP_GET_FILE_ERROR_MSG.replace(Const.wildcardString, "「プライバシーポリシー」");
	const ErrMsgEn = process.env.REACT_APP_GET_FILE_ERROR_MSG_EN.replace(Const.wildcardString, "\"Privacy Policy\" ");

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	useEffect(() => {
		if (props.customize) {
			//プライバシーポリシーのレイアウト情報を取得
			getPrivacyPolicy()
		}
	}, [props.customize, props.language]);

	const getPrivacyPolicy = async () => {
		if (props.customize) {
			let fileName = CustomizeFileConst.PRIVACY_POLICY_HTML_EN
			if (props.language === 'ja_JP') {
				fileName = CustomizeFileConst.PRIVACY_POLICY_HTML_JP
			}
			let url = props.customize.s3_path + fileName;
			// ファイル存在チェック
			const FileExistFlag = isFileExist(url);
			if (FileExistFlag) {
				//ファイルをテキスト形式で取得
				const data = await getTopics(url)
				if (Object.keys(data).length > 0) {
					setPrivacyPolicy(data)
				} else {
					setNoData(true);
				}
			} else {
				setNotFound(true);
			}
		}
	}

	const getTopics = async (url) => {
		try {
			const topics = await getFileDataText(url);
			return topics;
		} catch (error) {
			console.error(error);
		}
	}

	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="terms">
					<h1 className="common-title">プライバシーポリシー</h1>
					{notFound ? <p>{ErrMsgJp}</p> : ''}
					{noData ? '' : <section dangerouslySetInnerHTML={{ __html: privacyPolicy }} ></section>}
				</section>
			) : (
				<section className="terms">
					<h1 className="common-title">Privacy Policy</h1>
					{notFound ? <p>{ErrMsgEn}</p> : ''}
					{noData ? '' : <section dangerouslySetInnerHTML={{ __html: privacyPolicy }} ></section>}
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
		customize: state.customize.customize
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPrivacyPolicy);
