export const SettingConst = {
    DataValue: "value",
    DataValueEn: "valueEn",
    DataOmisstionValue: "omisstionValue",
    DataOmisstionValueEn: "omisstionValueEn",
    KeySetting: "setting",
    Tax: "Tax",
    WindowsOS:"WindowsOS",
    WindowsBrowser:"WindowsBrowser",
    MacOS:"MacOS",
    MacBrowser:"MacBrowser",
    AndroidOS:"AndroidOS",
    AndroidBrowser:"AndroidBrowser",
    iOS:"iOS",
    iOSBrowser:"iOSBrowser",
    iPadOS:"iPadOS",
    iPadOSBrowser:"iPadOSBrowser",
    KeyWindows: "Windows",
    KeyMac: "Mac",
    KeyAndroid: "Android",
    KeyiOS: "iOS",
    KeyiPad: "iPad"
}

export default SettingConst;