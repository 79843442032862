import Const from "./Const";
import { chain, ceil, round } from 'mathjs';
import SettingConst from "./SettingConst";

const httpStatus_200 = 200;
// ファイルの存在チェック
export const isFileExist = async(url) => {
    let ts = new Date().getTime();
    fetch(url + '?' + ts, {
        method: "HEAD",
      })
      .then(function(response) {
        return response.status;
      })
      .then(function(status) {
        return (status === httpStatus_200) ? true : false ;
      })
    /*
    let ts = new Date().getTime();
    var http = new XMLHttpRequest();
    http.open('HEAD', url + '?' + ts, false);
    http.send();
    return http.status === httpStatus_200;
    */
}

export const getFileData = async(url) => {
    let ts = new Date().getTime();
    const reponse = await fetch(url + '?' + ts);
    return await reponse.json();
}
//ファイルをテキスト形式で取得する
export const getFileDataText = async(url) => {
    let ts = new Date().getTime();
    const reponse = await fetch(url + '?' + ts);
    return await reponse.text();
}

// 文字列がnullかをチェックする
export const chkNull = (str) => {
    if (!str) {
        return true;
    }
    return false;
}

// 検索キーワードに含まれるURI禁止文字のエンコードを実施
export const encodeKeyword = (keyword) => {
    const searchType = "string"
    // キーワードに含まれる＃、スペース（全半角）をエンコードする
    if (typeof keyword == searchType) {
        if (keyword.includes(Const.sharp)) {
            // #のエンコード
            keyword = keyword.replaceAll(Const.sharp, encodeURIComponent(Const.sharp)) 
        }
        if (keyword.includes(Const.halfSpace)) {
            // 末尾のスペースはカットする
            if (keyword.slice(-1) === Const.halfSpace) {
                keyword = keyword.trimEnd()
            }
            // 半角スペースのエンコード
            keyword = keyword.replaceAll(Const.halfSpace, encodeURIComponent(Const.halfSpace)) 
        }
        if (keyword.includes(Const.Fullspace)) {
            // 末尾のスペースはカットする
            if (keyword.slice(-1) === Const.Fullspace) {
                keyword = keyword.trimEnd()
            }
            // 全角スペースのエンコード
            keyword = keyword.replaceAll(Const.Fullspace, encodeURIComponent(Const.Fullspace)) 
        }
    } 
    return keyword
}

export const getTaxPrice = (isJapan, price, tax) => {
	if (isJapan) {
		// 1.1をかけて、ceilで切り上げ
		let taxin_price = ceil(
			chain(price)
			.multiply(tax)
			.done());
		let one_digit = (taxin_price / 1) % 10;
		// 公演価格の切り上げ | 切り捨て
		if (one_digit == 9 || one_digit == 1) {
			taxin_price = round(taxin_price / 10) * 10;
		}
		return taxin_price.toLocaleString('ja');
	} else {
		// 海外はおそらく税抜き価格になる
		return price.toLocaleString('en-US');
	}
}

export const getTaxFromSettings = (json) => {
    for (const data of json) {
        if (data.key === SettingConst.Tax) {
            return Number(data[SettingConst.DataValue]);
        }
    }
}

export const getSystemRequirementsFromSettings = (json) => {
    let windowsOSVer
    let macOSVer
    let androidOSVer
    let iOSVer
    let iPadOSVer
    let windowsBrowserList = [];
    let windowsBrowserEnList = [];
    let windowsBrowserOmisstionList = [];
    let windowsBrowserOmisstionEnList = [];
    let macBrowserList = [];
    let macBrowserEnList = [];
    let macBrowserOmisstionList = [];
    let macBrowserOmisstionEnList = [];
    let androidBrowserList = [];
    let androidBrowserEnList = [];
    let androidBrowserOmisstionList = [];
    let androidBrowserOmisstionEnList = [];
    let iOSBrowserList = [];
    let iOSBrowserEnList = [];
    let iOSBrowserOmisstionList = [];
    let iOSBrowserOmisstionEnList = [];
    let iPadOSBrowserList = [];
    let iPadOSBrowserEnList = [];
    let iPadOSBrowserOmisstionList = [];
    let iPadOSBrowserOmisstionEnList = [];
    for (const data of json) {
        switch (data.key) {
            case SettingConst.WindowsOS:
                if (!chkNull(data[SettingConst.DataValue])) windowsOSVer = data[SettingConst.DataValue];
                break;
            case SettingConst.WindowsBrowser:
                if (!(chkNull(data[SettingConst.DataValue]) || chkNull(data[SettingConst.DataValueEn]) || chkNull(data[SettingConst.DataOmisstionValue]) || chkNull(data[SettingConst.DataOmisstionValueEn]))){
                        windowsBrowserList.push(data[SettingConst.DataValue]);
                        windowsBrowserEnList.push(data[SettingConst.DataValueEn]);
                        windowsBrowserOmisstionList.push(data[SettingConst.DataOmisstionValue]);
                        windowsBrowserOmisstionEnList.push(data[SettingConst.DataOmisstionValueEn]);
                }
                break;
            case SettingConst.MacOS:
                if (!chkNull(data[SettingConst.DataValue])) macOSVer = data[SettingConst.DataValue];
                break;
            case SettingConst.MacBrowser:
                if (!(chkNull(data[SettingConst.DataValue]) || chkNull(data[SettingConst.DataValueEn]) || chkNull(data[SettingConst.DataOmisstionValue]) || chkNull(data[SettingConst.DataOmisstionValueEn]))){
                        macBrowserList.push(data[SettingConst.DataValue]);
                        macBrowserEnList.push(data[SettingConst.DataValueEn]);
                        macBrowserOmisstionList.push(data[SettingConst.DataOmisstionValue]);
                        macBrowserOmisstionEnList.push(data[SettingConst.DataOmisstionValueEn]);
                }
                break;
            case SettingConst.AndroidOS:
                if (!chkNull(data[SettingConst.DataValue])) androidOSVer = data[SettingConst.DataValue];
                break;
            case SettingConst.AndroidBrowser:
                if (!(chkNull(data[SettingConst.DataValue]) || chkNull(data[SettingConst.DataValueEn]) || chkNull(data[SettingConst.DataOmisstionValue]) || chkNull(data[SettingConst.DataOmisstionValueEn]))){
                        androidBrowserList.push(data[SettingConst.DataValue]);
                        androidBrowserEnList.push(data[SettingConst.DataValueEn]);
                        androidBrowserOmisstionList.push(data[SettingConst.DataOmisstionValue]);
                        androidBrowserOmisstionEnList.push(data[SettingConst.DataOmisstionValueEn]);
                }
                break;
            case SettingConst.iOS:
                if (!chkNull(data[SettingConst.DataValue])) iOSVer = data[SettingConst.DataValue];
                break;
            case SettingConst.iOSBrowser:
                if (!(chkNull(data[SettingConst.DataValue]) || chkNull(data[SettingConst.DataValueEn]) || chkNull(data[SettingConst.DataOmisstionValue]) || chkNull(data[SettingConst.DataOmisstionValueEn]))){
                        iOSBrowserList.push(data[SettingConst.DataValue]);
                        iOSBrowserEnList.push(data[SettingConst.DataValueEn]);
                        iOSBrowserOmisstionList.push(data[SettingConst.DataOmisstionValue]);
                        iOSBrowserOmisstionEnList.push(data[SettingConst.DataOmisstionValueEn]);
                }
                break;
            case SettingConst.iPadOS:
                if (!chkNull(data[SettingConst.DataValue])) iPadOSVer = data[SettingConst.DataValue];
                break;
            case SettingConst.iPadOSBrowser:
                if (!(chkNull(data[SettingConst.DataValue]) || chkNull(data[SettingConst.DataValueEn]) || chkNull(data[SettingConst.DataOmisstionValue]) || chkNull(data[SettingConst.DataOmisstionValueEn]))){
                        iPadOSBrowserList.push(data[SettingConst.DataValue]);
                        iPadOSBrowserEnList.push(data[SettingConst.DataValueEn]);
                        iPadOSBrowserOmisstionList.push(data[SettingConst.DataOmisstionValue]);
                        iPadOSBrowserOmisstionEnList.push(data[SettingConst.DataOmisstionValueEn]);
                }
                break;
            default:
                break;
        }
    }
    // データが揃っている場合のみ、配列にして返却
    let response = {};
    if (windowsOSVer && windowsBrowserList.length > 0 && windowsBrowserEnList.length > 0 && windowsBrowserOmisstionList.length >0 && windowsBrowserOmisstionEnList.length > 0) {
        response[SettingConst.KeyWindows] = {os: windowsOSVer, browserList: windowsBrowserList, browserEnList: windowsBrowserEnList, omissionBrowserList: windowsBrowserOmisstionList, omissionBrowserEnList: windowsBrowserOmisstionEnList};
    }
    if (macOSVer && macBrowserList.length > 0 && macBrowserEnList.length > 0 && macBrowserOmisstionList.length >0 && macBrowserOmisstionEnList.length > 0) {
        response[SettingConst.KeyMac] = {os: macOSVer, browserList: macBrowserList, browserEnList: macBrowserEnList, omissionBrowserList: macBrowserOmisstionList, omissionBrowserEnList: macBrowserOmisstionEnList};
    }
    if (androidOSVer && androidBrowserList.length > 0 && androidBrowserEnList.length > 0 && androidBrowserOmisstionList.length >0 && androidBrowserOmisstionEnList.length > 0) {
        response[SettingConst.KeyAndroid] = {os: androidOSVer, browserList: androidBrowserList, browserEnList: androidBrowserEnList, omissionBrowserList: androidBrowserOmisstionList, omissionBrowserEnList: androidBrowserOmisstionEnList};
    }
    if (iOSVer && iOSBrowserList.length > 0 && iOSBrowserEnList.length > 0 && iOSBrowserOmisstionList.length >0 && iOSBrowserOmisstionEnList.length > 0) {
        response[SettingConst.KeyiOS] = {os: iOSVer, browserList: iOSBrowserList, browserEnList: iOSBrowserEnList, omissionBrowserList: iOSBrowserOmisstionList, omissionBrowserEnList: iOSBrowserOmisstionEnList};
    }
    if (iPadOSVer && iPadOSBrowserList.length > 0 && iPadOSBrowserEnList.length > 0 && iPadOSBrowserOmisstionList.length >0 && iPadOSBrowserOmisstionEnList.length > 0) {
        response[SettingConst.KeyiPad] = {os: iPadOSVer, browserList: iPadOSBrowserList, browserEnList: iPadOSBrowserEnList, omissionBrowserList: iPadOSBrowserOmisstionList, omissionBrowserEnList: iPadOSBrowserOmisstionEnList};
    }
    return response;
}