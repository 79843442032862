import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import CustomizeFileConst from '../../common/CustomizeFileConst';
import { isFileExist } from "../../common/CommonUtil"
import FileNameConst from "../../common/FileNameConst";



function PremiunFooter(props) {

	const [mainLogoPass, setMainLogoPass] = useState('');
	const [officialUrl, setOfficialUrl] = useState('');
	const [facebookUrl, setFacebookUrl] = useState('');
	const [twitterUrl, setTwitterUrl] = useState('');
	const [additionalUrl1, setAdditionalUrl1] = useState('');
	const [additionalTitle1, setAdditionalTitle1] = useState('');
	const [additionalTitle1En, setAdditionalTitle1En] = useState('');
	const [additionalUrl2, setAdditionalUrl2] = useState('');
	const [additionalTitle2, setAdditionalTitle2] = useState('');
	const [additionalTitle2En, setAdditionalTitle2En] = useState('');
	const [additionalTitle3, setAdditionalTitle3] = useState('');
	const [additionalUrl3, setAdditionalUrl3] = useState('');
	const [additionalTitle3En, setAdditionalTitle3En] = useState('');
	const [additionalTitle4, setAdditionalTitle4] = useState('');
	const [additionalUrl4, setAdditionalUrl4] = useState('');
	const [additionalTitle4En, setAdditionalTitle4En] = useState('');
	const [jasracImgPass, setJasracImgPass] = useState('');


	const defaultMainLogoPass = `${process.env.PUBLIC_URL}/assets/img/common/logo-ja.png`

	useEffect(() => {
		//イメージファイルをS3から取得する
		getJasracImg();
	}, []);

	useEffect(() => {
		//デフォルトのロゴのパスをセット
		setMainLogoPass(defaultMainLogoPass)
		if (props.customize) {
			setOfficialUrl(props.customize.official_url)
			setFacebookUrl(props.customize.facebook_url)
			setTwitterUrl(props.customize.twitter_url)
			//ロゴを取得する処理
			getMainLogo()
			setAdditionalInfo()
		}
	}, [props.customize]);

	const getMainLogo = () => {
		const url = props.customize.s3_path + CustomizeFileConst.MAIN_LOGO_JPEG;
		// ファイル存在チェック
		const FileExistFlag = isFileExist(url);
		if (FileExistFlag) {
			//S3に保存されているロゴのパスを設定
			setMainLogoPass(url)
		}
	}

	const getJasracImg = () => {
		// ファイル存在チェック
		const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.JASRAC_IMG);
		if (FileExistFlag) {
			setJasracImgPass(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.JASRAC_IMG);
		} else {
			//デフォルトイメージを取得する。
			setJasracImgPass(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.JASRAC_IMG);
		}
	}

	const setAdditionalInfo = () => {
		//URLをセット
		setAdditionalUrl1(props.customize.additional_url1)
		setAdditionalUrl2(props.customize.additional_url2)
		setAdditionalUrl3(props.customize.additional_url3)
		setAdditionalUrl4(props.customize.additional_url4)
		//日本語のタイトルをセット
		setAdditionalTitle1(props.customize.additional_title1)
		setAdditionalTitle2(props.customize.additional_title2)
		setAdditionalTitle3(props.customize.additional_title3)
		setAdditionalTitle4(props.customize.additional_title4)
		//英語のタイトルをセット
		setAdditionalTitle1En(props.customize.additional_title1_en)
		setAdditionalTitle2En(props.customize.additional_title2_en)
		setAdditionalTitle3En(props.customize.additional_title3_en)
		setAdditionalTitle4En(props.customize.additional_title4_en)
	}

	if (props.donePageLoading !== true) {
		return <p></p>;
	}
	return (
		<>
			<style jsx="true">
				{`
          			.footer-external {
						position: absolute;
						bottom: 60px;
						right: 40px;
						list-style: none;
					}
					.footer-external li {
						width: 60px;
						height: auto;
						float: right;
					}
					.footer-external li img {
						width: 100%;
					}
					.footer-external li p {
						font-size: 0.6rem;
						line-height: 1.2em;
					}
        		`}
			</style>
			{props.language === 'ja_JP' ? (
				<>
					<footer className="global-footer">
						<div className="global-footer__inner">
							<div className="global-footer__logo">
								<img src={mainLogoPass} alt="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO 2024" />

							</div>

							<div className="footer-links-wrapper">
								<ul className="footer-links">
									<li className="footer-links__item">
										<a href="/guide" title="ご利用ガイド">
											ご利用ガイド
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/test" title="動画再生確認">
											動画再生確認
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/faq" title="よくあるご質問">
											よくあるご質問
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/privacy_policy" title="プライバシーポリシー">
											プライバシーポリシー
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/personal_information" title="個人情報の取扱いについて">
											個人情報の取扱いについて
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/specified_commercial_transaction_law" title="特定商取引法に基づく表記">
											特定商取引法に基づく表記
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/term_of_service" title="利用規約">
											利用規約
										</a>
									</li>
								</ul>
								<ul className="footer-social">
									{officialUrl ?
										<li className="footer-links__item"><a href={officialUrl} target="_blank" rel="noreferrer" title="公式サイト">公式サイト</a></li>
										: ''
									}
									{additionalUrl1 && additionalTitle1 ?
										<li className="footer-links__item"><a href={additionalUrl1} target="_blank" rel="noreferrer" title={additionalTitle1}>{additionalTitle1}</a></li>
										: ''
									}
									{additionalUrl2 && additionalTitle2 ?
										<li className="footer-links__item"><a href={additionalUrl2} target="_blank" rel="noreferrer" title={additionalTitle2}>{additionalTitle2}</a></li>
										: ''
									}
									{additionalUrl3 && additionalTitle3 ?
										<li className="footer-links__item"><a href={additionalUrl3} target="_blank" rel="noreferrer" title={additionalTitle3}>{additionalTitle3}</a></li>
										: ''
									}
									{additionalUrl4 && additionalTitle4 ?
										<li className="footer-links__item"><a href={additionalUrl4} target="_blank" rel="noreferrer" title={additionalTitle4}>{additionalTitle4}</a></li>
										: ''
									}
									{facebookUrl ?
										<li className="footer-social__item footer-social__item--facebook">

											<a href={facebookUrl} title="Facebook" target="_blank" rel="noreferrer"><span>Facebook</span></a>
										</li>
										: ''
									}
									{twitterUrl ?
										<li className="footer-social__item footer-social__item--twitter">

											<a href={twitterUrl} title="Twitter" target="_blank" rel="noreferrer"><span>Twitter</span></a>
										</li>
										: ''
									}
								</ul>
								<ul className="footer-external">
									<li>
										<img src={jasracImgPass} alt="JASRAC" />
										<p></p>
									</li>
								</ul>
							</div>
							<p className="powerdby">IIJ Teatrista</p>
							<p className="copyright" />
						</div>
					</footer>
				</>
			) : (
				<>
					<footer className="global-footer">
						<div className="global-footer__inner">
							<div className="global-footer__logo">
								<img src={mainLogoPass} alt="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO 2024" />

							</div>

							<div className="footer-links-wrapper">
								<ul className="footer-links">
									<li className="footer-links__item">
										<a href="/guide" title="How to use">
											How to use
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/test" title="Video playback environment confirmation">
											Video playback environment confirmation
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/faq" title="FAQ">
											FAQ
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/privacy_policy" title="Privacy Policy">
											Privacy Policy
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/personal_information" title="Acquisition and use of personal information">
											Acquisition and use of personal information
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/specified_commercial_transaction_law" title="Specified Commercial Transaction Act">
											Specified Commercial Transaction Act
										</a>
									</li>
									<li className="footer-links__item">
										<a href="/term_of_service" title="Term of Service">
											Term of Service
										</a>
									</li>
								</ul>
								<ul className="footer-social">
									{officialUrl ?
										<li className="footer-links__item"><a href={officialUrl} target="_blank" rel="noreferrer" title="Official Site">Official Site</a></li>
										: ''
									}
									{additionalUrl1 && additionalTitle1En ?
										<li className="footer-links__item"><a href={additionalUrl1} target="_blank" rel="noreferrer" title={additionalTitle1En}>{additionalTitle1En}</a></li>
										: ''
									}
									{additionalUrl2 && additionalTitle2En ?
										<li className="footer-links__item"><a href={additionalUrl2} target="_blank" rel="noreferrer" title={additionalTitle2En}>{additionalTitle2En}</a></li>
										: ''
									}
									{additionalUrl3 && additionalTitle3En ?
										<li className="footer-links__item"><a href={additionalUrl3} target="_blank" rel="noreferrer" title={additionalTitle3En}>{additionalTitle3En}</a></li>
										: ''
									}
									{additionalUrl4 && additionalTitle4En ?
										<li className="footer-links__item"><a href={additionalUrl4} target="_blank" rel="noreferrer" title={additionalTitle4En}>{additionalTitle4En}</a></li>
										: ''
									}
									{facebookUrl ?
										<li className="footer-social__item footer-social__item--facebook">

											<a href={facebookUrl} title="Facebook" target="_blank" rel="noreferrer"><span>Facebook</span></a>
										</li>
										: ''
									}
									{twitterUrl ?
										<li className="footer-social__item footer-social__item--twitter">

											<a href={twitterUrl} title="Twitter" target="_blank" rel="noreferrer"><span>Twitter</span></a>
										</li>
										: ''
									}

								</ul>
								<ul className="footer-external">
									<li>
										<img src={jasracImgPass} alt="JASRAC" />
										<p></p>
									</li>
								</ul>
							</div>
							<p className="powerdby">IIJ Teatrista</p>
							<p className="copyright" />
						</div>
					</footer>
				</>
			)
			}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
		customize: state.customize.customize,
	};
};

export default connect(mapStateToProps)(PremiunFooter);
