function PremiumOfficialSiteButton(props) {
	if (props.language === 'ja_JP') {
		return (
			<>
				<a className="official-site-link" href="/guide" title="ご利用ガイド">
					ご利用ガイド
				</a>
				{props.officialUrl ?
					<a className="guide-link" href={props.officialUrl} title="公式サイト" target="_blank" rel="noreferrer">
						公式サイト
					</a>
					: ''
				}

				<ul className="header-social">
					{props.facebookUrl ?
						<li className="header-social__item header-social__item--facebook">
							<a href={props.facebookUrl} title="Facebook" target="_blank" rel="noreferrer" />
						</li>
						: ''
					}
					{props.twitterUrl ?
						<li className="header-social__item header-social__item--twitter">
							<a href={props.twitterUrl} title="Twitter" target="_blank" rel="noreferrer" />
						</li>
						: ''
					}
				</ul>
			</>
		);
	} else {
		return (
			<>
				<a className="official-site-link" href="/guide" title="How to use">
					How to use
				</a>
				{props.officialUrl ?
					<a className="guide-link" href={props.officialUrl} title="Official Site" target="_blank" rel="noreferrer">
						Official Site
					</a>
					: ''
				}
				<ul className="header-social">
					{props.facebookUrl ?
						<li className="header-social__item header-social__item--facebook">
							<a href={props.facebookUrl} title="Facebook" target="_blank" rel="noreferrer" />

						</li>
						: ''
					}
					{props.twitterUrl ?
						<li className="header-social__item header-social__item--twitter">
							<a href={props.twitterUrl} title="Twitter" target="_blank" rel="noreferrer" />
						</li>
						: ''
					}
				</ul>
			</>
		);
	}
}

export default PremiumOfficialSiteButton;
