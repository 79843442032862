export const MetaFieldConst = {
    performanceStartTime: 'performance_start_time',
    isPublicEnsemble: 'is_public_ensemble',
    performanceDate: 'performance_date',
    openTime: 'open_time', 
    venue: 'venue',
    performer: 'performer',
    program: 'program',
    purchaseStartTime: 'purchase_start_time',
    purchaseEndTime: 'purchase_end_time',
    officialSiteUrl: 'official_site_url',
    organizer: 'organizer',
    coOrganizer: 'co_organizer',
    support: 'support',
    specialCoOperation: 'special_co_operation',
    supportUrl: 'support_url',
    specialCoOperationUrl: 'special_co_operation_url',
    supportLogoUrl: 'support_logo_url',
    specialCoOperationLogoUrl: 'special_co_operation_logo_url',
    isEnsembleCancelled: 'is_ensemble_cancelled',
    cooperation: 'cooperation',
    cooperationEn: 'cooperation_en',
    subsidize: 'subsidize',
    subsidizeEn: 'subsidize_en',
    aclType: 'acl_type',
    aclTarget: 'acl_target',
    isMultipleAngles: 'is_multiple_angles',
    frameString: 'frame_string',
    performanceCancelledText: 'performance-cancelled-text',
    performanceCancelledUrl: 'performance-cancelled-url',
    freeText: 'free_text',
    thumCopyright: 'thum_copyright',
    surveyLinkText: 'survey_link_text',
    surveyLinkUrl: 'survey_link_url',
    jasrac: 'jasrac',
    pianoOfferId: 'piano_offer_id',
    titleEn: 'title_en',
    episodeEn: 'episode_en',
    seriesTitleEn: 'series_title_en',
    venueEn: 'venue_en',
    performerEn: 'performer_en',
    programEn: 'program_en',
    organizerEn: 'organizer_en',
    coOrganizerEn: 'co_organizer_en',
    supportEn: 'support_en',
    specialCoOperationEn: 'special_co_operation_en',
    frameStringEn: 'frame_string_en',
    performanceCancelledTextEn: 'performance-cancelled-text-en',
    performanceCancelledUrlEn: 'performance-cancelled-url-en',
    freeTextEn: 'free_text_en',
    jasracEn: 'jasrac-en',
    currencyEn: 'currency_en',
    isSpecialPerformance: 'is_special_performance',
    rerunFlg: 'rerun_flg',
    performanceEndDate: 'performance_end_date',
    archiveFlg: 'archive_flg',
    archiveStartDate: 'archive_start_date',
    archiveEndDate: 'archive_end_date',
    archivePurchaseStartTime: 'archive_purchase_start_time',
    archivePurchaseEndTime: 'archive_purchase_end_time',
    rerunEndDate: 'rerun_end_date'
}

export default MetaFieldConst;