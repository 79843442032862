import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';

function Refund(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);
	return (
		<>
			{props.language === 'ja_JP' ? (
				<section className="terms">
					<h1 className="common-title">開催中止公演のチケット代金の払い戻し手順</h1>
					<div className="terms-text rf-font-em">
						<p>購入に使用したサービスによって、お手続き方法が異なります。<br/>
						下記の１）～３）より、ご自身に該当する項目をご確認下さい。<br/><br/>
						ご使用したサービスが分からない場合は、お手数ですが【どのように購入したか分からない場合】に記載の手順でご確認ください。
						</p>
					</div>
					<section>
						<h2 className="terms-title2">１）Webサイトで購入した場合</h2>
						<div className="terms-text">
							<p>
								自動的に払戻しが行われますので、お客様によるお手続きは必要ありません。<br/>
								ご返金までには、約2週間お時間をいただきます。<br/>
								返金内容は、お支払いに使用したクレジットカードまたはApple Payの明細にてご確認ください。
							</p>
						</div>
{/*
						<h2 className="terms-title2">２）アプリで購入した場合（iPhone／iPad）</h2>
						<div className="terms-text">
							<p>
								必ず、購入から <strong>90日以内にお客様からの申請が必要</strong> です。<br/>
								申請がない場合はご返金手続きを進めることができませんのでご注意ください。<br/>
								お手続き方法は以下をご確認ください。<br/>
								<a className="local-link" href="https://support.apple.com/ja-jp/HT204084" target="_blank" rel="noreferrer">
									https://support.apple.com/ja-jp/HT204084
								</a>
							</p>
							<p>
								申請いただいた後、購入時に使った支払方法と同じお支払い方法で返金が行われます。<br/>
								ご返金までの所要時間は、お支払い方法によって異なります。<br/>
								個別の進捗状況は、<a className="local-link" href="https://reportaproblem.apple.com/" target="_blank" rel="noreferrer">こちらのページ</a>にてApple IDでログインいただき、「申請の状況を確認する」よりご確認いただけます。<br/>
								各お支払い方法の返金所用時間については<a className="local-link" href="https://support.apple.com/ja-jp/HT210904" target="_blank" rel="noreferrer">こちら</a>をご確認ください。
							</p>
						</div>
						<h2 className="terms-title2">３）アプリで購入した場合（Android）</h2>
						<div className="terms-text">
							<p>
								自動的に払戻しが行われますので、お客様によるお手続きは必要ありません。<br/>
								ご返金までの所要時間は、お支払い方法によって異なります。<br/>
								個別の進捗状況は、<a className="local-link" href="https://pay.google.com/" target="_blank" rel="noreferrer">こちらのページ</a>にGoogleアカウントでログインいただき、「ステータス」よりご確認いただけます。<br/>
								各お支払い方法の返金所用時間については<a className="local-link" href="https://support.google.com/googleplay/answer/2479637?hl=ja&ref_topic=2450225" target="_blank" rel="noreferrer">こちら</a>の後半部にある「払い戻しのスケジュール」をご確認ください。
							</p>
						</div>
*/}
						<h2 className="terms-title2">【どのように購入したか分からない場合】</h2>
						<div className="terms-text">
							<ol className="rf-list-ol">
								<li>Webブラウザにて「東京･春･音楽祭 LIVE Streaming 2024」のサイトを開き、ログインします。</li>
								<li>メニューより「マイページ」をクリックし、「ご契約内容」のタブにて、すべての購入済み公演をご確認いただけます。</li>
								<li>「決済履歴」タブを開きます。<br/>
									<ul className="rf-list-ul">
										<li>購入済みの公演のうち「決済履歴」に表示のあるものは、Webブラウザにて購入いただいた公演です。</li>
										{/*<li>購入済みの公演のうち「決済履歴」に表示のないものは、アプリにて購入いただいた公演です。</li>*/}
									</ul>
								</li>
							</ol>
						</div>
						<h2 className="terms-title2">【お問い合わせ】</h2>
						<div className="terms-text">
							<h3>「東京･春･音楽祭 LIVE Streaming ヘルプセンター」</h3>
							<p>
							　TEL: 03-5205-4462　営業時間　月・水・金　12:00～19:00<br/>
							　※お問合せ受付期間2月16日(金)～4月21日(日)<br/>
							　※アカウントをお持ちの方はマイページのヘルプタブにある「問い合わせ」ボタンからもお問い合わせいただけます。（回答は営業時間内のみ）<br/>
							　※音楽祭期間中は、土・日・祝含め全日営業（12:00～21:00）<br/><br/>
							　4月22日(月)以降は電話によるお問い合わせは受け付けておりません。お問い合わせはマイページのヘルプをご利用ください。<br/>
							</p>
						</div>
					</section>
				</section>
			) : (
				<section className="terms">
					<h1 className="common-title">Ticket refund procedure for cancelled performances.</h1>
					<section>
						<h2 className="terms-title2">Purchased on the website</h2>
						<div className="terms-text">
							<p>
								Refunds will be issued automatically, so there is no need for you to follow the procedure.<br/>
								Please allow approximately 2 weeks for your refund.<br/>
								Please check the credit card or Apple Pay statement used for payment for refund details.
							</p>
						</div>
						<h2 className="terms-title2">Inquiries</h2>
						<div className="terms-text">
							<h3>[Spring Festival in Tokyo LIVE Streaming Help Center]</h3>
							<p>
								　For inquiries, please use the help function on <a className="local-link" href="/mypage">My Page</a>.
							</p>
						</div>
					</section>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
