import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import FileNameConst from '../common/FileNameConst';
import { isFileExist } from "../common/CommonUtil";

function SpecifiedCommercialTransactionAct(props) {

    const [emailImgPass, setEmailImgPass] = useState('');

    useEffect(() => {
        props.setIsStreaming(false);
        props.donePageLoading(true);
        //メールアドレスのイメージファイルをS3から取得する
        getEmailImg();
    }, []);

    const getEmailImg = () => {
        // ファイル存在チェック
        const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.EMAIL_IMG);
        if (FileExistFlag) {
            setEmailImgPass(process.env.REACT_APP_S3_PASS_DEFAULT + FileNameConst.EMAIL_IMG);
        } else {
            //デフォルトイメージを取得する。
            setEmailImgPass(process.env.REACT_APP_DEFAULT_IMG_PASS + FileNameConst.EMAIL_IMG);
        }
    }
    return (
        <>
            <style jsx="true">
                {`
                .local-link { -webkit-font-smoothing: antialiased;
                font-family: 'Open Sans', 'Hiragino Kaku Gothic Pro', 'メイリオ', sans-serif;
                text-decoration: none;
                color: #da74a1;
                transition: color .5s ease-out;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px;
                letter-spacing: 0em;
                display: inline-block;}

                .local-img {
                    margin-top: 4px;
                }
                `}
            </style>
            {props.language === 'ja_JP' ? (
                <>
                    <section className="translaw">
                        <h1 className="common-title">特定商取引法に基づく表記</h1>

                        <dl className="translaw-list">
                            <dt className="translaw-title">販売業者</dt>
                            <dd className="translaw-text">株式会社インターネットイニシアティブ</dd>
                            <dt className="translaw-title">代表者</dt>
                            <dd className="translaw-text">代表取締役社長兼ＣＥＯ 勝 栄二郎</dd>
                            <dt className="translaw-title">販売責任者</dt>
                            <dd className="translaw-text">福田 一則</dd>
                            <dt className="translaw-title">所在地</dt>
                            <dd className="translaw-text">東京都千代田区富士見 ２－１０－２ 飯田橋グラン・ブルーム</dd>
                            <dt className="translaw-title">連絡先</dt>
                            <dd className="translaw-text">
                                <img className="local-img" src={emailImgPass} alt="" />
                                <br />
                                TEL : 03-5205-6480（受付時間 12:00-20:00）
                            </dd>
                            <dt className="translaw-title">商品等の名称、種類</dt>
                            <dd className="translaw-text">デジタルコンテンツ</dd>
                            <dt className="translaw-title">販売価格</dt>
                            <dd className="translaw-text">各商品毎に記載</dd>
                            <dt className="translaw-title">注文方法</dt>
                            <dd className="translaw-text">インターネットによる注文</dd>
                            <dt className="translaw-title">商品代金以外の費用</dt>
                            <dd className="translaw-text">サービスの利用（関連するアプリケーションのダウンロード等を含みます）に必要となる通信料金（インターネット使用料を含み、金額は各通信会社の定めによります）については、お客様の負担となります。</dd>
                            <dt className="translaw-title">代金支払い方法</dt>
                            <dd className="translaw-text">クレジットカード、Apple Pay</dd>
                            <dt className="translaw-title">支払期限</dt>
                            <dd className="translaw-text">各クレジットカード会社引き落とし日（クレジットカードでお支払いの場合）</dd>
                            <dt className="translaw-title">返品に関する特約</dt>
                            <dd className="translaw-text">
                                デジタルコンテンツという商品の性格上、返品には応じられません。
                                <br />
                                ただし、弊社事由により当該デジタルコンテンツが一切提供できない（配信予定のライブイベントが中止になった等）場合は、お客様規約第12条に基づき返金等の対応をいたします。
                            </dd>
                        </dl>
                    </section>
                </>
            ) : (
                <>
                    <section className="translaw">
                        <h1 className="common-title">Notation based on the Act on Specified Commercial Transactions</h1>

                        <dl className="translaw-list">
                            <dt className="translaw-title">Distributor</dt>
                            <dd className="translaw-text">Internet Initiative Japan Inc.</dd>
                            <dt className="translaw-title">Representative</dt>
                            <dd className="translaw-text">President: Eijiro Katsu (Co-CEO & COO)</dd>
                            <dt className="translaw-title">Sales Manager</dt>
                            <dd className="translaw-text">Kazunori Fukuda</dd>
                            <dt className="translaw-title">Location</dt>
                            <dd className="translaw-text">Iidabashi Grand Bloom 2-10-2 Fujimi, Chiyoda-ku, Tokyo 102-0071, JAPAN</dd>
                            <dt className="translaw-title">Contact</dt>
                            <dd className="translaw-text">
                                <img className="local-img" src={emailImgPass} alt="" />
                                <br />
                                TEL : 03-5205-6480（Available 12:00-20:00）
                            </dd>
                            <dt className="translaw-title">Name and type of product</dt>
                            <dd className="translaw-text">Digital contents</dd>
                            <dt className="translaw-title">Price</dt>
                            <dd className="translaw-text">Listed for each product</dd>
                            <dt className="translaw-title">Method for purchase</dt>
                            <dd className="translaw-text">Order through the Internet</dd>
                            <dt className="translaw-title">Costs other than product price</dt>
                            <dd className="translaw-text">Any communication charges (including Internet usage charges, the amount of which shall be determined by the respective telecommunication company) required for the use of the service (including downloading of related applications, etc.) shall be borne by the customer.</dd>
                            <dt className="translaw-title">Payment Method</dt>
                            <dd className="translaw-text">Credit card, Apple Pay</dd>
                            <dt className="translaw-title">Payment deadline</dt>
                            <dd className="translaw-text">Date of debit from each credit card company (when paying by credit card)</dd>
                            <dt className="translaw-title">Special Terms for Returning Products</dt>
                            <dd className="translaw-text">
                                Due to the nature of digital content products, we cannot accept returns.
                                <br />
                                However, in the event that we are unable to provide any of the relevant digital content due to reasons beyond our control (such as the cancellation of a live event scheduled for distribution), we will issue a refund or take other measures in accordance with Article 12 of the Customer Agreement.
                            </dd>
                        </dl>
                    </section>
                </>
            )}
        </>
    );
}

const mapStateToProps = state => {
    return {
        language: state.piano.language,
        donePageLoading: state.offer.donePageLoading,
    };
};

const mapDispatchToProps = dispatch => ({
    setIsStreaming: state => dispatch(setIsStreaming(state)),
    donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecifiedCommercialTransactionAct);
