import React, { useState, useEffect } from 'react';
import { chain, ceil, round } from 'mathjs';

function OfferDetailInfo(props) {
	let datepet = new Date(props.purchase_end_time);
	const [dayOfWeek, setDayOfWeek] = useState()
	useEffect(() => {
  	if (props.language === "ja_JP") {
    	setDayOfWeek(["日", "月", "火", "水", "木", "金", "土"][datepet.getDay()])
  	}
  	if (props.language === "en_US") {
    	setDayOfWeek(
      	["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][datepet.getDay()]
    	)
  	}
	}, [props])
	return (
		<dl className="detail-info">
			<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '価格' : 'Price'}</dt>
			<dd className="detail-info__text before-live-only">
				{getTaxPrice(props.isJapan, props.pianoPrice)}
				{props.language === 'ja_JP' ? '円（税込み）' : 'yen'}
			</dd>
			<dt className="detail-info__title before-live-only">{props.language === 'ja_JP' ? '購入期限' : 'Available until'}</dt>
			<dd className="detail-info__text before-live-only">{`${props.language === 'ja_JP' ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][datepet.getMonth()]}/${datepet.getDate()} [${dayOfWeek}] ${('0' + datepet.getHours()).slice(-2)}:${('0' + datepet.getMinutes()).slice(-2)}` : `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][datepet.getMonth()]},${datepet.getDate()} [${dayOfWeek}] ${('0' + datepet.getHours()).slice(-2)}:${('0' + datepet.getMinutes()).slice(-2)}`}`} </dd>
			<dt className="detail-info__text-title"> {props.language === 'ja_JP' ? '推奨環境' : 'System Requirements'}</dt>
			<dd className="detail-info__text">
				{props.language === 'ja_JP' ? (
					<div>
						<div className="detail-info__text-title">【PC】</div>
						<div className="detail-info__text-title">Windows 10</div>
						<p>・Google Chrome（最新版）</p>
						<p>・Mozilla Firefox（最新版）</p>
						<p>・Microsoft Edge（最新版）</p>
						<div className="detail-info__text-title">Mac OSX Catalina(10.15以降)</div>
						<p>・Google Chrome（最新版）</p>
						<p>・Mozilla Firefox（最新版）</p>
						<p>・Safari（最新版）</p>
						<div className="detail-info__text-title">【スマートフォン】</div>
						<div className="detail-info__text-title">Android （Android 9以降）</div>
						<p>・Google Chrome（最新版）</p>
						<div className="detail-info__text-title">Apple（iOS 15以降、iPadOS 15以降）</div>
						<p>・Safari（最新版）</p>
						<p>&nbsp;</p>
						<p>
							<a href="/test">動画再生環境確認ページ</a>でご利用する端末およびブラウザで動画が再生できることを確認してください
						</p>
					</div>
				) : (
					''
				)}
				{props.language === 'en_US' ? (
					<div>
						<div className="detail-info__text-title">【PC】</div>
						<div className="detail-info__text-title">Windows 10</div>
						<p>・Google Chrome（latest）</p>
						<p>・Mozilla Firefox（latest）</p>
						<p>・Microsoft Edge（latest）</p>
						<div className="detail-info__text-title">Mac OSX Catalina(10.15 or later)</div>
						<p>・Google Chrome（latest）</p>
						<p>・Mozilla Firefox（latest）</p>
						<p>・Safari（latest）</p>
						<div className="detail-info__text-title">【Smart Phone】</div>
						<div className="detail-info__text-title">Android （Android 9 or later）</div>
						<p>・Google Chrome（latest）</p>
						<div className="detail-info__text-title">Apple（iOS 15 or later、iPadOS 15 or later）</div>
						<p>・Safari（latest）</p>
						<p>&nbsp;</p>
						<p>
							Please confirm that the video can be played on the device and browser you will be using on the video playback environment <a href="/test">confirmation page</a>.
						</p>
					</div>
				) : (
					''
				)}
			</dd>
		</dl>
	);
}

function getTaxPrice(isJapan, price) {
	if (isJapan) {
		// 1.1をかけて、ceilで切り上げ
		let taxin_price = ceil(
			chain(price)
			.multiply(1.1)
			.done());
		let one_digit = (taxin_price / 1) % 10;
		// 公演価格の切り上げ | 切り捨て
		if (one_digit == 9 || one_digit == 1) {
			taxin_price = round(taxin_price / 10) * 10;
		}
		return taxin_price.toLocaleString('ja');
	} else {
		// 海外はおそらく税抜き価格になる
		return price.toLocaleString('en-US');
	}
}

export default OfferDetailInfo;
