import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import LoginLogoutForm from "./LoginLogoutForm"
import LanguageSwapButton from "./LanguageSwapButton"
import OfficialSiteButton from "./OfficialSiteButton"
import MyPageButton from "./MyPageButton"
import { connect } from "react-redux"
import FileNameConst from "../../common/FileNameConst"
import { isFileExist } from "../../common/CommonUtil";

function Header(props) {
	//logoImagePath[0]:ja_header_file, logoImagePath[1]:en_header_file 
	const [logoImagePath, setlogoImagePath] = useState([]);
	const [isDownloaded, setIsDownloaded] = useState(false);

	useEffect(() => {
		//イメージファイルをS3から取得する
		getLogoImg();
		setIsDownloaded(true);
	}, []);

	const getLogoImg = () => {
		setlogoImagePath( (prevState) => ([ ...prevState, getFilePath(FileNameConst.LOGO_JA_IMG) ]) )
		setlogoImagePath( (prevState) => ([ ...prevState, getFilePath(FileNameConst.LOGO_EN_IMG) ]) )
	}
	function getFilePath(fileImage) {
		const FileExistFlag = isFileExist(process.env.REACT_APP_S3_PASS_DEFAULT + fileImage);
		return (FileExistFlag) ? process.env.REACT_APP_S3_PASS_DEFAULT + fileImage : process.env.REACT_APP_DEFAULT_IMG_PASS + fileImage ;
	}

  return (
    <header className="global-header">
      <div className="global-header__inner">
        <div className="global-header__logo">
          <a href="/" title="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO">
          { (props.language === 'ja_JP') ?
              <img src={logoImagePath[0]} alt="東京･春･音楽祭 SPRING FESTIVAL IN TOKYO" style={{ display: isDownloaded ? 'block' : 'none' }} />
            : 
              <img src={logoImagePath[1]} alt="Tokyo Spring Music Festival" style={{ display: isDownloaded ? 'block' : 'none' }} />
          }
          </a>
        </div>
        <nav className="global-header-navi">
          <div className="global-header-navi__pc">
            <LanguageSwapButton language={props.language} />
            <OfficialSiteButton language={props.language} />
          <MyPageButton isLoggedIn={props.loggedIn} language={props.language} />
          <LoginLogoutForm
            isLoggedIn={props.loggedIn}
            language={props.language}
            isSp={false}
          />
          {/*<ul className="login-logout tp-cloak">*/}
          {/*  <li className="show-if-logged-out">*/}
          {/*    <a href="javascript:void(0);" className="login-button tp-login">ログイン</a>*/}
          {/*  </li>*/}
          {/*</ul>*/}
          <LoginLogoutForm
              isLoggedIn={props.loggedIn}
              language={props.language}
              isSp={true}
          />
          </div>
        </nav>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => {
  return {
    language: state.piano.language,
    loggedIn: state.piano.loggedIn,
  }
}

export default connect(mapStateToProps)(Header)
