import React, { useState, useEffect } from 'react';
import {getSystemRequirementsFromSettings} from '../../common/CommonUtil';

function OfferRecommendBrowserSp(props) {
	const [systemRequirements, setSystemRequirements] = useState()

	useEffect(() => {
		getOperatingEnvironments();
  	}, [props.settingJson])

	const getOperatingEnvironments = () => {
		if (!props.settingJson) {return;}
		setSystemRequirements(getSystemRequirementsFromSettings(props.settingJson));
	}

	return (
		<>
			<br/>
				<div className="mobile-menu__line2 sp-only"/>
				<dd className="detail-info__text sp-only">
						{props.language === 'ja_JP' ? (
							<p>
								<a href="/test">動画再生環境確認ページ</a>でご利用する端末およびブラウザで動画が再生できることを確認してください。
							</p>
							) : (
							<p>
								Please confirm that the video can be played on the device and browser you will be using on the video playback environment <a href="/test">confirmation page</a>.
							</p>
						)}
				</dd>
			{systemRequirements && Object.keys(systemRequirements).length ? (
				<>
				<dt className="detail-info__title sp-only"> {props.language === 'ja_JP' ? '推奨環境' : 'System Requirements'}</dt>
				<dt className="detail-info__text sp-only">
					{props.language === 'ja_JP' ? (
						<>
						<div>
						{systemRequirements.Windows || systemRequirements.Mac ? (
							<>
							<div className="detail-info__text-title">【PC】</div>
							{systemRequirements.Windows ? 
								<>
								<div className="detail-info__text-title">Windows {systemRequirements.Windows.os}</div>
								{systemRequirements.Windows.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.Mac ? 
								<>
								<div className="detail-info__text-title">Mac OSX Catalina({systemRequirements.Mac.os}以降)</div>
								{systemRequirements.Mac.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						): ('')}
						{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
							<>
							<div className="detail-info__text-title">【スマートフォン】</div>
							{systemRequirements.Android ? 
								<>
								<div className="detail-info__text-title">Android （Android {systemRequirements.Android.os}以降）</div>
								{systemRequirements.Android.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.iOS && systemRequirements.iPad ? 
								<>
								<div className="detail-info__text-title">Apple（iOS {systemRequirements.iOS.os}以降、iPadOS {systemRequirements.iPad.os}以降）</div>
								{systemRequirements.iOS.browserList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
							<p>&nbsp;</p>
						</div>
						</>
					) : (
						''
					)}
					{props.language === 'en_US' ? (
						<div>
						{systemRequirements.Windows || systemRequirements.Mac ? (
							<>
							<div className="detail-info__text-title">【PC】</div>
							{systemRequirements.Windows ? 
								<>
								<div className="detail-info__text-title">Windows {systemRequirements.Windows.os}</div>
								{systemRequirements.Windows.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.Mac ? 
								<>
								<div className="detail-info__text-title">Mac OSX Catalina({systemRequirements.Mac.os} or later)</div>
								{systemRequirements.Mac.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
						{systemRequirements.Android || systemRequirements.iOS || systemRequirements.iPad ? (
							<>
							<div className="detail-info__text-title">【Smart Phone】</div>
							{systemRequirements.Android ? 
								<>
								<div className="detail-info__text-title">Android （Android {systemRequirements.Android.os} or later）</div>
								{systemRequirements.Android.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							{systemRequirements.iOS && systemRequirements.iPad ? 
								<>
								<div className="detail-info__text-title">Apple（iOS {systemRequirements.iOS.os} or later、iPadOS {systemRequirements.iPad.os} or later）</div>
								{systemRequirements.iOS.browserEnList.map(browser => {
									return (<p>・{browser}</p>)
								})}
								</>
							: ''}
							</>
						) : ('')}
							<p>&nbsp;</p>
						</div>
					) : (
						''
					)}
				</dt>
				</>

			) : ('')}
		</>
	);
}

export default OfferRecommendBrowserSp;
