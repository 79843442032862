export const CustomizeFileConst = {
    LARGE_BANNER_JEPG: 'banner-large.jpeg',
    INFORMATION_JSON: 'information.json',
    MAIN_LOGO_JPEG: 'main-logo.jpeg',
    SMALL_LOGO_JPEG: 'small-logo.jpeg',
    OFFER_ATTENTION_TXT: 'OfferAttention.txt',
    OFFER_ATTENTION_EN_TXT: 'OfferAttention_en.txt',
    FAQ_JSON: 'faq.json',
    PERSONAL_INFO_HTML_JP: 'personalInfoJp.html',
    PERSONAL_INFO_HTML_EN: 'personalInfoEn.html',
    PRIVACY_POLICY_HTML_JP: 'privacyPolicyJp.html',
    PRIVACY_POLICY_HTML_EN: 'privacyPolicyEn.html'
}

export default CustomizeFileConst;