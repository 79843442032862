export const BackendResponsePerformanceStatusConst = {
    waitSale: 'WaitSale',
    onSale: 'OnSale',
    unavailable: 'Unavailable',
    done: 'Done',
    rerun: 'Rerun',
    archiveInPreparation: 'ArchiveInPreparation',
    archiveOnSale: 'ArchiveOnSale',
    archiveUnavailable:'ArchiveUnavailable'
}

export default BackendResponsePerformanceStatusConst;