import React from "react"
import Papa from "papaparse"

function OfferPerformer(props) {
  return (
    <dd className="detail-info__text">{parsePerformer(props.performer)}</dd>
  )
}

const parsePerformer = (performer) => {
  if (performer !== undefined) {
    let description = Papa.parse(performer)
    if (description !== undefined) {
      return (
        <>
          {description.data[0].map((item, index) => {
            return <p key={index}> {item} </p>
          })}
        </>
      )
    }
  }
}

export default OfferPerformer
