import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';

function TermOfService(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);
	return (
		<>

			{props.language === 'ja_JP' ? (
				<>
					<section className="terms">
						<h1 className="common-title">「IIJ Teatrista」（IIJ テアトリスタ）利用規約 </h1>
						<div className="terms-text">
							<p className="iij-number">■IIJ-TEA001-0001</p>
							<p>令和5年2月1日制定</p>
							<p>株式会社インターネットイニシアティブ</p>
						</div>

						<section>
							<div className="terms-text">
								<p>この規約（以下「本規約」といいます。）は、株式会社インターネットイニシアティブ（以下「当社」といいます。）が提供するサービス「IIJ Teatrista」（以下「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスを利用するすべてのお客様に適用されるものとします。</p>
							</div>
						</section>

						<section>
							<h2 className="terms-title2">第１条（定義）</h2>
							<div className="terms-text">
								<p>本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところによるものとします。</p>
								<ol className="terms-list-ol">
								<li>
									「ライブ・ストリーミングチケット」
									<br />
									本サービスにおける作成されたイベントに参加することを可能にする電子媒体のチケットをいいます。
								</li>
								<li>
									「イベント主催者」
									<br />
									本サービスを利用し、ライブ・ストリーミングチケットを組成し、本サービス上に表示する者（法人であるか個人であるかを問いません）をいいます。
								</li>
								<li>
									「お客様」
									<br />
									イベント主催者が本サービスを利用して販売するライブ・ストリーミングチケットを購入する者をいいます。
								</li>
								<li>
									「知的財産権」 <br />
									著作権（著作権法第２７条及び第２８条の権利を含みます。）、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）及びアイデア、ノウハウ等をいいます。
								</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２条（本規約への同意）</h2>
							<div className="terms-text">
								<p>お客様は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用できないものとします。本サービスに関して当社が配布、配信する文書等に規定する内容は、お客様との間で本規約の一部を構成するものとします。</p>
							</div>

							<h2 className="terms-title2">第３条（本規約の改定・変更）</h2>
							<div className="terms-text">
								<p>
									当社は、当社の判断において、当社ウェブサイトへ掲示の上、いつでも本規約の内容を変更又は追加できるものとします。変更後の利用規約は、当社が別途定める場合を除いて、当社の運営するウェブサイト（https://www.harusailive.jp）（理由の如何を問わず、当該ウェブサイトのドメイン又は内容が変更された場合は、当該変更後のドメイン等を含みます。）に掲示された時点より効力を生じるものとします。
									<br />
									お客様が、本規約の変更後も本サービスの利用を継続した場合は、変更後の本規約に同意したものとみなされます。
								</p>
							</div>

							<h2 className="terms-title2">第４条（登録手続）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>お客様は、本規約に同意し、当社に登録情報を提供することにより、会員登録の申請を行うことができます。</li>
									<li>当社は、前項に定める会員登録の申請を次条に定める場合を除いて、承諾するものとします。</li>
									<li>お客様は、登録情報の提供にあたっては、真実かつ正確な情報を提供しなければなりません。登録情報の内容に虚偽、誤り又は記載漏れがあったことによりお客様に損害が生じた場合であっても、当社は一切責任を負いません。</li>
									<li>登録をしたお客様は、登録情報に変更があった場合、当社が別途指定する方法により、遅滞なく変更内容を当社に通知するものとします。この通知を怠ったことにより当社からの通知が不到達となった場合、当該通知は通常到達すべき時に到達したとみなされます。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第５条（登録の拒否）</h2>
							<div className="terms-text">
								<p>当社は、第４条１項に基づき登録を申請した者（以下「登録申請者」といいます。）が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。その場合電子メールにて通知いたします。</p>
								<ol className="terms-list-ol">
									<li> 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
									</li>
									<li>当社に提供された登録情報の内容に虚偽、誤り又は記載漏れがあった場合
									</li>
									<li> 登録申請者が、本サービス利用に際して、過去にアカウント削除等の本サービス利用停止措置を受けたことがあり又は現在受けている場合
									</li>
									<li> 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。）であるか、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
									</li>
									<li>その他、当社が登録を適当でないと判断した場合</li>
								</ol>
							</div>

							<h2 className="terms-title2">第６条（アカウントの管理）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、お客様との間で本利用契約が成立した場合、当該お客様に対し、本サービスの利用にかかるアカウントを付与します。</li>
									<li>お客様は、自己の責任においてアカウントを管理・保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはなりません。当社は、当該アカウントの一致を確認した場合、当該アカウントを保有するものとして登録されたお客様が本サービスを利用したものとみなします。</li>
									<li> アカウントの管理不十分又は第三者の使用等による損害の責任は、お客様が負うものとし、当社は一切の責任を負いません。</li>
									<li> お客様は、アカウントが盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第７条（未成年者による利用）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>未成年者のお客様は、会員登録を申請する場合及び本サービスを利用してライブ・ストリーミングチケットの購入手続及び決済をする場合の一切につき、親権者等の法定代理人の同意を得た上でこれを行うものとします。未成年者が会員登録を完了した時点、又は未成年者が本サービスでライブ・ストリーミングチケットを決済した時点で、本サービスの利用及び本規約等の内容について、法定代理人の同意があったものとみなします。
									</li>
									<li>未成年者のお客様が、法定代理人の同意がないにもかかわらず同意があると偽り又は年齢について成年と偽って本サービスを利用してライブ・ストリーミングチケットの購入手続及び決済をした場合、その他行為能力者であることを信じさせるために詐術を用いた場合、ライブ・ストリーミングチケットの購入手続及び決済をすることについて、一切の法律行為を取り消すことはできないものとします。
									</li>
									<li>本規約の同意時に未成年であったお客様が成年に達した後に本サービスを利用した場合、お客様は本サービスに関する一切の法律行為を追認したものとみなされます。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第８条（外部サービス等の利用）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>お客様は、お客様が保有する外部サービスのアカウントに登録された情報を、本サービスの登録及び認証のために利用することができる場合があります。この場合、お客様は、当社に対し、当社が外部サービスから個人情報を含む登録された情報を取得することに同意するものとします。
									</li>
									<li>前項に定める他、本サービスの利用にあたりお客様が外部サービスの機能を利用する場合、お客様は、本規約のほか、当該外部サービスの定める利用規約その他の条件に従うものとします。
									</li>
									<li>外部サービスについては、当該外部サービスを提供する第三者が責任を負うものとし、当社は外部サービスについていかなる保証も行いません。
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">第９条（本サービスの内容及び本サービスを利用する場合の遵守事項）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>お客様は、本サービスを利用して、ライブ・ストリーミングチケットの購入手続の完了及び決済を行うにあたり、本利用規約及びこれに付随する規約を遵守して利用するものとします。
									</li>
									<li>お客様は、ライブ・ストリーミングチケットを購入する場合、掲載されたイベントに関する情報をよく読み、自らの責任の下で、ライブ・ストリーミングチケットを購入するものとします。
									</li>
									<li>お客様は、ライブ・ストリーミングチケットの購入代金について、イベント主催者から、イベント主催者に代わって代理受領する権利の付与を受けた当社に対し、当社が指定する方法により支払うものとします。お客様は、本サービスを利用して購入したライブ・ストリーミングチケットについて、イベント主催者に直接支払いをしてはならないものとします。
									</li>
									<li>ライブ・ストリーミングチケットに関する売買契約は、イベント主催者とお客様との間に成立し、当社は、ライブ・ストリーミングチケットに関する売買契約の当事者にはなりません。
									</li>
									<li>お客様によるライブ・ストリーミングチケットの支払いを当社において確認し、お客様のアカウントの購入履歴に、当該ライブ・ストリーミングチケットの購入が表示された時点で、イベント主催者とお客様との間でライブ・ストリーミングチケットに関する売買契約が成立するものとします。
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１０条（ライブ・ストリーミングチケット販売に関する責任）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>ライブ・ストリーミングチケットの販売に関して、イベント主催者とお客様との間に生じた一切の紛争又はトラブル（イベント情報の記載に起因して生じたトラブル等が想定されますが、これらに限られません。）は、お客様の費用と責任において解決するものとし、当社は一切責任を負わないものとします。
									</li>
									<li>前項に掲げるライブ・ストリーミングチケットの販売に関する紛争又はトラブルに起因し、当社が当該紛争又はトラブルを解決するために必要となる費用（解決金や、弁護士費用等が含まれますが、これらに限られません。）を支出した場合、お客様は、当該支出に関する費用のうち当社が指定した金額を支払うものとします。なお、支払に要する費用は、お客様が負担するものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１１条（決済代行サービス）</h2>
							<div className="terms-text">
								<p>お客様が本サービスを利用してライブ・ストリーミングチケットを購入した場合、当社は、ライブ・ストリーミングチケットにかかる代金（以下「商品代金」といいます。）を、イベント主催者を代理して受領します。</p>
							</div>

							<h2 className="terms-title2">第１２条（チケット代の返金について）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>公演中止等に伴う払い戻しについて、イベント主催者より返金判断がされた場合、チケット代を返金致します。
									</li>
									<li>返金の時期及び金額については、イベント主催者の判断に従うものとします。なお、イベント主催者において返金を決定した場合、当社は、最短で、イベントが実施される予定であった日が属する月の翌月末日までに返金手続きをします。
									</li>
									<li>本条は、チケット代の返金についての全てを定めたものであり、お客様都合等によるチケット代の返金は行われないものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１３条（知的財産権等）</h2>
							<div className="terms-text">
								<p>本サービスにかかる知的財産権は、全て当社又は当社に適法にライセンスをする第三者に帰属するものとし、お客様は、本契約の締結によっても、本サービスの利用権を取得するにすぎず、知的財産権の譲渡又はライセンスを受けるものではありません。</p>
							</div>

							<h2 className="terms-title2">第１４条（個人情報保護）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、お客様が本サービスを利用した際に提供した個人情報について、法令に従い、適切に取り扱うものとし、以下の利用目的の範囲内において取り扱います。
										<ol className="terms-list-ol">
											<li>本サービスの提供にかかる業務を行うこと。（業務上必要な連絡、通知等を契約者に対して行うことを含みます。）</li>
											<li>本サービスレベルの維持向上を図るため、アンケート調査及びその分析を行うこと。</li>
											<li>当社の商品、サービスに関する情報（本サービスに限らず、当社の別商品、サービス又は当社の新規商品、サービス紹介情報等を含みます。）又は提携先の商品、サービス等の情報を、お客様がアクセスした当社のWebページその他お客様の端末装置上に表示し、もしくはメール、郵便等により送付し、又は電話すること。なお、お客様は、当社が別途定める方法により、これらの取り扱いを中止又は再開することができます。</li>
											<li>前各号に付属する業務を行うこと。</li>
											<li>その他お客様から得た同意の範囲内で利用すること。</li>
										</ol>
									</li>
									<li>当社は、イベント主催者よりイベント情報等をご案内することを目的として、必要なセキュリティ措置を講じた上で、お客様がライブ・ストリーミングチケットを購入したイベント主催者へ、お客様の会員登録情報を第三者提供いたします。その他、お客様の同意に基づき必要な限度において個人情報を第三者に提供する場合があります。また、本サービスの提供に係る業務における個人情報の取扱いの全部又は一部を第三者に委託する場合にあっては、当社は、当社の監督責任下において個人情報を第三者に委託するものとします。</li>
									<li>前項にかかわらず、法令に基づく請求又は特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律（平成13年法律第137号）第４条に基づく開示請求の要件が充足された場合、その他法令に基づく場合は、当社は当該請求の範囲内で個人情報を請求者に開示する場合があります。</li>
									<li>当社は、お客様による本サービスの利用により取得した、年齢、ライブ・ストリーミングチケットの購入数量、購入頻度、購入された国、ライブ・ストリーミングチケットを購入したお客様の属性、個人情報が削除され匿名化された情報、統計データ、その他一切の情報について、無償で、何らの制限なく自由に利用することができるものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１５条（禁止行為）</h2>
							<div className="terms-text">
								<p>お客様は、本サービスのご利用に際し、以下に定める行為（それらを誘発する行為や準備行為も含みます。）を行わないことを当社に対し表明し、保証するものとします。</p>
								<ol className="terms-list-ol">
									<li>日本国の法令その他適用可能性のある国・地域の法令に違反する行為</li>
									<li>商品代金又は商品代金と実質的に同視すべき代金を、当社の定めるシステム又は手続きによらずイベント主催者に対して直接支払う行為</li>
									<li>第三者の使用するソフトウェア、ハードウエアなどの機能を破壊・妨害するプログラムなどの投稿などをする行為</li>
									<li>当社のサーバー又はネットワークの機能を破壊・妨害する行為</li>
									<li>本サービス、当社の配信する広告又は当社のサイト上で提供されているサービス・広告を妨害する行為</li>
									<li>第三者の個人情報や履歴情報及び属性情報などを、当該第三者に無断で収集・蓄積する行為</li>
									<li>本サービス又は本サービスを通じて入手した情報を、提供の趣旨に背く目的で利用する行為</li>
									<li>第三者のログイン情報を使用して本サービスを利用する行為</li>
									<li>手段の種類を問わず他人からログイン情報を入手し、又は他人にログイン情報を開示・提供する行為</li>
									<li>本サービスに関連して、反社会的勢力などに直接・間接に利益を提供する行為</li>
									<li>その他、上記に準じて当社が不適切と判断する行為</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１６条（本規約に違反した場合の措置等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、お客様が次の各号の一に該当し又は該当するおそれがあると当社が判断した場合には、当社の裁量により、何らの通知も行うことなく、本サービスの利用の一時停止若しくは制限、今後の利用禁止等、当社が必要と考えるあらゆる措置（以下「利用停止等」といいます。）を講じることができるものとします。
										<ol className="terms-list-ol">
											<li>第１４条に定める禁止行為を行った場合又は行うおそれがある場合</li>
											<li>本規約のいずれかの条項に違反し、又は違反したとの通報を受けた場合</li>
											<li>第三者の知的財産権、肖像権、プライバシー権その他の権利を侵害するおそれがある場合</li>
											<li>本サービスの利用につき他のお客様、当社、又はイベント主催者との間でトラブルを発生させ、当該トラブルにつきお客様の責に帰すべき事由があることが判明した場合</li>
											<li>当社に提供された情報の全部又は一部につき虚偽の事実があることが判明した場合</li>
											<li>お客様が支払停止若しくは支払不能となり、又は破産手続開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
											<li>当社からの問い合わせその他の回答を求める連絡に対して３０日間以上応答がない場合</li>
											<li>反社会的勢力等であるか、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力等との何らかの交流若しくは関与を行っている場合</li>
											<li>その他前各号に類する事由がある場合</li>
										</ol>
									</li>
									<li>お客様は、利用停止等の後も、当社及び第三者に対する本利用契約上の一切の義務及び債務を免れるものではありません。</li>
									<li>当社は、本条に基づき当社が行った措置によりお客様に生じた損害について一切の責任を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１７条（不可抗力）</h2>
							<div className="terms-text">
								<p>当社は、天災地変、戦争、暴動、内乱、法令の改廃制定、公権力による命令処分、同盟罷業その他の争議行為、輸送機関の事故等、自らの責に帰すことのできない不測の事態による履行遅滞または履行不能についてお客様に対してその責を負わないものとします。</p>
							</div>

							<h2 className="terms-title2">第１８条（損害賠償）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、本サービスの利用に関連して、当社の責めに帰すべき事由によりお客様に損害が生じた場合につき、お客様に現実に発生した直接かつ通常の損害に限り責任を負うものとし、当該損害賠償の額は、損害発生の原因となったお客様が購入したライブ・ストリーミングチケットの金額を上限とするものとします。</li>
									<li>当社の故意又は重過失によってお客様に損害が生じた場合には、前項の当該損害賠償の上限は適用されないものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１９条（秘密保持）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>お客様は、当社の事前の書面による承諾がある場合を除き、本サービスに関連して当社が秘密である旨指定して開示した非公知の情報を秘密に取り扱うものとします。</li>
									<li>お客様は、当社から求められた場合はいつでも、当社の指示に従い、遅滞なく、前項の情報及び当該情報を記載又は記録した書面その他の記録媒体物並びにその全ての複製物等を返却又は廃棄しなければなりません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２０条（反社会的勢力の排除等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社及びお客様は、次の各号に掲げる事項について相互に保証する。
									<br />
										<ol className="terms-list-ol">
											<li>自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる団体又はそれらの構成員若しくは関係者（以下「反社会的勢力」という。）ではないこと</li>
											<li>自己の役員、従業員、その他使用人が、反社会的勢力ではないこと</li>
											<li>自ら又はその役員、従業員、その他使用人が、反社会的勢力への資金提供を行うなどその活動を助長する行為を行ったことがなく、且つ、今後も行わないこと</li>
											<li>自ら又はその役員、従業員、その他使用人が、自身で又は第三者を利用して、相手方及びその顧客に対し、暴力的若しくは威圧的な行為又は名誉若しくは信用を毀損する行為を行わないこと</li>
										</ol>
									</li>
									<li>当社及びお客様は、相手方が前項のいずれか一つにでも違反していると合理的に認められる場合、相手方に対し、何らの通知・催告をすることなく、本利用契約を解除することができるものとします。なお、この場合、解除された当事者は、解除されたことにより受けた損害について、相手方に対し、何らの請求もできないものとします。</li>
									<li>当社及びお客様は、相手方が第1項のいずれかに違反したことにより損害を受けた場合、相手方に対し、その被った損害の賠償を請求することができるものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２１条（保証の否認及び免責）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、本サービスの利用につき、お客様の特定の目的への適合性、経済的価値、正確性、有用性、完全性、適法性、お客様に適用のある団体の内部規則等への適合性を有すること、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、如何なる保証も行うものではありません。</li>
									<li>当社は、本サービスが全ての情報端末及び情報端末のＯＳやブラウザのバージョンに対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、お客様はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２２条（本サービスの変更・中断・終了等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、お客様に事前に通知することなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。</li>
									<li>当社は、事前に、本サービス上又は当社の運営するウェブサイト上への掲示その他当社が適当と判断する方法でお客様に通知することにより、当社の裁量で、本サービスを終了することができるものとします。ただし、緊急の場合はお客様への通知を行わない場合があります。
										<ol className="terms-list-ol">
											<li>当社は、以下各号の事由が生じた場合には、お客様に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。</li>
											<li>本サービス用の通信機器設備等に関わるメンテナンスや修理を定期的又は緊急に行う場合</li>
											<li>アクセス過多、その他予期せぬ要因でシステムに負荷が集中した場合</li>
											<li>お客様及び第三者のセキュリティを確保する必要が生じた場合</li>
											<li>電気通信事業者の役務が提供されない場合</li>
											<li>天災等の不可抗力により本サービスの提供が困難な場合</li>
											<li>火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等により本サービスの提供が困難な場合</li>
											<li>法令又はこれらに基づく措置により本サービスの運営が不能となった場合</li>
											<li>その他前各号に準じ当社が必要と判断した場合</li>
										</ol>
									</li>
									<li>当社は、本条に基づき当社が行った措置によりお客様に生じた損害について一切の責任を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２３条（権利義務の譲渡）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>お客様は、当社の書面による事前の承諾がある場合を除き、本利用契約に基づくお客様の権利若しくは義務、又は本利用契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。</li>
									<li>当社が、本サービスにかかる事業を第三者に譲渡し、又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等により本サービスにかかる事業を包括承継させたときは、当社は、当該事業譲渡等に伴い、本利用契約上の地位、権利及び義務並びに登録情報その他のお客様に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、お客様は、あらかじめこれに同意するものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２４条（分離可能性）</h2>
							<div className="terms-text">
								<p>本規約のいずれかの条項又はその⼀部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び⼀部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、運営及び会員は、当該無効もしくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効もしくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。</p>
							</div>

							<h2 className="terms-title2">第２５条（準拠法及び合意管轄）</h2>
							<div className="terms-text">
								<p>本規約は日本法に準拠するものとし、本規約に起因し又は関連する一切の紛争については、訴額及び手続に応じ、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
							</div>

							<h2 className="terms-title2">第２６条（存続条項）</h2>
							<div className="terms-text">
								<p>第13条、第16条2項及び３項、第18条、第19条、第20条３項、第21条、第22条及び条項の性質に鑑み契約終了後も適用されると合理的に解釈される条項については、契約終了後もなお、効力を有するものとします。</p>
							</div>
						</section>
					</section>

					<section className="terms">
						<h1 className="common-title">「IIJ Teatrista」利用規約 （イベント主催者向け）</h1>

						<section>
							<div className="terms-text">
								<p>この規約（以下「本規約」といいます。）は、株式会社インターネットイニシアティブ（以下「当社」といいます。）が提供するサービス「IIJ Teatrista」（以下「本サービス」といいます。）の利用に関する条件を定めるものであり、本規約の規定に従い登録を完了したすべてのイベント主催者に適用され、本サービスの利用に関する当社とイベント主催者との間の契約（以下「本利用契約」といいます。）の内容を構成するものとします。</p>
							</div>
						</section>

						<section>
							<h2 className="terms-title2">第１条（定義）</h2>
							<div className="terms-text">
								<p>本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところによるものとします。 </p>
								<ol className="terms-list-ol">
									<li>
										「ライブ・ストリーミングチケット」
										<br />
										本サービスにおける作成されたイベントに参加することを可能にする電子媒体のチケットをいいます。
									</li>
									<li>
										「イベント主催者」
										<br />
										本サービスを利用し、ライブ・ストリーミングチケット等を組成し、本サービス上に表示し、お客様に販売する法人として、第４条による登録を完了した者をいいます。
									</li>
									<li>
										「お客様」
										<br />
										イベント主催者が本サービスを利用して販売するライブ・ストリーミングチケットを購入する者をいいます。
									</li>
									<li>
										「知的財産権」 <br />
										著作権（著作権法第２７条及び第２８条の権利を含みます。）、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）及びアイデア、ノウハウ等をいいます。
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２条（本規約への同意）</h2>
							<div className="terms-text">
								<p>イベント主催者は、本規約に従って本サービスを利用するものとし、本規約に同意しない限り本サービスを利用できないものとします。</p>
							</div>

							<h2 className="terms-title2">第３条（本規約の変更）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、当社の判断において、イベント主催者へ当社ウェブサイト及び電子メールにて通知の上、いつでも本規約の内容を変更又は追加できるものとします。変更後の利用規約は、当社が別途定める場合を除いて、当社の運営するウェブサイト（https://www.harusailive.jp）（理由の如何を問わず、当該ウェブサイトのドメイン又は内容が変更された場合は、当該変更後のドメイン等を含みます。）への掲載又はその他の方法において周知するものとし、当社が定めた効力発生時期より効力を生じるものとします。</li>
									<li>イベント主催者が、本規約の変更後も本サービスの利用を継続した場合は、変更後の本規約に同意したものとみなされます。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第４条（登録手続）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>本サービスをイベント主催者として利用することを希望する者（以下「申請者」といいます）は、本規約を遵守することに同意し、当社の定める方法に従い情報を当社に提供することにより、本サービスの利用登録の申請をすることができます。かかる申請があった場合、当社は、別途当社の定める審査基準に従って審査し、当該申請を承諾する場合には、当社ウェブサイト及び電子メールにてその旨の通知を行います。</li>
									<li>前項の場合、当社は、審査に必要な書類の提出を求めることがありますが、この場合、申請者は、当該書類を速やかに提出するものとします。なお、申請者が当該書類を提出しない場合には、当社は、登録を拒否することができるものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第５条（アカウントの管理）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、イベント主催者との間で本利用契約が成立した場合、当該イベント主催者に対し、本サービスの利用にかかるアカウントを付与します。 </li>
									<li>イベント主催者は、自己の責任においてアカウントを管理・保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買その他の処分をしてはなりません。当社は、当社が付与したアカウントにより本サービスが利用されたときは、付与対象のイベント主催者以外の第三者等による利用であった場合であっても、当該アカウントを保有するものとして登録されたイベント主催者が本サービスを利用したものとみなします。 </li>
									<li>アカウントの管理不十分又は第三者の使用等による損害の責任は、イベント主催者が負うものとし、当社は一切の責任を負いません。</li>
									<li>イベント主催者は、アカウントが盗用され又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第６条（外部サービス等の利用）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>イベント主催者は、イベント主催者が保有する外部サービス（Facebook、Twitter を含みますが、これに限りません。）のアカウントに登録された情報を、本サービスの登録及び認証のために利用することができる場合があります。この場合、イベント主催者は、当社に対し、当社が外部サービスから個人情報を含む登録された情報を取得することに同意するものとします。</li>
									<li>前項に定める他、本サービスの利用にあたりイベント主催者が外部サービスの機能を利用する場合、イベント主催者は、本規約のほか、当該外部サービスの定める利用規約その他の条件に従うものとします。</li>
									<li>外部サービスについては、当該外部サービスを提供する第三者が責任を負うものとし、当社は外部サービスについていかなる保証も行いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第７条（本サービスの内容及び本サービスを利用する場合の遵守事項）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>第４条の規定による登録が完了した時点で、イベント主催者と当社との間で、本規約を内容とする本利用契約が成立します。</li>
									<li>イベント主催者は、本サービスを利用してライブ・ストリーミングチケットを組成するにあたり、本規約の内容及び当社が記載する注意事項を遵守するものとします。</li>
									<li>イベント主催者は、ライブ・ストリーミングチケット組成する場合、以下の各事項を遵守するものとします。
									<br />
										<ol className="terms-list-ol">
											<li>お客様が認識しておくべき当該イベントやストリーミング配信に関する情報（日時、場所、注意事項等が含まれますが、それらに限られません。）その他の必要事項を正確に表示すること</li>
											<li>イベントやストリーミング配信に関する情報について誤りがあった場合又は変更が生じた場合、速やかに当該情報を修正又は変更すること。なお、イベント主催者の依頼に基づき当社が情報入力を代行した場合であっても、かかる情報の最終確認はイベント主催者の責任において行うものとし、誤りが発見された場合にはイベント主催者の責任において訂正するものとします。当社が代行入力した内容に誤りがあった場合でも、当社は一切の責任を負いません。</li>
											<li>第三者の知的財産権等の権利を侵害しないこと</li>
											<li>事前に掲載したイベントの開催要項（開始時間、終了時間を含みます。）を遵守すること。万が一、開始が遅延する等の変更がある場合には、イベント主催者の責任において、適切な方法でお客様に通知しなければなりません。また、イベント主催者によるイベントの開催要項の変更や開催要項に反する事項（開始時間や終了時間の不遵守を含みます。）に起因するアクセス集中等により、本サービスに障害等が発生した場合、当社は一切の責任を負わないものとし、イベント主催者は当社に発生した損害を賠償するものとします。</li>
											<li>その他当社が不適切と判断する情報を掲載しないこと</li>
										</ol>
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">第８条（ライブ・ストリーミングチケット販売に関する責任）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>ライブ・ストリーミングチケットに関する売買契約は、イベント主催者とお客様との間に成立し、当社は、ライブ・ストリーミングチケット関する売買契約の当事者にはなりません。</li>
									<li>お客様によるライブ・ストリーミングチケットの支払いを当社において確認し、お客様のアカウントの購入履歴に、当該ライブ・ストリーミングチケットの購入が表示された時点で、イベント主催者とお客様との間でライブ・ストリーミングチケットに関する売買契約が成立するものとします。</li>
									<li>ライブ・ストリーミングチケットの販売に関して、イベント主催者とお客様、その他イベント主催者と第三者との間に生じた一切の紛争又はトラブル（イベント情報の記載に起因して生じたトラブルや、メディアによる個人情報、画像、動画、テキスト、音声その他の情報の目的外利用等が想定されますが、これらに限られません。）は、イベント主催者の費用と責任において解決するものとし、当社は一切責任を負わないものとします。</li>
									<li>前項に掲げるライブ・ストリーミングチケットの販売に関する紛争又はトラブルに起因し、当社が当該紛争又はトラブルを解決するために必要となる費用（解決金や、弁護士費用等が含まれますが、これらに限られません。）を支出した場合、イベント主催者は、当該支出に関する費用のうち当社が指定した金額を支払うものとします。なお、支払に要する費用は、イベント主催者が負担するものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第９条（決済代行サービス）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li> イベント主催者は、当社に対し、イベント主催者が組成したライブ・ストリーミングチケットの代金（以下「商品代金」といいます。）について、イベント主催者に代わって代理受領する権利を付与するものとします。これにより、当社は、イベント主催者に代わって、お客様から商品代金を受領します。イベント主催者は、商品代金を、その名目及び方法を問わず、お客様から収受してはならないものとします。</li>
									<li>当社は、前項により代理受領した商品代金について、当社が別途定める手数料を控除のうえ、当社が別途定める期日限り、イベント主催者が指定する口座に振り込む方法により支払うものとします。</li>
									<li>当社は、当社の裁量で必要であると判断した場合には、お客様に対するライブ・ストリーミングチケットの返金を行うことができます。お客様に対するライブ・ストリーミングチケットの返金が発生した場合には、返金事務手数料については別途定めることとします。</li>
									<li>当社は、お客様がクレジットカードによってライブ・ストリーミングチケットを決済した場合において、当該決済についてチャージバック（当該決済について、クレジットカードの不正使用その他の事由により、クレジットカード会社によって当該決済にかかる承認が取り消された場合をいいます。）が発生したとき、当社は、当該決済についてクレジットカード会社から入金された金額を、イベント主催者に何らの通知をせずとも、自らの判断においてクレジットカード会社に対し返金することができるものとします。チャージバックが、イベント主催者への商品代金の支払前に発生した場合、当社は、商品代金総額からチャージバックに係る商品代金及びチャージバック手数料（もしあれば）を控除します。チャージバックが、イベント主催者への商品代金の支払後に発生した場合、イベント主催者は、当社に対し、チャージバックに係る商品代金及びチャージバック手数料（もしあれば）を、当社が指定する期日までに支払うものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１０条（キャンセルについて）</h2>
							<div className="terms-text">
								<p>イベントの中断又は中止が発生した場合の処理については、別紙「キャンセルポリシー」に定めるとおりとします。</p>
							</div>

							<h2 className="terms-title2">第１１条（ライブ・ストリーミングチケットの販売）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>イベント主催者は、本サービスを利用して、自身が提供するコンテンツ（ライブパフォーマンスを含みます。）についてストリーミング配信を行うことができ、また、お客様に対して、ライブ・ストリーミングチケットを販売することができます。</li>
									<li>当社は、イベント主催者に対し、ストリーミング配信及びライブ・ストリーミングチケットの販売のためのプラットフォームを提供するのみであり、配信された内容、及びストリーミング配信の広告宣伝についてはイベント主催者が一切の責任を負うものとし、当社は一切責任を負いません。</li>
									<li>配信コンテンツに関するガイドライン<br/>
									<br />
									<p>禁じられている内容:</p>
									<ul className="terms-list-ul">
										<li>第三者の著作権その他の権利（例: 商標権、プライバシー権）を侵害するコンテンツ（映画、音楽、テレビ番組など第三者が権利を有する素材を許諾なく使用する行為や第三者のプライバシーを侵害する行為を含みます。）</li>
										<li>性的コンテンツ、風俗産業又はポルノ</li>
										<li>憎悪表現、嫌がらせ、名誉・信用棄損、中傷や差別的発言</li>
										<li>テロリズム又は扇動集団を助長する内容</li>
										<li>未成年を搾取する内容</li>
										<li>暴力行為、過激な暴力や現実の暴力</li>
										<li>爆発物・発火装置、自家製・急造の銃器の作成方法を説明する内容</li>
										<li>動物に対する残虐な行為を表現あるいはそれらを助長する内容</li>
										<li>ワクチンの安全性に関する虚偽又は誤解を招く主張</li>
										<li>大きな惨事がデマであると主張する内容</li>
										<li>自傷行為、摂食障害又は薬物の乱用を表現又は助長する内容</li>
										<li>身体的損害や物的損害を引き起こす可能性のあるもの</li>
										<li>ユーザーや公共の安全を脅かすもの</li>
										<li>人に危害を与えることを助長したり称賛したりするようなもの</li>
										<li>その他法令又は公序良俗に反するもの</li>
									</ul>
									<br/>
									<p>行動指針</p>
									<p>禁じられている行動:</p>
									<ul className="terms-list-ul">
										<li>不快感を与える名前や画像・動画の使用（例: 卑猥な言葉、ヌード画像）</li>
										<li>無礼な態度や行動、又は人物若しくは団体に成りすます行為</li>
										<li>ハラスメントやストーカー行為</li>
										<li>未成年に害を及ぼす、又は搾取する行為</li>
										<li>憎悪表現や差別的発言</li>
										<li>スパム行為</li>
										<li>個人情報の悪用</li>
										<li>無許可で他人のアカウントを利用する行為</li>
										<li>違法行為に従事又はこれを唆し若しくはほう助する行為</li>
										<li>上記のいずれかの行為に他者を巻き込む行為</li>
									</ul>
									<br/>
									<p>技術的手段</p>
									<p>禁じられている行動:</p>
									<ul className="terms-list-ul">
										<li>本サービスに関してスクレイピング、再利用、二次的著作物を創作、逆コンパイル／リバースエンジニアリング／オルター／アーカイブ／逆アセンブルをすること、及び本サービスのセキュリティ保護／フィルター／レート制限／デジタル著作権管理を回避する行為</li>
										<li>悪意のあるコンピュータプログラム、スクリプト、コードの送信・組み込み</li>
										<li>合理的な範囲を超える数のリクエストをサーバーに送信する行為</li>
										<li>その他、本サービスを操る、障害を与える、又は干渉する行為</li>
									</ul>
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１２条（知的財産権等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>イベント主催者は、本サービスを利用して行うライブ・ストリーミングチケットの組成にあたり、自らが実施する権限を有するライブ・ストリーミングチケットのみを組成することとし、かつ、ライブ・ストリーミングチケットに関連して投稿その他の方法により送信するにあたり、適法な権利を有しているコンテンツのみを利用するものとし、第三者の権利を侵害してはならないものとします。</li>
									<li>イベント主催者は、本サービスを利用して行うライブ・ストリーミングチケットの組成にあたり、自らが配信する権限を有する動画に係るライブ・ストリーミングチケットのみを組成することとし、かつ、ライブ・ストリーミングチケットに関連して投稿その他の方法により送信するにあたり、適法な権利を有しているコンテンツのみを利用するものとし、第三者の権利を侵害してはならないものとします。</li>
									<li>イベント主催者は、自らが配信する権限を有する動画及び画像についてのみ、本サービス上でストリーミング配信に利用するものとします。</li>
									<li>本サービスにかかる知的財産権は、全て当社又は当社に適法にライセンスをする第三者に帰属するものとし、イベント主催者は、本利用契約の締結によっても、本サービスの利用権を取得するにすぎず、知的財産権の譲渡又はライセンスを受けるものではありません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１３条（個人情報保護）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、イベント主催者が本サービスを利用した際に提供した個人情報について、法令に従い、適切に取り扱うものとし、以下の利用目的の範囲内において取り扱います。
										<ol className="terms-list-ol">
											<li>本サービスの提供にかかる業務を行うこと。（業務上必要な連絡、通知等を契約者に対して行うことを含みます。）</li>
											<li>本サービスレベルの維持向上を図るため、アンケート調査及びその分析を行うこと。</li>
											<li>当社の商品、サービスに関する情報（本サービスに限らず、当社の別商品、サービス又は当社の新規商品、サービス紹介情報等を含みます。）又は提携先の商品、サービス等の情報を、イベント主催者がアクセスした当社のWebページその他イベント主催者の端末装置上に表示し、もしくはメール、郵便等により送付し、又は電話すること。なお、お客様は、当社が別途定める方法により、これらの取り扱いを中止又は再開することができます。</li>
											<li>前各号に付属する業務を行うこと。</li>
											<li>その他イベント主催者から得た同意の範囲内で利用すること。</li>
										</ol>
									</li>
									<li>当社は、イベント主催者の同意に基づき必要な限度において個人情報を第三者に提供する場合があります。また、本サービスの提供に係る業務における個人情報の取扱いの全部又は一部を第三者に委託する場合にあっては、当社は、当社の監督責任下において個人情報を第三者に委託するものとします。</li>
									<li>前項にかかわらず、法令に基づく請求又は特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律（平成13年法律第137号）第４条に基づく開示請求の要件が充足された場合、その他法令に基づく場合は、当社は当該請求の範囲内で個人情報を請求者に開示する場合があります。</li>
									<li>当社は、イベント主催者による本サービスの利用により取得した、ライブ・ストリーミングチケットの購入数量、購入頻度、購入された国、イベント主催者の属性、ライブ・ストリーミングチケット等を購入したお客様の属性、その他一切の情報について、無償で、無期限で、地域含めて何らの制限なく自由に利用することができるものとします。なお、本条項の規定は、イベント主催者が本サービスの利用を終了した後も有効に存続するものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１４条（禁止行為）</h2>
							<div className="terms-text">
								<p>イベント主催者は、本サービスの利用に際し、以下に定める行為（それらを誘発する行為や準備行為も含みます。）を行わないことを当社に対し表明し、保証するものとします。</p>
								<ol className="terms-list-ol">
									<li>日本国の法令その他適用可能性のある国・地域の法令に違反する行為</li>
									<li>商品代金又は商品代金と実質的に同視すべき代金を、当社の定めるシステム又は手続きによらずお客様より直接受領する行為</li>
									<li>本サービスを利用して、以下に該当する情報を含むライブ・ストリーミングチケットを組成する行為及び以下に該当する情報を本サービス上に表示する行為
										<ol className="terms-list-ol">
											<li>当社、他のイベント主催者、メディアその他の第三者の、名誉、信用その他の権利・利益を毀損・侵害する情報</li>
											<li>過度に暴力的又は残虐な表現を含む情報</li>
											<li>コンピューター・ウィルスその他の有害なプログラムを含む情報</li>
											<li>わいせつな表現を含む情報</li>
											<li>差別を助長する表現を含む情報</li>
											<li>自殺、自傷行為を助長する表現を含む情報</li>
											<li>薬物の不適切な利用を助長する表現を含む情報</li>
											<li>反社会的な表現を含む情報</li>
											<li>ジャンクメール、スパムメール、チェーンメール等の第三者への拡散を求める情報</li>
											<li>違法な勧誘、宣伝等を含む情報</li>
											<li>他人に不快感を与える表現を含む情報</li>
											<li>面識のない異性との出会いを目的とした情報</li>
											<li>法令・ガイドラインその他の法規範に違反する情報</li>
											<li>上記に類する情報</li>
										</ol>
									</li>
									<li>第三者の使用するソフトウェア、ハードウエアなどの機能を破壊・妨害するプログラムなどの投稿などをする行為</li>
									<li>当社のサーバー又はネットワークの機能を破壊・妨害する行為</li>
									<li>本サービス、当社の配信する広告又は当社のサイト上で提供されているサービス・広告を妨害する行為</li>
									<li>第三者の個人情報や履歴情報及び属性情報などを、当該第三者に無断で収集・蓄積する行為</li>
									<li>本サービス又は本サービスを通じて入手した情報を、提供の趣旨に背く目的で利用する行為</li>
									<li>第三者のログイン情報を使用して本サービスを利用する行為</li>
									<li>手段の種類を問わず他人からログイン情報を入手し、又は他人にログイン情報を開示・提供する行為</li>
									<li>本サービスに関連して、反社会的勢力などに直接・間接に利益を提供する行為</li>
									<li>当社の定めるガイドライン（第11条規定のものを含みます。）に反する行為</li>
									<li>その他、上記に準じて当社が不適切と判断する行為</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１５条（本規約に違反した場合の措置等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、イベント主催者が次の各号の一に該当し又は該当するおそれがあると当社が判断した場合には、当社の裁量により、その該当事由の軽重や当社側の帰責性の有無を問わず、何らの通知も行うことなく、アカウントの停止若しくは削除、本サービスの利用の一時停止若しくは制限、本利用契約の解除等の措置（以下「利用停止等」といいます。）を講じることができるものとします。
									<br />
										<ol className="terms-list-ol">
											<li>第13条に定める禁止行為を行った場合又は行うおそれがある場合</li>
											<li>本規約のいずれかの条項に違反し、又は違反したとの通報を受けた場合</li>
											<li>第三者の知的財産権、肖像権、プライバシー権その他の権利を侵害するおそれがある場合</li>
											<li>本サービスの利用につきお客様、当社、又は他のイベント主催者との間でトラブルを発生させ、当該トラブルにつきイベント主催者の責に帰すべき事由があることが判明した場合</li>
											<li>当社に提供された情報の全部又は一部につき虚偽の事実があることが判明した場合</li>
											<li>イベント主催者が支払停止若しくは支払不能となり、又は破産手続開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
											<li>当社からの問い合わせその他の回答を求める連絡に対して３０日間以上応答がない場合</li>
											<li>反社会的勢力等であるか、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等、反社会的勢力等との何らかの交流若しくは関与を行っている場合</li>
											<li>イベント主催者（イベント主催者の代表者及び従業員を含む）又はイベント主催者に所属するアーティストが当社、他のイベント主催者、その他の第三者の、名誉、信用その他の権利・利益を毀損・侵害する行為を行った場合</li>
											<li>その他前各号に類する事由がある場合</li>
										</ol>
									</li>
									<li>イベント主催者は、利用停止等の後も、当社及び第三者に対する本利用契約上の一切の義務及び債務を免れるものではありません。</li>
									<li>当社は、第1項の場合、当該事由が解消するまでの間、当社が保有する商品代金の支払いを留保することができ、また、当該商品代金から当社が被った損害額を控除することができます。</li>
									<li>当社は、本条に基づき当社が行った措置によりイベント主催者に生じた損害について一切の責任を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１６条（不可抗力等）</h2>
							<div className="terms-text">
								<p>当社は、天災地変、戦争、暴動、内乱、法令の改廃制定、公権力による命令処分、同盟罷業その他の争議行為、輸送機関の事故等、自らの責に帰すことのできない不測の事態による履行遅滞または履行不能についてイベント主催者に対してその責を負わないものとします。</p>
							</div>
							<h2 className="terms-title2">第１７条（損害賠償）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、イベント主催者による本サービスの利用について、本利用規約及び別紙「キャンセルポリシー」に別段の定めがある場合を除き、一切損害賠償義務を負わないものとします。</li>
									<li>当社がイベント主催者に対して何らかの損害賠償責任を負う場合であっても、その範囲は、イベント主催者に生じた直接かつ通常の損害に限られ、いかなる場合も、当社は特別損害、間接的損害、派生的損害、付随的損害、懲罰的損害及び逸失利益（ライブ・ストリーミングチケットが返金された場合における売上補填を含みます。）について責任を負いません。また、当社がイベント主催者に対して負う責任の総額は、当該損害の発生事由に係る本サービスの対価として当社がイベント主催者から受領した金額の総額を上限とします。</li>
									<li>当社は、イベント主催者に対して、いかなる場合も、本規約に明示的な定めがあるものを除き、配信状況等（視聴者数や時間等を含みます。）やシステム情報・技術的情報の分析、開示及び報告、並びに当社サイト、SNSその他あらゆる媒体における謝罪広告・説明文等の掲載その他の一切の非金銭的な義務を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１８条（秘密保持）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>イベント主催者は、本サービスに関連して当社が秘密である旨指定して開示した非公知の情報（以下「秘密情報」といいます。）を秘密として厳重に取り扱い、当社の事前の書面による承諾がある場合を除き、第三者に開示しないものとします。</li>
									<li>イベント主催者は、当社から求められた場合はいつでも、当社の指示に従い、遅滞なく、秘密情報及び秘密情報を記載又は記録した書面その他の記録媒体物並びにその全ての複製物等を返却又は廃棄しなければなりません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第１９条（反社会的勢力の排除等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社及びイベント主催者は、それぞれ相手方に対し、次の各号の事項を表明し、保証するものとします。
										<ol className="terms-list-ol">
											<li>自ら及びその役員（取締役、執行役、執行役員、監査役又はこれらに準ずる者をいう。）が、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、準暴力団、準暴力団関係企業、準暴力団と実質的に関係のある者及び組織、総会屋、社会運動等標ぼうゴロ又は特殊知能暴力集団、その他これらに準ずる者（以下、総称して「反社会的勢力」という。）ではないこと、及び反社会的勢力と社会的に非難されるべき関係を有していないこと</li>
											<li>自己又は第三者の利益を図る目的又は第三者に損害を与える目的をもって反社会的勢力を利用し、又は反社会的勢力に対して資金等を提供し、又は便宜を供与するなど、反社会的勢力の維持、運営に協力し、又は関与していると認められる関係を有していないこと</li>
											<li>反社会的勢力に自己の名義を利用させ、本利用契約を締結するものでないこと</li>
											<li>自ら又は第三者を利用して、相手方に対し、脅迫的な言動又は暴力を用いる行為、風説を流布し、偽計又は威力を用いて相手方の業務を妨害し又は信用を毀損する行為、法的な責任を超えた不当な要求行為、その他これらに準ずる行為をしないこと</li>
										</ol>
									</li>
									<li>当社及びイベント主催者は、相手方が前項の表明保証に違反した場合、何らの通知又は催告をすることなく、本利用契約を解除することができるものとします。</li>
									<li>当社及びイベント主催者は、前項の規定により本利用契約の全部又は一部を解除したことにより相手方に損害が生じた場合であっても、これを一切賠償することを要しないものとします。また、本条に違反した当事者は、当該違反に起因する一切の損害を賠償しなければならないものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２０条（保証の否認及び免責）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、本サービスの利用につき、イベント主催者が期待する売上があること、その他のイベント主催者の特定の目的への適合性、経済的価値、正確性、有用性、完全性、適法性、イベント主催者に適用のある団体の内部規則等への適合性を有すること、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、如何なる保証も行うものではありません。</li>
									<li>当社は、本サービスが全ての情報端末及び情報端末のＯＳやブラウザのバージョンに対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳやブラウザのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、イベント主催者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</li>
									<li>本サービスに接続する第三者のサービスや、イベント主催者又はお客様が使用するソフトウェア等の仕様の変更に伴い、本サービスの動作に不具合が生じる可能性があることにつき、イベント主催者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</li>
									<li>本サービスの利用に必要なソフトウェア（エンコーダーソフトウェアを含みます。）、機材、パソコン、会場のものを含むインターネット環境、バックアップ等、一切の機器・設備は、イベント主催者の費用と責任により用意するものとし、これらの機器・設備の不具合・故障等により本サービスの利用に影響があった場合、当社は一切の責任を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２１条（本サービスの利用終了）</h2>
							<div className="terms-text">
								<p>イベント主催者は、別途当社の定める方法により、本利用契約を解約することができます。ただし、イベント主催者は、本利用契約を解約後も、解約前に発生済みの当社及び第三者に対する本利用契約上の一切の義務及び債務を免れるものではありません。</p>
							</div>

							<h2 className="terms-title2">第２２条（本サービスの変更・中断・終了等）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>当社は、イベント主催者に事前に通知することなく、本サービスの内容の全部又は一部を変更又は追加することができるものとします。</li>
									<li>当社は、事前に、本サービス上又は当社の運営するウェブサイト上への掲示その他当社が適当と判断する方法でイベント主催者に通知することにより、当社の裁量で、本サービスを終了することができるものとします。ただし、緊急の場合はイベント主催者への通知を行わない場合があります。</li>
									<li>当社は、以下各号の事由が生じた場合には、イベント主催者に事前に通知することなく、本サービスの一部又は全部を一時的に中断することができるものとします。
										<br />
										<ol className="terms-list-ol">
											<li>本サービス用の通信機器設備等（外部サーバー、システムを含みます。以下同じです。）に関わるメンテナンス（保守・点検）や修理を定期的又は緊急に行う場合</li>
											<li>アクセス過多、その他予期せぬ要因で本サービス用の通信機器設備等に負荷が集中した場合</li>
											<li>イベント主催者及び第三者のセキュリティを確保する必要が生じた場合</li>
											<li>電気通信事業者の役務が提供されない場合</li>
											<li>天災等の不可抗力その他15条規定の事由により本サービスの提供が困難な場合</li>
											<li>火災、停電、その他の不慮の事故又は戦争、紛争、動乱、暴動、労働争議等により本サービスの提供が困難な場合</li>
											<li>法令又はこれらに基づく措置により本サービスの運営が不能となった場合</li>
											<li>その他前各号に準じ当社が必要と判断した場合</li>
										</ol>
									</li>
									<li>当社は、本条に基づき当社が行った措置によりイベント主催者に生じた損害について一切の責任を負いません。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２３条（権利義務の譲渡）</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>イベント主催者は、当社の書面による事前の承諾がある場合を除き、本利用契約に基づくイベント主催者の権利若しくは義務、又は本利用契約上の地位について、第三者への譲渡、承継、担保設定、その他一切の処分をすることはできません。</li>
									<li>当社が、本サービスにかかる事業を第三者に譲渡し、又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等により本サービスにかかる事業を包括承継させたときは、当社は、当該事業譲渡等に伴い、本利用契約上の地位、権利及び義務並びに登録情報その他のイベント主催者に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、イベント主催者は、あらかじめこれに同意するものとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">第２４条（分離可能性）</h2>
							<div className="terms-text">
								<p>本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
							</div>

							<h2 className="terms-title2">第２５条（準拠法及び合意管轄）</h2>
							<div className="terms-text">
								<p>本規約は日本法に準拠するものとし、本規約に起因し又は関連する一切の紛争については、訴額及び手続に応じ、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
							</div>

							<h2 className="terms-title2">第２６条（言語）</h2>
							<div className="terms-text">
								<p>本規約は、日本語を正文とします。本規約の他の言語による翻訳文と日本語との間に相違がある場合には、日本語版が優先するものとします。</p>
							</div>

							<h2 className="terms-title2">第２７条（存続条項）</h2>
							<div className="terms-text">
								<p>第5条第3項、第6条第3項、第8条、第11条第2号、第12条第4項及び第5項、第15条第2項及び第3項、第16条、第17条、第18条、第19条第3項、第20条、第21条、第22条第4項、第24条、第25条、第26条、本条並びに条項の性質に鑑み契約終了後も適用されると合理的に解釈される条項については、その終了理由を問わず契約終了後もなお、効力を有するものとします。</p>
							</div>
						</section>
					</section>

					<section className="terms">
						<h1 className="common-title">キャンセルポリシー</h1>

						<section>
							<h2 className="terms-title2">イベント主催者の都合によるイベントの中止・延期</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>万が一、イベント主催者の都合によりストリーミング配信イベントの開催がキャンセルされた場合、又は開催日変更や会場変更によりお客様にライブ・ストリーミングチケットの代金の返金対応が生じた場合は、原則として、イベント主催者の費用と責任によりお客様に対する返金対応を行うものとします。</li>
									<li>前号にかかわらず、当社は、当社の裁量により適切であると判断した場合には、お客様に対するライブ・ストリーミングチケットの返金対応を行うことができます。この場合の返金事務手数料については別途定めることとします。</li>
								</ol>
							</div>

							<h2 className="terms-title2">不可抗力又は障害等によるイベント配信の中断・中止</h2>
							<div className="terms-text">
								<p>
									不可抗力、又は本サービスの障害等、専ら当社の責めに帰すべき事由によりイベントのライブ配信が中断・中止された場合、イベント主催者の選択により、下記のいずれかの対応を行います。
								</p>
									<ol className="terms-list-ol">
										<li>当日のライブ配信を中止せず、アーカイブ期間を延長する</li>
										<li>当日のライブ配信を中止し、収録映像を利用して別日程（同日の遅い時間又は翌日以降）でイベントを開催する</li>
										<li>当日のライブ配信を中止し、別日程（同日の遅い時間又は翌日以降）にライブ配信を延期する</li>
										<li>イベント開催自体を中止する</li>
									</ol>
								<p>
									上記のいずれの場合も、イベント主催者が希望する場合には、当社は当該イベントに係るライブ・ストリーミングチケットの返金対応を行います。返金事務手数料について は別途定めることとします。
									<br />
									なお、上記１～4の措置に伴い発生する費用（機材、会場等に係る費用を含みます。）については、イベント主催者の負担とします。
								</p>
							</div>
						</section>
					</section>
				</>
			) : (
				<>
					<section className="terms">
						<h1 className="common-title">Terms of Use - IIJ Teatrista</h1>
						<div className="terms-text">
							<p className="iij-number">■IIJ-MSP005-0001</p>
							<p>Established February 1, 2022</p>
							<p>Internet Initiative Japan Inc.</p>
						</div>

						<section>
							<div className="terms-text">
								<p>These Terms of Use ("Terms") stipulate the conditions for the use of the "Virtual Theater Space Powered by IIJ" service ("Service") provided by Internet Initiative Japan Inc. ("Company") and shall apply to every customer using the Service.</p>
							</div>
						</section>

						<section>
							<h2 className="terms-title2">Article 1. Definitions</h2>
							<div className="terms-text">
								<p>As used in the Terms, the meanings of the expressions listed in the following items shall be as specified in each item.</p>
								<ol className="terms-list-ol">
									<li>Live Streaming Ticket
										<br />
										An electronic ticket created using the Service that enables the participation in an event.
									</li>
									<li>
										Event Organizer
										<br />
										A person (a corporation or an individual) who uses the Service to create and display using the Service a Live Streaming Ticket.
									</li>
									<li>
										Customer
										<br />
										A person who purchases a Live Streaming Ticket sold by an Event Organizer using the Service.
									</li>
									<li>
										Intellectual Property Right
										<br />
										A copyright (including the rights under articles 27 and 28 of the Copyright Act), patent right, utility model right, trademark right, design right, and any other intellectual property right (including the right to acquire any of the said rights and the right to apply for a registration, etc., with respect to any of the said rights) and ideas and know-how, etc.
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 2. Acceptance of the Terms</h2>
							<div className="terms-text">
								<p>A Customer shall use the Service in accordance with the Terms and shall not be able to use the Service unless the Customer accepts the Terms. The contents specified in documents distributed and disseminated by the Company regarding the Service shall form a part of the Terms to be accepted by a Customer.</p>
							</div>

							<h2 className="terms-title2">Article 3. Revision and changes of the Terms</h2>
							<div className="terms-text">
								<p>
									The Company may at any time, at its discretion, change or make additions to the contents of the Terms, subject to display on the Company’s website. Unless otherwise specified by the Company, the changed Terms shall take effect upon posting on the website operated by the Company (https://www.harusailive.jp/) (including any change of the domain or content of the website if any were to occur for any reason).
									<br />
									A Customer who continues to use the Service after a change of the Terms will be deemed to have accepted the changed Terms.
								</p>
							</div>

							<h2 className="terms-title2">Article 4. Registration procedure</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>A person can apply to be registered as a Customer by accepting the Terms and providing the Company with the applicant’s registration information.</li>
									<li>The Company shall accept a registration application pursuant to the preceding paragraph except in any of the cases specified below in article 5.</li>
									<li>An applicant must provide true and accurate registration information. The Company will not be held responsible for any damage caused to an applicant due to falsehood, error, or omission in the registration information.</li>
									<li>Any change in the registration information must be notified to the Company without delay in accordance with the method specified separately by the Company. If no such notice is given and a notification from the Company therefore fails to arrive, the notification from the Company shall be deemed to have arrived at the normal time of arrival.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 5. Denial of registration</h2>
							<div className="terms-text">
								<p>The Company may deny a registration if a registration applicant pursuant to article 4.1 ("Registration Applicant") meets any of the following conditions.<br/>In such a case the registration applicant will be notified by e-mail.</p>
								<ol className="terms-list-ol">
									<li>If an applicant is a minor, an adult ward, or a person under curatorship or assistance, who has not obtained the consent of a legal representative, guardian, curator, or assistant.</li>
									<li>If the registration information provided to the Company is false, incorrect, or incomplete.</li>
									<li>If a registration applicant was previously or is currently with respect to the use of the Service subject to a Service usage suspension measure such as account deletion, etc.</li>
									<li>If a registration applicant is in the judgment of the Company an element of organized crime, etc. (meaning a crime syndicate, a crime syndicate member, a right-wing group, an anti-social organization, or an entity similar to any of the foregoing) or in any way interacts or is involved with organized crime, etc., such as by cooperating with or participating in the maintenance, operation, or management of organized crime, etc., through the provision of funds or otherwise.</li>
									<li>Other cases in which the Company determines that registration is not appropriate.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 6. Account management</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>After this agreement is concluded with a Customer, the Company will assign to the Customer an account for the use of the Service.</li>
									<li>A Customer shall manage and preserve an account at the Customer’s own risk, and shall not allow the use of an account by a third party, nor loan, transfer, change the name of, or trade, etc., an account. A Customer registered as the holder of an account will be deemed to have used the Service when the Company has established that the account of the Customer matches with the account concerned.</li>
									<li>A Customer shall be liable for damages caused by a Customer’s insufficient account administration or by the usage of a Customer’s account by a third party. The Company is free from any and all liability.</li>
									<li>If a Customer finds that an account has been fraudulently used or used by a third party, the Customer shall immediately notify the Company and follow the instructions of the Company.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 7. Use by minors</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If an account applicant is a minor, the consent of a legal representative such as a parent or guardian must be obtained prior to applying to register as a Customer and prior to executing a purchase and settlement procedure for a Live Streaming Ticket using the Service. At the time a minor has completed an application to register as a Customer or at the time a minor has executed the settlement procedure for a Live Streaming Ticket using the Service, with respect to the use of the Service and the provisions of Terms, etc., the consent of a legal representative will be considered to have been obtained.</li>
									<li>If a minor who has not obtained the consent of a legal representative, pretending that consent has been obtained or pretending to have reached the age of majority, has executed the purchase and settlement procedure for a Live Streaming Ticket using the Service, or has used other deceptive means to misrepresent self as a having the capacity to contract, any and all legal acts concerning the purchase and settlement procedure for a Live Streaming Ticket shall not be cancelable.</li>
									<li>If a Customer who was a minor at the time of the acceptance of the Terms uses the Service after reaching the age of majority, the Customer is deemed to have ratified all legal acts relating to the Service.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 8. Use of external services, etc.</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>For the registration with the Service, authentication, and payment, a Customer may be able to use the information registered on a Customer’s account with an external service. In such a case, a Customer shall agree that the Company obtains registered information, including personal information, from the external service concerned.</li>
									<li>Further to the provisions of the preceding paragraph, when a Customer uses the functions of an external service in using the Service, a Customer shall comply with these Terms and any terms of use and other conditions specified by the external service.</li>
									<li>The responsibility for external services shall rest with the third party who provides the external services. The Company provides no guarantee for external services.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 9. Service content and matters to be observed when using the Service</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>When completing a purchase procedure and payment settlement for a Live Streaming Ticket using the Service, a Customer shall use the Service in compliance with the Terms and any accompanying terms.</li>
									<li>When purchasing a Live Streaming Ticket, a Customer shall read the information about the posted event carefully and purchase the Live Streaming Ticket at the Customer’s own risk.</li>
									<li>A Customer shall in accordance with the method specified by the Company pay the purchase price of a Live Streaming Ticket to the Company, which is authorized by the Event Organizer to receive the purchase price on the Event Organizer’s behalf. A Customer who has purchased a Live Streaming Ticket shall not pay the purchase price directly to the Event Organizer using the Service.</li>
									<li>A contract for the purchase of a Live Streaming Ticket is concluded between an Event Organizer and a Customer. The Company is not a party to a contract for the purchase of a Live Streaming Ticket.</li>
									<li>A contract for the purchase of a Live Streaming Ticket is concluded between an Event Organizer and a Customer upon payment confirmation of a Live Streaming Ticket at the Company and display of the purchase of the Live Streaming Ticket concerned in the purchase history of a Customer’s account.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 10. Responsibility for sales of Live Streaming Tickets</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Any dispute or problem concerning a sale of a Live Streaming Ticket that arises between an Event Organizer and a Customer (such as, without limitation, a problem caused in connection with published event information) shall be settled at the cost and liability of the Customer. The Company is free of any and all liability.</li>
									<li>If the Company has incurred expenses (including but not limited to settlement fees and attorney’s fees) necessary for resolving a dispute or problem arising in connection with a sale of a Live Streaming Ticket pursuant to the preceding paragraph, out such expenses a Customer shall pay the amount specified by the Company. A customer shall incur any charges necessary for effecting the payment.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 11. Payment agency service</h2>
							<div className="terms-text">
								<p>When a Customer purchases a Live Streaming Ticket using the Service, the Company will on behalf of the Event Organizer collect the payment for the Live Streaming Ticket ("Ticket Price").</p>
							</div>

							<h2 className="terms-title2">Article 12. Ticket price refund</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If an Event Organizer decides to make a refund because a performance was discontinued, etc., the Company will refund the ticket price.</li>
									<li>The timing and amount of a refund shall be at the discretion of an Event Organizer. If an Event Organizer decides to make a refund, at the earliest, the Company will process the refund by the end of the month following the month in which the event was scheduled to take place.</li>
									<li>This article stipulates all matters regarding the refund of a ticket price. No refund of a ticket price shall be made that is due to the circumstances of a Customer.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 13. Intellectual property rights, etc.</h2>
							<div className="terms-text">
								<p>All intellectual property rights relating to the Service belong to the Company or a third party who legally licenses the Company. By concluding this agreement a Customer acquires only the right to use the Service and no intellectual property right is assigned or licensed to a Customer.</p>
							</div>

							<h2 className="terms-title2">Article 14. Personal information protection</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Personal information provided by a Customer using the Service will be handled by the Company appropriately in accordance with laws and regulations and within the scope of the following purposes of use.
									<ol className="terms-list-ol">
										<li>For the execution of operations related to the provision of the Service (including contacting or notifying, etc., a Customer when necessary for operations.)</li>
										<li>For questionnaire surveys and analyses thereof to maintain and enhance the Service level.</li>
										<li>For the display on a Company web page accessed by a Customer or on other terminal devices of a Customer, or for the communication by e-mail, postal mail, etc., or telephone call, of information about the Company's products and services (including, without limitation to the Service, introductory information on other products and services of the Company or new products and services of the Company) or products and services of partners of the Company. A Customer can terminate or resume such handling by the Company using the Company’s separately stipulated method.</li>
										<li>For operations associated with each of the foregoing items.</li>
										<li>Other uses within the scope of consent obtained from a Customer.</li>
									</ol></li>
									<li>In order to convey event information, etc., from an Event Organizer, after implementing necessary security measures, the Company will provide a Customer’s customer registration information to an Event Organizer from whom a Customer has purchased a Live Streaming Ticket. In addition, the Company may provide personal information to a third party to the extent necessary based on the consent of a Customer. If the handling of personal information occurring in the course of operations related to the provision of the Service is in part or in total outsourced to a third party, the Company shall furnish a third party with personal information under the supervisory responsibility of the Company.</li>
									<li>Without limiting the generality of the preceding paragraph, in a case where the requirements are fulfilled of a disclosure request under laws and regulations or a disclosure request under article 4 of the Act on the Limitation of Liability for Damages of Specified Telecommunications Service Providers and the Right to Demand Disclosure of Identification Information of the Sender (Act No. 137 of 2001), or a disclosure request under other laws and regulations, the Company may within the scope of the request disclose personal information to the requesting party.</li>
									<li>The Company shall be able to use free of charge, at its liberty, without any restrictions, information obtained from a Customer through the use of the Service such as a Customer’s age, quantity and frequency of Live Streaming Ticket purchases, country of purchase, attributes of a Customer who has purchased a Live Streaming Ticket, anonymized information with personal information deleted, statistical data, and any and all other information.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 15. Prohibited acts</h2>
							<div className="terms-text">
								<p>A Customer shall represent and warrant to the Company that a Customer will not engage in, induce, or prepare any of the following acts.</p>
								<ol className="terms-list-ol">
									<li>Breach the laws of Japan and other applicable national or regional laws</li>
									<li>Pay a ticket purchase price, or a price in substance corresponding to a ticket purchase price, directly to an Event Organizer instead of through the system or procedure stipulated by the Company</li>
									<li>Post, etc., a computer program that destroys or interferes with functions of software and hardware used by a third party</li>
									<li>Destroy or interfere with a function of a server or network of the Company</li>
									<li>Interfere with services or advertisements disseminated by the Service or the Company, or services or advertisements provided on a website of the Company.</li>
									<li>Collect or accumulate personal information, biographical information, or attribute information, etc., of a third party without the permission of the third party.</li>
									<li>Use the Service or information obtained through the Service for a purpose in conflict with the intent of their provision.</li>
									<li>Use the Service by way of the login information of a third party.</li>
									<li>Irrespective of the method used, obtain the login information of another person or disclose or provide own login information to another person</li>
									<li>In connection with the Service, engage in action that directly or indirectly provides benefits to organized crime, etc.</li>
									<li>Other acts similar to those stated above deemed inappropriate by the Company</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 16. Measures, etc., in case of a breach of the Terms</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If the Company determines that a Customer meets any of the following conditions, the Company may at its discretion, without notice, temporarily suspend or limit a Customer’s use of the Service and take any other measures that the Company deems necessary, such as barring a Customer from future use (“Suspension of Use, etc.") of the Service.
									<ol className="terms-list-ol">
										<li>If an action pursuant to article 14 has been committed or may have been committed.</li>
										<li>If any provision of the Terms has been breached or a report of a breach has been received by the Company.</li>
										<li>If a third party’s intellectual property right, portrait right, privacy right, and other right may have been breached.</li>
										<li>If a Customer is found responsible for a dispute in connection with the use of the Service that arises with another Customer, the Company, or an Event Organizer.</li>
										<li>If it is found that all or part of the information provided by a Customer to the Company is false.</li>
										<li>If a Customer experiences equity insolvency or balance sheet insolvency or has filed for bankruptcy proceedings or similar proceedings.</li>
										<li>If a Customer fails for 30 days or more to respond to an inquiry from the Company or other inquiry requesting a response.</li>
										<li>If a Customer is an element of organized crime, etc., or has some kind of interaction or involvement with organized crime, etc., such as cooperating or participating in the maintenance, operation, or management of organized crime, etc., through the provision of funds or other means.</li>
										<li>If other reasons exist similar to those stated above.</li>
									</ol></li>
									<li>Suspension of Use, etc., does no exempt a Customer from any duty and obligation that a Customer has under the Terms to the Company and third parties.</li>
									<li>The Company is free of any and all liability for any damages sustained by a Customer due to measures taken by the Company under this article.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 17. Force majeure</h2>
							<div className="terms-text">
								<p>The Company shall not be liable to a Customer for delayed performance or non-performance due to unforeseen circumstances outside the responsibility of the Company such as natural disaster, war, riot, civil war, amendment and abolition of laws and regulations, orders and dispositions by governmental authority, labor strike and other dispute, or transportation infrastructure accident, etc.</p>
							</div>

							<h2 className="terms-title2">Article 18. Compensation for damages</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If a Customer in connection with the use of the Service sustains damages due to a reason attributable to the Company, the Company will be liable only for direct and ordinary damages actually sustained by the Customer. The maximum limit of such compensation for damages shall be the price of the Live Streaming Ticket which the Customer purchased and which is the cause of the damages.</li>
									<li>The maximum limit of compensation for damages set forth in the preceding paragraph shall not apply if a Customer has sustained damages due willful misconduct or gross negligence of the Company.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 19. Confidentiality</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Except in case the Company has given advance written permission, a Customer shall hold in confidence any non-public information concerning the Service disclosed by the Company designated as confidential.</li>
									<li>Whenever requested by the Company, a Customer shall without delay in accordance with the instructions of the Company return or dispose of information stated in the preceding paragraph and any documents and other recording media where such information is written or recorded, and all copies thereof, etc.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 20. Exclusion, etc., of organized crime</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>The Company and a Customer mutually warrant the following matters.
									<br />
									<ol className="terms-list-ol">
										<li>That the warranting party is not a crime syndicate, a crime syndicate related enterprise, a corporate racketeer, or a similar organization, or a constituent member or related party thereof (“Organized Crime”).</li>
										<li>That no corporate officer, employee, or other hired person of the warranting party is an element of Organized Crime.</li>
										<li>That the warranting party or its corporate officers, employees, or other hired persons have not and will not in future act to encourage activities of organized crime such as by providing funds to organized crime.</li>
										<li>That the warranting party, or its corporate officers, employees, or other hired persons have neither directly nor through a third party engaged in violence, intimidation, or defamation against the other party and its customers.</li>
									</ol></li>
									<li>The Company and a Customer may cancel for cause this agreement without notice or warning to the other party if it is reasonably found that the other party has breached any of the preceding paragraphs. In such a case, the party in breach shall not be able to bring any claim against the other party for damages sustained as a result of the cancellation.</li>
									<li>The Company and a Customer shall be able to claim from the other party compensation for damages sustained as a consequence if the other party has breached a provision of paragraph 1.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 21. No warranty; disclaimer</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>With respect to the use of the Service, the Company offers no warranty for conformity to a specific purpose of a Customer, economic value, accuracy, usefulness, completeness, legality, conformity with organization-internal rules, etc., applicable to a Customer, absence of security flaws, errors, bugs or defects, and non-infringement on rights of third parties.</li>
									<li>The Company does not guarantee that the Service is compatible with all information terminals and the OS and browser versions of the information terminals. A Customer shall acknowledge in advance that the Service may malfunction due to version upgrades, etc., of the OS of the information terminal for using the Service. The Company does not guarantee that such a malfunction will be resolved by a program modification, etc., performed by the Company if such a malfunction occurs.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 22. Change, interruption, and termination of the Service, etc.</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>The Company may without prior notice to a Customer change or add to the contents of the Service.</li>
									<li>The Company may terminate the Service at the discretion of the Company by notifying a Customer in advance by posting using the Service website or on a website operated by the Company or by any other method that the Company deems appropriate. However, notice to Customers may not be given in in case of an emergency.
									<ol className="terms-list-ol">
										<li>In any of the following cases the Company shall be able to temporarily suspend the Service in part or in total without advance notice to Customers.</li>
										<li>In case of regular or urgent maintenance or repair related to communication equipment, etc., for the Service</li>
										<li>In case of a concentrated load on the system due to excessive access or other unexpected factors</li>
										<li>In case necessary to ensure the security of Customers and third parties</li>
										<li>In case of unavailability of service of a telecommunications carrier</li>
										<li>In case Service provision is rendered impracticable by an event of force majeure such as natural disaster</li>
										<li>In case Service operation is rendered impracticable by laws and regulations or measures based thereon</li>
										<li>In other cases similar to the foregoing when deemed necessary by the Company</li>
									</ol></li>
									<li>The Company is free of any and all liability for damages sustained by a Customer due to measures taken by the Company based on this article.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 23. Assignment of rights and obligations</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Unless with the prior written permission of the Company, a Customer may not transfer to, cause succession by, establish as collateral for the benefit of, nor otherwise dispose with respect to a third party of any right or obligation of a Customer under this User Agreement or a position of a Customer under this User Agreement.</li>
									<li>A Customer shall agree in advance that in the event the Company transfers operations related to the Service to a third party, or the Company causes by merger or company spin-off  the comprehensive succession to the business related to the Service such that the Company is extinguished or broken up, the position, rights, and obligations under this User Agreement, as well as registration information and other information related to a Customer may be transferred to the assignee or successor under the said business transfer, etc.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 24. Severability</h2>
							<div className="terms-text">
								<p>If any provision, or a part thereof, of the Terms is judged to be invalid or unenforceable under the Consumer Contract Law or other laws and regulations, the remaining provisions of the Terms and the remaining portions of provisions of which some have been determined to be invalid or unenforceable shall continue in full force and effect. With respect to operations and Customers, efforts shall be made to amend the invalid or unenforceable provisions or parts of provisions to the extent necessary to render them legal and enforceable and to enable securing intents and legal and economic effects identical to the invalid or unenforceable provisions or parts of provisions.</p>
							</div>

							<h2 className="terms-title2">Article 25. Governing law and agreed jurisdiction</h2>
							<div className="terms-text">
								<p>The Terms shall be government by the laws of Japan. With respect to any and all dispute caused by or related to the Terms, depending on the amount in dispute and legal proceedings, the Tokyo District Court or the Tokyo Summary Court shall be the court of exclusive jurisdiction in the first instance.</p>
							</div>

							<h2 className="terms-title2">Article 26. Survival clause</h2>
							<div className="terms-text">
								<p>Article 13; article 16, paragraphs 2 and 3; article 18; article 19; article 20; paragraph 3; article 21; article 22; and provisions which in consideration of their nature can reasonably be construed as subsequently applicable shall remain in effect also after the end of an agreement.</p>
							</div>
						</section>
					</section>

					<section className="terms">
						<h1 className="common-title">Terms of Use - IIJ Teatrista" (For Event Organizers)</h1>

						<section>
							<div className="terms-text">
								<p>These Terms of Use ("Terms") stipulate the conditions for the use of the "Virtual Theater Space powered by IIJ" service ("Service") provided by Internet Initiative Japan Inc. ("Company") and shall apply to every Event Organizer who has completed registration in accordance with the stipulations of the Terms. The Terms constitute the provisions of the agreement between the Company and an Event Organizer (“User Agreement”) regarding the use of the Service.</p>
							</div>
						</section>

						<section>
							<h2 className="terms-title2">Article 1. Definitions</h2>
							<div className="terms-text">
								<p>As used in the Terms, the meanings of the expressions listed in the following items shall be as specified in each item.</p>
								<p>
									1.	Live Streaming Ticket
									<br />
									An electronic ticket created using the Service that enables the participation in an event.
								</p>
								<p>
									2.	Event Organizer
									<br />
									A person who has completed the registration under article 4 as a corporation which creates Live Streaming Tickets, etc., that it displays using the Service and sells to customers, using the Service.
								</p>
								<p>
									3.	Customer
									<br />
									A person who purchases a Live Streaming Ticket sold by an Event Organizer using the Service.
								</p>
								<p>
									4.	Intellectual Property Right
									<br />
									A copyright (including the rights under articles 27 and 28 of the Copyright Act), patent right, utility model right, trademark right, design right, and any other intellectual property right (including the right to acquire any of the said rights and the right to apply for a registration, etc., with respect to any of the said rights) and ideas and know-how, etc.
								</p>
							</div>

							<h2 className="terms-title2">Article 2. Acceptance of the Terms</h2>
							<div className="terms-text">
								<p>An Event Organizer shall use the Service in accordance with the Terms and shall not be able to use the Service unless the Event Organizer accepts the Terms.</p>
							</div>

							<h2 className="terms-title2">Article 3. Revision and change of the Terms</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>The Company may at any time, at its discretion, change or make additions to the contents of the Terms, subject to notification to an Event Organizer through the website of the Company or by email. Unless otherwise specified by the Company, the changed Terms shall be posted on the website operated by the Company (https://www.harusailive.jp/) (including any change of the domain or content of the website if any were to occur for any reason) or shall be communicated by other means, and shall take effect at the effective time stipulated by the Company.</li>
									<li>An Event Organizer who continues to use the Service after a change of the Terms will be deemed to have accepted the changed Terms..</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 4. Registration procedure</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>A person who wishes to use the Service as an Event Organizer ("Applicant") can apply for a user registration for the Service by agreeing to comply with the Terms and by providing the Company with information in accordance with the method specified by Company. Upon receipt of an application the Company will examine it in accordance with the screening criteria separately established by the Company. If the application is approved, the Company will notify the Applicant through the Company website and by e-mail.</li>
									<li>In case of an application pursuant to the preceding paragraph, the Company may request the submission of documents necessary for the screening, which documents an Applicant shall promptly submit. If an Applicant fails to submit a requested document, the Company may refuse a registration.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 5. Account management</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>After a User Agreement is concluded with an Event Organizer, the Company will assign the Event Organizer an account for the use of the Service.</li>
									<li>An Event Organizer shall manage and preserve an account at the Event Organizer’s own risk, and shall not allow a third party to use an account, nor loan, transfer, change the name of, trade, or otherwise dispose of an account. The use of the Service through an account assigned by the Company to an Event Organizer will be deemed usage of the Service by the Event Organizer registered as the holder of the account also if the usage was made by a third party distinct from the Event Organizer to whom the account was assigned.</li>
									<li>An Event Organizer shall be liable for damages caused by the Event Organizer’s insufficient account administration or account usage, etc., by a third party. The Company is free from any and all liability.</li>
									<li>If an Event Organizer finds that an account has been fraudulently used or used by a third party, the Event Organizer shall immediately notify the Company and follow the instructions of the Company.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 6. Use of external services, etc.</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>For the registration with the Service and for authentication, an Event Organizer may be able to use the information registered on an Event Holder’s account with an external service (including but not limited to Facebook and Twitter). In such a case, an Event Organizer shall agree that the Company obtains registered information, including personal information, from the external service concerned.</li>
									<li>Further to the provisions of the preceding paragraph, when an Event Organizer relies on the functions of an external service for using the Service, an Event Organizer shall in addition to these Terms comply with any terms of use and other conditions specified by an external service.</li>
									<li>The responsibility for an external service shall rest with the third party that provides the external service. The Company provides no guarantee for an external service.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 7. Service content and matters to be observed when using the Service</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>With the completion of a registration pursuant to the provisions of article 4, this User Agreement comprised of the Terms is concluded between an Event Organizer and the Company.</li>
									<li>When creating a Live Streaming Ticket using the Service, an Event Organizer shall comply with the contents of the Terms and any important information stated by the Company.</li>
									<li>When creating a Live Streaming Ticket, an Event Organizer shall comply with the following matters
										<ol className="terms-list-ol">
											<li>Accurately display any information about an event and the streaming delivery that a Customer should know (including, but not limited to, date and time, venue, important information, etc.) and other necessary matters.</li>
											<li>Promptly correct or adjust information regarding an event and the streaming delivery if the information provided contains an error or has changed. The final verification of information is the responsibility of an Event Organizer. This applies also to information entries executed by the Company at the request and on behalf of an Event Organizer, who shall at its responsibility correct any error discovered. The Company is free from any and all liability for error in the content data entered by the Company on behalf of an Event Organizer.</li>
											<li>Refrain from infringement on an intellectual property right of a third party.</li>
											<li>Deliver fulfillment in accordance with the event information posted in advance (including start and end times). In case of a change of event details, such as a delayed start, an event Organizer is responsible for notifying the Customer by an appropriate method. In case of a Service failure, such as in case of an access surge, etc., due to a change of event details by an Event Organizer or due to a failure to comply with event details (including failure to observe start and end times), the Company is free of any and all liability and the Event Organizer shall compensate the Company for damages sustained.</li>
											<li>Refrain from posting information considered inappropriate by the Company.</li>
										</ol>
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 8. Responsibility for sales of Live Streaming Tickets</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Any sales contract for a Live Streaming Ticket is entered into between an Event Organizer and a Customer. The Company is not a party to such a contract.</li>
									<li>A sales contract for a Live Streaming Ticket shall be concluded between an Event Organizer and a Customer upon payment confirmation of a Live Streaming Ticket at the Company and display of the purchase of the Live Streaming Ticket concerned in the purchase history of a Customer’s account.</li>
									<li>Any dispute or problem (This may be, without limitation, a problem caused by a description in an event information, or by media usage of personal information, images, videos, texts, sounds, and other information used outside the intended purpose.) concerning the sale of a Live Streaming Ticket between an Event Organizer and a Customer, or between an Event Organizer and a third party, shall be resolved at the expense and responsibility of an Event Organizer. The Company is free of any and all liability.</li>
									<li>If the Company has incurred expenses (including but not limited to settlement fees and attorney’s fees) necessary for resolving a dispute or problem arising in connection with a sale of a Live Streaming Ticket pursuant to the preceding paragraph, an Event Organizer shall out such expenses pay the amount specified by the Company. An Event Organizer shall incur any charges necessary for effecting the payment.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 9. Payment agency service</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>An event Organizer shall authorize the Company to collect on behalf of the Event Organizer the purchase price of a Live Streaming Ticket created by the Event Organizer ("Ticket Price"). Doing so will enable the Company to collect on behalf of an Event Organizer the Ticket price from a Customer. An Event Organizer shall not receive a ticket price payment from a Customer irrespective of the payment’s nominal designation and method.</li>
									<li>The Company shall remit a ticket price received on behalf of an Event Organizer pursuant to the preceding paragraph after deduction of a fee separately determined by the Company to the account specified by the Event Organizer by a due date specified separately by the Company.</li>
									<li>If judged necessary at the Company's discretion, the Company can refund a Live Streaming Ticket to a Customer. If a Live Streaming Ticket is refunded to a Customer, a refund handling fee will be determined separately.</li>
									<li>In case of a chargeback concerning a Customer who has payed a Live Streaming Ticket with a credit card (referring to the case where the credit card company’s clearance for the payment has been revoked due to fraudulent use of the credit card or other reasons.), the Company can at its discretion and without notice to an Event Organizer return to the credit card company the amount paid by the credit card company for the payment of the Live Streaming Ticket. If a chargeback occurs prior to the payment of the ticket price to an Event Organizer, the Company will deduct the ticket price and chargeback fee (if any) for the chargeback from the ticket price-total. If a chargeback occurs after the payment of the ticket price to an Event Organizer, the Event Organizer shall pay to the Company the ticket price and the chargeback fee (if any) by the due date specified by the Company.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 10. Cancellation</h2>
							<div className="terms-text">
								<p>In case an event is interrupted or discontinued, the handling separately set forth in the “Cancellation policy” shall apply.</p>
							</div>

							<h2 className="terms-title2">Article 11. Sales of Live Streaming Tickets</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>An Event Organizer can use the Service for the streaming delivery of self-provided contents (including live performances) and can also sell such Live Streaming Tickets to Customers.</li>
									<li>The Company only provides an Event Organizer with a platform for streaming delivery and for sales of Live Streaming Ticket. An Event Organizer is solely responsible for the contents delivered and the promotion of the streaming delivery. The Company is free from any and all liability.</li>
									<li>Guidelines for streaming contents<br/>
										<br/>
										<p>Prohibited contents:</p>
										<ul className="terms-list-ul">
											<li>Content that infringe on a copyright or other right of a third party (e.g., trademark rights, privacy rights) (including materials used without permission whose rights are held by a third party such as movies, music, and TV programs and materials whose use infringes upon the privacy of a third party.</li>
											<li>Sexual content including sex industry or pornographic content</li>
											<li>Hate speech, harassment, defamation, slander, and discriminatory language</li>
											<li>Content that encourages terrorism or sedition groups</li>
											<li>Content exploiting minors</li>
											<li>Violence, extreme violence, and real-life violence</li>
											<li>Content explaining the manufacture of explosives, detonators, and homemade or quick-build firearms</li>
											<li>Content depicting or encouraging cruelty against animals</li>
											<li>False or misleading claims about vaccine safety</li>
											<li>Content misrepresenting a major disaster as a hoax</li>
											<li>Content depicting or encouraging self-harm, eating disorders, or substance abuse</li>
											<li>Content capable of causing bodily harm or property damage</li>
											<li>Content threatening the safety of Service users and the public</li>
											<li>Content encouraging or praising acts of harming others</li>
											<li>Content that in other ways violates laws and regulations or public order and morals</li>
										</ul>
										<br/>
										<p>
											Action guidelines
										</p>
										<p>Prohibited actions:</p>
										<ul className="terms-list-ul">
											<li>Use of offensive names, images, or videos (e.g. obscene expressions, nude images)</li>
											<li>Display of rude attitudes or behaviors, or impersonation of an individual or a group</li>
											<li>Harassment and stalking</li>
											<li>Harming or exploiting minors</li>
											<li>Hate speech and discriminatory remarks</li>
											<li>Spamming</li>
											<li>Misuse of personal information</li>
											<li>Unauthorized use of another user’s account</li>
											<li>Engaging in, enticing, or assisting in an illegal act</li>
											<li>Involving others in any of the above acts</li>
										</ul>
										<br/>
										<p>
											Technical means
										</p>
										<p>Prohibited actions:</p>
										<ul className="terms-list-ul">
											<li>With respect to the Service, any of scraping, reusing, creating derivative works, decompiling, reverse engineering, altering, archiving, disassembling, and circumventing the security, filtering, rate restrictions, and digital rights management of the Service</li>
											<li>Sending or embedding malicious computer programs, scripts, and code</li>
											<li>Sending an unreasonable number of requests to a server</li>
											<li>Other acts that manipulate, impair, or interfere with the Service</li>
										</ul>
									</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 12. Intellectual property rights, etc.</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>In creating a Live Streaming Ticket using the Service, an Event Organizer shall only create a Live Streaming Ticket in respect of which the Event Organizer has the right of execution. In a transmission by posting or other means concerning a Live Streaming Ticket, an Event Organizer shall use only content to which the Event Organizer holds the legal  rights, and shall not infringe on a right of a third party.</li>
									<li>In creating a Live Streaming Ticket using the Service, an Event Organizer shall only create a Live Streaming Ticket in relation to video in respect of which the Event Organizer has the right of dissemination. In transmitting by posting or other means concerning a Live Streaming Ticket, an Event Organizer shall use only content to which the Event Organizer holds legal rights, and shall not infringe on a right of a third party.</li>
									<li>For streaming delivery using the Service an Event Organizer shall use only videos and images for which the Event Organizer has the right of dissemination.</li>
									<li>All intellectual property rights relating to the Service belong to the Company or a third party who legally licenses the Company. An Event Organizer shall by concluding this User Agreement acquire alone the right to use the Service and no intellectual property right shall be assigned or licensed to an Event Organizer.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 13. Personal information protection</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>Personal information provided by an Event Organizer in using the Service shall be handled by the Company appropriately in accordance with laws and regulations and within the scope of the following purposes of use.
									<ol className="terms-list-ol">
										<li>Performance of operations related to the provision of the Service (including contacting or notifying, etc., a Service user when necessary for operations)</li>
										<li>Questionnaire surveys and analyses thereof to maintain and enhance the Service level</li>
										<li>Display on a Company web page accessed by an Event Organizer or on other terminal devices of an Event Organizer, or communicate by e-mail, postal mail, etc., or telephone call, information about the Company's products and services (including, without limitation to the Service, introductory information on other products and services of the Company or new products and services of the Company) or products and services of partners of the Company. An Event Organizer can terminate or resume such handling by the Company using the Company’s separately stipulated method.</li>
										<li>Operations associated with each of the foregoing items.</li>
										<li>Other uses within the scope of consent obtained from an Event Organizer.</li>
									</ol>.</li>
									<li>The Company may to the extent necessary and based on the consent of an Event Organizer provide personal information to third parties. Furthermore, when the handling of personal information occurring in the course of operations related to the provision of the Service is in part or in total outsourced to a third party, the Company will furnish the third party with personal information under the supervisory responsibility of the Company.</li>
									<li>Without limiting the generality of the preceding paragraph, in a case where the requirements are fulfilled of a disclosure request under laws and regulations or a disclosure request under article 4 of the Act on Limitation of Liability for Damages of Specified Telecommunications Service Providers and Disclosure of Caller Information (Act No. 137 of 2001), or a disclosure request under other laws and regulations, the Company may within the scope of the request disclose personal information to the requesting party.</li>
									<li>The Company shall be able to use free of charge, indefinitely, at its liberty, without any restrictions, at any location, information obtained from an Event Organizer through the use of the Service such as a the quantity, frequency, and country of Live Streaming Ticket purchases, attributes of an Event Organizer, attributes of a Customer who has purchased a Live Streaming Ticket, and any and all other information. This provision shall remain in force and effect also after an Event Organizer has terminated the use of the Service.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 14. Prohibited acts</h2>
							<div className="terms-text">
								<p>An Event Organizer shall represent and warrant to the Company that an Event Organizer will refrain from the following acts (including acts of inducing or preparing prohibited acts).</p>
								<ol className="terms-list-ol">
									<li>Breach the laws of Japan and other applicable national or regional laws</li>
									<li>Receive a ticket purchase price or in substance corresponding price directly from a Customer instead of through the system or procedure stipulated by the Company</li>
									<li>Use the Service to create a Live Streaming Ticket containing the following information and display the following information using the Service
										<ul className="terms-list-ul">
											<li>Information defamatory of the reputation, credibility, and other rights and interests of the Company, another Event Organizer, media, or any other third party.</li>
											<li>Information containing subject matter of excessive violence or cruelty.</li>
											<li>Information containing computer viruses and other harmful programs.</li>
											<li>Information containing obscene subject matter.</li>
											<li>Information containing discriminatory subject matter.</li>
											<li>Information containing subject matter promoting suicide and self-harm.</li>
											<li>Information containing subject matter encouraging the improper use of drugs.</li>
											<li>Information containing antisocial subject matter.</li>
											<li>Information requesting the dissemination of junk mail, spam mail, or chain mail, etc., to third parties.</li>
											<li>Information containing illegal solicitation or promotion, etc..</li>
											<li>Information containing subject matter causing discomfort in others.</li>
											<li>Information for soliciting a date with an unknown person of the opposite sex.</li>
											<li>Information that violates laws, guidelines and other legal norms.</li>
											<li>Information similar to the above.</li>
										</ul></li>
									<li>Post, etc., a program that destroys or interferes with a function of software or hardware, etc., used by a third party</li>
									<li>Destroy or interfere with a function of a server or network of the Company</li>
									<li>Interfere with services or advertisements disseminated by the Service or the Company, or services or advertisements provided on a website of the Company</li>
									<li>Collect or accumulate personal information, biographical information, or attribute information, etc., of a third party without the permission of the third party.</li>
									<li>Use the Service or information obtained through the Service for a purpose in conflict with the intent of their provision.</li>
									<li>Use the Service via the login information of a third party.</li>
									<li>Irrespective of the method used, obtain the login information of another person or disclose or provide own login information to another person</li>
									<li>In connection with the Service, engage in action that directly or indirectly provides benefits to organized crime, etc.</li>
									<li>Act in breach of the guidelines set by the Company (including those stipulated in article 11)</li>
									<li>Other acts similar to those stated above deemed inappropriate by the Company.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 15. Measures in case of a breach of the Terms</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If the Company determines that any of the following conditions apply or may apply to an Event Organizer, the Company may at its discretion and without notice to the Event Organizer, irrespective of whether nor not the reason is serious and whether or not the Event Organizer is responsible, take measures such as suspending or deleting an Event Organizer’s account, suspending or restricting an Event Organizer’s use of Service, or canceling this User Agreement for cause ("Suspension of Use, etc.").
									<br />
									<ol className="terms-list-ol">
										<li>A prohibited act pursuant to article 13 has been committed or may have been committed</li>
										<li>Any of the provisions of the Terms has been breached or a breach has been reported</li>
										<li>An intellectual property right, portrait right, privacy right, or other right of a third party may have been infringed</li>
										<li>The Company has determined that an Event Organizer is responsible for a problem between the Event Organizer and a Customer, the Company, or another Event Organizer concerning the use of the Service</li>
										<li>The Company has determined that all or part of the information provided to the Company is false</li>
										<li>An Event Organizer has fallen into equity insolvency or balance sheet insolvency, or a petition has been filed for the commencement of bankruptcy proceedings or similar proceedings</li>
										<li>Failure to respond for 30 days or more to an inquiry from the Company or some other contact requesting a response</li>
										<li>An Event Organizer is an element of organized crime, etc., or engages in any kind of interaction or involvement with organized crime, etc., such as cooperation or involvement in the maintenance, operation, or management of organized crime, etc., through funding or other means</li>
										<li>An Event Organizer (including an Event Organizer’s representative and employees) or an artist belonging to an Event Organizer has defamed the reputation, credibility, and other rights and interests of the Company, another Event Organizer, or other third party</li>
										<li>Other reasons similar to those enumerated above</li>
									</ol></li>
									<li>An Event Organizer is not after a Suspension of Use, etc., discharged from any duty and obligation to the Company and third parties under this User Agreement.</li>
									<li>In a case pursuant to paragraph 1, the Company can withhold the payment of the ticket price held by the Company until the reason is resolved, and can deduct from the ticket price the amount of damages suffered by the Company.</li>
									<li>The Company is free from any and all liability for any damages sustained by an Event Organizer due to the measures taken by Company based on this article.</li>
								</ol>
							</div>

							<h2 className="terms-title2">Article 16. Force majeure, etc.</h2>
							<div className="terms-text">
								<p>The Company shall not be liable to an Event Organizer for delayed performance or non-performance due to unforeseen circumstances outside the responsibility of the Company such as natural disaster, war, riot, civil war, amendment and abolition of laws and regulations, orders and dispositions by governmental authority, labor strike and other dispute, or transportation infrastructure accident, etc.</p>

								<h2 className="terms-title2">Article 17. Compensation for damages</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>With respect to the use of the Service by an Event Organizer the Company shall not be liable for any damages unless otherwise stipulated in the Terms and the attached "Cancellation Policy."</li>
										<li>In case the Company is liable for any damages to an Event Organizer, the scope shall be limited to direct and ordinary damages sustained by an Event Organizer. The Company shall not be liable in any case for special damages, indirect damages, consequential damages, incidental damages, punitive damages, and lost profits (including compensation for sales if a Live Streaming Ticket is refunded). The total liability of the Company to an Event Organizer shall be limited to the total amount received by the Company from an Event Organizer as the consideration for the Service related to the cause of the damage.</li>
										<li>Except as expressly stated in the Terms, in no case has the Company with respect to an Event Organizer any non-monetary duty, such as analysis, disclosure, and report on the status, etc., of dissemination (including data such as the number of viewers and viewing time, etc.), system information, and technical information, or a duty of posting an apology and explanation on a website of the Company, or SNS, or any and all other media.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 18. Confidentiality</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>Except in case the Company has given advance written permission, an Event Organizer shall hold in strict confidence and not disclose to a third party any non-public information ("Confidential Information") concerning the Service disclosed to an Event Organizer by the Company under a designation of confidentiality.</li>
										<li>Whenever requested by the Company an Event Organizer shall without delay in accordance with the instructions of the Company return or dispose of Confidential Information and any documents and other recording media wherein Confidential Information is written or recorded, and all copies thereof, etc.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 19. Exclusion, etc., of organized crime</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>The Company and an Event Organizer shall mutually represent and warrant the following matters.
											<ol className="terms-list-ol">
												<li>That the warranting party and none of its corporate officers (meaning a director, executive officer, operating officer, corporate auditor, or person equivalent thereto) constitutes a crime syndicate, a crime syndicate member, a crime syndicate associate member, a crime syndicate related enterprise,  a quasi-crime syndicate, a quasi-crime syndicate related enterprise, a person or organization in substance related to a quasi-crime syndicate, a corporate racketeer, a crime syndicate fronting as civil activist, etc., a crime syndicate with special capabilities, or any other entity corresponding to any of the above (in the following collectively, “Organized Crime.”) and maintains no socially objectionable relationship with Organized Crime.</li>
												<li>That the warranting party does not use Organized Crime for its own benefit or for the benefit of a third party, or for causing damage to a third party, and has no relationship of cooperation with or involvement in the maintenance and operation of Organized Crime, such as by providing funds, etc., or facilitation for Organized Crime.</li>
												<li>That the warranting party has not made its name available for use by Organized Crime to conclude this User Agreement</li>
												<li>That the warranting party will not against the other party directly or through a third party use threatening behavior or violence, disseminate rumors, use deceit or influence to interfere with the business or defame the credibility of the other party, or bring unreasonable demands beyond legal liability, or engage in other similar acts.</li>
											</ol></li>
										<li>The Company and an Event Organizer may cancel this User Agreement for cause without notice or warning if the other party has breached the representations and warranties set forth in the preceding paragraph.</li>
										<li>The Company and an Event Organizer shall not be required to provide compensation for any damages arising to the other party by canceling for cause all or part of this User Agreement pursuant to the provisions of the preceding paragraph. The party in breach of this article shall provide compensation for any and all damages caused by the breach.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 20. No warranty; disclaimer</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>With respect to the use of the Service, the Company offers no warranty for the expected sales of an Event Organizer, the suitability for a particular purpose of an Event Organizer, economic value, accuracy, usefulness, completeness, legality, conformity with organization-internal rules, etc., applicable to an Event Organizer, absence of security flaws, errors, bugs or defects, and non-infringement on rights of third parties.</li>
										<li>The Company does not guarantee that the Service is compatible with all information terminals and the OS and browser versions of information terminals. An Event Organizer shall acknowledge in advance that the Service may malfunction due to version upgrades, etc., of the OS and browser of an information terminal for using the Service. The Company does not guarantee that such a malfunction will be resolved by a program modification, etc., performed by the Company if such a malfunction occurs.</li>
										<li>An Event Organizer shall acknowledge in advance that the Service may malfunction due to changes in the specifications of third-party services connected to the Service and the software, etc., used by an Event Organizer or a Customer. The Company does not guarantee that such a malfunction will be resolved by a program modification, etc., performed by the Company if such a malfunction occurs.</li>
										<li>Any and all devices and installations necessary for using the Service, such as software (including encoder software), equipment, personal computers, Internet environments including at the venue, and back-ups, etc., shall be provided by and at the expense and responsibility of an Event Organizer. The Company is free of any and all liability if the use of the Service is affected by a malfunction or failure, etc., of such devices or equipment.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 21. Ending the use of the Service</h2>
								<div className="terms-text">
									<p>An Event Organizer can terminate this User Agreement in accordance with the method separately prescribed by the Company. However, an Event Organizer shall not after a termination of this User Agreement be discharged from any duties and obligations to the Company and to third parties which an Event Organizer has incurred under this User Agreement prior to termination.</p>
								</div>

								<h2 className="terms-title2">Article 22. Change, suspension, and termination, etc., of the Service</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>The Company can without prior notice to an Event Organizer change or add to the contents of the Service.</li>
										<li>The Company may terminate the Service at the discretion of the Company by notifying an Event Organizer in advance by posting a notice on the Service website or on a website operated by the Company or by any other method that the Company deems appropriate. However, notice to an Event Organizer may be omitted in case of an emergency.</li>
										<li>In any of the following cases the Company shall be able to temporarily suspend the Service in part or in total without advance notice to a Customer.
										<br />
											<ol className="terms-list-ol">
												<li>In case of regular or urgent maintenance (maintenance and inspection) or repair related to communication equipment, etc., for the Service (including external servers and systems; the same applies below.)</li>
												<li>In case of a concentrated load on the system due to excessive access or other unexpected factors</li>
												<li>In case necessary to ensure the security of an Event Organizer or third party</li>
												<li>In case of unavailability of service of a telecommunications carrier</li>
												<li>In case the provision of the Service is rendered impracticable by an event of force majeure such as natural disaster</li>
												<li>In case the provision of the Service is rendered impracticable due to outbreak of fire, power outage, other unexpected accident, or war, conflict, riot, disturbance, or labor dispute, etc.</li>
												<li>In case the operation of the Service is rendered impracticable due to laws and regulations or measures based thereon</li>
												<li>In other cases similar to the foregoing when deemed necessary by the Company</li>
											</ol></li>
										<li>The Company is free of any and all liability for damages sustained by a Customer due to measures taken by the Company based on this article.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 23. Assignment of rights and obligations</h2>
								<div className="terms-text">
									<ol className="terms-list-ol">
										<li>Unless with the prior written permission of the Company, an Event Organizer may not transfer to, cause succession by, establish as collateral for the benefit of, or otherwise dispose with respect to a third party of any right or obligation or of a position of an Event Organizer under this User Agreement.</li>
										<li>An Event Organizer shall agree in advance that in case the Company transfers operations related to the Service to a third party, or the Company causes by merger or company split a comprehensive succession to the business related to the Service such that the Company is extinguished or broken up, the position, rights, and obligations under this User Agreement, as well as registration information and other information related to an Event Organizer can be transferred to the assignee or successor under the said business transfer, etc.</li>
									</ol>
								</div>

								<h2 className="terms-title2">Article 24. Severability</h2>
								<div className="terms-text">
									<p>If any provision, or a part thereof, of the Terms is judged to be invalid or unenforceable under laws and regulations, etc., the remaining provisions of the Terms and the remaining portions of provisions of which some have been determined to be invalid or unenforceable shall continue in full force and effect.</p>
								</div>

								<h2 className="terms-title2">Article 25. Governing law and agreed jurisdiction</h2>
								<div className="terms-text">
									<p>The Terms shall be government by the laws of Japan. With respect to any and all dispute caused by or related to the Terms, depending on the amount in controversy and legal proceedings, the Tokyo District Court or the Tokyo Summary Court shall be the court of exclusive jurisdiction in the first instance.</p>
								</div>

								<h2 className="terms-title2">Article 26. Language</h2>
								<div className="terms-text">
									<p>
										The Japanese version constitutes the official text of the Terms. In case of a discrepancy between the Japanese version of the Terms and a translation in another language, the Japanese version shall prevail.
									</p>
								</div>

								<h2 className="terms-title2">Article 27. Survival clause</h2>
								<div className="terms-text">
									<p>
										Article 5; paragraph 3; article 6, paragraph 3; article 8; article 11, clause (2); article 12, paragraphs 4 and 5; article 15, paragraphs 2 and 3; article 16 ; article 17; article 18; article 19, paragraph 3; article 20; article 21; article 22, paragraph 4; article 24; article 25; article 26; article 27, and provisions which in consideration of their nature can reasonably be construed as subsequently applicable shall remain in effect also after the end of this agreement.
									</p>
								</div>
							</div>
						</section>
					</section>

					<section className="terms">
						<h1 className="common-title">Cancellation Policy</h1>

						<section>
							<h2 className="terms-title2">Event discontinuation or postponement due to circumstances of an Event Organizer</h2>
							<div className="terms-text">
								<ol className="terms-list-ol">
									<li>If a streaming delivery event is canceled due to circumstances of the Event Organizer, or if a change of the event date or the event venue gives rise to a refund of the price of a Live Streaming Ticket to a Customer, as a rule, the Customer shall be reimbursed at the cost and liability of the Event Organizer..</li>
									<li>Without limiting the generality of the preceding item, if judged appropriate by the Company at its discretion, the Company can refund the price of a Live Streaming Ticket to a Customer. In this case, the refund handling fee shall be determined separately..</li>
								</ol>
							</div>

							<h2 className="terms-title2">Suspension or discontinuation of an event dissemination due to force majeure or failure, etc.</h2>
							<div className="terms-text">
								<p>
									If a live dissemination of an event is suspended or discontinued due to a reason solely attributable to the Company, such as force majeure or failure of the Service, the Company will take any of the following measures in accordance with the choice of an Event Organizer.
								</p>
									<ol className="terms-list-ol">
										<li>Extension of the archive period without discontinuation of the live dissemination of the day</li>
										<li>Discontinuation of the live dissemination of the day and re-scheduling using recorded video for a later time on the same day or on or after the next day</li>
										<li>Discontinuation of the live dissemination of the day and re-scheduling the live dissemination for a later time on the same day or on or after the next day</li>
										<li>Discontinuation of the event itself</li>
									</ol>
								<p>
									In any of the above cases, if desired by an Event Organizer, the Company will refund the Live Streaming Tickets for that event. The refund handling fee will be determined separately.
									<br />
									An Event Organizer shall pay any expenses associated with the measures set forth in (1) through (4) above (including expenses related to equipment and venue, etc.).
								</p>
							</div>
						</section>
					</section>
				</>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfService);
