import React, { useEffect, useState} from "react";
import {connect} from "react-redux";
import { chkNull } from '../../common/CommonUtil';

function PremiumSupporting(props) {
    const [isDisplayOrganizer, setIsDisplayOrganizer] = useState(false);
    const [isDisplayCoOrganizer, setIsDisplayCoOrganizer] = useState(false);

    useEffect(() => {
      if (props.customize === null) return;
      if (props.language === 'ja_JP') {
        if (chkNull(props.customize.organizer)) {
          setIsDisplayOrganizer(false);
        } else {
          setIsDisplayOrganizer(true);
        }
        if (chkNull(props.customize.co_organizer)) {
            setIsDisplayCoOrganizer(false); 
        } else {
            setIsDisplayCoOrganizer(true); 
        }
      } else {
        if (chkNull(props.customize.organizer_en)) {
          setIsDisplayOrganizer(false);
        } else {
          setIsDisplayOrganizer(true);
        }
        if (chkNull(props.customize.co_organizer_en)) {
          setIsDisplayCoOrganizer(false); 
        } else {
          setIsDisplayCoOrganizer(true); 
        }
      }
    }, [props.customize, props.language]);

    if (props.donePageLoading !== true) {
        return <p/>
    }
    // TOP page only. not search and purchased
    if (props.sortType !== "date" && props.sortType !== "search"){
        return <p/>
    }
    return (
        <>
      {props.language === 'ja_JP' ? (
          isDisplayOrganizer || isDisplayCoOrganizer?
                  <section className="home-credit">
                      {isDisplayOrganizer ?
                        <section className="home-credit-block">
                          <div className="home-credit-block__inner">
                            <h2 className="home-credit-block__title">主催</h2>
                            <p>
                              {props.customize.organizer}
                            </p>
                          </div>
                        </section>
                      :''}
                      {isDisplayCoOrganizer?
                      <section className="home-credit-block">
                        <div className="home-credit-block__inner">
                          <h2 className="home-credit-block__title">共催</h2>
                          <p>
                            {props.customize.co_organizer}
                          </p>
                        </div>
                      </section>
                      :''}
                  </section>
            : ''
         ) : (
          isDisplayOrganizer || isDisplayCoOrganizer?
                  <section className="home-credit">
                      {isDisplayOrganizer ?
                        <section className="home-credit-block">
                          <div className="home-credit-block__inner">
                            <h2 className="home-credit-block__title">Organizer</h2>
                            <p>
                              {props.customize.organizer_en}
                            </p>
                          </div>
                        </section>
                      :''}
                      {isDisplayCoOrganizer?
                      <section className="home-credit-block">
                        <div className="home-credit-block__inner">
                          <h2 className="home-credit-block__title">Co-presenter</h2>
                          <p>
                            {props.customize.co_organizer_en}
                          </p>
                        </div>
                      </section>
                      :''}
                  </section>
              :''
          )
         }
      </>
    )
}



const mapStateToProps = (state) => {
    return {
        language: state.piano.language,
        donePageLoading: state.offer.donePageLoading
    }
}

export default connect(mapStateToProps)(PremiumSupporting)

