import axios from 'axios';
import PlanConst from '../common/PlanConst';

export const GET_PROFESSIONAL_DISTRIBUTORS_REQUEST = 'GET_PROFESSIONAL_DISTRIBUTORS_REQUEST';
const getProfessionalDistributorsRequest = error => {
	return {
		type: GET_PROFESSIONAL_DISTRIBUTORS_REQUEST,
		error: error,
	};
};

export const GET_PROFESSIONAL_DISTRIBUTORS_SUCCESS = 'GET_PROFESSIONAL_DISTRIBUTORS_SUCCESS';
const getProfessionalDistributorsSuccess = (json, err) => {
	return {
		type: GET_PROFESSIONAL_DISTRIBUTORS_SUCCESS,
		professionalDistributors: json,
		error: err,
	};
};

export const GET_PROFESSIONAL_DISTRIBUTORS_FAILURE = 'GET_PROFESSIONAL_DISTRIBUTORS_FAILURE';
const getProfessionalDistributorsFailure = err => {
	return {
		type: GET_PROFESSIONAL_DISTRIBUTORS_FAILURE,
		error: err,
	};
};

export const GET_PREMIUM_DISTRIBUTORS_REQUEST = 'GET_PREMIUM_DISTRIBUTORS_REQUEST';
const getPremiumDistributorsRequest = error => {
	return {
		type: GET_PREMIUM_DISTRIBUTORS_REQUEST,
		error: error,
	};
};

export const GET_PREMIUM_DISTRIBUTORS_SUCCESS = 'GET_PREMIUM_DISTRIBUTORS_SUCCESS';
const getPremiumDistributorsSuccess = (json, err) => {
	return {
		type: GET_PREMIUM_DISTRIBUTORS_SUCCESS,
		premiumDistributors: json,
		error: err,
	};
};

export const GET_PREMIUM_DISTRIBUTORS_FAILURE = 'GET_PREMIUM_DISTRIBUTORS_FAILURE';
const getPremiumDistributorsFailure = err => {
	return {
		type: GET_PREMIUM_DISTRIBUTORS_FAILURE,
		error: err,
	};
};

// プロフェッショナル契約配信事業者取得
export const getProfessionalDistributors = () => {
	return dispatch => {
		dispatch(getProfessionalDistributorsRequest());
		// plan = 3で配信事業者一覧返却APIを呼び出す
		getDistributors(PlanConst.professional)
		.then(distributors => {
			dispatch(getProfessionalDistributorsSuccess(distributors));
		})
		.catch(err => {
			dispatch(getProfessionalDistributorsFailure(err));
		})
	}
}

// プレミアム契約配信事業者取得
export const getPremiumDistributors = () => {
	return dispatch => {
		dispatch(getPremiumDistributorsRequest());
		// plan = 4で配信事業者一覧返却APIを呼び出す
		getDistributors(PlanConst.premium)
		.then(distributors => {
			dispatch(getPremiumDistributorsSuccess(distributors));
		})
		.catch(err => {
			dispatch(getPremiumDistributorsFailure(err));
		})
	}
}

// 配信事業者一覧返却
const getDistributors = async(plan) => {
	// 指定された契約プランの配信事業者一覧を取得
	return await axios
		.get(process.env.REACT_APP_API_DIR_URL +`v1/distributors?plan=${plan}`)
		.then(res => {
			let distributors = res.data;
			return distributors;
		})
		.catch(err => {
			console.log(err);
			return err;
		});
};