import React from "react";
import {connect} from "react-redux";

function Supporting(props) {
    if (props.donePageLoading !== true) {
        return <p/>
    }
    // TOP page only. not search and purchased
    if (props.sortType !== "date" && props.sortType !== "search"){
        return <p/>
    }

    return (
        <>
			{props.language === 'ja_JP' ? (
                <section className="home-credit">
                    <section className="home-credit-block">
                      <div className="home-credit-block__inner">
                        <h2 className="home-credit-block__title">主催</h2>
                        <p>
                          東京･春･音楽祭実行委員会
                        </p>
                      </div>
                    </section>
                </section>
			) : (
                <section className="home-credit">
                    <section className="home-credit-block">
                      <div className="home-credit-block__inner">
                        <h2 className="home-credit-block__title">Organizer</h2>
                        <p>
                          Spring Festival in Tokyo Executive Committee
                        </p>
                      </div>
                    </section>
                </section>
            )}
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        language: state.piano.language,
        donePageLoading: state.offer.donePageLoading
    }
}

export default connect(mapStateToProps)(Supporting)

