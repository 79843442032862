import React from 'react';
import { connect } from 'react-redux';
import { purchaseTicket } from '../../actions/piano';

function OfferPurchaseButton(props) {
	if (props.isLocationNG) {
		return getButton(props, '登録不可地域', 'Non-registable area', true);
	}
	// return getButton(props, "デバッグモード","Purchased",false)
	switch (props.programState) {
		case 'Purchased':
			return getButton(props, '登録済み', 'Registered', true);
		case 'Streaming':
			return ''; // 何も表示しない
		case 'Done':
			return ''; // 何も表示しない
		case 'WaitSale':
			return ''; // 何も表示しない
		case 'Unavailable':
			return ''; // 何も表示しない
		case 'OnSale':
			return getButton(props, '登録する', 'Register', false);
		case 'Cancel':
			return '';
		default:
			return '';
	}
}

function getButton(props, body, body_en, isInvalidation) {
	// 購入不可
	if (isInvalidation) {
		if (props.isSP) {
			return (
				<div className="detail-side__buy-button sp-only before-live-only" title={body} style={{ cursor: 'default' }}>
					{props.language === 'ja_JP' ? body : body_en}
				</div>
			);
		} else {
			return (
				<div className="detail-side__buy-button before-live-only" title={body} style={{ cursor: 'default' }}>
					{props.language === 'ja_JP' ? body : body_en}
				</div>
			);
		}
	} else {
		// 購入可
		if (props.isSP) {
			return (
				<a
					className="detail-side__buy-button sp-only before-live-only"
					title={body}
					onClick={() => {
						props.purchaseTicket(props.offerId);
					}}
				>
					{props.language === 'ja_JP' ? body : body_en}
				</a>
			);
		} else {
			return (
				<a
					className="detail-side__buy-button before-live-only"
					title={body}
					onClick={() => {
						props.purchaseTicket(props.offerId);
					}}
				>
					{props.language === 'ja_JP' ? body : body_en}
				</a>
			);
		}
	}
}

const mapDispatchToProps = dispatch => ({
	purchaseTicket: offerId => dispatch(purchaseTicket(offerId)),
});

export default connect(null, mapDispatchToProps)(OfferPurchaseButton);
