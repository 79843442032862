import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setIsStreaming, donePageLoading } from '../actions/offer';
import CustomizeFileConst from '../common/CustomizeFileConst';
import Const from '../common/Const';
import { isFileExist, getFileDataText } from "../common/CommonUtil"

function PremiumPersonalInformation(props) {

	const [personalInformation, setPersonalInformation] = useState('');
	const [noData, setNoData] = useState(false);
	const [notFound, setNotFound] = useState(false);


	const ErrMsgJp = process.env.REACT_APP_GET_FILE_ERROR_MSG.replace(Const.wildcardString, "「個人情報の取扱いについて」");
	const ErrMsgEn = process.env.REACT_APP_GET_FILE_ERROR_MSG_EN.replace(Const.wildcardString, "\"Concerning the Handling of Personal Information\"");

	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	useEffect(() => {
		if (props.customize) {
			//個人情報取扱ページを取得
			getPersonalInformation()
		}
	}, [props.customize, props.language]);

	const getPersonalInformation = async () => {
		if (props.customize) {
			let fileName = CustomizeFileConst.PERSONAL_INFO_HTML_EN;
			if (props.language === 'ja_JP') {
				fileName = CustomizeFileConst.PERSONAL_INFO_HTML_JP;
			}
			let url = props.customize.s3_path + fileName;
			// ファイル存在チェック
			const FileExistFlag = isFileExist(url);
			if (FileExistFlag) {
				//ファイルをテキスト形式で取得
				const data = await getTopics(url)
				if (Object.keys(data).length > 0) {
					setPersonalInformation(data)
				} else {
					setNoData(true);
				}
			} else {
				setNotFound(true);
			}
		}
	}

	const getTopics = async (url) => {
		try {
			const topics = await getFileDataText(url);
			return topics;
		} catch (error) {
			console.error(error);
		}
	}


	return (
		<section className="terms">
			{props.language === 'ja_JP' ? (
				<>
					<h1 className="common-title">個人情報の取扱いについて</h1>
					{notFound ? <p>{ErrMsgJp}</p> : ''}
					{noData ? '' : <section dangerouslySetInnerHTML={{ __html: personalInformation }} ></section>}
				</>
			) : (
				<>
					<h1 className="common-title">Concerning the Handling of Personal Information</h1>
					{notFound ? <p>{ErrMsgEn}</p> : ''}
					{noData ? '' : <section dangerouslySetInnerHTML={{ __html: personalInformation }} ></section>}
				</>
			)}
		</section>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
		customize: state.customize.customize
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumPersonalInformation);
