import React from 'react';
import { connect } from 'react-redux';
import { purchaseTicket } from '../../actions/piano';
import { ceil, chain, round } from 'mathjs';

function OfferSpOnlyPurchaseButtonAndInfo(props) {
	let date = new Date(props.purchase_end_time);
	return (
		<>
			{getButtonSpSwitch(props)}
			<dl className="purchase-info sp-only before-live-only">
				<dt className="purchase-info__title">{props.language === 'ja_JP' ? '価格' : 'Price'}</dt>
				<dd className="purchase-info__text">
					{getTaxPrice(props.isJapan, props.pianoPrice)}
					{props.language === 'ja_JP' ? '円（税込み）' : 'yen'}
				</dd>
				<dt className="purchase-info__title">{props.language === 'ja_JP' ? '購入期限' : 'Available until'}</dt>
				<dd className="purchase-info__text">{`${props.language === 'ja_JP' ? `${['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][date.getMonth()]}/${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}` : `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()]},${date.getDate()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`}`}</dd>
			</dl>
		</>
	);
}

function getButtonSpSwitch(props) {
	if (props.isLocationNG) {
		return getButtonSP(props, '登録不可地域', 'Non-purchasable area', true);
	}
	switch (props.programState) {
		case 'Purchased':
			return getButtonSP(props, '登録済み', 'Purchased', true);
		case 'Streaming':
			return ''; // 何も表示しない
		case 'Done':
			return ''; // 何も表示しない
		case 'WaitSale':
			return getButtonSP(props, '登録開始前', 'Before sales start', true);
		case 'Unavailable':
			return getButtonSP(props, '登録受付終了', 'end of sales', true);
		case 'OnSale':
			return getButtonSP(props, '登録する', 'Purchase Ticket', false);
		default:
			return '';
	}
}

function getButtonSP(props, body, body_en, isInvalidation) {
	// 購入不可
	if (isInvalidation) {
		return (
			<div className="detail-side__buy-button sp-only before-live-only" title={body} style={{ cursor: 'default' }}>
				{props.language === 'ja_JP' ? body : body_en}
			</div>
		);
	} else {
		// 購入可
		return (
			<a
				className="detail-side__buy-button sp-only before-live-only"
				title={body}
				onClick={() => {
					props.purchaseTicket(props.offerId);
				}}
			>
				{props.language === 'ja_JP' ? body : body_en}
			</a>
		);
	}
}

function getTaxPrice(isJapan, price) {
	if (isJapan) {
		// 1.1をかけて、ceilで切り上げ
		let taxin_price = ceil(
			chain(price)
			.multiply(1.1)
			.done());
		let one_digit = (taxin_price / 1) % 10;
		// 公演価格の切り上げ | 切り捨て
		if (one_digit == 9 || one_digit == 1) {
			taxin_price = round(taxin_price / 10) * 10;
		}
		return taxin_price.toLocaleString('ja');
	} else {
		// 海外はおそらく税抜き価格になる
		return price.toLocaleString('en-US');
	}
}

const mapDispatchToProps = dispatch => ({
	purchaseTicket: offerId => dispatch(purchaseTicket(offerId)),
});

export default connect(null, mapDispatchToProps)(OfferSpOnlyPurchaseButtonAndInfo);
