import React from 'react';

function OfferTester(uid) {
	console.log('tester check!');
	let env_debug_users = process.env.REACT_APP_DEBUG_USER;
	let testUsers = env_debug_users.split(",")
	return testUsers.includes(uid)
}

export default OfferTester;
