import React from 'react';

function OfferOfficialSite(props) {
	return (
		<>
			{props.language === 'ja_JP' ? (
				<a className="detail-body__link" href={props.official_site_url} title="関連コンテンツはこちら" target="_blank" rel="noreferrer">
					関連コンテンツはこちら
				</a>
			) : (
				''
			)}
			{props.language === 'en_US' ? (
				<a className="detail-body__link" href={props.official_site_url} title="Related content" target="_blank" rel="noreferrer">
					Related content
				</a>
			) : (
				''
			)}
		</>
	);
}

export default OfferOfficialSite;
