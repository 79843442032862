import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SearchBar from '../components/SearchBar/SearchBar';
import ShowCase from '../components/ShowCase/ShowCase';
import PremiumSupporting from '../components/Footer/PremiumSupporting';
import TopInformation from '../components/Header/TopInformation';
import AppRelease from '../components/Header/AppRelease';
import Closed from './Closed';

import { login, showPasswordReset } from '../actions/piano';
import { getOffers, searchOffers, getPurchasedOffers, setIsStreaming, donePageLoading } from '../actions/offer';
import { getPremiumDistributors } from '../actions/distributor';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import { isFileExist, encodeKeyword } from '../common/CommonUtil';
import Const from '../common/Const';
import CustomizeFileConst from '../common/CustomizeFileConst';
import queryString from 'query-string';

function Index(props) {
	const [isLoading, setLoading] = useState(true);
	const [sortType, setSortType] = useState('');
	const [keyword, setKeyword] = useState('');
	const location = useLocation();
	const [isQueryCheck, setIsQueryCheck] = useState(false);
	const [isRedirect, setIsRedirect] = useState(false);
	const [selectedDistributorId, SetSelectedDistributorId] = useState(null);
	const [premiumDistributors, SetPremiumDistributors] = useState(null);
	const [distributorS3Path, SetDistributorS3Path] = useState(null);
	const [smallLogoUrl, setSmallLogoUrl] = useState('');
	const [mainDispLogoUrl, setMainDispLogoUrl] = useState('');
	// お知らせ表示
	const [isInformation, setIsInformation] = useState(false);
	const [informationUrl, setInformationUrl] = useState('');
	const [isInformationCalled, setIsInformationCalled] = useState(false);
	const [isAppReleaseNotification, setIsAppReleaseNotification] = useState(false);

	// 終了表示
	const [isEventEnded, setIsEventEnded] = useState(false);


	useEffect(() => {
		// クエリパラメーター取得
		//const queryString = require('query-string');
		let parsed = queryString.parse(props.location.search);
		if(Object.keys(parsed).length === 0) window.location.href = '/?sortType=date';

		if (parsed.distributor_id) {
			SetSelectedDistributorId(parsed.distributor_id);
		}

		// 検索クエリの場合
		if (!isQueryCheck && parsed.q !== '') {
			setKeyword(parsed.q);
			parsed.sortType = 'search';

			// 稀にsetKeywordでセットされない場合がある@リトライで治る
			if (keyword === '') {
				setIsQueryCheck(false);
			} else {
				setIsQueryCheck(true);
			}
			handleSearch(false);
			return;
		}
		if (parsed.sortType === 'date') {
			setSortType('date');
			return;
		}
		if (parsed.sortType === 'purchased') {
			setSortType('purchased');
			return;
		}
	});
	useEffect(() => {
		// クエリパラメーターにqがある場合は必ずsearchに切り替えさせる
		//const queryString = require('query-string');
		let parsed = queryString.parse(props.location.search);
		if (parsed.q !== '' && sortType !== 'search' && parsed.q !== undefined && props.offers !== null) {
			setSortType('search');
		}
	}, [sortType]);

	useEffect(() => {
		// クエリパラメーター取得
		//const queryString = require('query-string');
		let query = queryString.parse(props.location.search);
		if (query !== undefined) {
			props.showPasswordReset(query, gotoTopPage);
		}
	});
	const gotoTopPage = () => {
		window.location.href = '/';
	};

	useEffect(() => {
		if (selectedDistributorId) {
			getOfferList(selectedDistributorId);
		}
	}, [props.loggedIn, sortType, selectedDistributorId]);

	const getOfferList = distributorId => {
		props.donePageLoading(false);
		if (sortType === 'date') {
			props.getOffers(props.loggedIn, distributorId);
		}
		props.setIsStreaming(false);
		if (location.state) {
			setSortType(location.state.sortType);
		}
		if (sortType === 'purchased') props.getPurchasedOffers(props.loggedIn, distributorId);
		if (sortType === 'purchased' && !props.loggedIn) {
			props.login();
		}
		setLoading(false);
	}

	useEffect(() => {
		if (props.premiumDistributors !== null) {
			SetPremiumDistributors(props.premiumDistributors.filter(premiumDistributor => {return premiumDistributor.distributor_id === selectedDistributorId}));
		}
	}, [props.premiumDistributors])

	useEffect(() => {
		if (props.customize) {
			// 配信事業者IDをローカルステートに保持
			SetSelectedDistributorId(props.customize.distributor_id);
			// S3Pathをローカルステートに保持
			SetDistributorS3Path(props.customize.s3_path);
			// お知らせURLをローカルステートに保持
			setInformationUrl(props.customize.information_url);
			// 公演カードのロゴをローカルステートに保持
			if (isFileExist(props.customize.s3_path + CustomizeFileConst.SMALL_LOGO_JPEG)) {
				setSmallLogoUrl(props.customize.s3_path + CustomizeFileConst.SMALL_LOGO_JPEG + "?" + new Date().getTime());
			}
			// メインビジュアルロゴをローカルステートに保持
			if (isFileExist(props.customize.s3_path + CustomizeFileConst.LARGE_BANNER_JEPG)) {
				setMainDispLogoUrl(props.customize.s3_path + CustomizeFileConst.LARGE_BANNER_JEPG + "?" + new Date().getTime());
			}
			// プレミアム契約配信事業者取得
			props.getPremiumDistributors();
			getOfferList(props.customize.distributor_id);
		}
	}, [props.customize])

	// エラーハンドリング
	useEffect(() => {
		if (props.error !== null && props.error !== undefined) {
			// ネットワークエラー
			if (props.error.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.error.response !== undefined) {
				if (props.error.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.error.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
		// props.err
		if (props.err !== null && props.err !== undefined) {
			// ネットワークエラー
			if (props.err.toString() === 'Error: Network Error') {
				console.log('ネットワークエラー');
			}
			// ステータスコードが帰ってきた場合
			if (props.err.response !== undefined) {
				if (props.err.response.status >= 500) {
					window.location.href = '/500';
				}
				if (props.err.response.status === 404) {
					window.location.href = '/404';
				}
			}
		}
	}, [props.error, props.err]);

	// Get JSON file(お知らせ)
	useEffect(() => {
		let ts = new Date().getTime();
		console.log(ts);
		if(!isInformationCalled && distributorS3Path){
			setIsInformationCalled(true);
			fetch(distributorS3Path + CustomizeFileConst.INFORMATION_JSON  + "?" + ts)
			.then(res => res.json())
			.then(
			(result) => {
				window.topics = result;
				setIsInformation(true);
			},
			(error) => {
				console.log('NG');
			}
			)
		}
	}, [isInformationCalled, distributorS3Path]);

	const handleSearch = isEnter => {
		if (keyword === '' || keyword === undefined) {
			return;
		}
		setSortType('search');
		if (isEnter) {
			setIsRedirect(true);
			return;
		}
		props.searchOffers(keyword, props.loggedIn, selectedDistributorId);
	};

	const handleSortType = functionSortType => {
		if (functionSortType === 'purchased') {
			if (!props.loggedIn) {
				props.login();
				return;
			}
		}
		setSortType(functionSortType);
	};
	const redirectScript = (keyword, selectedDistributorId) => {
		if (selectedDistributorId === null) {
			window.location.href = Const.hrefSearch + encodeKeyword(keyword);
		} else {
			window.location.href = Const.hrefSearch + encodeKeyword(keyword) + Const.addDistributorQuery + selectedDistributorId;
		}
	};

	function redirectSearch(keyword, selectedDistributorId) {
		redirectScript(keyword, selectedDistributorId);
	}

	if (isLoading) {
		return (
			<div>
				<style jsx="true">{'\
        .global-footer{\
          display:none;\
        }\
      '}</style>
			</div>
		);
	}
	return (
		<div>
			{mainDispLogoUrl === '' ? '' :<section className="large-banner-frame"> <img className="large-banner" src={mainDispLogoUrl} alt=""/></section>}
			{isInformation ? <TopInformation language={props.language} topics={window.topics} informationUrl={informationUrl} isPremium={true}/> : ''}
			{!isEventEnded
		   		? isAppReleaseNotification
				   ? <AppRelease language={props.language} />
			 	 : ''
			: ''
			}
				<section className="home">
					<div className="home__inner">
				{isEventEnded ? (
					<>
						<Closed language={props.language}></Closed>
						{props.isFetching ? '' : 
							<ShowCase 
								isEventEnded={isEventEnded} 
								offers={props.offers} 
								sortType={sortType} 
								keyword={keyword} 
								language={props.language} 
								donePageLoading={props.donePageLoading} 
								selectedDistributorId={selectedDistributorId}
								smallLogoUrl={smallLogoUrl}
							/>}
					</>
				) : (
					<>
						{isRedirect ? redirectSearch(keyword, selectedDistributorId) : ''}
						<SearchBar sortType={sortType} keyword={keyword} handleSortType={handleSortType} setKeyword={setKeyword} onEnterPressed={handleSearch} language={props.language} selectedDistributorId={selectedDistributorId} distributors={premiumDistributors}/>
						<section className='contents-tags'>
							{props.isFetching ? '' : 
								<ShowCase 
									offers={props.offers} 
									sortType={sortType} 
									keyword={keyword} 
									language={props.language} 
									donePageLoading={props.donePageLoading} 
									selectedDistributorId={selectedDistributorId}
									smallLogoUrl={smallLogoUrl}
								/>}
						</section>
					</>
				)}
				</div>
				</section>
			<PremiumSupporting sortType={sortType} customize={props.customize} />
		</div>
	);
}

const mapStateToProps = state => {
	return {
		isFetching: state.offer.isFetching,
		offers: state.offer.offers,
		language: state.piano.language,
		loggedIn: state.piano.loggedIn,
		error: state.offer.error,
		err: state.offer.err,
		customize: state.customize.customize,
		premiumDistributors: state.distributors.premiumDistributors
	};
};

const mapDispatchToProps = dispatch => ({
	login: () => dispatch(login()),
	getOffers: (isLoggedIn, distributorId) => dispatch(getOffers(isLoggedIn, distributorId)),
	searchOffers: (keyword, isLoggedIn, distributorId) => dispatch(searchOffers(keyword, isLoggedIn, distributorId)),
	getPurchasedOffers: (isLoggedIn, distributorId) => dispatch(getPurchasedOffers(isLoggedIn, distributorId)),
	showPasswordReset: (query, gotoTopPage) => dispatch(showPasswordReset(query, gotoTopPage)),
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
	getPremiumDistributors: async() => await dispatch(getPremiumDistributors())
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
