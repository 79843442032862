import React from 'react';

function OfferOfficialSite(props) {
	return (
		<>
			{props.language === 'ja_JP' ? (
				<a className="detail-body__link" href={props.official_site_url} title="曲目解説・プロフィールはこちらから" target="_blank" rel="noreferrer">
					曲目解説・プロフィールはこちらから
				</a>
			) : (
				''
			)}
			{props.language === 'en_US' ? (
				<a className="detail-body__link" href={props.official_site_url} title="Official page" target="_blank" rel="noreferrer">
					Official page
				</a>
			) : (
				''
			)}
		</>
	);
}

export default OfferOfficialSite;
