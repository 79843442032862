import React from 'react';

function OfferCancelled(props) {

	if (props.performance_cancelled_text !== '') {
		return (
			<>
				{props.language === 'ja_JP' ? (
					<a className="detail-body__cancelledlink" href={props.performance_cancelled_url} title={props.performance_cancelled_text} target="_blank" rel="noreferrer">
						{props.performance_cancelled_text}
					</a>
				) : (
					<a className="detail-body__cancelledlink" href={props.performance_cancelled_url_en} title={props.performance_cancelled_text_en} target="_blank" rel="noreferrer">
						{props.performance_cancelled_text_en}
					</a>
				)}
			</>
		);
	} else {
		return '';
	}
}

export default OfferCancelled;
