import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { login, logout, langSwap } from "../../actions/piano"

function MobileMenu(props) {
  const [active, setActive] = useState("")
  const handleActive = () => {
    if (active === "") {
      setActive("active")
    } else if (active === "active") {
      setActive("")
    }
  }
  const handlePurchaseActive = () => {
    if (active === "") {
      setActive("active")
    } else if (active === "active") {
      setActive("")
      window.location.href = "/"
    }
  }

  return (
    <>
      <nav className={`mobile-menu ${active}`}>
        <div className="mobile-menu__line1"/>
        <ul className="mobile-menu-links">
          <li className="mobile-menu-item">
            <Link to="/" title="プログラム" onClick={() => handlePurchaseActive()}>
              {props.language === "ja_JP" ? "プログラム" : "Programs"}
            </Link>
          </li>
          <li className="mobile-menu-item">
            <Link
              to={{
                pathname: "/",
                state: { sortType: "purchased" }
              }}
              title="購入済み"
              onClick={() => handlePurchaseActive()}
            >
              {props.language === "ja_JP" ? "購入済み" : "Purchased"}
            </Link>
          </li>
        </ul>
        <div className="mobile-menu__line2"/>
        <ul className="mobile-menu-links tp-cloak">
          {props.loggedIn ? (
            <>
              <li className="mobile-menu-item show-if-logged-in">
                <Link
                  to="/mypage"
                  title="マイページ"
                  onClick={() => handleActive()}
                >
                  {props.language === "ja_JP" ? "マイページ" : "MyPage"}
                </Link>
              </li>
              <li className="mobile-menu-item show-if-logged-in">
                <a
                  href="#"
                  className="tp-logout"
                  title="ログアウト"
                  onClick={() => {
                    handleActive()
                    props.logout()
                  }}
                >
                  {props.language === "ja_JP" ? "ログアウト" : "Logout"}
                </a>
              </li>
            </>
          ) : (
            <li className="mobile-menu-item show-if-logged-out">
              <a
                href="#"
                className="tp-login"
                title="ログイン"
                onClick={() => {
                  handleActive()
                  props.login()
                }}
              >
                {props.language === "ja_JP" ? "ログイン" : "Login"}
              </a>
            </li>
          )}
        </ul>

        <ul className="menu-small-links">
          <li className="menu-small-links__item">
            <Link
                to="/guide"
                title="ご利用ガイド"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "ご利用ガイド"
                  : "How to use"}
            </Link>
          </li>

          <li className="menu-small-links__item">
            <Link
                to="/test"
                title="動画再生確認"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "動画再生確認"
                  : "Video playback environment confirmation page"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/faq"
              title="よくあるご質問"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP" ? "よくあるご質問" : "FAQ"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/privacy_policy"
              title="プライバシーポリシー"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                ? "プライバシーポリシー"
                : "Privacy Policy"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
                to="/personal_information"
                title="個人情報の取扱いについて"
                onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                  ? "個人情報の取扱いについて"
                  : "Acquisition and use of personal information"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/specified_commercial_transaction_law"
              title="特定商取引法に基づく表記"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP"
                ? "特定商取引法に基づく表記"
                : "Specified Commercial Transaction Act"}
            </Link>
          </li>
          <li className="menu-small-links__item">
            <Link
              to="/term_of_service"
              title="利用規約"
              onClick={() => handleActive()}
            >
              {props.language === "ja_JP" ? "利用規約" : "Term of Service"}
            </Link>
          </li>
        </ul>
        <ul className="mobile-menu-links">
          <li className="mobile-menu-item">
            <a
                href="https://www.tokyo-harusai.com/" target="_blank"
                title={props.language === "ja_JP" ? "公式サイト" : "Official Site"}
            >
              {props.language === "ja_JP" ? "公式サイト" : "Official Site"}
            </a>
          </li>
          <li className="menu-small-links__item">
            <a
                href="https://harusai.base.shop/" target="_blank"
                title={props.language === "ja_JP" ? "公式オンラインショップ" : "Official Online Shop"}
            >
              {props.language === "ja_JP"
                  ? "公式オンラインショップ"
                  : "Official Online Shop"}
            </a>
          </li>
          <li className="menu-small-links__item">
            <a
                href="https://www.tokyo-harusai.com/support/" target="_blank"
                title={props.language === "ja_JP" ? "東京･春･音楽祭 ご支援のお願い" : "Support Spring Festival in Tokyo"}
            >
              {props.language === "ja_JP"
                  ? "東京･春･音楽祭 ご支援のお願い"
                  : "Support Spring Festival in Tokyo"}
            </a>
          </li>
        </ul>

        <ul
          className="language language--ja language--menu"
          onClick={() => props.langswap()}
        >
           <li className={props.language === "ja_JP" ? "language__ja" : "language__en"}>
            <a title="日本語">日本語</a>
          </li>
          <li className="language__slash">/</li>
           <li className={props.language === "en_US" ? "language__ja" : "language__en"}>
            <a title="English">English</a>
          </li>
        </ul>
      </nav>
      <div
        className={`mobile-menu-button ${active}`}
        onClick={() => handleActive()}
      >
        <a href="#">
          <span className="mobile-menu-button__line"></span>
          <span className="mobile-menu-button__line"></span>
          <span className="mobile-menu-button__line"></span>
        </a>
      </div>
    </>
  )
}

const ActiveStyle = {
  display: "inline-block",
  textDecoration: "none",
  color: "#EDEDED",
  cursor: "pointer"
}

const InActiveStyle = {
  display: "inline-block",
  color: "#D56096",
  textDecoration: "none",
  pointerEvents: "none"
}

const mapStateToProps = (state) => {
  return {
    language: state.piano.language,
    loggedIn: state.piano.loggedIn
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
  logout: () => dispatch(logout()),
  langswap: () => dispatch(langSwap())
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
