import React, { useEffect, useState } from 'react';
import CustomizeFileConst from '../../common/CustomizeFileConst';
import { isFileExist, getFileDataText } from "../../common/CommonUtil"
import { Const } from '../../common/Const';
function OfferAttention(props) {

	const [offerAttentionMessage, setOfferAttentionMessage] = useState('');
	const afterReplaceTargetGetFileErrorMsg = "「公演注意事項」"
	const afterReplaceTargetGetFileErrorMsgEn = "\"Offer's attention\" "
	useEffect(() => {
		// メッセージ切り替え
		setMessage();
	}, [props.language]);

	useEffect(() => {
		// 注意書き文言を表示する
		setMessage();
	}, [props.customize]);


	const setMessage = () => {
		let fileName = CustomizeFileConst.OFFER_ATTENTION_TXT;
		let errMsg = process.env.REACT_APP_GET_FILE_ERROR_MSG.replace(Const.wildcardString,afterReplaceTargetGetFileErrorMsg);
		let filePass = process.env.REACT_APP_OFFER_ATTENTION_MESSAGE_DIR_URL;
		//カスタマイズ対象であるか判定する。
		if (props.customize) {
			//カスタマイズ対象の場合、S3からファイルを取得する。
			filePass = props.customize.s3_path
		}
		if (props.language === 'en_US') {
			fileName = CustomizeFileConst.OFFER_ATTENTION_EN_TXT;
			errMsg = process.env.REACT_APP_GET_FILE_ERROR_MSG_EN.replace(Const.wildcardString,afterReplaceTargetGetFileErrorMsgEn);
		}
		const url = filePass + fileName;
		// ファイル存在チェック
		const FileExistFlag = isFileExist(url);
		if (FileExistFlag) {
			getTopics(url)
		} else {
			setOfferAttentionMessage(errMsg);
		}
	}

	const getTopics = async (url) => {
		try {
			const topics = await getFileDataText(url);
			setOfferAttentionMessage(topics);
		} catch (error) {
			console.error(error);
		}
	}

	return (
		<>
			<div className="detail-body__attention">
				<pre>
					{offerAttentionMessage}
				</pre>
			</div>
		</>
	);
}

export default OfferAttention;
