import {
  GET_OFFER_REQUEST,
  GET_SINGLE_OFFER_SUCCESS,
  GET_SINGLE_OFFER_FAILURE,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_OFFERS_SEARCH_FAILURE,
  GET_PURCHASED_OFFER_FAILURE,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  GET_PLAYER_FAILURE,
  SET_IS_STREAMING,
  DONE_PAGE_LOADING
} from "../actions/offer"

const initialState = {
  isFetching: false,
  isStreaming: false,
  donePageLoading: false,
  offers: null,
  singleOffer: null,
  hash: null,
  key: null,
  script: null
}

const offers = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_OFFER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: actions.error
      }
    case GET_SINGLE_OFFER_SUCCESS:
      if (actions.secrets !== null)
        return {
          ...state,
          isFetching: false,
          singleOffer: { ...actions.offer, state: null },
          hash: actions.secrets.hash,
          key: actions.secrets.key,
          error: actions.error,
          script: actions.secrets.script_url,
          script4K: actions.secrets.script_url_4_k,
          scriptMulti: actions.secrets.script_url_multi,
          scriptCaption: actions.secrets.script_url_caption
        }
      return {
        ...state,
        isFetching: false,
        singleOffer: { ...actions.offer, state: null },
        hash: null,
        script: null,
        key: null,
        error: actions.error
      }

    case GET_SINGLE_OFFER_FAILURE:
      return {
        ...state,
        isFetching: false,
        singleOffer: [],
        hash: null,
        script: null,
        key: null,
        error: actions.err
      }
    case GET_OFFERS_SUCCESS:
      return { ...state, isFetching: false, offers: actions.offers , error: actions.error}
    case GET_OFFERS_FAILURE:
      return { ...state, isFetching: false, error: actions.error }
    case GET_OFFERS_SEARCH_FAILURE:
      return { ...state, isFetching: false, offers: [], error: actions.error }
    case GET_PURCHASED_OFFER_FAILURE:
      return { ...state, isFetching: false, offers: [], error: actions.error }
    case GET_PLAYER_REQUEST:
      return { ...state, isFetching: true, hash: null, key: null, script: null , error: actions.error}
    case GET_PLAYER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hash: actions.res.hash,
        key: actions.res.key,
        error: actions.error,
        script: actions.secrets.script_url,
        script4K: actions.secrets.script_url_4_k,
        scriptMulti: actions.secrets.script_url_multi,
        scriptCaption: actions.secrets.script_url_caption
      }
    case GET_PLAYER_FAILURE:
      return {
        ...state,
        isFetching: false,
        hash: null,
        key: null,
        script: null,
        err: actions.err
      }
    case SET_IS_STREAMING:
      return {
        ...state,
        isStreaming: actions.isStreaming
      }
    case DONE_PAGE_LOADING:
      return {
        ...state,
        donePageLoading: actions.donePageLoading
      }
    default:
      return state
  }
}

export default offers
