import { all, index } from 'mathjs';
import React, { useEffect, useState, useRef } from 'react';
import Card from '../Card/Card';
import Cardm from '../Card/Cardm';
import OfferListTopData from './OfferListTopDate';
import ReactPaginate from 'react-paginate';
import Const from '../../common/Const'
import MetaFieldConst from '../../common/MetaFieldConst'
import BackendResponsePerformanceCategoryConst from '../../common/BackendResponsePerformanceCategoryConst';

function ShowCase(props) {

	// ====== 定数 ======
	//リッカルド・ムーティ・アカデミー抽出用シリーズ名
	const specialPerformanceTitle = 'アカデミー聴講プログラム／リハーサル';

	const perPage = parseInt(process.env.REACT_APP_MSP_PERFORMANCE_MAX_DISP_COUNT, 10); // 1ページあたりに表示したいアイテムの数
	const marginPagesDisplayed = parseInt(process.env.REACT_APP_PAGINATE_MARGIN_PAGES_DISPLAYED, 10); // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
	const pageRangeDisplayed = parseInt(process.env.REACT_APP_PAGINATE_PAGE_RANGE_DISPLAYED, 10); // 現在のページの前後をいくつページ数を表示させるか

	const searchDone = 'searchDone'
	const purchasedDone = 'purchasedDone';
	const purchasedRerun = 'purchasedRerun';
	const purchasedArchive = 'purchasedArchive';
	const dayover = 'dayover';
	const archive = 'archive';
	const value = 'value';
	const key = 'key';
	const offset = 'offset';
	const text = 'text';
	const textEn = 'textEn';
	const selected = 'selected';
	const dayoverAll = 'dayoverAll';
	const dayoverRerun = 'dayoverRerun';
	const dayoverArchive = 'dayoverArchive';
	const searchRerun = 'searchRerun';
	const searchArchive = 'searchArchive';
	const dayoverAllText = '全て';
	const dayoverAllTextEn = 'All';
	const rerunText = '見逃し配信';
	const rerunTextEn = 'Rerun';
	const archiveText = 'アーカイブ配信';
	const archiveTextEn = 'Archive';
	const performanceDoneText = '終了した公演';
	const performanceDoneTextEn = 'Finished performance';
	const performancesListText = '配信前 / 配信中公演';
	const performancesListTextEn = 'WaitStreaming / Streaming';
	const childTabIdAll = 'All'

	// ====== useState ======
	const [categories, setCategories] = useState([]);
	const [allPerformances, setAllPerformances] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [performancesCount, setPerformancesCount] = useState(0);
	const [purchasedPerformance, setPurchasedPerformancesCount] = useState(0);
	const [searchPerformance, setSearchPerformancesCount] = useState(0);
	const selectedTab = useRef("");
	const selectedChildTabId = useRef("");

	const [dispChildTabFlg, setDispChildTabFlg] = useState(false);
	const [childTabList, setChildTabList] = useState([]);
	const [isDescSort, setIsDescSort] = useState(false);

	useEffect(() => {
		let temp = [];
		// 連想配列を使って公演情報をカテゴリー毎に分類する
		let tempPerformances = [];
		let tempArray = {};
		if (props.offers !== null) {
			// 公演済みオファー
			let donePerformances = props.offers.filter(performance => performance.is_done_performance && performance.performance_title !== specialPerformanceTitle);
			if (isDescSort) {
				donePerformances.sort(compareDesc);
			} else {
				donePerformances.sort(compare);
			}
			// 公演済みオファーを連想配列に入れる
			tempArray = { key: dayover, value: donePerformances, offset: getAllPerformancesOffset(dayover) };
			tempPerformances.push(tempArray);

			// 公演前オファー
			let performances = props.offers.filter(performance => !performance.is_done_performance && performance.performance_title !== specialPerformanceTitle);
			if (isDescSort) {
				performances.sort(compareDesc);
			} else {
				performances.sort(compare);
			}

			//ソートする要素別カテゴリー分け
			// 公演日別
			if (props.sortType === Const.date) {
				// カテゴリー毎に公演情報をまとめる
				for (const performance of performances) {
					temp.push(performance.performance_category);
				}
				//重複するカテゴリーの削除
				let categories = Array.from(new Set(temp));
				setCategories(categories);

				// カテゴリー毎に公演情報をまとめる
				categories.forEach(category => {
					tempArray = { key: category, value: performances.filter(performance => performance.performance_category === category), offset: getAllPerformancesOffset(category) };
					tempPerformances.push(tempArray);
				});

				//見逃し配信のオファー
				const RerunPerformances = donePerformances.filter(performance => performance.isLivePurchased && performance.performance_category === BackendResponsePerformanceCategoryConst.rerun);
				//見逃し配信のオファーを連想配列に入れる
				tempArray = { key: dayoverRerun, value: RerunPerformances, offset: getAllPerformancesOffset(dayoverRerun) };
				tempPerformances.push(tempArray);

				//アーカイブ配信のオファー
				const doneArchivePerformances = donePerformances.filter(performance => performance.performance_category === BackendResponsePerformanceCategoryConst.archive);
				//アーカイブ配信のオファーを連想配列に入れる
				tempArray = { key: archive, value: doneArchivePerformances, offset: getAllPerformancesOffset(archive) };
				tempPerformances.push(tempArray);

				//終了した公演タブ内で表示するアーカイブ配信のオファー
				tempArray = { key: dayoverArchive, value: doneArchivePerformances, offset: getAllPerformancesOffset(dayoverArchive) };
				//連想配列に入れる
				tempPerformances.push(tempArray);
				// 子タブ情報作成
				createChildTabList(tempPerformances)
			} else if (props.sortType === Const.purchased) {
				let isCountExist = false;
				// 公演前の購入済みオファーを連想配列に入れる
				tempArray = { key: Const.purchased, value: [], offset: getAllPerformancesOffset(Const.purchased) };
				tempArray[value] = performances.filter(performance => performance.isPurchased);
				tempPerformances.push(tempArray);
				//公演前の購入済みオファーの件数をセット
				if (tempArray[value].length > 0) {
					setPurchasedPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}

				//見逃し配信の購入済みオファーを連想配列に入れる
				tempArray = { key: purchasedRerun, value: [], offset: getAllPerformancesOffset(purchasedRerun) };
				tempArray[value] = donePerformances.filter(performance => performance.isLivePurchased && performance.performance_category === BackendResponsePerformanceCategoryConst.rerun);
				tempPerformances.push(tempArray);
				if (!isCountExist && tempArray[value].length > 0) {
					setPurchasedPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}

				//アーカイブ配信の購入済みオファーを連想配列に入れる
				tempArray = { key: purchasedArchive, value: [], offset: getAllPerformancesOffset(purchasedArchive) };
				tempArray[value] = donePerformances.filter(performance => performance.performance_category === BackendResponsePerformanceCategoryConst.archive);
				tempPerformances.push(tempArray);
				if (!isCountExist && tempArray[value].length > 0) {
					setPurchasedPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}
				// 公演済みの購入済みオファーを連想配列に入れる
				tempArray = { key: purchasedDone, value: [], offset: getAllPerformancesOffset(purchasedDone) };
				tempArray[value] = donePerformances.filter(performance => performance.isPurchased);
				tempPerformances.push(tempArray);
				//公演済みの購入済みオファーの件数をセット
				if (!isCountExist && tempArray[value].length > 0) {
					setPurchasedPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}
				// 子タブ情報作成
				createChildTabList(tempPerformances)

			} else if (props.sortType === Const.search) {
				let isCountExist = false;
				if (selectedChildTabId.current !== '' ) {
					isCountExist = true;
				}
				//検索時の公演前オファーを連想配列に入れる
				tempArray = { key: Const.search, value: performances, offset: getAllPerformancesOffset(Const.search) };
				tempPerformances.push(tempArray);
				//検索時の公演前オファーの件数をセット
				if (!isCountExist && performances.length > 0) {
					setSearchPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}

				//検索時の見逃し配信オファーを連想配列に入れる
				tempArray = { key: searchRerun, value: [], offset: getAllPerformancesOffset(searchRerun) };
				tempArray[value] = donePerformances.filter(performance => performance.isLivePurchased && performance.performance_category === BackendResponsePerformanceCategoryConst.rerun);
				tempPerformances.push(tempArray);
				if (!isCountExist && tempArray[value].length > 0) {
					setSearchPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}

				//検索時のアーカイブ配信オファーを連想配列に入れる
				tempArray = { key: searchArchive, value: [], offset: getAllPerformancesOffset(searchArchive) };
				tempArray[value] = donePerformances.filter(performance => performance.performance_category === BackendResponsePerformanceCategoryConst.archive);
				tempPerformances.push(tempArray);
				if (!isCountExist && tempArray[value].length > 0) {
					setSearchPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}

				//検索時の公演済みオファーを連想配列に入れる
				tempArray = { key: searchDone, value: donePerformances, offset: getAllPerformancesOffset(searchDone) };
				tempPerformances.push(tempArray);
				if (!isCountExist && tempArray[value].length > 0) {
					setSearchPerformancesCount(tempArray[value].length);
					isCountExist = true;
				}
				// 子タブ情報作成
				createChildTabList(tempPerformances)
			}
			setAllPerformances(tempPerformances);
			props.donePageLoading(true);
		}
	}, [isDescSort]);
	useEffect(() => {
		setKeyword(props.keyword);
	}, []);
	useEffect(
		() => {
			if ((props.sortType === Const.search || props.sortType === Const.purchased) && selectedChildTabId.current === '') {
				//子タブが存在するタブを選んだときのみ実行
				setTimeout(tagInitChild(), 1000);
			} else if (document.getElementsByClassName('home-tab') &&
				props.keyword === undefined && selectedChildTabId.current === '') {
				setTimeout(tagInit(selectedTab.current), 1000);
				getPerformancesCount(selectedTab.current);
			}
		}
	);
	//選択されている親タブに変化があった場合
	useEffect(() => {
		//子タブの先頭を選択状態にする
		setTimeout(tagInitChild(selectedTab.current), 1000);
	}, [selectedTab.current]);

	// 子タブリスト作成
	const createChildTabList = (performances) => {
		let childTaglists = [];
		let childTab = {};

		if (props.sortType === Const.date) {
			// 終了した公演
			performances.forEach(performance => {
				if (performance.key === dayoverRerun && performance[value].length > 0) {
					childTab = { key: dayoverRerun, text: rerunText, textEn: rerunTextEn }
					childTaglists.push(childTab);
				} else if (performance.key === dayoverArchive && performance[value].length > 0) {
					childTab = { key: dayoverArchive, text: archiveText, textEn: archiveTextEn }
					childTaglists.push(childTab);
				}
			})
		} else if (props.sortType === Const.purchased) {
			// 購入済み
			let isPurchasedExsit = false
			let isDoneExist = false;
			performances.forEach(performance => {
				if (performance.key === Const.purchased && performance[value].length > 0) {
					childTab = { key: Const.purchased, text: performancesListText, textEn: performancesListTextEn }
					childTaglists.push(childTab);
					isPurchasedExsit = true;
				} else if (performance.key === purchasedRerun && performance[value].length > 0) {
					childTab = { key: purchasedRerun, text: rerunText, textEn: rerunTextEn }
					childTaglists.push(childTab);
					isDoneExist = true;
				} else if (performance.key === purchasedArchive && performance[value].length > 0) {
					childTab = { key: purchasedArchive, text: archiveText, textEn: archiveTextEn }
					childTaglists.push(childTab);
					isDoneExist = true;
				} else if (performance.key === purchasedDone && performance[value].length > 0) {
					childTab = { key: purchasedDone, text: performanceDoneText, textEn: performanceDoneTextEn }
					childTaglists.push(childTab);
					isDoneExist = true;
				}
			})
			if (!isPurchasedExsit && !isDoneExist) {
				// 購入済みが存在しない場合は、配信前／配信中の子タブのみ表示する
				childTab = { key: Const.purchased, text: performancesListText, textEn: performancesListTextEn }
				childTaglists.push(childTab);
			}
		} else if (props.sortType === Const.search) {
			// 検索結果
			performances.forEach(performance => {
				if (performance.key === Const.search && performance[value].length > 0) {
					childTab = { key: Const.search, text: performancesListText, textEn: performancesListTextEn }
					childTaglists.push(childTab);
				} else if (performance.key === searchRerun && performance[value].length > 0) {
					childTab = { key: searchRerun, text: rerunText, textEn: rerunTextEn }
					childTaglists.push(childTab);
				} else if (performance.key === searchArchive && performance[value].length > 0) {
					childTab = { key: searchArchive, text: archiveText, textEn: archiveTextEn }
					childTaglists.push(childTab);
				} else if (performance.key === searchDone && performance[value].length > 0) {
					childTab = { key: searchDone, text: performanceDoneText, textEn: performanceDoneTextEn }
					childTaglists.push(childTab);
				}
			})
		}
		setChildTabList(childTaglists);
	}

	//ページングコンポーネントのページボタンをクリック時
	const PerformancesPageChange = (selectedTab, data) => {
		let page_number = data[selected]; // クリックした部分のページ数-1が{selected: 2}のような形で返ってくる
		let tab = getNowSelectedTab(selectedTab);
		// offsetを変更し、表示開始するアイテムの番号を変更
		setAllPerformancesOffset(tab, page_number);
		window.document.documentElement.scrollTop = 0;
	}

	const getNowSelectedTab = (selectedTab) => {
		let tab = '';
		let _today = new Date();
		let this_month = _today.getFullYear() + Const.slash + (_today.getMonth() + 1) + Const.slash;
		let _show = document.getElementById('T_' + this_month);
		if (selectedTab) {
			tab = selectedTab;
		} else if (_show) {
			tab = this_month;
		} else {
			//先頭タブのIDを取得する。
			let _tags = document.getElementsByClassName('home-tabs')[0];
			if (_tags) {
				tab = _tags.firstElementChild.id;
			}
		}
		return tab;
	}

	const tagFocus = (e) => {
		//子タブの選択状態を空にする
		if (selectedChildTabId.current) {
			selectedChildTabId.current = ''
		}
		resetTags();
		e.target.classList.add('home-tab--active');
		e.target.parentElement.classList.add('home-tab--active');
		//選択したタブのIDを保持
		selectedTab.current = e.target.id;
		//選択されたタブが終了した公演タブならON
		if (selectedTab.current === dayover) {
			setDispChildTabFlg(true)
		} else {
			setDispChildTabFlg(false)
		}

		Object.values((document.getElementsByClassName('day-tag'))).forEach(
			(elm) => {
				elm.classList.remove('show');
			}
		);
		document.getElementById('T_' + e.target.id).classList.add('show');

		getPerformancesCount(selectedTab.current);
		return false;
	}
	const tagFocusChild = (e) => {
		resetTagsChild();
		//クリックされた子タブを選択する
		e.target.classList.add('home-tab-child--active');
		e.target.parentElement.classList.add('home-tab-child--active');
		//表示されている公演情報を非表示にする
		Object.values((document.getElementsByClassName('day-tag'))).forEach(
			(elm) => {
				elm.classList.remove('show');
			}
		);

		let dispListKey = e.target.id
		//子タブの内容を全て表示するタブがクリックされたか判定する
		if (e.target.id.includes(childTabIdAll)) {
			//親タブの条件にのみ一致するIDを生成
			dispListKey = e.target.id.substring(0, e.target.id.length - childTabIdAll.length)

		}
		let dispID = 'T_' + dispListKey
		document.getElementById(dispID).classList.add('show');
		selectedChildTabId.current = e.target.id
		//表示するリストの件数を取得する
		getPerformancesCount(dispListKey);
		return false;
	}
	const tagInitChild = () => {
		resetTagsChild();
		let _tags = document.getElementsByClassName('home-tabs-child')[0];
		// 先頭の子タブを選択状態にする
		if (_tags) {
			let _tag = _tags.firstElementChild;
			if (_tag) {
				_tag.classList.add('home-tab-child--active');
				selectedChildTabId.current = _tag.id
				if (_tag.id !== '') {
					let _sec_id = 'T_' + _tag.id;
					//子タブの内容を全て表示するタブがクリックされたか判定する
					if (_tag.id.includes(childTabIdAll)) {
						//親タブの条件にのみ一致するIDを生成
						_sec_id = _tag.id.substring(0, _tag.id.length - childTabIdAll.length)
					}
					document.getElementById(_sec_id).classList.add('show');
				}
			}
		}
	}

	// ソート
	const sortClick = (sort) => {
		let isDesc = !sort;
		if (isDesc) {
			document.getElementById('sort').classList.remove('sort-icon');
            document.getElementById('sort').classList.add('sort-icon-desc');
		} else {
			document.getElementById('sort').classList.remove('sort-icon-desc');
            document.getElementById('sort').classList.add('sort-icon');
		}
		setIsDescSort(isDesc);
	}

	//タブ毎の公演件数をカウントする関数
	const getPerformancesCount = (selectedTab) => {
		let count = 0;
		let tab = getNowSelectedTab(selectedTab);
		//選択されているタブ情報を元に公演情報の件数を取得する。
		for (const temp of allPerformances) {
			if (tab !== '' && temp[key] === tab) {
				let PerformancesArray = temp[value];
				count = PerformancesArray.length;
				break;
			}
		}
		if (props.sortType === Const.date) {
			setPerformancesCount(count);
		} else if (props.sortType === Const.purchased) {
			setPurchasedPerformancesCount(count);
		} else if (props.sortType === Const.search) {
			setSearchPerformancesCount(count);
		}
	}
	//全ての公演情報を保持している連想配列から指定したKeyのValueを取得する関数
	const getAllPerformancesValue = (specifiedKey) => {
		let paramKeyMatchPerformancesArray = [];
		for (const performances of allPerformances) {
			if (performances[key] === specifiedKey) {
				paramKeyMatchPerformancesArray = performances[value];
				break;
			}
		}
		return paramKeyMatchPerformancesArray;
	}
	//全ての公演情報を保持している連想配列から指定したKeyのoffsetを取得する関数
	const getAllPerformancesOffset = (specifiedKey) => {
		let paramKeyMatchOffset = 0;
		for (const performances of allPerformances) {
			if (performances[key] === specifiedKey) {
				paramKeyMatchOffset = performances[offset];
				break;
			}
		}
		return paramKeyMatchOffset;
	}
	//全ての公演情報を保持している連想配列から指定したKeyのoffsetを更新する関数
	const setAllPerformancesOffset = (specifiedKey, pageNum) => {
		for (const performances of allPerformances) {
			if (performances[key] === specifiedKey) {
				performances[offset] = (perPage * pageNum);
				break;
			}
		}
		//参照が同じだと再レンダリングが行われないため、値渡しをする。
		let updateAllPerformances = allPerformances.slice();
		setAllPerformances(updateAllPerformances);
	}
	if (!props.isEventEnded) {
		if (props.sortType === Const.search) {
			//公演前リスト
			const performancesArray = getAllPerformancesValue(Const.search);
			//公演前リストのオフセット
			const performancesOffset = getAllPerformancesOffset(Const.search);
			//見逃し配信リスト
			const rerunPerformancesArray = getAllPerformancesValue(searchRerun);
			//見逃し配信リストのオフセット
			const rerunPerformancesOffset = getAllPerformancesOffset(searchRerun);
			//アーカイブ配信リスト
			const archivePerformancesArray = getAllPerformancesValue(searchArchive);
			//アーカイブ配信リストのオフセット
			const archivePerformancesOffset = getAllPerformancesOffset(searchArchive);
			//公演済みリスト
			const donePerformancesArray = getAllPerformancesValue(searchDone);
			//公演済みリストのオフセット
			const donePerformancesOffset = getAllPerformancesOffset(searchDone);
			return (
				<>
					<section className="day">
						{props.sortType === Const.search ? (
							<div>
								<h2 className="home-title">{props.language === 'ja_JP' ? `「${keyword}」の検索結果` : `Search Result: ${keyword}`}
								</h2>
								{props.offers.length === 0 ? <div style={{ color: 'white', margin: '30px 10px' }}>{props.language === 'ja_JP' ?
									`キーワードに一致する結果が見つかりませんでした` : `No results were found that match your keywords`}
									<h2 className="perfomances-count-inline">{props.language === 'ja_JP' ? `${searchPerformance} 件` : `${searchPerformance} Results`}</h2></div> : ''}
								<div className='home-navi'>
									<ul className='home-tabs-child'>
										{childTabList.length > 0 ?
											childTabList.map((childTab,index) => {
												return (
													<li className='home-tab-child' id={childTab[key]} onClick={tagFocusChild} key={index}>
														<a id={childTab[key]} onClick={tagFocusChild} >
															{props.language === 'ja_JP' ? childTab[text] : childTab[textEn]}
														</a>
													</li>
												)
											})
											: ''}
									</ul>
									{props.offers.length > 0 ? 
										<span className='sort-count-inline'>
											<h2 className="perfomances-count-inline">{props.language === 'ja_JP' ? `${searchPerformance} 件` : `${searchPerformance} Results`}</h2>
											<div className="sort-icon" id="sort" onClick={() => sortClick(isDescSort)}/>
										</span>
									: ''}
								</div>
								{performancesArray.length > 0 ? (
									<section className="day-tag" id={'T_' + Const.search}>
										<div className="card-list">
											{performancesArray.slice(performancesOffset, performancesOffset + perPage)
												.map((offer, index) => {
													return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
												})}
										</div>
										<ReactPaginate
											pageCount={Math.ceil(performancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
											marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
											pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
											onPageChange={(e) => PerformancesPageChange(Const.search, e)} // クリック時のfunction
											containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
											pageClassName="page-item" // li
											activeClassName="page-item-selected"
											disabledClassName="page-item-disabeld"
											previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
											previousClassName="page-item"
											nextLabel={'>'} //次のページに進むボタンのテキスト
											nextClassName="page-item"
											breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
											breakClassName="page-item"
										></ReactPaginate>
									</section >
								) : ('')}
								{rerunPerformancesArray.length > 0 ? (
									<section className="day-tag" id={'T_' + searchRerun}>
										<div className="card-list">
											{rerunPerformancesArray.slice(rerunPerformancesOffset, rerunPerformancesOffset + perPage)
												.map((offer, index) => {
													return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
												})}
										</div>
										<ReactPaginate
											pageCount={Math.ceil(rerunPerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
											marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
											pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
											onPageChange={(e) => PerformancesPageChange(searchRerun, e)} // クリック時のfunction
											containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
											pageClassName="page-item" // li
											activeClassName="page-item-selected"
											disabledClassName="page-item-disabeld"
											previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
											previousClassName="page-item"
											nextLabel={'>'} //次のページに進むボタンのテキスト
											nextClassName="page-item"
											breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
											breakClassName="page-item"
										></ReactPaginate>
									</section >
								) : ('')}
								{archivePerformancesArray.length > 0 ? (
									<section className="day-tag" id={'T_' + searchArchive}>
										<div className="card-list">
											{archivePerformancesArray.slice(archivePerformancesOffset, archivePerformancesOffset + perPage)
												.map((offer, index) => {
													return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
												})}
										</div>
										<ReactPaginate
											pageCount={Math.ceil(archivePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
											marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
											pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
											onPageChange={(e) => PerformancesPageChange(searchArchive, e)} // クリック時のfunction
											containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
											pageClassName="page-item" // li
											activeClassName="page-item-selected"
											disabledClassName="page-item-disabeld"
											previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
											previousClassName="page-item"
											nextLabel={'>'} //次のページに進むボタンのテキスト
											nextClassName="page-item"
											breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
											breakClassName="page-item"
										></ReactPaginate>
									</section >
								) : ('')}
								{donePerformancesArray.length > 0 ? (
									<section className="day-tag" id={'T_' + searchDone}>
										<div className="card-list">
											{donePerformancesArray.slice(donePerformancesOffset, donePerformancesOffset + perPage)
												.map((offer, index) => {
													return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
												})}
										</div>
										<ReactPaginate
											pageCount={Math.ceil(donePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
											marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
											pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
											onPageChange={(e) => PerformancesPageChange(searchDone, e)} // クリック時のfunction
											containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
											pageClassName="page-item" // li
											activeClassName="page-item-selected"
											disabledClassName="page-item-disabeld"
											previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
											previousClassName="page-item"
											nextLabel={'>'} //次のページに進むボタンのテキスト
											nextClassName="page-item"
											breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
											breakClassName="page-item"
										></ReactPaginate>
									</section >
								) : ('')}
							</div>
						) : (
							''
						)}
					</section>
				</>
			);
		}

		if (props.sortType === Const.date) {
			//公演終了リスト
			const donePerformancesArray = getAllPerformancesValue(dayover);
			//公演終了リストのオフセット
			const donePerformancesOffset = getAllPerformancesOffset(dayover);

			//見逃し配信リスト
			const rerunPerformancesArray = getAllPerformancesValue(dayoverRerun);
			//見逃し配信リストのオフセット
			const rerunPerformancesOffset = getAllPerformancesOffset(dayoverRerun);

			//アーカイブリスト
			const archivePerformancesArray = getAllPerformancesValue(archive);
			//アーカイブリストのオフセット
			const archivePerformancesOffset = getAllPerformancesOffset(archive);

			//アーカイブリスト
			const dayoverArchivePerformancesArray = getAllPerformancesValue(dayoverArchive);
			//アーカイブリストのオフセット
			const dayoverArchivePerformancesOffset = getAllPerformancesOffset(dayoverArchive);

			return (
				<>
					<div className='home-navi'>
						<ul className='home-tabs'>
							{categories.map((category,index) => {
								return (
									<li className='home-tab' id={category} onClick={tagFocus}  key={index} >
										<a id={category} onClick={tagFocus} >
											<OfferListTopData date={category} language={props.language} />
										</a>
									</li>
								)
							})}
							{archivePerformancesArray.length > 0 ? (
								<li className='home-tab' id={'archive'} onClick={tagFocus} >
									<a id='archive' onClick={tagFocus} >
										{props.language === 'ja_JP' ? 'アーカイブ配信' : 'Archive'}
									</a>
								</li>
							) : (
								''
							)}

							{donePerformancesArray.length > 0 ? (
								<li className='home-tab' id={'dayover'} onClick={tagFocus} >
									<a id='dayover' onClick={tagFocus} >
										{props.language === 'ja_JP' ? '終了した公演' : 'Finished performance'}
									</a>
								</li>
							) : (
								''
							)}
							<li className='home-tab'>
								<a id='purchased' href={props.selectedDistributorId === null ? Const.hrefPurchased : Const.hrefPurchased + Const.addDistributorQuery + props.selectedDistributorId} title="購入済み">
									{props.language === 'ja_JP' ? '購入済み' : 'Purchased'}
								</a>
							</li>
						</ul>
						{/* 終了した公演内の子タブ*/}
						{selectedTab.current === dayover && dispChildTabFlg ? (
							<ul className='home-tabs-child'>
								{(donePerformancesArray.length > 0 && (rerunPerformancesArray.length > 0 || archivePerformancesArray.length > 0)) ? (
									<li className='home-tab-child' id={dayoverAll} onClick={tagFocusChild}>
										<a id={dayoverAll} onClick={tagFocusChild} >
											{props.language === 'ja_JP' ? dayoverAllText : dayoverAllTextEn}
										</a>
									</li>
								) : (''
								)}
								{childTabList.length > 0 ?
									childTabList.map((childTab,index) => {
										return (
											<li className='home-tab-child' id={childTab[key]} onClick={tagFocusChild} key={index}>
												<a id={childTab[key]} onClick={tagFocusChild} >
													{props.language === 'ja_JP' ? childTab[text] : childTab[textEn]}
												</a>
											</li>
										)
									})
									: ''}
							</ul>
						) : (
							''
						)}
						<div className="perfomances-count">
							<label >
								{props.language === 'ja_JP' ? `${performancesCount} 件` : `${performancesCount} Results`}
							</label>
						</div>
					</div>
					{categories.map((category,index) => {
						//連想配列からKeyがカテゴリに一致するリストを取得
						//公演前リスト
						const performancesArray = getAllPerformancesValue(category);
						//公演前リストのオフセット
						const performancesOffset = getAllPerformancesOffset(category);
						return (
							<section className="day-tag" id={'T_' + category} key={index}>
								<div className="card-list">
									{performancesArray.length > 0
										? performancesArray.slice(performancesOffset, performancesOffset + perPage)
											.map((offer, index) => {
												return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
											})
										: ''}
								</div>
								{performancesArray.length > 0
									? <ReactPaginate
										pageCount={Math.ceil(performancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
										marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
										pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
										onPageChange={(e) => PerformancesPageChange(selectedTab.current, e)} // クリック時のfunction
										containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
										pageClassName="page-item" // li
										activeClassName="page-item-selected"
										disabledClassName="page-item-disabeld"
										previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
										previousClassName="page-item"
										nextLabel={'>'} //次のページに進むボタンのテキスト
										nextClassName="page-item"
										breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
										breakClassName="page-item"
									></ReactPaginate>
									: ''}
							</section>
						);
					})}
					{/* アーカイブ配信タブ*/}
					{
						archivePerformancesArray.length > 0 ? (
							<section className="day-tag" id={'T_' + archive}>
								<div className="card-list">
									{archivePerformancesArray.slice(archivePerformancesOffset, archivePerformancesOffset + perPage)
										.map((offer, index) => {
											return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
										})}
								</div>
								<ReactPaginate
									pageCount={Math.ceil(archivePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
									marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
									pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
									onPageChange={(e) => PerformancesPageChange(selectedTab.current, e)} // クリック時のfunction
									containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
									pageClassName="page-item" // li
									activeClassName="page-item-selected"
									disabledClassName="page-item-disabeld"
									previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
									previousClassName="page-item"
									nextLabel={'>'} //次のページに進むボタンのテキスト
									nextClassName="page-item"
									breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
									breakClassName="page-item"
								></ReactPaginate>
							</section >

						) : (
							''
						)
					}
					{/* 終了した公演タブ(全て)*/}
					{
						donePerformancesArray.length > 0 ? (
							<label>
								<section className="day-tag" id={'T_' + dayover}>
									{/*}							<h2 className="day-title">{props.language === 'ja_JP' ? '終了した公演' : 'Finished performance'}</h2> */}
									<div className="card-list">
										{donePerformancesArray.slice(donePerformancesOffset, donePerformancesOffset + perPage)
											.map((offer, index) => {
												return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
											})}
									</div>
									<ReactPaginate
										pageCount={Math.ceil(donePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
										marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
										pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
										onPageChange={(e) => PerformancesPageChange(dayover, e)} // クリック時のfunction
										containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
										pageClassName="page-item" // li
										activeClassName="page-item-selected"
										disabledClassName="page-item-disabeld"
										previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
										previousClassName="page-item"
										nextLabel={'>'} //次のページに進むボタンのテキスト
										nextClassName="page-item"
										breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
										breakClassName="page-item"
									></ReactPaginate>
								</section>
							</label>
						) : (
							''
						)
					}
					{/*終了した公演タブ(見逃し配信) */}
					{
						rerunPerformancesArray.length > 0 ? (
							<label>
								<section className="day-tag" id={'T_' + dayoverRerun}>
									{/*}							<h2 className="day-title">{props.language === 'ja_JP' ? '終了した公演' : 'Finished performance'}</h2> */}
									<div className="card-list">
										{rerunPerformancesArray.slice(rerunPerformancesOffset, rerunPerformancesOffset + perPage)
											.map((offer, index) => {
												return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
											})}
									</div>
									<ReactPaginate
										pageCount={Math.ceil(rerunPerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
										marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
										pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
										onPageChange={(e) => PerformancesPageChange(dayoverRerun, e)} // クリック時のfunction
										containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
										pageClassName="page-item" // li
										activeClassName="page-item-selected"
										disabledClassName="page-item-disabeld"
										previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
										previousClassName="page-item"
										nextLabel={'>'} //次のページに進むボタンのテキスト
										nextClassName="page-item"
										breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
										breakClassName="page-item"
									></ReactPaginate>
								</section>
							</label>
						) : (
							''
						)
					}
					{/*終了した公演タブ(アーカイブ配信)*/}
					{
						dayoverArchivePerformancesArray.length > 0 ? (
							<section className="day-tag" id={'T_' + dayoverArchive}>
								<div className="card-list">
									{dayoverArchivePerformancesArray.slice(dayoverArchivePerformancesOffset, dayoverArchivePerformancesOffset + perPage)
										.map((offer, index) => {
											return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
										})}
								</div>
								<ReactPaginate
									pageCount={Math.ceil(dayoverArchivePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
									marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
									pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
									onPageChange={(e) => PerformancesPageChange(dayoverArchive, e)} // クリック時のfunction
									containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
									pageClassName="page-item" // li
									activeClassName="page-item-selected"
									disabledClassName="page-item-disabeld"
									previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
									previousClassName="page-item"
									nextLabel={'>'} //次のページに進むボタンのテキスト
									nextClassName="page-item"
									breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
									breakClassName="page-item"
								></ReactPaginate>
							</section >
						) : (
							''
						)
					}
				</>
			);
		}

		if (props.sortType === Const.purchased) {
			//公演前リスト
			const performancesArray = getAllPerformancesValue(Const.purchased);
			//公演前リストのオフセット
			const performancesOffset = getAllPerformancesOffset(Const.purchased);
			//見逃し配信リスト
			const rerunPerformancesArray = getAllPerformancesValue(purchasedRerun);
			//見逃し配信リストのオフセット
			const rerunPerformancesOffset = getAllPerformancesOffset(purchasedRerun);
			//購入済み公演アーカイブリスト
			const archivePerformancesArray = getAllPerformancesValue(purchasedArchive);
			//購入済み公演アーカイブリストのオフセット
			const archivePerformancesOffset = getAllPerformancesOffset(purchasedArchive);
			//公演済みリスト
			const donePerformancesArray = getAllPerformancesValue(purchasedDone);
			//公演済みリストのオフセット
			const donePerformancesOffset = getAllPerformancesOffset(purchasedDone);
			return (
				<>
					<section className="day">
						<div className='home-navi'>
							<ul className='home-tabs-child'>
								{childTabList.length > 0 ?
									childTabList.map((childTab,index) => {
										return (
											<li className='home-tab-child' id={childTab[key]} onClick={tagFocusChild} key={index}>
												<a id={childTab[key]} onClick={tagFocusChild} >
													{props.language === 'ja_JP' ? childTab[text] : childTab[textEn]}
												</a>
											</li>
										)
									})
									: ''}
							</ul>
							<h2 className="perfomances-count-inline">{props.language === 'ja_JP' ? `${purchasedPerformance} 件` : `${purchasedPerformance} Results`}</h2>
						</div>

						{performancesArray.length > 0 || donePerformancesArray.length === 0 ? (
							<section className="day-tag" id={'T_' + Const.purchased}>
								{performancesArray.length > 0 ?
									<>
										<div className="card-list">
											{performancesArray.slice(performancesOffset, performancesOffset + perPage)
												.map((offer, index) => {
													if (!offer.isPurchased) {
														return '';
													}
													return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
												})}
										</div>
										<ReactPaginate
											pageCount={Math.ceil(performancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
											marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
											pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
											onPageChange={(e) => PerformancesPageChange(Const.purchased, e)} // クリック時のfunction
											containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
											pageClassName="page-item" // li
											activeClassName="page-item-selected"
											disabledClassName="page-item-disabeld"
											previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
											previousClassName="page-item"
											nextLabel={'>'} //次のページに進むボタンのテキスト
											nextClassName="page-item"
											breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
											breakClassName="page-item"
										></ReactPaginate>
									</>
									: ''}
							</section>
						) : ('')}

						{/* 購入済みタブ（見逃し配信） */}
						{rerunPerformancesArray.length > 0 ? (
							<section className="day-tag" id={'T_' + purchasedRerun}>
								<div className="card-list">
									{rerunPerformancesArray.slice(rerunPerformancesOffset, rerunPerformancesOffset + perPage)
										.map((offer, index) => {
											if (!offer.isPurchased) {
												return '';
											}
											return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
										})}
								</div>
								<ReactPaginate
									pageCount={Math.ceil(rerunPerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
									marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
									pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
									onPageChange={(e) => PerformancesPageChange(purchasedRerun, e)} // クリック時のfunction
									containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
									pageClassName="page-item" // li
									activeClassName="page-item-selected"
									disabledClassName="page-item-disabeld"
									previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
									previousClassName="page-item"
									nextLabel={'>'} //次のページに進むボタンのテキスト
									nextClassName="page-item"
									breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
									breakClassName="page-item"
								></ReactPaginate>
							</section >
						) : ('')}
						{/* 購入済みタブ（アーカイブ配信） */}
						{archivePerformancesArray.length > 0 ? (
							<section className="day-tag" id={'T_' + purchasedArchive}>
								<div className="card-list">
									{archivePerformancesArray.slice(archivePerformancesOffset, archivePerformancesOffset + perPage)
										.map((offer, index) => {
											if (!offer.isPurchased) {
												return '';
											}
											return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
										})}
								</div>
								<ReactPaginate
									pageCount={Math.ceil(archivePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
									marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
									pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
									onPageChange={(e) => PerformancesPageChange(purchasedArchive, e)} // クリック時のfunction
									containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
									pageClassName="page-item" // li
									activeClassName="page-item-selected"
									disabledClassName="page-item-disabeld"
									previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
									previousClassName="page-item"
									nextLabel={'>'} //次のページに進むボタンのテキスト
									nextClassName="page-item"
									breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
									breakClassName="page-item"
								></ReactPaginate>
							</section >
						) : ('')}
						{/* 購入済みタブ（終了した公演） */}
						{donePerformancesArray.length > 0 ? (
							<section className="day-tag" id={'T_' + purchasedDone}>
								<div className="card-list">
									{donePerformancesArray.slice(donePerformancesOffset, donePerformancesOffset + perPage)
										.map((offer, index) => {
											if (!offer.isPurchased) {
												return '';
											}
											return <Card key={index} offerJson={offer} language={props.language} smallLogoUrl={props.smallLogoUrl} query={props.query} selectedDistributorId={props.selectedDistributorId}/>;
										})}
								</div>
								<ReactPaginate
									pageCount={Math.ceil(donePerformancesArray.length / perPage)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
									marginPagesDisplayed={marginPagesDisplayed} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
									pageRangeDisplayed={pageRangeDisplayed} // 現在のページの前後をいくつページ数を表示させるか
									onPageChange={(e) => PerformancesPageChange(purchasedDone, e)} // クリック時のfunction
									containerClassName="pagination_ul" // ページネーションであるulに着くクラス名
									pageClassName="page-item" // li
									activeClassName="page-item-selected"
									disabledClassName="page-item-disabeld"
									previousLabel={'<'}//前のページ番号に戻すリンクのテキスト
									previousClassName="page-item"
									nextLabel={'>'} //次のページに進むボタンのテキスト
									nextClassName="page-item"
									breakLabel={'...'} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
									breakClassName="page-item"
								></ReactPaginate>
							</section >
						) : ('')}
					</section>
				</>
			);
		}
	}
	return '';
}

export default ShowCase;

const compare = (a, b) => {
	let dateObjectA = {
		date: null,
	};
	let dateObjectB = {
		date: null,
	};
	a.meta_struct.forEach(meta => {
		if (meta.meta_field_name === MetaFieldConst.performanceStartTime) {
			dateObjectA.date = meta.value;
		}
	});
	b.meta_struct.forEach(meta => {
		if (meta.meta_field_name === MetaFieldConst.performanceStartTime) {
			dateObjectB.date = meta.value;
		}
	});

	let dateA = new Date(dateObjectA.date);
	let dateB = new Date(dateObjectB.date);

	let comparison = 0;
	if (dateA > dateB) {
		comparison = 1;
	} else if (dateA < dateB) {
		comparison = -1;
	}
	return comparison;
};

const compareDesc = (a, b) => {
	let dateObjectA = {
		date: null,
	};
	let dateObjectB = {
		date: null,
	};
	a.meta_struct.forEach(meta => {
		if (meta.meta_field_name === MetaFieldConst.performanceStartTime) {
			dateObjectA.date = meta.value;
		}
	});
	b.meta_struct.forEach(meta => {
		if (meta.meta_field_name === MetaFieldConst.performanceStartTime) {
			dateObjectB.date = meta.value;
		}
	});

	let dateA = new Date(dateObjectA.date);
	let dateB = new Date(dateObjectB.date);

	let comparison = 0;
	if (dateA > dateB) {
		comparison = -1;
	} else if (dateA < dateB) {
		comparison = 1;
	}
	return comparison;
};

const tagInit = (selectedTab) => {
	resetTags();
	//	let this_month = (new Date()).toLocaleDateString().substring(0,7);
	let _today = new Date();
	let this_month = _today.getFullYear() + Const.slash + (_today.getMonth() + 1) + Const.slash;
	let _show = document.getElementById('T_' + this_month);
	if (selectedTab) {
		document.getElementById(selectedTab).classList.add('home-tab--active');
	} else if (_show) {
		_show.classList.add('show');
		document.getElementById(this_month).classList.add('home-tab--active');
	} else {
		let _tags = document.getElementsByClassName('home-tabs')[0];
		if (_tags) {
			let _tag = _tags.firstElementChild;
			_tag.classList.add('home-tab--active');
			if (_tag.id !== '') {
				let _sec_id = 'T_' + _tag.id;
				document.getElementById(_sec_id).classList.add('show');
			}
		}
	}
	window.document.documentElement.scrollTop = 0;
}

const resetTags = () => {
	Object.values((document.getElementsByClassName('home-tab'))).forEach(
		(elm) => {
			elm.classList.remove('home-tab--active');
			if (elm.children.length > 0) elm.children[0].classList.remove('home-tab--active');
		}
	);
}

const resetTagsChild = () => {
	Object.values((document.getElementsByClassName('home-tab-child'))).forEach(
		(elm) => {
			elm.classList.remove('home-tab-child--active');
		}
	);
}