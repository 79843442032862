import axios from 'axios';

export const GET_CUSTOMIZE_REQUEST = 'GET_CUSTOMIZE_REQUEST';
const getCustomizeRequest = error => {
	return {
		type: GET_CUSTOMIZE_REQUEST,
		error: error,
	};
};

export const GET_CUSTOMIZE_SUCCESS = 'GET_CUSTOMIZE_SUCCESS';
const getCustomizeSuccess = (json, err) => {
	return {
		type: GET_CUSTOMIZE_SUCCESS,
		customize: json,
		error: err,
	};
};

export const GET_CUSTOMIZE_FAILURE = 'GET_CUSTOMIZE_FAILURE';
const getCustomizeFailure = err => {
	return {
		type: GET_CUSTOMIZE_FAILURE,
		error: err,
	};
};

// カスタマイズ情報取得
export const getCustomize = (distributorId) => {
	return dispatch => {
		dispatch(getCustomizeRequest());

		axios
		.get(process.env.REACT_APP_API_DIR_URL + `v1/customize?distributor_id=${distributorId}`)
		.then(res => {
			dispatch(getCustomizeSuccess(res.data));
		})
		.catch(err => {
			dispatch(getCustomizeFailure(err));
		});
	}
}
