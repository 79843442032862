import axios from 'axios';
import Cookies from 'js-cookie';
import Const from '../common/Const'
import {encodeKeyword} from '../common/CommonUtil'

export const GET_OFFER_REQUEST = 'GET_OFFER_REQUEST';
const getOfferRequest = error => {
	return {
		type: GET_OFFER_REQUEST,
		error: error,
	};
};

export const GET_SINGLE_OFFER_SUCCESS = 'GET_SINGLE_OFFER_SUCCESS';
const getSingleOfferSuccess = (json, secrets, error) => {
	return {
		type: GET_SINGLE_OFFER_SUCCESS,
		offer: json,
		secrets: secrets,
		error: error,
	};
};

export const GET_SINGLE_OFFER_FAILURE = 'GET_SINGLE_OFFER_FAILURE';
const getSingleOfferFailure = err => {
	return {
		type: GET_SINGLE_OFFER_FAILURE,
		err: err,
	};
};

export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
const getOffersSuccess = (json, err) => {
	return {
		type: GET_OFFERS_SUCCESS,
		offers: json,
		error: err,
	};
};

export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE';
const getOffersFailure = err => {
	return {
		type: GET_OFFERS_FAILURE,
		error: err,
	};
};

export const GET_OFFERS_SEARCH_FAILURE = 'GET_OFFERS_SEARCH_FAILURE';
const getOfferSearchFailure = err => {
	return {
		type: GET_OFFERS_SEARCH_FAILURE,
		error: err,
	};
};

export const GET_PURCHASED_OFFER_FAILURE = 'GET_PURCHASED_OFFER_FAILURE';
const getPurchasedOfferFailure = err => {
	return {
		type: GET_PURCHASED_OFFER_FAILURE,
		error: err,
	};
};

export const getOffers = (isLoggedIn, distributorId = null) => {
	return dispatch => {
		if (isLoggedIn !== null) {
			dispatch(getOfferRequest());
			// オファー一覧を取得
			let url = process.env.REACT_APP_API_DIR_URL + 'v1/ensemble'
			if (distributorId !== null) {
				url += Const.newDistributorQueryNonSlash + distributorId
			}
			axios
				.get(url)
				.then(res => {
					let offers = res.data.sort(compare);
					checkPayment(offers, isLoggedIn, dispatch);
				})
				.catch(err => dispatch(getOffersFailure(err)));
		}
	};
};

export const getSingleOffer = (isLoggedIn, offer_id, user_token = null) => {
	let utp = getCookie('__utp');
	if (user_token !== null) {
		utp = user_token;
	}
	return dispatch => {
		dispatch(getOfferRequest());
		axios
			.get(process.env.REACT_APP_API_DIR_URL + `v1/ensemble/${offer_id}`)
			.then(offer => {
				if (isLoggedIn || user_token !== null) {
					let params = new URLSearchParams()
					params.append("jwt",utp)
					axios
						.post(
							process.env.REACT_APP_API_DIR_URL + `v1/ensemble/${offer_id}/payment`,params
						)
						.then(res => {
							if (res.data.is_ok) {
								dispatch(getSingleOfferSuccess({ ...offer.data, isPurchased: true, isLivePurchased: res.data.is_live_purchased, isArchivePurchased: res.data.is_archive_purchased}, res.data));
							} else {
								dispatch(getSingleOfferSuccess({ ...offer.data, isPurchased: false, isLivePurchased: false, isArchivePurchased: false}, null));
							}
						})
						.catch(err => {
							console.log(err);
							dispatch(getSingleOfferSuccess({ ...offer.data, isPurchased: false, isLivePurchased: false, isArchivePurchased: false}, null, err));
						});
				} else if (isLoggedIn === false) {
					dispatch(getSingleOfferSuccess({ ...offer.data, isPurchased: false, isLivePurchased: false, isArchivePurchased: false}, null));
				}
			})
			.catch(err => {
				dispatch(getSingleOfferFailure(err));
			});
	};
};

export const searchOffers = (keyword, isLoggedIn, distributorId = null) => {
	return dispatch => {
		dispatch(getOfferRequest());
		let url = process.env.REACT_APP_API_DIR_URL + 'v1/search?q=' + encodeKeyword(keyword)
		if (distributorId !== null) {
			url += Const.addDistributorQuery + distributorId
		}
		axios
			.get(url)
			.then(res => {
				let offers = res.data.sort(compare);
				checkPayment(offers, isLoggedIn, dispatch);
			})
			.catch(err => {
				console.log(err);
				dispatch(getOfferSearchFailure(err));
			});
	};
};

export const getPurchasedOffers = (isLoggedIn, distributorId = null) => {
	let utp = getCookie('__utp');
	return dispatch => {
		dispatch(getOfferRequest());
		if (isLoggedIn) {
			let params = new URLSearchParams()
			params.append("jwt",utp)
			if (distributorId !== null) {
				params.append("distributor_id",distributorId)
			}
			axios
				.post(
					process.env.REACT_APP_API_DIR_URL + `v1/ensemble/paid`,params
				)
				.then(res => {
					let offers = res.data.sort(compare);
					offers = offers.map(offer => {
						return { ...offer, isPurchased: true, isLivePurchased: offer.is_live_purchased, isArchivePurchased: offer.is_archive_purchased};
					});
					dispatch(getOffersSuccess(offers));
				})
				.catch(err => {
					dispatch(getPurchasedOfferFailure(err));
				});
		} else {
			let err = 'notLoggedIn';
			dispatch(getPurchasedOfferFailure(err));
		}
	};
};

const checkPayment = (offers, isLoggedIn, dispatch) => {
	let utp = getCookie('__utp');
	if (isLoggedIn) {
		let params = new URLSearchParams()
		params.append("jwt",utp)
		axios
			.post(
				process.env.REACT_APP_API_DIR_URL + `v1/ensemble/paid`,params
			)
			.then(res => {
				let data = [];
				offers.forEach(offer => {
					let flag = false;
					let isLivePurchased = false;
					let isArchivePurchased = false;
					if (res.data !== null) {
						res.data.forEach(purchasedOffer => {
							if ((purchasedOffer.performance_id === offer.performance_id) &&
							(purchasedOffer.distributor_id === offer.distributor_id)) {
								flag = true;
								isLivePurchased = purchasedOffer.is_live_purchased;
								isArchivePurchased = purchasedOffer.is_archive_purchased;
							}
						});
						if (flag) {
							data = data.concat([{ ...offer, isPurchased: true, isLivePurchased: isLivePurchased, isArchivePurchased: isArchivePurchased}]);
						} else {
							data = data.concat([{ ...offer, isPurchased: false, isLivePurchased: false, isArchivePurchased: false}]);
						}
					} else {
						data = data.concat([{ ...offer, isPurchased: false, isLivePurchased: false, isArchivePurchased: false}]);
					}
				});
				dispatch(getOffersSuccess(data));
			})
			.catch(err => {
				dispatch(getPurchasedOfferFailure(err));
				let data = offers.map(offer => {
					return { ...offer, isPurchased: false, isLivePurchased: false, isArchivePurchased: false };
				});
				dispatch(getOffersSuccess(data));
			});
	} else {
		let data = offers.map(offer => {
			return { ...offer, isPurchased: false, isLivePurchased: false, isArchivePurchased: false };
		});
		dispatch(getOffersSuccess(data));
	}
};

export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const getPlayerRequest = () => {
	return {
		type: GET_PLAYER_REQUEST,
	};
};

export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const getPlayerSuccess = json => {
	return {
		type: GET_PLAYER_SUCCESS,
		res: json,
	};
};

export const GET_PLAYER_FAILURE = 'GET_PLAYER_FAILURE';
export const getPlayerFailure = err => {
	return {
		type: GET_OFFERS_FAILURE,
		err: err,
	};
};

export const SET_IS_STREAMING = 'SET_IS_STREAMING';
export const setIsStreaming = isStreaming => {
	return {
		type: SET_IS_STREAMING,
		isStreaming: isStreaming,
	};
};

export const DONE_PAGE_LOADING = 'DONE_PAGE_LOADING';
export const donePageLoading = donePageLoading => {
	return {
		type: DONE_PAGE_LOADING,
		donePageLoading: donePageLoading,
	};
};
//公演日 + 公演開始時間でソート
const compare = (a, b) => {
	let dateObjectA = {
		date: null,
		time: null,
	};
	let dateObjectB = {
		date: null,
		time: null,
	};
	a.meta_struct.forEach(meta => {
		if (meta.meta_field_name === 'performance_date') {
			dateObjectA.date = meta.value;
		}
		if (meta.meta_field_name === 'performance_start_time') {
			dateObjectA.time = meta.value;
		}
	});
	b.meta_struct.forEach(meta => {
		if (meta.meta_field_name === 'performance_date') {
			dateObjectB.date = meta.value;
		}
		if (meta.meta_field_name === 'performance_start_time') {
			dateObjectB.time = meta.value;
		}
	});

	let dateA = new Date(dateObjectA.date + ' ' + dateObjectA.time);
	let dateB = new Date(dateObjectB.date + ' ' + dateObjectB.time);

	let comparison = 0;
	if (dateA > dateB) {
		comparison = 1;
	} else if (dateA < dateB) {
		comparison = -1;
	}
	return comparison;
};

//ソート確認用
const confirmSort = data => {
	data.forEach(offer => {
		offer.meta_struct.forEach(meta => {
			if (meta.meta_field_name === 'performance_date') console.log(meta.value);
		});
	});
};

function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}
