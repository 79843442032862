import React from 'react';

function OfferSurvey(props) {
	if ((props.survey_link_text !== '' && props.survey_link_text !== undefined) && (props.survey_link_url !== '' && props.survey_link_url !== undefined)) {
		return (
			<>
				{props.language === 'ja_JP' ? (
					<a className="detail-body__surveylink" href={props.survey_link_url} title={props.survey_link_text} target="_blank" rel="noreferrer">
						{props.survey_link_text}
					</a>
				) : (
					''
				)}
				{props.language === 'en_US' ? '' : ''}
			</>
		);
	} else {
		return '';
	}
}

export default OfferSurvey;
