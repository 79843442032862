import React, { useEffect } from 'react';
import { donePageLoading, setIsStreaming } from '../actions/offer';
import { connect } from 'react-redux';

function Closed(props) {
	useEffect(() => {
		props.setIsStreaming(false);
		props.donePageLoading(true);
	}, []);

	return (
		<>
			<style>
				{`
					.guide {
						padding: 52px 0px 120px !important;
						text-align: center;
						font-size: 16px;
					}
					.guide-section {
						max-width: 100% !important;
						text-align: center;
					}
					.guide-title { border-bottom: none; font-size: 2.0em; font-family:serif;}
					.guide-text { font-weight: normal; line-height: 2.0em; font-size: 1.2em;}
						.guide-text span { font-weight: bold; }
					.guide-text.black { width:80%; background-color: #0004; padding: 2rem; border-radius: 1rem; margin: 4rem 10%; }
					h2 { font-size: 2.0em; line-height: 1.6em; font-family:serif;}
					.guide-section p { line-height: 2.0em; }
					.mt { margin-top: 1.0em; }
					.guide-linkbox {
						padding: 2rem;
						width: 80%;
						margin: 4rem 10%;
					}
					.guide-linkbox a {
						text-decoration: none;
						color: white;
						font-weight: bold;
						font-size: 2rem;
					}
					@media screen and (max-width: 640px) {
						.guide { font-size: 12px;}
					}
					@media screen and (max-width: 480px) {
						.guide { font-size: 10px; }
					}
				`}
			</style>
			{props.language === 'ja_JP' ? (
				<section className="guide" id="guide-basic">
					<h1 className="common-title">東京･春･音楽祭 Live Streaming 2024</h1>
					<section className="guide-section">
						<p className="guide-text"><span>東京･春･音楽祭 LIVE Streaming 2024</span>をお楽しみいただき誠にありがとうございました。</p>
						<p className="guide-text"><span>東京･春･音楽祭</span> に関する情報は<a href="https://www.tokyo-harusai.com/" target="_blank">公式サイト</a>からご覧ください。</p>
						<div className="guide-linkbox">
							東京･春･音楽祭 公式サイト<br/><br/>
							<a href="https://www.tokyo-harusai.com/">https://www.tokyo-harusai.com</a>

						</div>
						<p className="guide-text black">
							東京･春･音楽祭 LIVE Streaming 2024に関するお問い合わせは<a href="/mypage">こちら</a>からログイン後、「ヘルプ」よりお問い合わせください。<br/>
						</p>
					</section>
				</section>
			) : (
				<section className="guide" id="guide-basic">
					<h1 className="common-title">Spring Festival in Tokyo 2024</h1>
					<section className="guide-section">
						<p className="guide-text">Thank you very much for enjoying Spring Festival in Tokyo LIVE Streaming 2024.</p>
						<p className="guide-text">Please visit <a href="https://www.tokyo-harusai.com/" target="_blank">Spring Festival in Tokyo Official Site</a> for more information.</p>
						<div className="guide-linkbox">
						Spring Festival in Tokyo Official Site<br/><br/>
							<a href="https://www.tokyo-harusai.com/">https://www.tokyo-harusai.com/</a>
						</div>
						<p className="guide-text black">
							For inquiries about Tokyo Spring Music Festival LIVE Streaming 2024,&nbsp;
							go to <a href="/mypage">
								My Page
							</a>
							&nbsp;and click "Help" after logged in to contact us.<br/>
						</p>
					</section>
				</section>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		language: state.piano.language,
		donePageLoading: state.offer.donePageLoading,
	};
};

const mapDispatchToProps = dispatch => ({
	setIsStreaming: state => dispatch(setIsStreaming(state)),
	donePageLoading: state => dispatch(donePageLoading(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Closed);
