import {
    GET_PROFESSIONAL_DISTRIBUTORS_REQUEST,
    GET_PROFESSIONAL_DISTRIBUTORS_SUCCESS,
    GET_PROFESSIONAL_DISTRIBUTORS_FAILURE,
    GET_PREMIUM_DISTRIBUTORS_REQUEST,
    GET_PREMIUM_DISTRIBUTORS_SUCCESS,
    GET_PREMIUM_DISTRIBUTORS_FAILURE
  } from "../actions/distributor"

  const initialState = {
    isProfessionalDistributorsFetching: false,
    professionalDistributors: null,
    isPremiumDistributorsFetching: false,
    premiumDistributors: null
  }

  const distributors = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_PROFESSIONAL_DISTRIBUTORS_REQUEST:
            return {
                ...state,
                isProfessionalDistributorsFetching: true,
                error: actions.error
            }
        case GET_PROFESSIONAL_DISTRIBUTORS_SUCCESS:
            return { ...state, isProfessionalDistributorsFetching: false, professionalDistributors: actions.professionalDistributors , error: actions.error}
        case GET_PROFESSIONAL_DISTRIBUTORS_FAILURE:
            return { ...state, isProfessionalDistributorsFetching: false, error: actions.error }
        case GET_PREMIUM_DISTRIBUTORS_REQUEST:
            return {
                ...state,
                isPremiumDistributorsFetching: true,
                error: actions.error
            }
        case GET_PREMIUM_DISTRIBUTORS_SUCCESS:
            return { ...state, isPremiumDistributorsFetching: false, premiumDistributors: actions.premiumDistributors , error: actions.error}
        case GET_PREMIUM_DISTRIBUTORS_FAILURE:
            return { ...state, isPremiumDistributorsFetching: false, error: actions.error }
        default:
            return state
    }
  }

  export default distributors